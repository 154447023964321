import { AbstractControl, ValidationErrors, AsyncValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export function inArrayAsyncValidator(validValues$: Observable<string[]>): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    return validValues$.pipe(
      map((validValues) => {
        const value = control.value;

        if (validValues.some((validValue) => validValue === value)) {
          return { inArray: true }; // Value not found in array, validation error
        } else {
          return null; // Value is valid, no error
        }
      })
    );
  };
}
