import { Component, Input, ChangeDetectionStrategy, TemplateRef, ContentChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Icon } from '@n/nui';

@Component({
  selector: 'ui-forms-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectComponent {
  @Input() parentForm!: FormGroup;
  @Input() controlName!: string;
  @Input() options: Array<string> | null = null;
  @Input() selectable: Array<string> | null = null;
  @Input() placeholder!: string;
  @Input() name = '';
  @Input() label = '';
  @Input() icon: Icon | null = null;
  @Input() errorBorderColor: string = 'hover:border-red-500 border-red-500';
  @ContentChild('errors') errors!: TemplateRef<any>;

  get control() {
    return this.parentForm.controls[this.controlName];
  }
}
