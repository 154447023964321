import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { CodeModel } from '@ngstack/code-editor';

@Component({
  selector: 'ui-forms-codearea',
  styleUrls: ['./codearea.component.scss'],
  templateUrl: './codearea.component.html'
})
export class CodeareaComponent implements AfterViewInit {
  theme = 'vs';

  isLoaded = false;

  @Input() model: CodeModel | null = null;
  _model: CodeModel | null = null;
  @Output() codeChanged = new EventEmitter<string>();

  options = {
    contextmenu: false,
    minimap: {
      enabled: false
    }
  };

  onCodeChanged(value: any) {
    this.codeChanged.emit(value);
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this._model = this.model;
    }, 0);
  }
}
