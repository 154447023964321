import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ServiceInstance } from '@tx/api';
import { Bucket } from '@tx/s3';
import { NotificationService } from '@tx/ui';

@Component({
  selector: 'tx-portal-bucket-item',
  templateUrl: './bucket-item.component.html',
  styleUrls: ['./bucket-item.component.scss']
})
export class BucketItemComponent implements OnInit {
  date: Date = new Date();

  @Input()
  serviceInstance!: ServiceInstance;

  @Input() bucket!: Bucket;

  manageBucket() {
    this.manage.emit(this.bucket);
  }
  @Output() manage: EventEmitter<Bucket> = new EventEmitter();

  constructor(private notificationService: NotificationService) {}

  onCopy(data: string) {
    this.notificationService.showInfo('Kopiert', data);
  }

  ngOnInit(): void {}
}
