import { Injectable } from '@angular/core';
import { ActiveToast, IndividualConfig, ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  // Toastr Doc https://www.npmjs.com/package/ngx-toastr
  constructor(private toastr: ToastrService) {}

  showSuccess(
    title: string,
    message: string,
    duration: number = 5000,
    override: Partial<IndividualConfig> | undefined = undefined
  ): ActiveToast<any> {
    return this.toastr.success(message, title, {
      timeOut: duration,
      tapToDismiss: true,
      toastClass: 'ngx-toastr telemaxx-toastr',
      ...override
    });
  }

  showError(
    title: string,
    message: string,
    duration: number = 5000,
    override: Partial<IndividualConfig> | undefined = undefined
  ): ActiveToast<any> {
    return this.toastr.error(message, title, {
      timeOut: duration,
      tapToDismiss: true,
      toastClass: 'ngx-toastr telemaxx-toastr',
      ...override
    });
  }

  showInfo(
    title: string,
    message: string,
    duration: number = 5000,
    override: Partial<IndividualConfig> | undefined = undefined
  ): ActiveToast<any> {
    return this.toastr.info(message, title, {
      timeOut: duration,
      tapToDismiss: true,
      toastClass: 'ngx-toastr telemaxx-toastr',
      ...override
    });
  }

  clear(id: number | undefined = undefined) {
    this.toastr.clear(id);
  }
}
