import { Component, HostListener, Input, OnInit } from '@angular/core';
import { event } from 'cypress/types/jquery';

@Component({
  selector: 'ui-cell-page-header',
  templateUrl: './page-header.component.html'
})
export class CellPageHeaderComponent implements OnInit {
  isPastViewpoint: boolean = false;
  isSubheadingTwoRows: boolean = false;
  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll() {
    this.isPastViewpoint = window.scrollY > 140
  }

  @Input() heading: string = '';
  @Input() subheading: string = '';

  ngOnInit(): void {
    this.isSubheadingTwoRows = this.subheading.length > 40
    this.isPastViewpoint = window.scrollY > 140
  }
}
