import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CustomCellRenderer } from '../base/custom-renderer.base';

@Component({
  selector: 'service-level-renderer',
  templateUrl: './service-level-renderer.component.html',
  styleUrls: ['./service-level-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceLevelRendererComponent extends CustomCellRenderer {
  public get content() {
    return this.data.value;
  }

  public get level() {
    switch (this.data.additionalData) {
      case 0:
        return 'B';
      case 1:
        return 'S';
      case 2:
        return 'G';
      case 3:
        return 'P';

      default:
        return 'B';
    }
  }

  public get levelColor() {
    switch (this.data.additionalData) {
      case 0:
        return 'bronze';
      case 1:
        return 'silver';
      case 2:
        return 'gold';
      case 3:
        return 'platin';

      default:
        return 'bronze';
    }
  }
}
