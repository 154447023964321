import { Component, OnInit } from '@angular/core';
import { Icon } from '@n/nui';

interface service {
  name: string,
  icon: Icon,
  listItems: Array<string>,
  link: string
}

@Component({
  selector: 'ui-cell-additional-services',
  templateUrl: './additional-services.component.html',
  styleUrls: ['./additional-services.component.scss'],
})
export class AdditionalServicesComponent implements OnInit {
  services: Array<service> = [{
    name: 'Colocation & Housing',
    icon: 'map-pin',
    listItems: [
      'Von der Höheneinheit über das Rack bis hin zum Cage',
      'DE-CIX & Hybrid Cloud geeignet',
      'Verfügbarkeit Strom & Klimatisierung: 99,9 %'
    ],
    link: 'https://www.telemaxx.de/rechenzentrum/colocation/colocation-kosten',
  },{
    name: 'Interconnection oder DE-CIX Connect',
    icon: 'globe-europe-africa',
    listItems: [
      'Redundante DE-CIX Connect Verbindung',
      'u.a. DE-CIX Services DirectCLOUD, GlobePEER, MAPS verfügbar',
      'Verfügbarkeit: 99,9 % pro Monat'
    ],
    link: 'https://www.telemaxx.de/it-services/interconnection/de-cix-connect',
  },{
    name: 'Compute Cloud',
    icon: 'cloud',
    listItems: [
      'Selfservice - virtuelle Maschinen ausführen und skalieren',
      'Transparentes Nutzungsbasiertes Preismodell - keine versteckten Kosten',
      'Premium Business SLA - 99,99 % pro Monat'
    ],
    link: 'https://www.telemaxx.de/it-services/cloud/telemaxx-cloud',
  },{
    name: 'Virtual Private Cloud (VPC)',
    icon: 'cloud-arrow-up',
    listItems: [
      'Hochverfügbare private Cloud Lösung',
      '100 % dedizierte Compute-Ressourcen',
      'Premium Business SLA - 99,99 % pro Monat'
    ],
    link: 'https://www.telemaxx.de/it-services/cloud/virtual-private-cloud',
  },{
    name: 'Managed Services',
    icon: 'code-bracket-square',
    listItems: [
      'Wir managen Ihr System: Firewall, Switch und vieles mehr.',
      '24/7 Überwachung',
      'Verfügbarkeit: 99,9 % pro Monat'
    ],
    link: 'https://www.telemaxx.de/it-services/managed-services',
  },{
    name: 'Telekommunikation und Business Internet',
    icon: 'server-stack',
    listItems: [
      'ab 100 Mbit/s bis 10 Gbit/s',
      'Redundante Wegeführung (redundanter Uplink)',
      'Verfügbarkeit: 99,9 % pro Monat'
    ],
    link: 'https://www.telemaxx.de/telekommunikation/internet/business-internet',
  }]

  timer: any = setInterval(() => {}, 10000)
  active: number = 0;
  activeService: service = this.services[this.active];
  currentState: '' | 'out' | 'in' = '';

  nextClick() {
    this.next();
    clearTimeout(this.timer);
    this.timer = setInterval(() => {
      this.next();
    }, 10000)
  }

  prevClick() {
    this.prev();
    clearTimeout(this.timer);
    this.timer = setInterval(() => {
      this.next();
    }, 10000)
  }

  next() {
    this.active = this.active + 1;
    if(this.active > this.services.length - 1) {
      this.active = 0;
    }
    this.setService();
  }

  prev() {
    this.active = this.active - 1;
    if(this.active < 0) {
      this.active = this.services.length - 1;
    }
    this.setService();
  }

  setService() {
    this.currentState = 'out';
    setTimeout(() => {
      this.activeService = this.services[this.active];
      this.currentState = 'in';
    }, 500)
    setTimeout(() => {
      this.currentState = '';
    }, 500)
  }

  openLink(link: string) {
    window.open(link, '_blank')?.focus();
  }

  ngOnInit(): void {
    this.timer = setInterval(() => {
      this.next();
    }, 10000)
  }
}
