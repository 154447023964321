<table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">
  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->
  <ng-container matColumnDef="position">
    <th mat-header-cell *matHeaderCellDef>No.</th>
    <td mat-cell *matCellDef="let element">
      <currency-renderer [data]="element.position"></currency-renderer>
    </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let element">{{ element.name }}</td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="weight">
    <th mat-header-cell *matHeaderCellDef>Weight</th>
    <td mat-cell *matCellDef="let element">{{ element.weight }}</td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="symbol">
    <th mat-header-cell *matHeaderCellDef>Symbol</th>
    <td mat-cell *matCellDef="let element">
      <tag-renderer [data]="{ value: element.symbol, meta: { verified: true } }"> </tag-renderer>
    </td>
  </ng-container>

  <!-- Level Column -->
  <ng-container matColumnDef="level">
    <th mat-header-cell *matHeaderCellDef>Level</th>
    <td mat-cell *matCellDef="let element">
      <service-level-renderer [data]="{ value: element.level, additionalData: element.level }"></service-level-renderer>
    </td>
  </ng-container>

  <!-- Created Column -->
  <ng-container matColumnDef="created">
    <th mat-header-cell *matHeaderCellDef>Created</th>
    <td mat-cell *matCellDef="let element">
      <date-renderer [data]="element.created"></date-renderer>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns"
    matRipple
    class="element-row"
    [cdkDetailRow]="row"
    [cdkDetailRowTpl]="tpl"
  ></tr>
</table>

<mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" length="50" aria-label="Select page of users"></mat-paginator>

<ng-template #tpl let-element>
  <!-- <div class="mat-row detail-row" [@detailExpand] style="overflow: hidden">
		The symbol for {{element.name}} is {{element.symbol}}
	</div> -->
</ng-template>
