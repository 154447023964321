import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'ui-forms-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss']
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToggleComponent {
  @Input() parentForm!: FormGroup;
  @Input() controlName!: string;
  @Input() label = '';
  @Input() description = '';
  @Input() id = '';
  @Input() readonly: boolean = false;

  changeValue() {
    if (this.parentForm.controls[this.controlName].disabled) return;
    let newValue = !this.parentForm.controls[this.controlName].value;
    this.parentForm.controls[this.controlName].setValue(newValue);
  }
}
