import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Output } from '@angular/core';
import { Sort } from '@angular/material/sort';
export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
  level: number;
  created: Date;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H', level: 1, created: new Date() },
  { position: 2, name: 'Helium', weight: 4.0026, symbol: 'He', level: 1, created: new Date() },
  { position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li', level: 2, created: new Date() },
  { position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be', level: 1, created: new Date() },
  { position: 5, name: 'Boron', weight: 10.811, symbol: 'B', level: 1, created: new Date() },
  { position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C', level: 3, created: new Date() },
  { position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N', level: 1, created: new Date() },
  { position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O', level: 1, created: new Date() },
  { position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F', level: 4, created: new Date() },
  { position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne', level: 1, created: new Date() }
];

@Component({
  selector: 'ui-table, [uiTable]',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('void', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('*', style({ height: '*', visibility: 'visible' })),
      transition('void <=> *', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class TableComponent {
  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol', 'level', 'created'];
  dataSource = ELEMENT_DATA;
  sortingChanged = new EventEmitter<Sort>();

  sortData(sort: any) {
    this.sortingChanged.emit(sort as Sort);
  }

  @Output()
  isExpansionDetailRow = (index: any, row: any) => row.hasOwnProperty('detailRow');
}
