<div class="relative inline-block text-left" #elem>
  <ui-button
    [color]="'gray'"
    [isOnlyIcon]="true"
    [size]="'xs'"
    [icon]="icon"
    (cclick)="toggleMenu()"
    aria-expanded="true"
    aria-haspopup="true"
  ></ui-button>

  <!-- menu -->
  <div
    *ngIf="isMenuOpen"
    class="absolute right-0 z-10 w-56 py-1 mt-2 origin-top-right bg-white shadow-lg rounded-xl"
    role="menu"
    aria-orientation="vertical"
    aria-labelledby="menu-button"
  >
    <ng-container *ngIf="hasContent">
      <div class="px-4 py-3" role="none">
        <ng-content></ng-content>
      </div>
      <hr />
    </ng-container>
    <div class="py-1 border-b border-gray-200 last:border-b-0" role="none" *ngFor="let menu of contextMenu">
      <button
        *ngFor="let item of menu"
        (click)="click(item.event)"
        class="flex items-center w-full gap-2 px-4 py-2 text-sm text-left outline-none focus:ring focus:ring-blue-500 focus:ring-offset-2"
        [ngClass]="item.isDanger ? 'text-red-500 hover:bg-red-100' : 'text-gray-600 hover:bg-gray-200'"
        role="menuitem"
      >
        <ui-icon [icon]="item.icon" class="w-4 h-4"></ui-icon>
        {{ item.label }}
      </button>
    </div>
  </div>
</div>
