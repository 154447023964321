import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { Icon } from '@n/nui';

@Component({
  selector: 'ui-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent {
  @Input()
  isFullWidth = false;

  @Input()
  label = '';

  @Input()
  isOnlyIcon = false;

  @Input()
  icon: Icon | undefined = undefined;

  @Input()
  color: 'goblin' | 'red' | 'amber' | 'lime' | 'blue' | 'electricblue' | 'purple' | 'ghost' | 'white' | 'gray' = 'gray';

  @Input()
  type: 'primary' | 'secondary' = 'primary';

  @Input()
  size: 'xs' | 'sm' | 'base' | 'lg' = 'base';

  @Input()
  align: 'left' | 'center' | 'right' = 'center';

  @Input()
  isLoading = false;

  @Input()
  isDisabled: Boolean | null = false;

  @Input()
  class = '';

  public get disabled(): boolean {
    if (this.isLoading === true || this.isDisabled === true) {
      return true;
    }
    return false;
  }

  // outputs
  @Output()
  cclick = new EventEmitter<Event>();
}
