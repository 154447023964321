<!-- service url -->
<div class="flex flex-col gap-4">
  <div class="flex items-center gap-4 xl:gap-8 justify-between">
    <ui-headline heading="Service-URL" size="lg" class="shrink-0"></ui-headline>
    <button (click)="copyServiceURL()" class="font-semibold text-lg leading-6 text-gray-500 hover:underline">
      https://s3.telemaxx.cloud
    </button>
    <ui-button
      [isOnlyIcon]="true"
      [size]="'sm'"
      [icon]="'clipboard'"
      [color]="'white'"
      (cclick)="copyServiceURL()"
    ></ui-button>
  </div>
  <hr class="my-2" />
  <!-- keynotes -->
  <div class="flex justify-center divide-x divide-gray-200" *ngIf="bucketData$ | async as _bucketData">
    <div class="flex flex-col items-center px-8 w-1/4">
      <span class="font-semibold text-2xl">{{ _bucketData.buckets }}</span>
      <span class="font-medium">Buckets</span>
    </div>
    <div class="flex flex-col items-center px-8 w-1/4">
      <span class="font-semibold text-2xl" *ngIf="serviceAccountLength$ | async as serviceAccountLength">{{
        serviceAccountLength
      }}</span>
      <span class="font-medium">User</span>
    </div>
    <div class="flex flex-col items-center px-8 w-1/4">
      <span class="font-semibold text-2xl">{{ _bucketData.files | number }}</span>
      <span class="font-medium">Files</span>
    </div>
    <div class="flex flex-col items-center px-8 w-1/4">
      <span class="font-semibold text-2xl">{{ _bucketData.size | bytes }}</span>
      <span class="font-medium">{{ _bucketData.size | bytes : true }}</span>
    </div>
  </div>
  <div class="w-full flex items-center justify-center" *ngIf="pieChartData.datasets[0].data.length > 0">
    <div class="max-w-lg w-full">
      <canvas baseChart class="chart" [data]="pieChartData" [type]="pieChartType" [options]="pieChartOptions"> </canvas>
    </div>
  </div>
</div>
