import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ui-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarComponent {
  @Input()
  firstname = '';

  @Input()
  lastname = '';

  @Input()
  avatar = '';

  @Input()
  size: 'sm' | 'base' | 'lg' = 'base';

  @Input()
  status: 'offline' | 'online' | 'busy' | 'away' = 'offline';

  public get initials(): string {
    if (this.firstname && this.lastname) {
      const initials: string = this.firstname.charAt(0) + this.lastname.charAt(0);
      return initials;
    }
    return 'TX';
  }

  public get name(): string {
    const name = this.firstname + ' ' + this.lastname;
    return name;
  }
}
