import { Component, OnInit } from '@angular/core';
import { PartyService } from './party.service';

@Component({
  selector: 'tx-portal-register',
  template: ''
})
export class RegisterComponent implements OnInit {
  partyService: PartyService;

  constructor(partyService: PartyService) {
    this.partyService = partyService;
  }

  ngOnInit(): void {}
}
