import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ContextMenuItem } from '@tx/ui';
import { OtpCredential, PartyService, UserCredentialMetadatas } from '../party.service';

@Component({
  selector: 'tx-portal-tfa',
  templateUrl: './tfa.component.html',
  styleUrls: ['./tfa.component.scss']
})
export class TfaComponent implements OnInit {
  partyService: PartyService;

  constructor(partyService: PartyService, private fb: FormBuilder) {
    this.partyService = partyService;
  }

  // devices: Array<any> = [];

  deviceToRename: UserCredentialMetadatas | null = null;

  contextMenuRename: ContextMenuItem[][] = [
    [{ label: 'Gerät umbenennen', icon: 'pencil-square', event: 'rename' }],
    [{ label: 'Gerät löschen', icon: 'v-trash', event: 'delete', isDanger: true }]
  ];

  contextMenuCancel: ContextMenuItem[][] = [
    [{ label: 'Abbrechen', icon: 'x-mark', event: 'cancel' }],
    [{ label: 'Gerät löschen', icon: 'v-trash', event: 'delete', isDanger: true }]
  ];

  renameForm = this.fb.group({
    deviceLabel: ['', Validators.required]
  });

  async removeOtpDevices(credentials: OtpCredential) {
    for (const device of credentials.userCredentialMetadatas) {
      this.partyService.deleteOtpDevice(device);
    }
  }

  renameDevice() {
    if (!this.deviceToRename || !this.renameForm.controls['deviceLabel'].value) return;
    this.partyService.updateOtpDeviceLabel(this.deviceToRename, this.renameForm.controls['deviceLabel'].value);
    this.renameForm.reset();
    this.deviceToRename = null;
  }

  contextMenuClick(event: string, device: UserCredentialMetadatas) {
    switch (event) {
      case 'rename':
        this.deviceToRename = device;
        this.renameForm.controls.deviceLabel.setValue(device.credential.userLabel);
        return;
      case 'delete':
        this.partyService.deleteOtpDevice(device);
        return;
      case 'cancel':
        this.deviceToRename = null;
        this.renameForm.reset();
        return;
      default:
        break;
    }
  }

  ngOnInit(): void {}
}
