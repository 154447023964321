import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ObjectStorageService } from './services/object-storage.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from './token.interceptor';
import { CodeEditorModule } from '@ngstack/code-editor';

@NgModule({
  declarations: [],
  exports: [],
  imports: [CommonModule, HttpClientModule, CodeEditorModule.forRoot()],

  providers: [
    ObjectStorageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ]
})
export class TxS3Module {}
