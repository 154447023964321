import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Bucket, ObjectStorageService } from '@tx/s3';
import { Observable, map, pipe, filter } from 'rxjs';

import {
  ChartConfiguration,
  ChartData,
  ChartEvent,
  ChartType,
  Plugin,
  PluginChartOptions,
  PluginOptionsByType
} from 'chart.js';
import { BaseChartDirective, baseColors } from 'ng2-charts';
import { environment } from '../../../../environments/environment';
import { NotificationService } from '@tx/ui';

@Component({
  selector: 'tx-portal-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  pieChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: (ttItem) => `${ttItem.label}: ${ttItem.parsed} MB`
        }
      },
      legend: {
        labels: {
          boxWidth: 12
        },
        display: true,
        position: 'bottom'
      }
    }
  };

  serviceURL: string = 'https://s3.telemaxx.cloud';
  copyServiceURL() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.serviceURL;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.notificationService.showInfo('In Zwischenablage kopiert', this.serviceURL);
  }

  pieChartData: ChartData<'pie', number[], string | string[]> = {
    labels: [],
    datasets: [
      {
        data: [],
        hoverBackgroundColor: environment.colors.cartjs.backgroundColor,
        backgroundColor: environment.colors.cartjs.backgroundColor,
        hoverBorderColor: 'rgb(60,140,60)'
      }
    ]
  };

  pieChartType: ChartType = 'pie';

  @Input() bucketList$!: Observable<Bucket[]>;
  @Input() serviceAccountLength$!: Observable<number>;

  @Input()
  bucketData$!: Observable<{ buckets: number; files: number; size: number }> | undefined;

  @Input() userList!: string[] | null;

  constructor(private s3Service: ObjectStorageService, private notificationService: NotificationService) {}

  openService(serviceUrl: string) {
    window.open(serviceUrl, '_blank');
  }

  resetChart() {
    this.pieChartData.labels = [];
    this.pieChartData.datasets[0].data = [];
  }

  ngOnInit() {
    this.bucketList$.pipe(filter((buckets) => buckets !== undefined && buckets.length > 0)).subscribe((buckets) => {
      this.resetChart();

      buckets = buckets.filter((bucket) => bucket.usage['rgw.main'] !== undefined);
      buckets = buckets.sort((a, b) => {
        let aSize = a.usage['rgw.main']?.['size_actual'] || 0;
        let bSize = b.usage['rgw.main']?.['size_actual'] || 0;
        return bSize - aSize;
      });

      const fistBuckets = buckets.slice(0, 10);
      const otherBuckets = buckets.slice(10);
      const otherBucketsSize = otherBuckets.reduce((acc, bucket) => {
        return acc + (bucket.usage['rgw.main']?.['size_actual'] || 0);
      }, 0);

      if (otherBucketsSize > 0) {
        fistBuckets.push({
          bucket: 'Andere',
          usage: {
            'rgw.main': {
              size_actual: otherBucketsSize
            }
          }
        } as Bucket);
      }

      fistBuckets.forEach((bucket) => {
        this.pieChartData.labels?.push(bucket.bucket);
        let number: number = bucket.usage['rgw.main']?.['size_actual'] / 1024 / 1024;

        this.pieChartData.datasets[0].data.push(Math.round(number * 1e2) / 1e2);
      });
      this.chart?.render();
    });
  }
}
