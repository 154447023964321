<div #elem>
  <label *ngIf="label" class="block text-sm font-medium text-gray-700 mb-2">{{ label }}</label>
  <div class="relative">
    <div
      class="relative flex flex-wrap w-full gap-2 py-2 pl-3 pr-12 bg-white border border-gray-300 rounded-md outline-none focus:border-gray-300 focus-within:ring focus-within:ring-offset-2 focus-within:ring-blue-500 sm:text-sm"
    >
      <ui-button
        (cclick)="removeSelectedValue(i)"
        *ngFor="let opt of selectedValues; let i = index"
        [size]="'xs'"
        [icon]="'x-mark'"
      >
        {{ opt.label }}
      </ui-button>
      <div class="flex-1">
        <input
          [formControl]="searchInput"
          (focus)="isOpen = true"
          class="w-full h-full bg-transparent outline-none appearance-none border-0 p-0 focus:ring-0"
        />
      </div>
      <button
        type="button"
        tabindex="-1"
        class="absolute inset-y-0 right-0 flex items-center px-2 rounded-r-md focus:outline-none"
        (click)="isOpen = !isOpen"
      >
        <ui-icon class="w-5 h-5 text-gray-400 hover:text-gray-500" [icon]="'chevron-up-down'"></ui-icon>
      </button>
    </div>

    <!-- dropdown -->
    <ul
      *ngIf="isOpen && (filteredOptions$ | async) as options"
      class="absolute z-10 w-full p-1 mt-1 overflow-auto text-base bg-white shadow-lg rounded-xl max-h-60 focus:outline-none sm:text-sm"
      role="listbox"
    >
      <li
        (click)="addAllValues(options)"
        *ngIf="showSelectAll"
        class="relative flex items-center gap-4 px-3 py-2 transition duration-200 ease-in-out rounded-lg cursor-pointer select-none hover:bg-gray-100 hover:text-blue-500"
        role="option"
      >
        <div class="flex flex-col">
          <span class="font-medium truncate">{{ allLabel }}</span>
          <!-- <span class="text-sm text-gray-500 truncate">Alle Services </span> -->
        </div>
      </li>
      <li
        (click)="selectedValuesPlain.includes(option.value) ? (isOpen = false) : addSelectedValue(option)"
        *ngFor="let option of options"
        class="relative flex items-center gap-4 px-3 py-2 transition duration-200 ease-in-out rounded-lg cursor-pointer select-none hover:bg-gray-100 hover:text-blue-500"
        role="option"
      >
        <span *ngIf="selectedValuesPlain.includes(option.value)" class="block w-2 h-2 bg-blue-500 rounded-full"></span>
        <div class="flex flex-col">
          <span class="font-medium truncate"> {{ option.label }} </span>
          <span class="text-sm text-gray-500 truncate">{{ option.description }} </span>
        </div>
      </li>
    </ul>
  </div>
</div>
