import { Injectable } from '@angular/core';
import {
  Company,
  CompanyFeatures,
  ContactPerson,
  Organisation,
  PeopleQuery,
  PeopleQueryVariables,
  Person,
  TxApi
} from '@tx/api';

import { OAuthService, AuthConfig, OAuthEvent } from 'angular-oauth2-oidc';
import { QueryRef } from 'apollo-angular/query-ref';
import { BehaviorSubject, merge, Observable, ReplaySubject, Subject } from 'rxjs';
import { map, switchMap, filter, first, pairwise } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { shareReplay, tap } from 'rxjs/operators';
import { cloneDeep } from '@apollo/client/utilities';

import { NotificationService } from '../../../../../libs/ui/src/lib/notification/notification.service';
import { DeleteOnePersonPerson, UpdatePersonRoles } from '../account-management/account-management.component';
import { AddBillingAddressOutput } from '../services/service-detail/service-contract/service-contract.component';
import { CreatePersonOutput } from '../account-management/create-person/create-person.component';
import {
  AddPersonAsContactPerson,
  CreatePersonAsContactPerson,
  EditPersonAsContactPerson
} from '../services/contact/contact.component';
import { CreateAndAddServiceUser } from '../shared/models';
import { AuthService } from './auth.service';

export type UserCredentialMetadatas = {
  credential: {
    id: string;
    type: 'otp' | 'password';
    userLabel: string;
    createdDate: string;
  };
};
export type OtpCredential = {
  type: 'otp' | 'password' | 'none';
  category: 'basic-authentication' | 'two-factor';
  createAction: 'CONFIGURE_TOTP' | 'UPDATE_PASSWORD';
  removeable: boolean;
  userCredentialMetadatas: UserCredentialMetadatas[];
};

type JwtToken = {
  sub: string;
  sid: string;
  email_verified: boolean;
  groups: string[];
  companies: any[];
  company: {
    id: string;
    name: string;
  };
  fulltenants: string[];
};

@Injectable({
  providedIn: 'root'
})
export class PartyService {
  constructor(
    private oauthService: OAuthService,
    private txApi: TxApi,
    private http: HttpClient,
    private notificationService: NotificationService,
    private authService: AuthService
  ) {
    this.personQuery = this.txApi.peopleWatch();
    this.watchLongPollingPeople();

    if (this.authService.hasValidToken()) {
      this._oauth$.next(this.oauthService.getIdentityClaims() as JwtToken);
    }
    // keep old behavior
    this.oauthService.events.subscribe((event) => {
      if (event instanceof OAuthEvent && event.type === 'token_received') {
        console.warn('OAuthEvent Object:', event);
        this._oauth$.next(this.oauthService.getIdentityClaims() as JwtToken);
      }
    });
  }

  personQuery!: QueryRef<PeopleQuery, PeopleQueryVariables>;

  personUpdateMutation!: any;

  organisation$!: Observable<Organisation>;

  hasMoreThanOneCompany$ = new BehaviorSubject(false);

  _oauth$ = new ReplaySubject<JwtToken>(1);
  oauth$ = this._oauth$.pipe(shareReplay(1));

  isAuthenticated$ = this.authService.canActivateProtectedRoutes$;

  private refreshTrigger$ = new Subject<void>();

  otpCredentials$: Observable<OtpCredential[]> = merge(this.oauth$, this.refreshTrigger$).pipe(
    switchMap((identityClaims) =>
      this.http
        .get<OtpCredential[]>(`${environment.oidc.issuer}/account/credentials`, {
          headers: {
            'content-type': 'application/json',
            authorization: `Bearer ${this.oauthService.getAccessToken()}`
          }
        })
        .pipe(map((data) => data.filter((c) => c.type === 'otp')))
    ),
    shareReplay()
  );

  // this is the logged in Account from us not keycloak
  // emits the current Person object of the logged in user
  person$: Observable<Person> = this.oauth$.pipe(
    filter((data) => {
      return data !== null;
    }),
    switchMap((identityClaims) => {
      return this.personQuery.valueChanges.pipe(
        map(({ data, loading }) => {
          return data.people.edges.map((n) => n.node);
        }),
        map(cloneDeep),
        map((data) => {
          let pers = data.find((person) => person.keycloakUser === identityClaims.sub) as Person;
          if (!pers) {
            this.devError(
              `Most likely there is no Person with sub id ${identityClaims.sub}`,
              'No User matches Keycloak Sub'
            );
          }

          return pers;
        })
      );
    }),
    shareReplay()
  );

  // all people of the current tenant
  people$: Observable<Person[]> = this.oauth$.pipe(
    filter((data) => data !== null),
    switchMap((identityClaims) => {
      return this.personQuery.valueChanges.pipe(
        map(({ data, loading }) => {
          return data.people.edges.map((n) => n.node) as Person[];
        })
      );
    }),
    shareReplay()
  );

  // all companies of the current tenant
  companies$: Observable<Company[]> = this.person$.pipe(
    map((person) => {
      this.hasMoreThanOneCompany$.next(person.companies.length > 1);
      return person.companies;
    })
  );

  sessions$: Observable<any> = this.oauth$.pipe(
    filter((data) => data !== null),
    switchMap((identityClaims) => {
      return this.http.get(`${environment.oidc.issuer}/account/sessions/devices`, {
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${this.oauthService.getAccessToken()}`
        }
      });
    }),
    shareReplay()
  );

  // the current tenant is the dynamic scope from company:* and must be included in the tenant scope
  // if the user logged in via a company:company_id scope the current tenant is available
  currentTenant$: Observable<{ id: string; name: string } | undefined> = this.oauth$.pipe(
    filter((data) => data !== null),
    map((identityClaims) => {
      return identityClaims.company;
    }),
    shareReplay()
  );

  currentCompany$: Observable<Company | undefined> = this.oauth$.pipe(
    filter((data) => data !== null),
    switchMap((identityClaims) => {
      return this.person$.pipe(
        map((person) => {
          return person.companies.find((c) => c.keycloakId === identityClaims.company.id) as Company;
        }),
        map(cloneDeep)
      );
    }),
    shareReplay()
  );

  // the current organisation - matched via tenant=company and recieved by the company->organisation relation
  organisations$: Observable<Organisation[]> = this.oauth$.pipe(
    filter((data) => data !== null),
    switchMap((identityClaims) => {
      return this.currentTenant$.pipe(
        switchMap((tenant) => {
          if (!tenant) {
            throw new Error('No Tenant selected');
          }

          return this.personQuery.valueChanges.pipe(
            map(({ data, loading }) => {
              return data.people.edges.map((n) => n.node);
            }),
            map((data) => {
              let pers = data.find((person) => person.keycloakUser === identityClaims.sub) as Person;
              return pers;
            }),
            map(cloneDeep),
            map((data) => {
              return data.companies.find((c) => c.keycloakId === tenant.id)?.organisations as Organisation[];
            })
          );
        })
      );
    }),
    shareReplay()
  );

  // checks assigned roles for administrator privileges
  isAdministrator$: Observable<boolean> = this.oauth$.pipe(
    filter((data) => data !== null),
    map((identityClaims) => {
      if (!identityClaims.groups) return false;
      return identityClaims.groups.includes(`Account Administrator`);
    }),
    shareReplay()
  );

  // Load the Company Features
  // checks for enabled Features on the Company
  enabledFeatures$: Observable<CompanyFeatures> = this.currentCompany$.pipe(
    map((comapny) => comapny?.features || {}),
    shareReplay()
  );

  verifyUserOnFirstLogin() {
    this.oauth$
      .pipe(
        filter((data) => data !== null),
        first()
      )
      .subscribe((identityClaims) => {
        // if the user is not verified we try this here.
        this.person$.pipe(first()).subscribe((pers) => {
          if (!identityClaims.email_verified || !pers.isKeycloakVerified) {
            this.verifyKeycloakUser(pers);
          }
        });
      });
  }

  // @deperecated use authService instead
  login(scope: string = '') {
    this.authService.loginWithScope(scope);
  }

  watchLongPollingPeople() {
    this.people$
      .pipe(
        pairwise(), // Pair the previous and current values
        filter(([previousValue, currentValue]) => {
          return currentValue.length !== previousValue.length;
        }),
        map(() => {
          this.personQuery.stopPolling();
        })
      )
      .subscribe();
  }

  devError(message: string, title: string = 'Notification', severity: string = 'error') {
    if (window.location.hostname !== 'my.telemaxx.de') {
      this.notificationService.showError(`[DEV] ${title}`, message, 3600 * 100);
    }
  }

  deleteSession() {
    return this.http
      .delete(`${environment.oidc.issuer}/account/sessions`, {
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${this.oauthService.getAccessToken()}`
        }
      })
      .subscribe((d) => {
        this.notificationService.showSuccess('Erfolg!', `Session wurde gelöscht`);
      });
  }

  async reset() {
    await this.oauthService.initLoginFlow('', { kc_action: 'UPDATE_PASSWORD' });
  }

  async configureTotp() {
    await this.oauthService.initLoginFlow('', { kc_action: 'CONFIGURE_TOTP' });
  }

  async deleteOtpDevice(device: UserCredentialMetadatas) {
    return this.http
      .delete<void>(`${environment.oidc.issuer}/account/credentials/${device.credential.id}`, {
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${this.oauthService.getAccessToken()}`
        }
      })
      .subscribe(() => {
        // @ToDo refactor
        //this.loadCredentials();
        this.refreshTrigger$.next();
        this.notificationService.showSuccess('Erfolg!', `2FA Gerät wurde entfernt`);
      });
  }
  async updateOtpDeviceLabel(device: UserCredentialMetadatas, label: string) {
    return this.http
      .put<void>(`${environment.oidc.issuer}/account/credentials/${device.credential.id}/label`, `"${label}"`, {
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${this.oauthService.getAccessToken()}`
        }
      })
      .subscribe((d) => {
        //this.loadCredentials();
        this.refreshTrigger$.next();
        this.notificationService.showSuccess('Erfolg!', `2FA Gerät wurde neu benannt`);
      });
  }

  async logout() {
    await this.oauthService.revokeTokenAndLogout();
  }

  updateCompanyOTPSettings() {
    this.txApi.toggleCompanyOtpRequirement().subscribe((data) => {
      if (!data.loading) {
        if (data.data?.toggleCompanyOTPRequirement.isOtpRequired) {
          this.notificationService.showSuccess('Erfolg!', `2FA wurde Firmenweit aktiviert`);
        }
        if (!data.data?.toggleCompanyOTPRequirement.isOtpRequired) {
          this.notificationService.showSuccess('Erfolg!', `2FA wurde Firmenweit deaktiviert`);
        }
        this.personQuery.refetch();
      }
    });
  }

  updatePersonNotificationSettings(person: Person, data: any) {
    this.personUpdateMutation = this.txApi
      .updateOnePerson({
        input: {
          id: person.id,
          update: {
            ...person,
            ...data
          }
        }
      })
      .subscribe((data) => {
        if (!data.loading) {
          this.notificationService.showSuccess('Erfolg!', `Dein Profil wurde aktualisiert`);
        }
      });
  }

  verifyKeycloakUser(person: Person) {
    this.http
      .post(`${environment.apiBasePath}/party/user/verify`, {
        person
      })
      .subscribe(() => {
        this.personQuery.refetch();
      });
  }
  updatePerson(person: any) {
    this.personUpdateMutation = this.txApi
      .updateOnePerson({
        input: {
          id: person.id,
          update: {
            ...person
          }
        }
      })
      .subscribe((data) => {
        if (!data.loading) {
          this.notificationService.showSuccess('Erfolg!', `Dein Profil wurde aktualisiert`);
        }
      });
  }
  createOnePerson(createPersonOutput: CreatePersonOutput) {
    let serviceOwnerOf = createPersonOutput.serviceOwnerOf || [];
    this.http
      .post(`${environment.apiBasePath}/party/user/register`, {
        username: createPersonOutput.email,
        ...createPersonOutput
      })
      .subscribe({
        next: (data: Person | any) => {
          if (serviceOwnerOf.length === 0) {
            this.notificationService.showSuccess('Erfolg!', `Benutzer wurde angelegt`);
            this.personQuery.startPolling(3000);
            return;
          }
          this.addServiceOwnerOfToPerson(data.id, serviceOwnerOf);
        },
        error: (error) => {
          this.notificationService.showError('Fehler!', error.error.message);
        }
      });
  }
  updateOneOrganisation(organisation: any) {
    this.txApi
      .updateOneOrganisation({
        input: {
          id: organisation.id,
          update: organisation
        }
      })
      .subscribe((data) => {
        if (!data.loading) {
          this.notificationService.showSuccess('Erfolg!', `Organisation wurde aktualisiert`);
        }
      });
  }

  upsertOneBillingAddress(event: AddBillingAddressOutput) {
    if (event.invoiceRecipient.id === '') {
      delete event.invoiceRecipient.id;
    }
    this.txApi
      .updateOneServiceInstance(
        {
          input: {
            id: event.id,
            update: event
          }
        },
        {
          refetchQueries: ['serviceInstances']
        }
      )
      .subscribe((data) => {
        if (!data.loading) {
          this.notificationService.showSuccess('Erfolg!', `Rechnungsempfänger wurde aktualisiert`);
        }
      });
  }

  async createOneAddress(organisation: Organisation) {
    const newAddress = {
      city: '',
      street: '',
      postal: '',
      companyName: '',
      email: '',
      firstName: '',
      lastName: '',
      country: '',
      organisationId: organisation.id
    };
    this.txApi
      .createOneAddress(
        {
          input: {
            address: newAddress
          }
        },
        {
          refetchQueries: ['people']
        }
      )
      .subscribe((data) => {
        if (!data.loading) {
          this.notificationService.showSuccess('Erfolg!', `Alternativer Rechnungsempfänger wurde hinzugefügt`);
        }
      });
  }
  removeContactPerson(dto: ContactPerson) {
    this.txApi
      .deleteOneContactPerson(
        {
          input: {
            id: dto.id
          }
        },
        {
          refetchQueries: ['serviceInstances']
        }
      )
      .subscribe((data) => {
        if (!data.loading) {
          this.notificationService.showSuccess(
            'Erfolg!',
            `Der Kontakt wurde entfernt der Benutzer ist nicht mehr verknüpft`
          );
        }
      });
  }
  addContactPerson(dto: AddPersonAsContactPerson) {
    this.txApi
      .createOneContactPerson(
        {
          contactPerson: {
            contactPersonFor: dto.for,
            personId: dto.id,
            note: dto.note,
            serviceInstanceId: dto.serviceInstanceId
          }
        },
        {
          refetchQueries: ['serviceInstances']
        }
      )
      .subscribe((data) => {
        if (!data.loading) {
          this.notificationService.showSuccess(
            'Erfolg!',
            `Der Kontakt wurde hinzugefügt und mit dem Benutzer verknüpft`
          );
        }
      });
  }
  editContactPerson(dto: EditPersonAsContactPerson) {
    this.txApi
      .updateOneContactPerson(
        {
          input: {
            id: dto.id,
            update: {
              note: dto.note,
              person: dto.person
            }
          }
        },
        {
          refetchQueries: ['serviceInstances']
        }
      )
      .subscribe((data) => {
        if (!data.loading) {
          this.notificationService.showSuccess('Erfolg!', `Der Kontakt wurde aktalisiert`);
        }
      });
  }
  addContact(dto: CreatePersonAsContactPerson) {
    this.txApi
      .createOneContactPerson(
        {
          contactPerson: {
            contactPersonFor: dto.for,
            note: dto.note,
            serviceInstanceId: dto.serviceInstanceId,
            person: dto.person
          }
        },
        {
          refetchQueries: ['serviceInstances']
        }
      )
      .subscribe((data) => {
        if (!data.loading) {
          this.notificationService.showSuccess('Erfolg!', `Der Kontakt wurde hinzugefügt`);
        }
      });
  }
  addServiceOwnerOfToPerson(personId: string, serviceInstanceIds: string[]) {
    this.txApi
      .addServiceOwnerOfToPerson(
        {
          id: personId,
          serviceInstanceIds: serviceInstanceIds
        },
        {
          refetchQueries: ['serviceInstances', 'people']
        }
      )
      .subscribe((data) => {
        if (!data.loading) {
          this.notificationService.showSuccess('Erfolg!', `Service-Owner wurde hinzugefügt`);
        }
      });
  }
  addServiceUserOfToPerson(personId: string, serviceInstanceIds: string[]) {
    this.txApi
      .addServiceUserOfToPerson(
        {
          id: personId,
          serviceInstanceIds: serviceInstanceIds
        },
        {
          refetchQueries: ['serviceInstances', 'people']
        }
      )
      .subscribe((data) => {
        if (!data.loading) {
          this.notificationService.showSuccess('Erfolg!', `Service-User wurde hinzugefügt`);
        }
      });
  }

  removeServiceOwnerOfToPerson(personId: string, serviceInstanceId: string) {
    this.txApi
      .removeServiceOwnerOfFromPerson(
        {
          id: personId,
          serviceInstanceIds: [serviceInstanceId]
        },
        {
          refetchQueries: ['serviceInstances', 'people']
        }
      )
      .subscribe((data) => {
        if (!data.loading) {
          this.notificationService.showSuccess('Erfolg!', `Service-Owner wurde entfernt`);
        }
      });
  }

  removeServiceUserOfFromPerson(personId: string, serviceInstanceId: string) {
    this.txApi
      .removeServiceUserOfFromPerson(
        {
          id: personId,
          serviceInstanceIds: [serviceInstanceId]
        },
        {
          refetchQueries: ['serviceInstances', 'people']
        }
      )
      .subscribe((data) => {
        if (!data.loading) {
          this.notificationService.showSuccess('Erfolg!', `Service-User wurde entfernt`);
        }
      });
  }

  createInstanceServiceOwner(person: Person, serviceInstanceId: string) {
    this.http
      .post(`${environment.apiBasePath}/party/user/register`, {
        username: person.email,
        email: person.email,
        firstName: person.firstName,
        lastName: person.lastName,
        companyName: person.companyName,
        phone: person.phone,
        mobilePhone: person.mobilePhone,
        gender: person.gender
      })
      .subscribe({
        next: (data: Person | any) => {
          this.addServiceOwnerOfToPerson(data.id, [serviceInstanceId]);
        },
        error: (error) => {
          this.notificationService.showError('Fehler!', error.error.message);
        }
      });
  }

  createPerson(person: Person) {
    return this.http.post(`${environment.apiBasePath}/party/user/register`, {
      username: person.email,
      email: person.email,
      firstName: person.firstName,
      lastName: person.lastName,
      companyName: person.companyName,
      phone: person.phone,
      mobilePhone: person.mobilePhone,
      gender: person.gender
    });
  }
  createServiceUser(dto: CreateAndAddServiceUser) {
    this.http
      .post(`${environment.apiBasePath}/party/user/register`, {
        username: dto.person.email,
        email: dto.person.email,
        firstName: dto.person.firstName,
        lastName: dto.person.lastName,
        companyName: dto.person.companyName,
        phone: dto.person.phone,
        mobilePhone: dto.person.mobilePhone,
        gender: dto.person.gender,
        serviceUserGroups: dto.serviceUserGroups || { data: {} }
      })
      .subscribe({
        next: (data: Person | any) => {
          this.addServiceUserOfToPerson(data.id, [dto.serviceInstanceId]);
        },
        error: (error) => {
          this.notificationService.showError('Fehler!', error.error.message);
        }
      });
  }

  deleteOnePerson(person: DeleteOnePersonPerson) {
    this.http
      .post(`${environment.apiBasePath}/party/user/delete`, {
        personId: person.personId,
        keycloakUser: person.keycloakUser
      })
      .subscribe({
        next: (data: Person | any) => {
          this.notificationService.showSuccess('Erfolg!', `Benutzer wurde gelöscht`);
          this.personQuery.refetch();
        },
        error: (error) => {
          this.notificationService.showError('Fehler!', error.error.message);
        }
      });
  }

  resendInviteTo(event: string) {
    this.http
      .post(`${environment.apiBasePath}/party/user/resendinvite`, {
        email: event
      })
      .subscribe((data) => {
        this.notificationService.showSuccess('Erfolg!', `Einladungs E-Mail wurde erneut gesendet`);
      });
  }

  async updatePersonRoles(event: UpdatePersonRoles) {
    let roles = [...event.keycloakRoles];

    const roleString = `Account Administrator`;

    if (event.isAdministrator && !roles.includes(roleString)) {
      await this.http.get(`${environment.apiBasePath}/party/user/${event.personId}/admin/add`).toPromise();
    }
    if (!event.isAdministrator && roles.includes(roleString)) {
      await this.http.get(`${environment.apiBasePath}/party/user/${event.personId}/admin/remove`).toPromise();
    }

    this.updatePerson({
      id: event.personId,
      email: event.email,
      serviceOwnerOf: event.serviceOwnerOf,
      companies: event.companies
    });
  }

  get scopes() {
    return this.oauthService.getGrantedScopes();
  }
}
