import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'permission'
})
export class PermissionPipe implements PipeTransform {
  transform(permission: string, ...args: number[]): string {
    switch (permission) {
      case 'READ':
        return 'Read';
      case 'WRITE':
        return 'Write';
      case 'READ_ACP':
        return 'ACL lesen';
      case 'WRITE_ACP':
        return 'ACL schreiben';
      case 'FULL_CONTROL':
        return 'Read / Write';
      default:
        return permission;
    }
  }
}
