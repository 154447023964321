import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CustomCellRenderer } from '../base/custom-renderer.base';

@Component({
  selector: 'action-renderer',
  templateUrl: './action-renderer.component.html',
  styleUrls: ['./action-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionRendererComponent extends CustomCellRenderer {
  public get content() {
    return this.data;
  }
}
