import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserRolesCardComponent } from './user-roles-card/user-roles-card.component';
import { UiModule } from '@tx/ui';
import { ServiceOwnerCardComponent } from './service-owner-card/service-owner-card.component';
import { UserCardComponent } from './user-card/user-card.component';



@NgModule({
  declarations: [ UserRolesCardComponent, ServiceOwnerCardComponent, UserCardComponent ],
  imports: [ CommonModule, UiModule ],
  exports: [ UserRolesCardComponent, ServiceOwnerCardComponent, UserCardComponent ]
})
export class SharedModule { }
