import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { CodeModel } from '@ngstack/code-editor';
import { Bucket } from '@tx/s3';
import { Person, ServiceInstance } from '@tx/api';
import { Observable, first, map } from 'rxjs';
import { RoutableBaseComponent } from '../../routable-base-modal';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NotificationService, Option, inArrayAsyncValidator, jsonValidator } from '@tx/ui';

@Component({
  selector: 'tx-portal-create-bucket-modal',
  templateUrl: './create-bucket-modal.component.html'
})
export class CreateBucketModalComponent extends RoutableBaseComponent {
  override modalId: string = 'create-bucket';

  constructor(
    private fb: FormBuilder,
    private notificationService: NotificationService,
    route: ActivatedRoute,
    router: Router
  ) {
    super(route, router);
    route.queryParamMap
      .pipe(
        map((params: ParamMap) => {
          return params.get('modal') === this.modalId;
        })
      )
      .subscribe((open) => {
        if (!open) {
          this.resetCreateBucketForm();
        }
      });
  }

  isCreateBucketModalLoading: boolean = false;

  @Input() serviceAccountOptions$!: Observable<Option[]>;
  @Input() bucketList$!: Observable<any[]>;

  @Input() serviceInstance!: ServiceInstance;

  @Output() closeCreateBucketModal: EventEmitter<any> = new EventEmitter();
  @Output() createBucket: EventEmitter<any> = new EventEmitter();
  @Output() jumpToServiceAccountsTab: EventEmitter<any> = new EventEmitter();

  selectPerson(person: Option) {
    this.createBucketForm.controls['serviceAccountName'].setValue(person.value);
    this.selectedPerson = person;
  }
  selectedPerson: Option | null = null;

  resetCreateBucketForm() {
    if (!this.createBucketForm) return;
    //this.createBucketForm.reset();
    this.selectedPerson = null;
    this.createBucketForm.patchValue({
      versioning: false,
      objectLocking: false,
      retention: false,
      retentionMode: 'COMPLIANCE',
      retentionValidity: 90,
      accessPolicy: 'private',
      name: '',
      serviceAccountName: '',
      code: '""'
    });
  }

  createBucketForm!: FormGroup;

  bucketNameValidators: Array<{ description: string; regex: RegExp }> = [
    {
      description: 'Der Bucket Bezeichner muss zwischen 3 und 63 Zeichen lang sein',
      regex: /^.{3,63}$/
    },
    {
      description: 'Der Bucket Bezeichner dürfen nicht einer IP gleichen (z.B. 192.168.5.4)',
      regex: /^((?!\b((?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(?:(?<!\.)\b|\.)){4}).)*$/
    },
    {
      description: "Der Bucket Bezeichner dürfen nicht mit dem Suffix '-s3alias' enden",
      regex: /^.+(?<!-s3alias)$/
    },
    {
      description: "Der Bucket Bezeichner dürfen nicht mit dem Präfix 'xn--' starten",
      regex: /^(?!xn--).+$/
    },
    {
      description: 'Der Bucket Bezeichner darf nur Kleinbuchstaben, Zahlen, Punkte und Bindestrichen enthalten',
      regex: /^[a-z0-9.-]*$/
    }
  ];

  testRegExp(regex: RegExp, string: string | null): boolean {
    if (string) return regex.test(string);
    return false;
  }

  accessPolicyOptions: string[] = ['private', 'authenticated-read', 'public-read', 'public-read-write'];
  retentionModeOptions: string[] = ['COMPLIANCE', 'GOVERNANCE'];

  // policyModel: CodeModel | null = null;

  createBucketWrapper() {
    let policyValue = `{
      "Version": "2012-10-17",
      "Statement": 
      [{
          "Effect": "Allow",
          "Principal": {"AWS": ["arn:aws:iam::${this.serviceInstance.ptid}:user/${this.createBucketForm.controls['serviceAccountName'].value}"]},
          "Action": ["s3:*"],
          "Resource": 
          [
            "arn:aws:s3::${this.serviceInstance.ptid}:${this.createBucketForm.controls['name'].value}/*",
            "arn:aws:s3::${this.serviceInstance.ptid}:${this.createBucketForm.controls['name'].value}"
          ]
      }]
    }`;
    this.createBucketForm.controls['code'].setValue(policyValue);
    //console.log(this.createBucketForm.value);
    this.createBucket.emit(this.createBucketForm.value);
  }

  ngOnInit(): void {
    this.createBucketForm = this.fb.group({
      name: [
        '',
        [
          Validators.pattern(this.bucketNameValidators[0].regex),
          Validators.pattern(this.bucketNameValidators[1].regex),
          Validators.pattern(this.bucketNameValidators[2].regex),
          Validators.pattern(this.bucketNameValidators[3].regex),
          Validators.pattern(this.bucketNameValidators[4].regex)
        ],
        [
          inArrayAsyncValidator(
            this.bucketList$.pipe(
              map((bucket) => bucket.map((b: Bucket) => b.bucket)),
              first()
            )
          )
        ]
      ],
      serviceAccountName: ['', Validators.required],
      versioning: [false],
      objectLocking: [false],
      retention: [false],
      retentionMode: 'COMPLIANCE',
      retentionValidity: [90, [Validators.required, Validators.min(1), Validators.max(365 * 10)]],
      accessPolicy: 'private',
      code: ['""', [jsonValidator()]]
    });

    this.createBucketForm.updateValueAndValidity();

    this.createBucketForm.controls['versioning'].valueChanges.subscribe((value) => {
      // if versioning is enabled, object locking must be enabled too
      if (this.createBucketForm.controls['objectLocking'].value) {
        this.createBucketForm.controls['objectLocking'].setValue(false, { emitEvent: false });
        this.createBucketForm.controls['retention'].setValue(false, { emitEvent: false });
        this.notificationService.showInfo(
          'Versioning & Object Locking',
          "Ohne 'Versioning' wird 'Object Locking' deaktiviert"
        );
      }
    });
  }
}
