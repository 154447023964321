import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnInit,
  forwardRef,
  ChangeDetectorRef,
  SimpleChange,
  SimpleChanges
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  Validators
} from '@angular/forms';
import { Icon } from '@n/nui';

@Component({
  selector: 'ui-forms-input, [txInput]',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputComponent implements OnInit {
  // Inputs
  @Input() type: 'date' | 'password' | 'text' | 'email' | 'time' | 'number' | 'datetime-local' = 'text';
  @Input() parentForm!: FormGroup;
  @Input() controlName!: string;
  @Input() label = '';
  @Input() name = '';
  @Input() placeholder = '';
  @Input() readonly = false;

  @Input() icon: Icon | null = null;
  @Input() description?: string;
  @Input() addon?: string;
  @Input() min?: number;
  @Input() mask?: string;
  @Input() prefix?: string;
  @Input() max?: number | string;
  @Input() step?: number;

  // depracted
  @Input() required: boolean = true;
  @Input() value!: any;

  // Outputs
  @Output() ffocus = new EventEmitter<FocusEvent>();
  @Output() bblur = new EventEmitter<FocusEvent>();
  @Output() kkeyup = new EventEmitter<KeyboardEvent>();
  @Output() cchange = new EventEmitter<any>();

  isRequired: boolean = false;

  ngOnInit() {
    this.isRequired = this.parentForm.controls[this.controlName].hasValidator(Validators.required)
  }
}
