import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'ui-table-configurator',
  templateUrl: './table-configurator.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableConfiguratorComponent {
  isMenuOpen: boolean = false;

  @ViewChild('elem') elem!: ElementRef;
  constructor(private renderer: Renderer2, private cdr: ChangeDetectorRef) {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (!this.elem.nativeElement.contains(e.target)) {
        this.isMenuOpen = false;
        this.cdr.detectChanges();
      }
    });
  }

  @Input()
  cols: Array<{ label: string; id: string; isShown: boolean }> = [];

  @Input()
  columnForm!: FormGroup;

  @Output()
  resetForm: EventEmitter<null> = new EventEmitter();
}
