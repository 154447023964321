import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { Person } from '@tx/api';
import { ContextMenuItem } from '@tx/ui';
import { Observable, map, switchMap } from 'rxjs';
import { DcamService } from '../../dcam/dcam.service';

@Component({
  selector: 'tx-portal-user-roles-card',
  templateUrl: './user-roles-card.component.html',
  styleUrls: ['./user-roles-card.component.scss']
})
export class UserRolesCardComponent {
  @ContentChild('mail') mail!: TemplateRef<any>;
  @ContentChild('header') header!: TemplateRef<any>;
  @ContentChild('badges') badges!: TemplateRef<any>;

  isDCAMServiceOwner$: Observable<boolean | undefined>;
  constructor(private dcamService: DcamService) {
    this.isDCAMServiceOwner$ = this.dcamService.dcam$.pipe(
      map(dcam => {
        return dcam.serviceOwners.map((so) => so.id).includes(this.person.id)
      })
    );
  }

  @Input() person!: Person;

  @Input()
  contextMenu!: Array<ContextMenuItem[]>;

  @Output()
  contextMenuClick: EventEmitter<string> = new EventEmitter();
}
