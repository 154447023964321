<ui-modal [isModalOpen]="!(partyService.isAuthenticated$ | async)" label="" [isCloseButtonVisible]="false">
  <ng-container *ngIf="!(partyService.isAuthenticated$ | async)">
    <div class="flex justify-center">
      <svg width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#3c8c3c">
        <g fill="none" fill-rule="evenodd">
          <g transform="translate(1 1)" stroke-width="2">
            <circle stroke-opacity=".5" cx="18" cy="18" r="18" />
            <path d="M36 18c0-9.94-8.06-18-18-18">
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 18 18"
                to="360 18 18"
                dur="1s"
                repeatCount="indefinite"
              />
            </path>
          </g>
        </g>
      </svg>
    </div>
  </ng-container>
  <ng-container *ngIf="partyService.hasMoreThanOneCompany$ | async">
    <ui-headline
      class="mr-24 mb-8"
      [heading]="'Organisation wählen'"
      [subheading]="
        'Ihr Account gehört zu mehreren Organisationen. Bitte wählen Sie die gewünschte Organisation für Ihren Login:'
      "
    >
    </ui-headline>
    <div *ngIf="partyService.person$" class="grid grid-cols-1 lg:grid-cols-3 sm:gap-8 gap-4">
      <div
        class="flex flex-col justify-center items-center p-4 border-gray-200 bg-gray-100 rounded-2xl"
        *ngFor="let company of (partyService.person$ | async)?.companies; let i = index"
      >
        <ui-icon [icon]="'briefcase'" class="w-5 h-5 my-4"></ui-icon>
        <span class="block font-medium text-center">{{ company.name.replace(' Company', '') }}</span>
        <span class="block text-sm text-neutral-500 leading-none mb-4">
          <span *ngFor="let customerNumber of company.organisations[0].customerNumbers">{{ customerNumber }}</span>
        </span>
        <ui-button
          [isFullWidth]="true"
          [size]="'base'"
          class="w-full"
          color="blue"
          [id]="'company-select-' + i"
          (cclick)="partyService.login(company.keycloakId)"
          >Wählen</ui-button
        >
      </div>
    </div>
  </ng-container>
</ui-modal>

<div class="flex flex-nowrap" *ngIf="partyService.isAuthenticated$ | async">
  <ui-nav
    [isAdmin]="(partyService.isAdministrator$ | async) == true"
    [isDcamServiceOwner]="(dcamService.isDcamServiceOwner$ | async) == true"
    [enabledFeatures$]="partyService.enabledFeatures$"
    (routeChange)="onRouteChange($event)"
    [profile]="profile$ | async"
    [companyName]="(partyService.currentTenant$ | async)?.name + ''"
    [companies$]="partyService.companies$"
    [activeItem]="activeRoute"
    (changeCompany)="partyService.login($event)"
    (logout)="partyService.logout()"
    (sidebarCollapse)="setSidebarCollaped($event)"
  ></ui-nav>
  <div
    class="w-full p-4 md:p-8 transition-all duration-75 ease-in-out"
    [class]="isSidebarCollapsed ? 'md:pl-collapsed' : 'md:pl-104'"
  >
    <router-outlet></router-outlet>
  </div>
</div>
