<div
  class="relative flex items-start forms-checkbox"
  *ngIf="parentForm && parentForm.controls[controlName]"
  [formGroup]="parentForm"
>
  <div class="flex items-center h-5">
    <input
      [formControlName]="controlName"
      [id]="id.toLowerCase()"
      [name]="id.toLowerCase()"
      type="checkbox"
      class="w-4 h-4 border-gray-300 rounded"
      [ngClass]="parentForm.controls[controlName].disabled ? 'text-gray-300' : 'text-blue-500 focus:ring-blue-500'"
    />
  </div>
  <div class="ml-3 text-sm">
    <label
      [for]="id.toLowerCase()"
      class="font-medium text-gray-900"
      [ngClass]="parentForm.controls[controlName].disabled ? '' : 'cursor-pointer'"
      >
        <div class="empty-checker"><ng-content></ng-content></div>
        <div class="empty-checker-default">{{label}}</div>
      </label
    >
    <p *ngIf="description" [id]="id.toLowerCase() + '-description'" class="text-gray-500">{{ description }}</p>
  </div>
</div>
