<div ui-card class="border border-gray-200">
  <div class="pb-4 border-b border-gray-200 flex justify-between items-center">
    <div class="flex flex-col">
      <span class="font-semibold text-xl flex items-center gap-2 mb-1">
        <ui-icon [icon]="'bucket'" class="block w-5 h-5"></ui-icon>
        {{ bucket.bucket }}
      </span>
      <span class="text-gray-400 text-sm">Erstellt am {{ bucket.creation_time | date }}</span>
    </div>
    <ui-button [size]="'sm'" [icon]="'cog-8-tooth'" [color]="'gray'" (cclick)="manageBucket()">Verwalten</ui-button>
  </div>
  <div class="grid grid-cols-1 lg:grid-cols-3 divide-y lg:divide-y-0 lg:divide-x divide-gray-200">
    <div class="flex-col flex gap-1 items-center py-4">
      <div class="flex gap-1 items-center">
        <ui-icon [icon]="'key'" class="w-4 h-4"></ui-icon>
        <span>Owned by</span>
      </div>
      <div class="text-l font-medium">{{ bucket.owner }}</div>
    </div>
    <div class="flex-col flex gap-1 items-center py-4">
      <div class="flex gap-1 items-center">
        <ui-icon [icon]="'chart-pie'" class="w-4 h-4"></ui-icon>
        <span>Usage</span>
      </div>
      <div class="text-xl font-medium" *ngIf="bucket.usage['rgw.main']">
        {{ bucket.usage['rgw.main'].size_actual | bytes }} {{ bucket.usage['rgw.main'].size_actual | bytes : true }}
      </div>
      <div class="text-xl font-medium" *ngIf="!bucket.usage['rgw.main']">0</div>
    </div>
    <div class="flex-col flex gap-1 items-center py-4">
      <div class="flex gap-1 items-center">
        <ui-icon [icon]="'cube'" class="w-4 h-4"></ui-icon>
        <span>Objects</span>
      </div>
      <div class="text-xl font-medium" *ngIf="bucket.usage['rgw.main']">{{ bucket.usage['rgw.main'].num_objects }}</div>
      <div class="text-xl font-medium" *ngIf="!bucket.usage['rgw.main']">0</div>
    </div>
  </div>
  <ui-forms-input-copy
    title="Bucket ARN"
    class="w-full"
    [value]="'arn:aws:s3::' + this.serviceInstance.ptid + ':' + bucket.bucket"
    (onCopy)="this.onCopy($event)"
  ></ui-forms-input-copy>
</div>
