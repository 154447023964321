import { ChangeDetectorRef, Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCell, MatCellDef, MatRowDef } from '@angular/material/table';

export interface CellAction {
  eventType?: string;
  eventData?: any;
  colIndex?: number;
  rowIndex?: number;
  row?: any;
  formatedData?: any;
  rawData?: any;
}

@Directive()
export abstract class CustomCellRenderer implements OnInit {
  @Output() action: EventEmitter<CellAction> = new EventEmitter();

  public colIndex!: number;
  public rowIndex!: number;

  @Input()
  public row: any;

  @Input()
  public data: any;

  constructor(private detector: ChangeDetectorRef) {}

  public ngOnInit() {
    this.detector.detectChanges();
  }

  abstract get content(): string;

  public emitEvent(action: CellAction) {
    action.rawData = this.data;
    action.formatedData = this.content;
    action.colIndex = this.colIndex;
    action.rowIndex = this.rowIndex;
    action.row = this.row;
    this.action.emit(action);
  }
}
