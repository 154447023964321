<tx-portal-add-service-account-modal
  (addServiceAccount)="addServiceAccount.emit($event)"
  (closeAddServiceAccountModal)="closeAddServiceAccountModal.emit()"
  [serviceInstance]="serviceInstance"
  [isAddServiceAccountModalOpen]="isAddServiceAccountModalOpen"
></tx-portal-add-service-account-modal>

<ui-modal
  [isModalOpen]="isRemoveServiceAccountModalOpen"
  (onSubmit)="removeServiceAccount(_serviceAccount); isRemoveServiceAccountModalOpen = false"
  [title]="'Service Account entfernen'"
  (onClose)="isRemoveServiceAccountModalOpen = false"
  [submitLabel]="'Service-Account entfernen'"
  [isSubmitDanger]="true"
>
  <p>
    Möchten Sie den Service-Account
    <span class="font-semibold">{{ this._serviceAccount?.identifier }}</span> wirklich entfernen?
  </p>
</ui-modal>

<tx-portal-create-credentials-modal
  (closeCreateCredentialsModal)="closeCreateCredentialsModal.emit()"
  [isCreateCredentialsModalOpen]="isCreateCredentialsModalOpen"
  [_generatedAccessKey]="_generatedAccessKey"
></tx-portal-create-credentials-modal>

<ui-modal
  [isModalOpen]="isDeleteCredentialsModalOpen"
  [title]="'Credentials löschen'"
  (onClose)="isDeleteCredentialsModalOpen = false"
  [submitLabel]="'Credentials löschen'"
  [isSubmitDanger]="true"
  (onSubmit)="deleteCredentials(); isDeleteCredentialsModalOpen = false"
>
  <p>
    Möchten Sie die Credentials
    <span class="font-semibold">{{ this._credentialsToDelete }}</span> wirklich löschen?
  </p>
</ui-modal>

<ui-modal
  *ngIf="isManageAssignedPoliciesModalOpen"
  [isModalOpen]="isManageAssignedPoliciesModalOpen"
  [showButtons]="false"
  [title]="'Policy'"
  (onClose)="closePolicy()"
>
  <ui-forms-input [label]="'Name'" [parentForm]="modalPolicyForm" [controlName]="'name'" class="mb-4"></ui-forms-input>
  <span class="block text-sm font-medium text-gray-700 mb-2">Policy</span>
  <div class="border-y border-gray-200 -mx-4 sm:-mx-8">
    <ui-forms-codearea
      [model]="policyModel"
      (codeChanged)="modalPolicyForm.patchValue({ code: $event })"
    ></ui-forms-codearea>
  </div>
  <div class="flex items-center justify-end gap-4 mt-6">
    <ui-button [size]="'sm'" [color]="'gray'" (cclick)="closePolicy()">Abbrechen</ui-button>
    <ui-button
      [size]="'sm'"
      [color]="'goblin'"
      (cclick)="savePolicy(_serviceAccount)"
      [isDisabled]="!modalPolicyForm.valid"
      >Policy speichern</ui-button
    >
  </div>
</ui-modal>

<div class="flex flex-col gap-6">
  <div
    ui-card
    class="border border-gray-200 grid grid-cols-3 items-center gap-2"
    *ngFor="let ServiceAccount of serviceAccount$ | async"
  >
    <div class="flex flex-col">
      <span class="font-semibold">{{ ServiceAccount.identifier }}</span>
    </div>
    <div class="flex items-center justify-end gap-4 col-span-2">
      <div class="flex items-center gap-1">
        <ui-icon [icon]="'lock-closed'" class="w-4 h-4"></ui-icon>
        {{ ServiceAccount.credentials?.length }} Credentials
      </div>
      <div
        class="flex items-center gap-1"
        *ngIf="ServiceAccount.identifier && policies.get(ServiceAccount.identifier) as policyNames"
      >
        <ui-icon [icon]="'shield-check'" class="w-4 h-4"></ui-icon>
        {{ policyNames.length }} Policies
      </div>
      <ui-context-menu
        [contextMenu]="contextMenu"
        (contextMenuClick)="contextMenuClick($event, ServiceAccount)"
      ></ui-context-menu>
    </div>
    <div class="col-span-3">
      <ui-button
        [color]="'gray'"
        [icon]="
          _openServiceAccountByDesc === ServiceAccount.identifier ? 'magnifying-glass-minus' : 'magnifying-glass-plus'
        "
        [size]="'sm'"
        [isFullWidth]="true"
        (cclick)="togglePolicyTree(ServiceAccount.identifier); _serviceAccount = ServiceAccount"
      >
        Details {{ _openServiceAccountByDesc === ServiceAccount.identifier ? 'schließen' : 'anzeigen' }}
      </ui-button>
      <div *ngIf="_openServiceAccountByDesc === ServiceAccount.identifier" class="flex flex-col gap-4 mt-4">
        <ui-forms-input-copy
          [value]="'arn:aws:iam::' + serviceInstance.ptid + ':user/' + ServiceAccount.identifier"
          (onCopy)="this.onCopy($event)"
        ></ui-forms-input-copy>
        <div class="lg:border-l border-gray-200 flex flex-col gap-2 text-sm pt-2">
          <!-- item 1-->
          <div class="gap-4 items-center grid grid-cols-4">
            <div class="flex items-center gap-4 col-span-4 xl:col-span-1">
              <div class="h-px bg-gray-200 w-4 hidden lg:block"></div>
              <span>Zugewiesene Policies:</span>
            </div>
            <div class="col-span-4 xl:col-span-3 flex items-center gap-4">
              <ng-container *ngIf="ServiceAccount.identifier && policies.get(ServiceAccount.identifier) as policyNames">
                <!-- policy item-->
                <div
                  class="flex gap-1 items-center p-1 border-gray-200 border rounded-xl"
                  *ngFor="let p of policyNames"
                >
                  <span class="font-semibold block px-1">{{ p }}</span>
                  <ui-button
                    [icon]="'pencil'"
                    [color]="'gray'"
                    [size]="'xs'"
                    [isOnlyIcon]="true"
                    (cclick)="editPolicy(ServiceAccount.identifier, p)"
                  ></ui-button>
                  <ui-button
                    [icon]="'v-trash'"
                    [color]="'red'"
                    [size]="'xs'"
                    [isOnlyIcon]="true"
                    (cclick)="deletePolicy(ServiceAccount.identifier, p)"
                  ></ui-button>
                </div>
              </ng-container>
            </div>
          </div>
          <!-- list items -->
          <div
            class="gap-1 md:gap-4 items-center grid md:grid-cols-4 grid-cols-1"
            *ngFor="let credentials of ServiceAccount.credentials"
          >
            <div class="flex items-center gap-4">
              <div class="h-px bg-gray-200 w-4 hidden lg:block"></div>
              <span>Credentials:</span>
            </div>
            <div class="md:col-span-3 flex items-center justify-between gap-2 md:gap-4 ml-0 lg:ml-8 md:ml-0">
              <span class="font-semibold">{{ credentials }}</span>
              <ui-button
                [color]="'red'"
                [icon]="'v-trash'"
                [size]="'xs'"
                (cclick)="
                  isDeleteCredentialsModalOpen = true;
                  _credentialsToDelete = credentials;
                  _serviceAccount = ServiceAccount
                "
                >Löschen</ui-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ui-button
    [isFullWidth]="true"
    [icon]="'user-plus'"
    [color]="'gray'"
    [size]="'sm'"
    (cclick)="openAddServiceAccountModal.emit()"
    >Service-Account hinzufügen</ui-button
  >
</div>
