import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'inputSlicer'
})
export class InputSlicerPipe implements PipeTransform {
  transform(input: string, maxLength: number): string {
    input = input.trim();
    if (input.length > maxLength) {
      return input.slice(0, Math.max(0, maxLength - 2)) + '...';
    }
    return input;
  }
}
