<div>
  <div class="hidden sm:hidden">
    <label for="tabs" class="sr-only">Tab wählen</label>
    <select
      id="tabs"
      name="tabs"
      class="block w-full border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
      (change)="change($event)"
    >
      <option *ngFor="let tab of tabs">{{ tab }}</option>
    </select>
  </div>
  <div class="sm:block">
    <nav class="flex space-x-4" aria-label="Tabs" *ngIf="objTabs">
      <button
        *ngFor="let tab of tabs; let index = index"
        class="px-4 py-2 text-sm font-medium rounded-xl focus:ring focus:ring-offset-2 focus:ring-blue-500"
        [ngClass]="[tab === activeTab && !onWhite ? 'text-gray-800 bg-white' : '', tab === activeTab && onWhite ? 'text-gray-800 bg-gray-100' : '', tab !== activeTab ? 'text-gray-400 bg-transparent hover:text-gray-800' : '']"
        [attr.aria-current]="tab === activeTab ? 'page' : ''"
        (click)="click(tab)"
      >
        <ng-container *ngIf="isMobile && objTabs.length > 0">
          <span *ngIf="tab == activeTab">
            {{ tab }}
          </span>
          <span class="block h-6 w-6" *ngIf="tab != activeTab">
            <ui-icon class="h-6 w-6" [icon]="objTabs[index].icon"></ui-icon>
          </span>
        </ng-container>
        <ng-container *ngIf="!isMobile || (objTabs && objTabs.length == 0)">
          {{ tab }}
        </ng-container>
      </button>
    </nav>
  </div>
</div>
