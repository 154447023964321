import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, NonNullableFormBuilder, Validators } from '@angular/forms';
import { Person, S3ServiceAccountsQuery, ServiceInstance, ServiceType, TxApi } from '@tx/api';
import { ObjectStorageService } from '@tx/s3';
import { ContextMenuItem, Option, jsonValidator } from '@tx/ui';
import { Observable, map } from 'rxjs';
import { PartyService } from '../../../party/party.service';
import { CodeModel } from '@ngstack/code-editor';
import { NotificationService } from '../../../../../../../libs/ui/src/lib/notification/notification.service';
import { QueryRef } from 'apollo-angular';
import { set } from 'cypress/types/lodash';

export interface Policy {
  name: string;
  id: string;
}

type ServiceAccount = S3ServiceAccountsQuery['s3ServiceAccounts']['edges'][0]['node'];

@Component({
  selector: 'tx-portal-accounts',
  templateUrl: './accounts.component.html',
})
export class AccountsComponent implements OnInit {
  @Input() serviceInstance!: ServiceInstance;
  @Input() isAddServiceAccountModalOpen!: boolean;
  @Output() closeAddServiceAccountModal: EventEmitter<any> = new EventEmitter();
  @Output() openAddServiceAccountModal: EventEmitter<any> = new EventEmitter();
  @Output() addServiceAccount: EventEmitter<string> = new EventEmitter();

  @Input() isCreateCredentialsModalOpen: boolean = false;
  @Input() _generatedAccessKey: any;
  @Output() writeGeneratedAccessKey: EventEmitter<any> = new EventEmitter();
  @Output() openCreateCredentialsModal: EventEmitter<any> = new EventEmitter();
  @Output() closeCreateCredentialsModal: EventEmitter<any> = new EventEmitter();

  serviceAccount$!: Observable<ServiceAccount[]>;
  serviceAccountWatch$!: QueryRef<S3ServiceAccountsQuery>;

  policies = new Map<string, string[]>();

  defaultPolicyValue = `{
    "Version": "2012-10-17",
    "Statement": [
        {
            "Effect": "Allow",
            "Action": [
                "s3:*"
            ],
            "Resource": [
                "arn:aws:s3:::*"
            ]
        }
    ]
  }`;

  constructor(
    private txApi: TxApi,
    public s3Service: ObjectStorageService,
    private partyService: PartyService,
    private fb: FormBuilder,
    private notificationService: NotificationService
  ) {}

  onCopy(data: string) {
    this.notificationService.showInfo('Kopiert', data);
  }

  ngOnInit() {
    this.serviceAccountWatch$ = this.txApi.s3ServiceAccountsWatch({
      serviceInstanceId: this.serviceInstance.id
    });
    this.serviceAccount$ = this.serviceAccountWatch$.valueChanges.pipe(
      map((users) => {
        return users.data.s3ServiceAccounts.edges.map((u) => u.node);
      })
    );
  }

  contextMenu: ContextMenuItem[][] = [
    [
      { label: 'Credentials erstellen', icon: 'lock-open', event: 'create-credentials' },
      { label: 'Neue Policy zuweisen', icon: 'shield-check', event: 'create-policy' }
    ],
    [{ label: 'Service Account entfernen', icon: 'v-trash', event: 'remove', isDanger: true }]
  ];

  async contextMenuClick(event: string, serviceAccount: any) {
    switch (event) {
      case 'create-credentials':
        this.openCreateCredentialsModal.emit();
        this.addCredentials(serviceAccount);
        return;
      case 'create-policy':
        this.modalPolicyForm.patchValue({ code: this.defaultPolicyValue });
        this.policyModel.value = this.defaultPolicyValue;
        this.isManageAssignedPoliciesModalOpen = true;
        this._serviceAccount = serviceAccount;
        return;
      case 'remove':
        this._serviceAccount = serviceAccount;
        this.isRemoveServiceAccountModalOpen = true;
        return;
      default:
        break;
    }
  }

  _openServiceAccountByDesc: string = '';

  togglePolicyTree(id: string | undefined | null) {
    if (!id) return;
    this._openServiceAccountByDesc = this._openServiceAccountByDesc === id ? '' : id;
    if (this._openServiceAccountByDesc.length > 0) {
      this.loadPolicies();
    }
  }

  // modals
  isRemoveServiceAccountModalOpen: boolean = false;
  isDeleteCredentialsModalOpen: boolean = false;
  isManageAssignedPoliciesModalOpen: boolean = false;

  modalPolicyForm = this.fb.group({
    name: [
      '',
      [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),
        Validators.pattern('(?!(^xn--|.+-s3alias$))^[a-z0-9][a-z0-9-]{1,61}[a-z0-9]$')
      ]
    ],
    code: ['', [Validators.required, jsonValidator()]]
  });

  serviceAccountForm = this.fb.group({
    identifier: [
      '',
      [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),
        Validators.pattern('(?!(^xn--|.+-s3alias$))^[a-z0-9][a-z0-9-]{1,61}[a-z0-9]$')
      ]
    ]
  });

  policyModel: CodeModel = {
    language: 'json',
    uri: 'main.json',
    value: ''
  };

  // policy which is opend in the current modal
  modalPolicyObject: string = '';

  async loadPolicies() {
    try {
      const policies = await this.s3Service.loadUserPolicies(
        this._openServiceAccountByDesc,
        this.serviceInstance.ptid + ''
      );
      if (policies.PolicyNames) {
        this.policies.set(this._openServiceAccountByDesc, policies.PolicyNames);
      }
    } catch (e) {}
  }

  async deletePolicy(username: string, policy: string) {
    await this.s3Service.deletePolicy(`${this.serviceInstance.ptid}$${username}`, policy);
    await this.loadPolicies();
  }

  // TODO
  async editPolicy(username: string, policy: string) {
    const userPolicy = await this.s3Service.getUserPolicy(`${this.serviceInstance.ptid}$${username}`, policy);
    if (userPolicy.PolicyDocument) {
      this.modalPolicyForm.controls.code.setValue(userPolicy.PolicyDocument);
      this.modalPolicyForm.controls.name.setValue(policy);
      this.policyModel.value = userPolicy.PolicyDocument;
      this.modalPolicyObject = policy;
      this.isManageAssignedPoliciesModalOpen = true;
      this.modalPolicyForm.controls.name.disable();
    }
  }

  async savePolicy(user: any) {
    try {
      await this.s3Service.putUserPolicy(user, this.modalPolicyForm.value, this.serviceInstance.ptid + '');
      this.modalPolicyForm.reset();
      this.closePolicy();
      this._serviceAccount = user;
      this.notificationService.showSuccess('Policy', 'erfolgreich gespeichert');
      setTimeout(() => {
        this.loadPolicies();
      }, 1000);
    } catch (e) {
      this.notificationService.showError('Fehler', 'Policy konnte nicht gespeichert werden');
    }
  }

  closePolicy() {
    this.modalPolicyObject = '';
    this.modalPolicyForm.controls.name.reset();
    this.isManageAssignedPoliciesModalOpen = false;
    this.modalPolicyForm.controls.name.enable();
  }

  _credentialsToDelete: any;
  _serviceAccount: any;

  // TODO
  async deleteCredentials() {
    if (!this._credentialsToDelete) return;
    if (!this._serviceAccount) return;
    if (!this.serviceInstance.ptid) return;

    try {
      await this.s3Service.deleteKey(this.serviceInstance.ptid, this._credentialsToDelete);
    } catch (e) {
      console.log(e);
    }
    // remove service account anyways
    await this.txApi
      .updateOneS3ServiceAccount({
        input: {
          id: this._serviceAccount.id,
          update: {
            credentials: this._serviceAccount.credentials.filter((s: any) => s !== this._credentialsToDelete)
          }
        }
      })
      .subscribe((data) => {
        if (data.loading) return;
        this.notificationService.showSuccess('Credentials', 'erfolgreich entfernt');
      });
  }

  // TODO
  async addCredentials(ServiceAccount: ServiceAccount) {
    if (!this.serviceInstance.ptid || !ServiceAccount.identifier) return;
    const res = await this.s3Service.createKey(this.serviceInstance.ptid, ServiceAccount.identifier);
    const credentials = res.keys.map((r: any) => r.access_key);
    this.txApi
      .updateOneS3ServiceAccount({
        input: {
          id: ServiceAccount.id,
          update: {
            ...ServiceAccount,
            credentials: credentials
          }
        }
      })
      .subscribe((data) => {
        if (data.loading) return;
        // this._generatedAccessKey = res.newKey;
        this.writeGeneratedAccessKey.emit(res.newKey);
        this.notificationService.showSuccess('Credentials', 'erfolgreich erstellt');
      });
  }

  // TODO
  async removeServiceAccount(ServiceAccount: ServiceAccount) {
    if (this.serviceInstance.ptid && ServiceAccount.identifier) {
      await this.s3Service.deleteUser(this.serviceInstance.ptid, ServiceAccount.identifier);
      this.txApi
        .deleteOneS3ServiceAccount({
          input: {
            id: ServiceAccount.id
          }
        })
        .subscribe(async (data) => {
          if (data.loading) return;
          this.notificationService.showSuccess('Service User', 'Erfolgreich gelöscht');
          this.serviceAccountWatch$.refetch();
        });
    }
  }

  selectedPerson: Option | null = null;

  selectPerson(person: Option) {
    this.selectedPerson = person;
  }
}
