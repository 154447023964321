<div [ngClass]="['badge', 'badge-' + this.type]">
  <span class="badge-label" [ngClass]="{ 'pl-4 pr-2': label == '', 'px-4': label != '', 'pr-6': content != '' }">
    <ui-icon *ngIf="icon && !loading" [icon]="icon" class="w-4 h-4 mr-1 -ml-1"></ui-icon>
    <div
      *ngIf="loading"
      class="w-3 h-3 mr-1 border-2 border-current rounded-full border-r-transparent animate-spin"
    ></div>
    {{ label }}
  </span>
  <span class="badge-content" *ngIf="content !== ''; else elseBlock">
    {{ content }}
  </span>
  <ng-template #elseBlock>
    <span class="badge-content">
      <ng-content></ng-content>
    </span>
  </ng-template>
</div>
