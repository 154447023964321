<div
  [ngClass]="[
    'tag',
    'tag-' + this.type,
    this.label !== '' ? '' : 'tag-no-label',
    this.content !== '' ? '' : 'tag-no-content',
    this.isBroken ? 'tag-broken' : ''
  ]"
>
  <span *ngIf="label !== ''" class="tag-label">
    <ui-icon *ngIf="icon && !loading" [icon]="icon" class="flex-shrink-0 w-4 h-4 mr-1"></ui-icon>
    <div
      *ngIf="loading"
      class="w-3 h-3 mr-1 border-2 border-current rounded-full border-r-transparent animate-spin"
    ></div>
    {{ label }}
  </span>
  <ng-container *ngIf="content !== '' && content !== null; else elseBlock">
    {{ content | inputSlicer : 15 }}
  </ng-container>
  <ng-template #elseBlock>
    <span class="tag-content">
      <ui-icon *ngIf="icon && label === '' && !loading" [icon]="icon" class="w-4 h-4 mr-1"></ui-icon>
      <div
        *ngIf="loading && label === ''"
        class="w-3 h-3 mr-1 border-2 border-current rounded-full border-r-transparent animate-spin"
      ></div>
      <ng-content></ng-content>
    </span>
  </ng-template>
</div>
