<div #elem>
  <label class="block text-sm font-medium text-gray-700">{{ label }}</label>
  <div class="relative mt-1">
    <input
      (keyup)="changeValue($event)"
      (focus)="isOpen = true"
      [formControl]="inputControl"
      type="text"
      class="w-full py-2 pl-3 pr-12 bg-white border border-gray-300 rounded-md focus:border-gray-300 focus:outline-none focus:ring-offset-2 focus:ring-blue-500 sm:text-sm"
      role="combobox"
      aria-controls="options"
      aria-expanded="false"
    />
    <button
      type="button"
      tabindex="-1"
      class="absolute inset-y-0 right-0 flex items-center px-2 rounded-r-md focus:outline-none"
      (click)="isOpen = !isOpen"
    >
      <ui-icon class="w-5 h-5 text-gray-400 hover:text-gray-500" [icon]="'chevron-up-down'"></ui-icon>
    </button>

    <ul
      *ngIf="isOpen"
      class="absolute z-10 w-full p-1 mt-1 overflow-auto text-base bg-white shadow-lg rounded-xl max-h-60 focus:outline-none sm:text-sm"
      role="listbox"
    >
      <li
        *ngFor="let option of filteredOptions; index as i"
        (click)="select(option)"
        class="relative flex items-center gap-4 px-3 py-2 transition duration-200 ease-in-out rounded-lg cursor-pointer select-none hover:bg-gray-100 hover:text-blue-500"
        role="option"
      >
        <span
          *ngIf="selectedValue?.value === option.value"
          class="block shrink-0 w-2 h-2 bg-blue-500 rounded-full"
        ></span>
        <div class="flex flex-col">
          <span class="font-medium truncate"> {{ option.label }} </span>
          <span class="text-sm text-gray-500 truncate">{{ option.description }} </span>
        </div>
      </li>
    </ul>
  </div>
</div>
