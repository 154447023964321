<div class="flex items-center justify-start gap-2">
  <ui-button
    [icon]="'pencil'"
    [size]="'sm'"
    [color]="'blue'"
    [isOnlyIcon]="true"
    (onClick)="emitEvent({ eventType: 'test', eventData: 'testData' })"
    aria-expanded="true"
    aria-haspopup="true"
    id="menu-button"
  ></ui-button>
  <ui-button
    [icon]="'v-trash'"
    [color]="'red'"
    [size]="'sm'"
    [isOnlyIcon]="true"
    (onClick)="emitEvent({ eventType: 'test', eventData: 'testData' })"
    aria-expanded="true"
    aria-haspopup="true"
    id="menu-button"
  ></ui-button>
</div>

<div class="action-menu" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1"></div>
