import { CommonModule } from '@angular/common';
import { LOCALE_ID, NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule, IConfig } from 'ngx-mask';
export const options: Partial<null | IConfig> | (() => Partial<IConfig>) = null;

registerLocaleData(localeDe);

// atoms
import { CardComponent } from './components/atoms/card/card.component';
import { AvatarComponent } from './components/atoms/avatar/avatar.component';
import { HeadlineComponent } from './components/atoms/headline/headline.component';
import { TagComponent } from './components/atoms/tag/tag.component';
import { ModalComponent } from './components/atoms/modal/modal.component';

import { TableComponent } from './components/atoms/table/table.component';
import { TableSearchComponent } from './components/atoms/table/table-search/table-search.component';
import { TableConfiguratorComponent } from './components/atoms/table/table-configurator/table-configurator.component';
import { InfoComponent } from './components/atoms/info/info.component';

// forms
import { InputComponent } from './components/forms/input/input.component';
import { TextareaComponent } from './components/forms/textarea/textarea.component';
import { InputCopyComponent } from './components/forms/input-copy/input-copy.component';
import { CheckboxComponent } from './components/forms/checkbox/checkbox.component';
import { SelectComponent } from './components/forms/select/select.component';
import { ToggleComponent } from './components/forms/toggle/toggle.component';
import { CustomSelectComponent } from './components/forms/custom-select/custom-select.component';
import { ComboboxComponent } from './components/forms/combobox/combobox.component';
import { CodeareaComponent } from './components/forms/codearea/codearea.component';

// navigation
import { NavItemComponent } from './components/nav/item/item.component';
import { NavProfileComponent } from './components/nav/profile/profile.component';
import { NavSidebarComponent } from './components/nav/sidebar/sidebar.component';

// cells
import { CellPageHeaderComponent } from './components/atoms/page-header/page-header.component';

import { BadgeComponent } from './components/atoms/badge/badge.component';
import { TabsComponent } from './components/atoms/tabs/tabs.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { CurrencyRendererComponent } from './components/atoms/table/custom-renderer/currency-renderer/currency-renderer.component';

import { CdkDetailRowDirective } from './components/atoms/table/cdk-detail-row.directive';
import { getGermanPaginatorIntl } from './components/atoms/table/paginator.intl';
import { ActionRendererComponent } from './components/atoms/table/custom-renderer/action-renderer/action-renderer.component';
import { ContactRendererComponent } from './components/atoms/table/custom-renderer/contact-renderer/contact-renderer.component';
import { ServiceLevelRendererComponent } from './components/atoms/table/custom-renderer/service-level-renderer/service-level-renderer.component';
import { TagRendererComponent } from './components/atoms/table/custom-renderer/tag-renderer/tag-renderer.component';
import { DateRendererComponent } from './components/atoms/table/custom-renderer/date-renderer/date-renderer.component';
import { FormWrapperComponent } from './components/forms/form-wrapper/form-wrapper.component';

import { RouterModule } from '@angular/router';
import { ToastrExampleComponent } from './components/atoms/toastr/toastr.component';

import { NotificationService } from './notification/notification.service';
import { ToastrModule } from 'ngx-toastr';
import { MultiSelectComponent } from './components/forms/multi-select/multi-select.component';
import { ContextMenuComponent } from './components/atoms/context-menu/context-menu.component';
import { MatSortModule } from '@angular/material/sort';

// Pipes
import { KeycloakRolePipe } from './keycloak-role/keycloak-role.pipe';
import { ErrorsPipe } from './pipes/errors.pipe';
import { NuiModule } from '@n/nui';
import { ButtonComponent } from './components/atoms/button/button.component';
import { HighlightService } from './services/highlight.service';

import { CodeEditorModule } from '@ngstack/code-editor';
import { AdditionalServicesComponent } from './components/atoms/additional-services/additional-services.component';
import { ServiceIconPipe } from './pipes/service-icon.pipe';
import { InputSlicerPipe } from './pipes/input-slicer.pipe';

@NgModule({
  declarations: [
    ButtonComponent,
    CurrencyRendererComponent,
    CardComponent,
    TagComponent,
    AvatarComponent,
    HeadlineComponent,
    NavItemComponent,
    NavProfileComponent,
    NavSidebarComponent,
    InputComponent,
    InputCopyComponent,
    SelectComponent,
    CheckboxComponent,
    ModalComponent,
    ToggleComponent,
    CustomSelectComponent,
    ComboboxComponent,
    BadgeComponent,
    TabsComponent,
    CdkDetailRowDirective,
    TableComponent,
    CurrencyRendererComponent,
    CdkDetailRowDirective,
    ServiceLevelRendererComponent,
    TagRendererComponent,
    ActionRendererComponent,
    ContactRendererComponent,
    DateRendererComponent,
    FormWrapperComponent,
    ToastrExampleComponent,
    MultiSelectComponent,
    ContextMenuComponent,
    KeycloakRolePipe,
    ErrorsPipe,
    TableSearchComponent,
    TableConfiguratorComponent,
    InfoComponent,
    CodeareaComponent,
    CellPageHeaderComponent,
    AdditionalServicesComponent,
    ServiceIconPipe,
    TextareaComponent,
    InputSlicerPipe
  ],
  providers: [
    //AuthService,
    {
      provide: LOCALE_ID,
      useValue: 'de-DE'
    },
    { provide: MatPaginatorIntl, useValue: getGermanPaginatorIntl() },
    NotificationService,
    HighlightService
  ],

  imports: [
    CommonModule,
    FormsModule,
    MatTableModule,
    MatSortModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    RouterModule,
    ToastrModule.forRoot(), // ToastrModule added,
    NgxMaskModule.forRoot(),
    NuiModule,
    CodeEditorModule.forRoot()
  ],
  exports: [
    ButtonComponent,
    CurrencyRendererComponent,
    CdkDetailRowDirective,
    CardComponent,
    TagComponent,
    AvatarComponent,
    HeadlineComponent,
    NavItemComponent,
    NavProfileComponent,
    NavSidebarComponent,
    InputComponent,
    InputCopyComponent,
    SelectComponent,
    CheckboxComponent,
    ModalComponent,
    ToggleComponent,
    CustomSelectComponent,
    ComboboxComponent,
    BadgeComponent,
    TabsComponent,
    TableComponent,
    CurrencyRendererComponent,
    CdkDetailRowDirective,
    ServiceLevelRendererComponent,
    TagRendererComponent,
    ActionRendererComponent,
    ContactRendererComponent,
    DateRendererComponent,
    FormWrapperComponent,
    ToastrExampleComponent,
    MultiSelectComponent,
    ContextMenuComponent,
    KeycloakRolePipe,
    TableSearchComponent,
    TableConfiguratorComponent,
    NuiModule,
    InfoComponent,
    ErrorsPipe,
    CodeareaComponent,
    CellPageHeaderComponent,
    AdditionalServicesComponent,
    ServiceIconPipe,
    TextareaComponent,
    InputSlicerPipe,
  ]
})
export class UiModule {}
