import { Injectable } from '@angular/core';
import {
  TxApi,
  Gender,
  DcamQuery,
  DcamQueryVariables,
  CreateOneDcamContactMutationVariables,
  UpdateOneDcamContactMutationVariables,
  UpdateOneDcamAccessMutationVariables,
  UpdateOneDcamMutationVariables
} from '@tx/api';
import { QueryRef } from 'apollo-angular/query-ref';
import { catchError, map, switchMap, shareReplay } from 'rxjs/operators';
import { cloneDeep } from '@apollo/client/utilities';
import { NotificationService } from '../../../../../libs/ui/src/lib/notification/notification.service';
import { Observable } from 'rxjs';
import { PartyService } from '../party/party.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DcamService {
  dcamQuery!: QueryRef<DcamQuery, DcamQueryVariables>;

  dcam$: Observable<DcamQuery['dcams'][0]>;

  isDcamServiceOwner$!: Observable<boolean | undefined>;

  constructor(
    private txApi: TxApi,
    private notificationService: NotificationService,
    private partyService: PartyService,
    private router: Router
  ) {
    this.dcamQuery = this.txApi.dcamWatch();

    this.dcam$ = this.dcamQuery.valueChanges.pipe(
      map(({ data }) => {
        return data.dcams[0];
      }),
      map(cloneDeep)
    );

    this.isDcamServiceOwner$ = this.dcam$.pipe(
      switchMap((dcam) => {
        return this.partyService.person$.pipe(
          map((person) => {
            return dcam.serviceOwners?.map((so) => so.id).includes(person.id);
          })
        );
      })
    );
  }

  deleteOneContact(id: string) {
    return this.txApi
      .deleteOneDcamContact(
        { input: { id: id } },
        {
          update: (cache, { data }) => {
            if (data?.deleteOneDcamContact) {
              cache.evict({ id: `DcamContact:${id}` });
              cache.gc();
            }
          }
        }
      )
      .pipe(
        map((response) => {
          if (response.data?.deleteOneDcamContact) {
            this.notificationService.showSuccess('Erfolg', 'Kontakt wurde gelöscht');
          }
          return response;
        }),
        catchError((error) => {
          this.notificationService.showError('Fehler', 'Kontakt konnte nicht gelöscht werden');
          throw error;
        })
      );
  }

  createContact(contact: CreateOneDcamContactMutationVariables['input']['dcamContact']) {
    return this.txApi
      .createOneDcamContact({
        input: {
          dcamContact: contact
        }
      })
      .pipe(
        map((response) => {
          if (response.data?.createOneDcamContact) {
            this.notificationService.showSuccess('Erfolg', 'Kontakt wurde erstellt');
          }
          return response;
        }),
        catchError((error) => {
          this.notificationService.showError('Fehler', 'Kontakt konnte nicht erstellt werden');
          throw error;
        })
      );
  }

  updateContact(id: string, contact: UpdateOneDcamContactMutationVariables['input']['update']) {
    return this.txApi
      .updateOneDcamContact({
        input: {
          id: id,
          update: contact
        }
      })
      .pipe(
        map((response) => {
          if (response.data?.updateOneDcamContact) {
            this.notificationService.showSuccess('Erfolg', 'Kontakt wurde aktualisiert');
          }
          return response;
        }),
        catchError((error) => {
          this.notificationService.showError('Fehler', 'Kontakt konnte nicht aktualisiert werden');
          throw error;
        })
      );
  }

  updateOneDcamAccess(id: string, dcam: UpdateOneDcamAccessMutationVariables['input']['update']) {
    return this.txApi
      .updateOneDcamAccess({
        input: {
          id: id,
          update: dcam
        }
      })
      .pipe(
        map((response) => {
          if (response.data?.updateOneDcamAccess) {
            this.notificationService.showSuccess('Erfolg', 'Notiz wurde aktualisiert');
          }
          return response;
        }),
        catchError((error) => {
          this.notificationService.showError('Fehler', 'Notiz konnte nicht aktualisiert werden');
          throw error;
        })
      );
  }

  updateOneDcam(id: string, dcam: UpdateOneDcamMutationVariables['input']['update']) {
    return this.txApi
      .updateOneDcam({
        input: {
          id: id,
          update: dcam
        }
      })
      .pipe(
        map((response) => {
          if (response.data?.updateOneDcam) {
            this.notificationService.showSuccess('Erfolg', 'Service-Owner wurde erstellt');
          }
          return response;
        }),
        catchError((error) => {
          this.notificationService.showError('Fehler', 'DCAM konnte nicht aktualisiert werden');
          throw error;
        })
      );
  }
  removeServiceOwnersFromDcam(id: string, serviceOwners: string[]) {
    return this.txApi
      .removeServiceOwnersFromDcam({
        input: {
          id: id,
          relationIds: serviceOwners
        }
      })
      .pipe(
        map((response) => {
          if (response.data?.removeServiceOwnersFromDcam) {
            this.notificationService.showSuccess('Erfolg', 'Service-Owner wurde entfernt');
          }
          return response;
        }),
        catchError((error) => {
          this.notificationService.showError('Fehler', 'Service-Owner konnte nicht entfernt werden');
          throw error;
        })
      );
  }
  addServiceOwnersToDcam(id: string, serviceOwners: string[]) {
    return this.txApi
      .addServiceOwnersToDcam({
        input: {
          id: id,
          relationIds: serviceOwners
        }
      })
      .pipe(
        map((response) => {
          if (response.data?.addServiceOwnersToDcam) {
            this.notificationService.showSuccess('Erfolg', 'Service-Owner wurde hinzugefügt');
          }
          return response;
        }),
        catchError((error) => {
          this.notificationService.showError('Fehler', 'Service-Owner konnte nicht hinzugefügt werden');
          throw error;
        })
      );
  }
}
