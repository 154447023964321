import { Component, Input, OnInit } from '@angular/core';
import { S3ServiceAccount, S3ServiceAccountsQuery, ServiceInstance, ServiceType, TxApi } from '@tx/api';
import { Bucket, ObjectStorageService } from '@tx/s3';
import { NotificationService, Option, TxTab } from '@tx/ui';
import { BehaviorSubject, Observable, firstValueFrom, map, of, startWith, switchMap, tap, zip } from 'rxjs';
import { PartyService } from '../../party/party.service';
import { CodeEditorService } from '@ngstack/code-editor';
import { QueryRef } from 'apollo-angular';
import { Router } from '@angular/router';

@Component({
  selector: 'tx-portal-s3',
  templateUrl: './s3.component.html',
  styleUrls: ['./s3.component.scss']
})
export class S3Component implements OnInit {
  constructor(
    public s3Service: ObjectStorageService,
    private txApi: TxApi,
    public partyService: PartyService,
    private notificationService: NotificationService,
    private router: Router
  ) {}

  isServiceOwner$!: Observable<boolean>;

  @Input()
  serviceInstance!: ServiceInstance;

  @Input()
  disabled = false;

  bucketData$?: Observable<{ buckets: number; files: number; size: number }>;
  bucketList$ = new BehaviorSubject<Bucket[]>([]);
  userList!: Promise<any>;

  serviceAccountLength$!: Observable<number>;

  hasServiceAccounts$!: Observable<boolean>;

  isCreateBucketModalLoading: boolean = false;

  isAddServiceAccountModalOpen: boolean = false;
  isCreateCredentialsModalOpen: boolean = false;
  _createdUser: any;
  _generatedAccessKey?: any = null;

  serviceAccountWatch$!: QueryRef<S3ServiceAccountsQuery>;

  serviceAccountOptions$!: Observable<Option[]>;

  jumpToServiceAccountsTab() {
    this.router.navigate([], {
      queryParams: {
        modal: null
      },
      queryParamsHandling: 'merge'
    });
    this.switchTab('Service Accounts & Credentials');
  }

  ngOnInit() {
    this.loadBucketData();
    this.reloadBuckets();
    this.userList = this.s3Service.getUserList(this.serviceInstance.ptid + '');

    this.serviceAccountWatch$ = this.txApi.s3ServiceAccountsWatch({
      serviceInstanceId: this.serviceInstance.id
    });
    this.serviceAccountLength$ = this.serviceAccountWatch$.valueChanges.pipe(
      map((users) => {
        return users.data.s3ServiceAccounts.edges.map((u) => u.node).length;
      })
    );
    this.hasServiceAccounts$ = this.serviceAccountLength$.pipe(
      map((length) => {
        return length > 0;
      })
    );
    this.isServiceOwner$ = this.partyService.person$.pipe(
      map((person) => {
        return this.serviceInstance.serviceOwners?.some((so) => so.id === person.id);
      })
    );
    this.serviceAccountOptions$ = this.serviceAccountWatch$.valueChanges.pipe(
      map((users) => {
        return users.data.s3ServiceAccounts.edges.map((u) => ({
          label: u.node.identifier ? u.node.identifier : '',
          value: u.node.identifier ? `${u.node.identifier}` : '',
          description: `${u.node.credentials?.length} Credentials`
        }));
      })
    );

    this.initS3Client();
  }

  loadBucketData() {
    this.bucketData$ = this.s3Service.getBucketStatsAggregated(this.serviceInstance.ptid + '');
  }

  runAction(step: number) {
    switch (step) {
      case 1:
        this.isAddServiceAccountModalOpen = true;
        break;
      case 2:
        this.router.navigate([], {
          queryParams: {
            modal: 'create-bucket'
          },
          queryParamsHandling: 'merge'
        });
        break;
      default:
        break;
    }
  }

  reloadBuckets() {
    this.partyService.people$
      .pipe(
        switchMap((people) => {
          return this.s3Service.getBuckets(this.serviceInstance.ptid + '').pipe(
            map((buckets: Bucket[]) => {
              return buckets.map((bucket: Bucket) => {
                const ownerId = bucket.owner.slice(-36);
                const owner = people.find((p) => {
                  return p.keycloakUser === ownerId;
                });

                return {
                  ...bucket,
                  owner: owner ? owner.email : bucket.owner.replace(`${bucket.tenant}$`, '')
                } as Bucket;
              });
            }),
            map((buckets: Bucket[]) => {
              return buckets.sort((a, b) => {
                return a.bucket.localeCompare(b.bucket);
              });
            })
          );
        })
      )
      .subscribe((buckets) => {
        this.bucketList$.next(buckets);
      });
  }

  async createBucket(bucket: any) {
    this.isCreateBucketModalLoading = true;
    let retention = undefined;
    if (bucket.retention) {
      retention = {
        DefaultRetention: {
          Mode: bucket.retentionMode,
          Days: bucket.retentionValidity
        }
      };
    }
    try {
      await this.s3Service.createBucket(
        bucket.name,
        retention,
        bucket.objectLocking,
        bucket.versioning,
        bucket.code,
        this.serviceInstance.ptid + '',
        bucket.serviceAccountName
      );
      this.notificationService.showSuccess('Bucket erstellt', `Bucket ${bucket.name} wurde erfolgreich erstellt`);
    } catch (e: any) {
      this.notificationService.showError('Fehler beim Erstellen des Buckets', e.message);
      this.isCreateBucketModalLoading = false;
      return;
    }

    this.isCreateBucketModalLoading = false;
    this.router.navigate([], {
      queryParams: {
        modal: null
      },
      queryParamsHandling: 'merge'
    });
    this.loadBucketData();
    this.reloadBuckets();
  }

  async addServiceAccount(identifier: string) {
    if (!this.serviceInstance.ptid) return;
    this._createdUser = await this.s3Service.createUser(this.serviceInstance.ptid, identifier ?? '');
    this.txApi
      .createOneS3ServiceAccount({
        input: {
          s3ServiceAccount: {
            credentials: [this._createdUser.keys[0].access_key],
            serviceInstanceId: this.serviceInstance.id,
            identifier: identifier,
            service: ServiceType.S3
          }
        }
      })
      .subscribe(async (data) => {
        if (data.loading) return;
        this._generatedAccessKey = this._createdUser.keys[0];
        this.notificationService.showSuccess('Service Account', 'Erfolgreich hinzugefügt');
        this.serviceAccountWatch$.refetch();
        this.isAddServiceAccountModalOpen = false;
        this.isCreateCredentialsModalOpen = true;
      });
  }

  async deleteBucket(bucket: any) {
    await this.s3Service.deleteBucket(bucket);
    this.reloadBuckets();
  }

  async initS3Client() {
    const isServiceOwner = await firstValueFrom(this.isServiceOwner$);

    const isS3Allowed$ = zip(this.isServiceOwner$, this.partyService.isAdministrator$).pipe(
      map(([isServiceOwner, isAdministrator]) => {
        return isServiceOwner || isAdministrator;
      })
    );
    isS3Allowed$.subscribe((isAllowed) => {
      if (isAllowed) {
        this.s3Service.assumeRoleWithWebIdentity(this.serviceInstance.ptid + '').then(() => {
          this.s3Service.getBucketStatsAggregated(this.serviceInstance.ptid + '');
        });
      } else {
        console.log('not allowed');
      }
    });
  }

  tabs: TxTab[] = [
    { value: 'Overview', icon: 'chart-pie' },
    { value: 'Buckets', icon: 'bucket' },
    { value: 'Service Accounts & Credentials', icon: 'users' }
  ];

  activeTab$: BehaviorSubject<string> = new BehaviorSubject(this.tabs[0].value);

  switchTab(tab: string) {
    this.activeTab$.next(tab);
  }
}
