import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnInit,
  forwardRef,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { Icon } from '@n/nui';
import { NotificationService } from 'libs/ui/src/lib/notification/notification.service';

@Component({
  selector: 'ui-forms-input-copy',
  templateUrl: './input-copy.component.html',
  styleUrls: ['./input-copy.component.scss']
})
export class InputCopyComponent {
  @ViewChild('text') text: any;
  // Inputs
  @Input() label = '';
  @Input() value = '';

  @Input() description?: string;
  @Output() onCopy = new EventEmitter<string>();

  copyValue() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.value;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.onCopy.emit(this.value);
  }
}
