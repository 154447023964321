import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'tx-paginator',
  templateUrl: './paginator.component.html'
})
export class PaginatorComponent extends MatPaginator implements AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(intl: MatPaginatorIntl, changeDetectorRef: ChangeDetectorRef, private route: ActivatedRoute) {
    super(intl, changeDetectorRef);
  }

  ngAfterViewInit() {
    this.route.queryParamMap.subscribe((paramMap) => {
      console.log({ paramMap });
      const pageIndex = Number(paramMap.get('page')) - 1;
      const pageSize = Number(paramMap.get('size'));
      if (pageSize) {
        this.pageSize = pageSize;
        this.paginator.pageSize = pageSize;
      }
      if (pageIndex) {
        this.pageIndex = pageIndex;
        this.paginator.pageIndex = pageIndex;
      }
    });
  }
}
