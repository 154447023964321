<div class="relative h-full">
  <div class="flex flex-col justify-between h-full" [ngClass]="done ? 'opacity-75' : ''">
    <div>
      <span class="font-normal text-sm opacity-75 mb-1 block">Schritt {{ step }}</span>
      <ui-headline [size]="'sm'" [heading]="heading"></ui-headline>
      <p class="mt-4 text-sm">
        <ng-content></ng-content>
      </p>
    </div>
    <ui-button
      *ngIf="cta"
      [icon]="icon"
      [label]="cta"
      [isFullWidth]="true"
      [color]="done ? 'gray' : 'goblin'"
      class="block mt-8"
      (cclick)="onAction.emit()"
    ></ui-button>
  </div>
  <div
    *ngIf="done"
    class="absolute top-0.5 right-0 w-10 h-10 flex items-center justify-center bg-klee-20/50 text-klee-100 rounded-full"
  >
    <ui-icon [icon]="'check-circle'" class="block w-6 h-6"></ui-icon>
  </div>
</div>
