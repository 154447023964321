import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableBaseComponent } from './components/table-base.component';
import { DialogModule } from '@angular/cdk/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';

@NgModule({
  declarations: [TableBaseComponent],
  imports: [CommonModule, DialogModule, MatTableModule, MatPaginatorModule, MatSortModule],
  exports: [TableBaseComponent, DialogModule, MatTableModule, MatPaginatorModule, MatSortModule]
})
export class TableBaseModule {}
