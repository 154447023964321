import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Person, PersonFragmentFragment } from '@tx/api';
import { ContextMenuItem } from '@tx/ui';

type UserCardPerson = Pick<PersonFragmentFragment, 'id' | 'firstName' | 'lastName' | 'email'>;

@Component({
  selector: 'tx-portal-user-card',
  templateUrl: './user-card.component.html',
})
export class UserCardComponent {
  @Input()
  user!: UserCardPerson;

  @Input()
  disabled: boolean = false;

  @Input()
  contextMenu!: Array<ContextMenuItem[]>;

  @Output()
  contextMenuClick: EventEmitter<string> = new EventEmitter();
}
