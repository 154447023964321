import colors from './colors';

export const environment = {
  production: false,
  apiBasePath: 'https://my-dev.telemaxx.de/api',
  graphqlEndpoint: 'https:///my-dev.telemaxx.de/api/graphql',
  graphqlEndpointWs: 'wss:///my-dev.telemaxx.de/api/graphql',
  services: {
    s3: {
      defaultConfig: {
        endpoint: 'https://s3-stage.telemaxx.cloud',
        region: 'default',
        tls: true
      }
    }
  },
  oidc: {
    clientId: 'account',
    issuer: 'https://auth-dev.telemaxx.de/realms/TelemaxX'
  },
  colors
};
