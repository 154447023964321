<div
  [ngClass]="[isPastViewpoint ? '!-translate-y-[0px]' : '']"
  class="-translate-y-[calc(100%+26px)] md:-translate-y-[calc(100%-42px*-1)] sticky top-0 inset-x-0 -ml-4 md:-ml-8 -mr-4 md:-mr-8 px-4 md:px-8 transition-all ease-in-out duration-300 z-30 shadow-lg shadow-black/5 bg-white py-4 md:py-8"
>
  <div class="max-w-[calc(100%-38px-16px)] md:max-w-none">
    <ui-headline [heading]="heading" [subheading]="subheading"></ui-headline>
  </div>
</div>

<div [ngClass]="isSubheadingTwoRows ? '-mt-[110px]' : '-mt-[86px]'" class="mb-4 md:mb-8 md:-mt-[118px]">
  <div class="max-w-[calc(100%-38px-16px)] md:max-w-none">
    <ui-headline [heading]="heading" [subheading]="subheading"></ui-headline>
  </div>
</div>
