import { Inject, Injectable } from '@angular/core';
import { IAMClient, GetUserCommand, IAM } from '@aws-sdk/client-iam';
import { STSClient, AssumeRoleWithWebIdentityCommand, Credentials } from '@aws-sdk/client-sts';
import { OAuthService } from 'angular-oauth2-oidc';
import { BucketVersioningStatus, ObjectLockEnabled, ObjectLockRule, S3 } from '@aws-sdk/client-s3';
import { fromWebToken } from '@aws-sdk/credential-providers'; // ES6 import
import { firstValueFrom, map, of, switchMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';

export interface Bucket {
  id: string;
  bucket: string;
  creation_time: Date;
  owner: string;
  tenant: string;
  usage: {
    'rgw.main': {
      size_actual: number;
      num_objects?: number;
    };
  };
}

@Injectable()
export class ObjectStorageService {
  iam: IAM;
  sts: STSClient;
  s3: S3;

  iamCredentials?: Credentials;

  constructor(private oauthService: OAuthService, private http: HttpClient, @Inject('env') private env: any) {
    this.sts = new STSClient(env.services.s3.defaultConfig);
    this.s3 = new S3(env.services.s3.defaultConfig);
    this.iam = new IAM(env.services.s3.defaultConfig);
  }

  generateAccessKey() {
    const length = 20;
    let result = '';
    const characters = 'ABCDEFGHIJKMNPQRSTUVWXYZ123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  async buyS3(context: Record<string, any>) {
    return firstValueFrom(
      this.http.post<any>(`${this.env.apiBasePath}/object-storage/buy`, {
        acceptedDataprivacyAndAGB: context['s3'],
        acceptedRightOfWithdrawal: context['withdrawal'],
        acceptedServiceDescription: context['policy'],
        orgId: context['orgId']
      })
    );
  }

  async getUserPolicy(username: string, policyname: string) {
    return this.iam.getUserPolicy({
      UserName: username,
      PolicyName: policyname
    });
  }

  async deletePolicy(username: string, policyname: string) {
    return this.iam.deleteUserPolicy({
      UserName: `${username}`,
      PolicyName: policyname
    });
  }

  async putUserPolicy(account: any, policy: any, tenant: string) {
    return this.iam.putUserPolicy({
      UserName: `${tenant}$${account.identifier}`,
      PolicyDocument: policy.code,
      PolicyName: policy.name
    });
  }
  async loadUserPolicies(UserName: string, tenant: string) {
    return this.iam.listUserPolicies({
      UserName: `${tenant}$${UserName}`
    });
  }

  async saveBucketAccessPolicy(bucket: string, policy: string) {
    const policyRes = this.s3.putBucketPolicy({
      Bucket: bucket,
      Policy: policy
    });
    return Promise.all([policyRes]);
  }
  async getBucketDetails(bucket: Bucket) {
    const versioning = this.s3
      .getBucketVersioning({
        Bucket: bucket.bucket
      })
      .catch((e) => undefined);

    const retention = this.s3
      .getObjectLockConfiguration({
        Bucket: bucket.bucket
      })
      .catch((e) => undefined);

    const policy = this.s3
      .getBucketPolicy({
        Bucket: bucket.bucket
      })
      .catch((e) => undefined);

    return Promise.all([versioning, retention, policy]);
  }

  async deleteBucket(bucket: any) {
    return this.s3.deleteBucket({
      Bucket: bucket.bucket
    });
  }

  async saveBucketRetentionSettings(
    bucket: any,
    objectLockRule?: ObjectLockRule,
    ObjectLockEnabledForBucket: boolean = true
  ) {
    await this.enableBucketVersioning(bucket);
    await this.s3.putObjectLockConfiguration({
      Bucket: bucket.bucket,
      ObjectLockConfiguration: {
        ObjectLockEnabled: ObjectLockEnabledForBucket ? ObjectLockEnabled.Enabled : undefined,
        Rule: objectLockRule
      }
    });
  }
  async disableBucketVersioning(bucket: any) {
    await this.s3.putBucketVersioning({
      Bucket: bucket.bucket,
      VersioningConfiguration: {
        Status: BucketVersioningStatus.Suspended
      }
    });
  }
  async enableBucketVersioning(bucket: any) {
    await this.s3.putBucketVersioning({
      Bucket: bucket.bucket,
      VersioningConfiguration: {
        Status: BucketVersioningStatus.Enabled
      }
    });
  }

  async createBucket(
    Bucket: string,
    objectLockRule?: ObjectLockRule,
    ObjectLockEnabledForBucket: boolean = false,
    versioning: boolean = false,
    policy?: string,
    tenant?: string,
    user?: string
  ) {
    const bucket = await this.s3.createBucket({ Bucket, ObjectLockEnabledForBucket });

    if (versioning) {
      await this.s3.putBucketVersioning({
        Bucket,
        VersioningConfiguration: {
          Status: BucketVersioningStatus.Enabled
        }
      });
    }
    if (ObjectLockEnabledForBucket && objectLockRule) {
      await this.s3.putObjectLockConfiguration({
        Bucket,
        ObjectLockConfiguration: {
          ObjectLockEnabled: ObjectLockEnabled.Enabled,
          Rule: objectLockRule
        }
      });
    }
    if (tenant && policy && policy.length > 0 && policy !== '') {
      this.s3.putBucketPolicy({
        Bucket,
        Policy: policy
      });
    }
    if (user) {
      try {
        await firstValueFrom(
          this.http.get<any>(`${this.env.apiBasePath}/object-storage/${tenant}/bucket/${Bucket}?user=${user}`)
        );
      } catch (e) {
        throw new Error('ServiceAccount konnte nicht verknüpft werden.');
      }
    }
    return bucket;
  }

  async createKey(tenant: string, uid: string) {
    const key = this.generateAccessKey();
    const res = await firstValueFrom(
      this.http.put<any>(`${this.env.apiBasePath}/object-storage/${tenant}/user/key`, {
        uid,
        'access-key': key
      })
    );
    return {
      keys: res,
      newKey: res.find((keys: any) => keys.access_key === key)
    };
  }

  async deleteKey(tenant: string, key: string) {
    return firstValueFrom(
      this.http.delete<any>(`${this.env.apiBasePath}/object-storage/${tenant}/user/key?access-key=${key}`)
    );
  }

  async createUser(tenant: string, uid: string) {
    return firstValueFrom(
      this.http.put<any>(`${this.env.apiBasePath}/object-storage/${tenant}/user`, {
        'display-name': uid,
        uid
      })
    );
  }

  async deleteUser(tenant: string, id: string) {
    return firstValueFrom(
      this.http.delete<string[]>(`${this.env.apiBasePath}/object-storage/${tenant}/user?uid=${tenant}$${id}`)
    );
  }

  async getUserList(tenant: string) {
    return firstValueFrom(this.http.get<string[]>(`${this.env.apiBasePath}/object-storage/${tenant}/users`));
  }

  async getUserListAndPolicies(tenant: string) {
    const users = await firstValueFrom(
      this.http.get<string[]>(`${this.env.apiBasePath}/object-storage/${tenant}/users`)
    );

    const userList: any = (
      await Promise.all(
        users.map(async (user) => {
          try {
            const userPolicies = await this.iam.listUserPolicies({
              UserName: user
            });
            return {
              user: user,
              policies: userPolicies.PolicyNames
            };
          } catch (e) {
            return {
              user: user,
              policies: null
            };
          }
        })
      )
    ).filter((res) => res !== null);
    return userList;
  }

  getBuckets(tenant: string) {
    return this.http.get<Bucket[]>(
      `${this.env.apiBasePath}/object-storage/${tenant}/buckets/stats?format=json&stats=true`
    );
  }

  getBucketStatsAggregated(tenant: string) {
    return this.getBuckets(tenant).pipe(
      map((buckets) => {
        return buckets.reduce(
          (res, curr) => {
            res['buckets']++;
            if (curr['usage']['rgw.main']?.num_objects) {
              res['files'] += curr['usage']['rgw.main'].num_objects;
            }
            if (curr['usage']['rgw.main']?.size_actual) {
              res['size'] += curr['usage']['rgw.main'].size_actual;
            }
            return res;
          },
          { buckets: 0, files: 0, size: 0 }
        );
      })
    );
  }

  assumeRoleWithWebIdentity(tenant: string) {
    return new Promise((resolve, reject) => {
      const params = {
        RoleArn: `arn:aws:iam::${tenant}:role/tenant`,
        WebIdentityToken: this.oauthService.getAccessToken(),
        RoleSessionName: `kdc-${Date.now()}`
      };
      const command = new AssumeRoleWithWebIdentityCommand(params);

      this.sts.send(command, (err, data) => {
        if (!err && data?.Credentials && data?.Credentials.AccessKeyId && data?.Credentials.SecretAccessKey) {
          this.iamCredentials = data.Credentials;
          this.s3 = new S3({
            ...this.env.services.s3.defaultConfig,
            forcePathStyle: true,
            credentials: {
              accessKeyId: data.Credentials.AccessKeyId,
              secretAccessKey: data.Credentials.SecretAccessKey,
              sessionToken: data.Credentials.SessionToken,
              expiration: data.Credentials.Expiration
            }
          });
          this.iam = new IAM({
            ...this.env.services.s3.defaultConfig,
            credentials: {
              accessKeyId: data.Credentials.AccessKeyId,
              secretAccessKey: data.Credentials.SecretAccessKey,
              sessionToken: data.Credentials.SessionToken,
              expiration: data.Credentials.Expiration
            }
          });

          resolve(data);
        }
      });
    });
  }
}
