import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export function inArrayValidator(validValues: any[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (validValues.some((validValue) => validValue === value)) {
      return null; // Value is valid, no error
    } else {
      return { inArray: true }; // Value not found in array, validation error
    }
  };
}
