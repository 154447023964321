<button
  type="button"
  class="flex items-center justify-start w-full cursor-pointer rounded-xl transition-none"
  [ngClass]="[isActive ? 'bg-gray-100' : 'hover:bg-gray-100', collapsed ? 'justify-center py-3' : 'p-4 justify-start']"
  (click)="clickCheck($event)"
  title="Profil"
>
  <ui-avatar
    [firstname]="firstname"
    [lastname]="lastname"
    [size]="'sm'"
    [ngClass]="collapsed ? '' : 'mr-4'"
  ></ui-avatar>
  <div class="flex flex-col" *ngIf="!collapsed">
    <span class="font-semibold leading-snug text-left" *ngIf="firstname !== 'null'"
      >{{ firstname }} {{ lastname }}</span
    >
    <span class="font-semibold leading-snug text-left" *ngIf="firstname === 'null'">Ihr Profil</span>
    <span class="leading-snug text-left text-xs">
      <!-- {{ this.companyName ? fixCompanyName(this.companyName) : 'Privat' }} -->
      {{ email | inputSlicer : 32 }}
    </span>
  </div>
  <ng-container *ngIf="companies$ | async as companies">
    <div class="ml-auto" *ngIf="!collapsed && companies.length !== 1" #contextMenu>
      <ui-context-menu
        [icon]="'arrow-path'"
        [hasContent]="true"
        [contextMenu]="contextMenu$ | async"
        (contextMenuClick)="contextMenuClick($event)"
      >
        <span class="text-sm font-medium">Wählen Sie Ihre Organisation</span>
      </ui-context-menu>
    </div>
  </ng-container>
</button>
