<button
  [ngClass]="[
    'button',
    'button-' + this.size,
    'button-' + this.color,
    'button-' + this.type,
    'button-' + this.align,
    this.isFullWidth ? 'w-full justify-center' : '',
    this.isLoading ? 'button-loading' : '',
    this.isDisabled ? 'button-disabled' : '',
    this.isOnlyIcon ? 'button-icon' : ''
  ]"
  [disabled]="disabled"
  type="button"
  (click)="cclick.emit($event)"
>
  <ui-icon
    class="button-icon-left"
    *ngIf="icon"
    [icon]="icon"
    [ngClass]="[this.size === 'sm' ? 'w-5 h-5' : this.size === 'xs' ? 'w-4 h-4' : 'h-6 w-6']"
  ></ui-icon>
  <span class="button-label" *ngIf="label !== ''; else elseBlock">
    {{ label }}
  </span>
  <ng-template #elseBlock>
    <span class="button-label">
      <ng-content></ng-content>
    </span>
  </ng-template>
  <div *ngIf="isLoading" class="button-load-helper"></div>
</button>
