import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { S3Component } from './s3.component';
import { UiModule } from '@tx/ui';
import { BucketsComponent } from './buckets/buckets.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AccountsComponent } from './accounts/accounts.component';
import { PoliciesComponent } from './policies/policies.component';
import { BucketItemComponent } from './buckets/bucket-item/bucket-item.component';
import { DetailComponent } from './buckets/detail/detail.component';
import { TxS3Module } from '@tx/s3';
import { BytesPipe } from './bytes.pipe';
import { PermissionPipe } from './permission.pipe';
import { NgChartsModule } from 'ng2-charts';
import { AddServiceAccountModal } from './add-service-account-modal/add-service-account-modal.component';
import { FirstStepsComponent } from './first-steps/first-steps.component';
import { StepComponent } from './first-steps/step/step.component';
import { CreateBucketModalComponent } from './create-bucket-modal/create-bucket-modal.component';
import { CreateCredentialsModalComponent } from './create-credentials-modal/create-credentials-modal.component';

@NgModule({
  declarations: [
    S3Component,
    BucketsComponent,
    DashboardComponent,
    AccountsComponent,
    PoliciesComponent,
    BucketItemComponent,
    DetailComponent,
    FirstStepsComponent,
    StepComponent,
    AddServiceAccountModal,
    BytesPipe,
    PermissionPipe,
    CreateBucketModalComponent,
    CreateCredentialsModalComponent
  ],
  imports: [CommonModule, UiModule, TxS3Module, NgChartsModule],
  exports: [S3Component]
})
export class S3Module {}
