<div class="flex items-center">
  <div class="flex flex-col gap-2">
    <div class="flex flex-col">
      <span class="font-semibold">{{ bucket.bucket }}</span>
      <span class="text-gray-400 text-sm">Erstellt am {{ bucket.creation_time | date }}</span>
      <span class="text-gray-400 text-sm">Besitzer: {{ bucket.owner }}</span>
    </div>
  </div>
  <div class="flex-col flex items-center ml-16" *ngIf="bucket.usage['rgw.main']">
    <div class="flex gap-1 items-center">
      <ui-icon [icon]="'chart-pie'" class="w-4 h-4"></ui-icon>
      <span>Usage</span>
    </div>
    <div class="text-xl font-medium">
      {{ bucket.usage['rgw.main'].size_actual | bytes }} {{ bucket.usage['rgw.main'].size_actual | bytes : true }}
    </div>
  </div>
</div>
<ui-forms-input-copy
  class="mt-4 w-full"
  [value]="'arn:aws:s3::' + this.serviceInstance.ptid + ':' + bucket.bucket"
  (onCopy)="this.onCopy($event)"
></ui-forms-input-copy>
<div ui-card class="border border-gray-200 mt-6" *ngIf="bucketDetails | async as _bucketDetails">
  <div class="flex items-center justify-start gap-2">
    <span class="font-semibold text-lg">Bucket Policy</span>
    <ui-button
      [icon]="'pencil'"
      [color]="'gray'"
      [isOnlyIcon]="true"
      size="xs"
      (cclick)="isAccessPolicyModalOpen = true"
    ></ui-button>
  </div>
  <div class="grid grid-cols-3 mt-3 pt-3 border-t border-gray-200">
    <div class="flex flex-col"></div>
  </div>

  <div class="flex items-center justify-start gap-2 mt-8">
    <span class="font-semibold text-lg">Versioning</span>
    <ui-button
      [icon]="'pencil'"
      [color]="'gray'"
      [isOnlyIcon]="true"
      size="xs"
      (cclick)="isVersioningModalOpen = true"
    ></ui-button>
  </div>
  <div class="grid grid-cols-3 mt-3 pt-3 border-t border-gray-200">
    <div class="flex flex-col">
      <span class="font-semibold">Status</span>
      <span>{{ _bucketDetails.versioning?.Status ? _bucketDetails.versioning.Status : 'Disabled' }}</span>
    </div>
  </div>
  <div class="flex items-center justify-start gap-2 mt-8">
    <span class="font-semibold text-lg">Object Locking</span>
  </div>
  <div class="grid grid-cols-3 mt-3 pt-3 border-t border-gray-200">
    <div class="flex flex-col">
      <span class="font-semibold">Status</span>
      <span>{{
        _bucketDetails.retention?.ObjectLockConfiguration
          ? _bucketDetails.retention?.ObjectLockConfiguration.ObjectLockEnabled
          : 'Disabled'
      }}</span>
    </div>
  </div>
  <div class="flex items-center justify-start gap-2 mt-8">
    <span class="font-semibold text-lg">Retention Policy</span>
    <ui-button
      [icon]="'pencil'"
      [color]="'gray'"
      [isOnlyIcon]="true"
      size="xs"
      (cclick)="isRetentionModalOpen = true"
      *ngIf="_bucketDetails.retention?.ObjectLockConfiguration?.ObjectLockEnabled"
    ></ui-button>
  </div>
  <div class="grid grid-cols-3 mt-3 pt-3 border-t border-gray-200">
    <div class="flex flex-col">
      <span class="font-semibold">Status</span>
      <span>{{ editRetentionModeForm.value.retention ? 'Enabled' : 'Disabled' }}</span>
    </div>
    <div class="flex flex-col">
      <span class="font-semibold">Modus</span>
      <span>{{
        _bucketDetails.retention?.ObjectLockConfiguration
          ? _bucketDetails.retention?.ObjectLockConfiguration?.Rule?.DefaultRetention.Mode
          : '-'
      }}</span>
    </div>
    <div class="flex flex-col">
      <span class="font-semibold">Validity</span>
      <!-- *ngIf="_bucketDetails.retention?.ObjectLockConfiguration?.Rule.DefaultRetention.Mode" -->
      <span>{{
        _bucketDetails.retention?.ObjectLockConfiguration?.Rule?.DefaultRetention?.Days
          ? _bucketDetails.retention?.ObjectLockConfiguration?.Rule?.DefaultRetention?.Days + ' Tage'
          : '-'
      }}</span>
    </div>
  </div>
</div>

<ui-modal
  [isModalOpen]="isAccessPolicyModalOpen"
  [title]="'Bucket Policy ändern'"
  (onClose)="isAccessPolicyModalOpen = false; resetPolicyModal()"
  (onSubmit)="saveAccessPolicy(bucket)"
>
  <p class="mb-2">
    Ändern Sie die Zugriffsrechte für den Bucket. Sie können zwischen den vordefinierten Zugriffsrechten wählen oder
    eine eigene Policy definieren.
  </p>
  <div class="mt-4">
    <span class="block text-sm font-medium text-gray-700 mb-2">Bucket Policy</span>
    <div class="border-y border-gray-200 -mx-4 sm:-mx-8" *ngIf="isAccessPolicyModalOpen">
      <ui-forms-codearea
        [model]="policyModel"
        (codeChanged)="editAccessPolicyForm.patchValue({ code: $event })"
      ></ui-forms-codearea>
    </div>
  </div>
</ui-modal>

<ui-modal
  *ngIf="bucketDetails | async as _bucketDetails"
  [isModalOpen]="isObjectLockingModalOpen"
  [title]="'Object Locking ändern'"
  (onClose)="isObjectLockingModalOpen = false"
  (onSubmit)="isObjectLockingModalOpen = false"
>
  <p>Ändern Sie die Object Locking Einstellungen für den Bucket.</p>
</ui-modal>

<ui-modal
  [isModalOpen]="isVersioningModalOpen"
  [title]="'Versioning ändern'"
  label=""
  (onClose)="isVersioningModalOpen = false"
  [showButtons]="false"
>
  <p>
    Ändern Sie die Versioning Einstellungen für den Bucket. Wenn Versioning aktiviert ist, werden alle Objekte, die
    gelöscht werden, in Versioning gespeichert. Sie können Versioning jederzeit wieder deaktivieren.
  </p>
  <p class="mt-2">Es werden keine Daten gelöscht.</p>
  <br />
  <ui-info *ngIf="(bucketDetails | async)?.retention?.ObjectLockConfiguration?.ObjectLockEnabled">
    Versioning kann nicht deaktiviert werden, da Object Locking aktiviert ist.
  </ui-info>
  <div class="flex items-center justify-end gap-4 mt-6" *ngIf="bucketDetails | async as _bucketDetails">
    <ui-button [size]="'sm'" [color]="'gray'" (cclick)="isVersioningModalOpen = false">Abbrechen</ui-button>
    <ui-button
      [size]="'sm'"
      [color]="'red'"
      (cclick)="disableVersioning(bucket)"
      [isDisabled]="!!_bucketDetails.retention?.ObjectLockConfiguration?.ObjectLockEnabled"
      *ngIf="_bucketDetails.versioning?.Status === 'Enabled'"
      >Versioning abschalten</ui-button
    >
    <ui-button
      [size]="'sm'"
      [color]="'goblin'"
      (cclick)="enableVersioning(bucket)"
      *ngIf="_bucketDetails.versioning?.Status === 'Suspended' || !_bucketDetails.versioning?.Status"
      >Versioning einschalten</ui-button
    >
  </div>
</ui-modal>

<ui-modal
  [isModalOpen]="isRetentionModalOpen"
  [title]="'Retention Policy Einstellungen ändern'"
  label=""
  (onClose)="isRetentionModalOpen = false"
  [showButtons]="false"
>
  <ui-forms-checkbox [id]="'retention'" [parentForm]="editRetentionModeForm" [controlName]="'retention'">
    <span class="font-bold">Retention Policy</span><br /><span class="font-normal">
      Diese Einstellung ändert die Retention Einstellungen für den Bucket. Die Retention bestimmt, wie lange alte
      Versionen von Objekten aufbewahrt werden.
    </span>
  </ui-forms-checkbox>
  <div *ngIf="editRetentionModeForm.controls.retention?.value" class="grid grid-cols-2 gap-4 ml-7 mt-2">
    <ui-forms-select
      [parentForm]="editRetentionModeForm"
      [controlName]="'retentionMode'"
      [label]="'Modus'"
      [options]="retentionModeOptions"
    ></ui-forms-select>
    <ui-forms-input
      [parentForm]="editRetentionModeForm"
      [controlName]="'retentionValidity'"
      [label]="'Validity'"
      [type]="'number'"
      [addon]="'Tage'"
    ></ui-forms-input>
  </div>
  <div class="flex items-center justify-end gap-4 mt-6">
    <ui-button [size]="'sm'" [color]="'gray'" (cclick)="isRetentionModalOpen = false">Abbrechen</ui-button>
    <ui-button [size]="'sm'" [color]="'goblin'" (cclick)="saveRetentionSettings(bucket)">Speichern</ui-button>
  </div>
</ui-modal>
