<div
  class="flex gap-4 py-3 px-4 items-center rounded-xl text-sm"
  [ngClass]="[
    type === 'info' ? 'text-blue-500 bg-blue-500/10' : '',
    type === 'warning' ? 'text-amber-600 bg-amber-600/10' : '',
    type === 'danger' ? 'text-red-500 bg-red-500/10' : '',
    type === 'success' ? 'text-lime-600 bg-lime-600/10' : ''
  ]"
>
  <ng-content></ng-content>
</div>
