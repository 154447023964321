import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'ui-form-wrapper',
  templateUrl: './form-wrapper.component.html'
})
export class FormWrapperComponent {
  @Input()
  parentForm!: FormGroup;
}
