import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Icon } from '@n/nui';

@Component({
  selector: 'ui-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagComponent {
  @Input()
  label = '';

  @Input()
  content: string | null = '';

  @Input()
  loading = false;

  @Input()
  type:
    | 'goblin'
    | 'red'
    | 'amber'
    | 'lime'
    | 'blue'
    | 'purple'
    | 'mono' | 'mono-lighter' | 'mono-darker'
    | 'bronze'
    | 'silver'
    | 'gold'
    | 'green'
    | 'platin'
    | 'eblue' = 'goblin';

  @Input()
  icon: Icon | undefined = undefined;

  @Input()
  isBroken: boolean = false;
}
