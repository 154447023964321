import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// components
import { IconComponent } from './icon/icon.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { PaginatorComponent } from './paginator/paginator.component';

@NgModule({
  declarations: [IconComponent, PaginatorComponent],
  imports: [CommonModule, MatPaginatorModule],
  exports: [IconComponent, PaginatorComponent, MatPaginatorModule]
})
export class NuiModule {}
