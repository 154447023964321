<ui-modal
  *ngIf="isAddServiceAccountModalOpen"
  [isModalOpen]="isAddServiceAccountModalOpen"
  [title]="'Service-Account hinzufügen'"
  (onClose)="closeAddServiceAccountModal.emit()"
  (onSubmit)="addServiceAccountWrapper()"
  [submitLabel]="'Service-Account erstellen'"
>
  <ui-forms-input
    name="identifier"
    label="Service-Account Bezeichnung"
    description="Die Bezeichnung des Service-Accounts. Wird verwendet um den Service-Account zu identifizieren und ist Teil der ARN."
    [parentForm]="serviceAccountForm"
    [controlName]="'identifier'"
  ></ui-forms-input>

  <ui-forms-input-copy
    class="mt-2"
    description="ARN des Service-Accounts. Wird automatisch generiert."
    [value]="'arn:aws:iam::' + serviceInstance.ptid + ':user/' + serviceAccountForm.controls.identifier.value"
    (onCopy)="this.onCopy($event)"
  ></ui-forms-input-copy>
</ui-modal>
