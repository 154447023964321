import { Component, Input, OnDestroy, TemplateRef } from '@angular/core';
import { ComponentType } from '@angular/cdk/overlay';
import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { filter, map } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'table-base',
  template: '',
  styleUrls: ['./table-base.component.scss']
})
export class TableBaseComponent {
  @Input() data: any[] = [];

  detailComponent?: ComponentType<any>;

  private dialogRef: DialogRef<any> | null = null;

  constructor(private dialog: Dialog, private router: Router, private route: ActivatedRoute) {
    dialog.afterAllClosed.subscribe(() => {
      if (this.dialogRef) {
        this.dialogRef = null;
        this.navigateToBaseRoute();
      }
    });
  }

  initDetailView() {
    const id = this.route.snapshot.paramMap.get('id');

    if (id && this.detailComponent) {
      this.dialogRef = this.dialog.open(this.detailComponent, { data: { id } });
    }
  }

  navigateToBaseRoute() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
