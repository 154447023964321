import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { Option } from '../combobox/combobox.component';

@Component({
  selector: 'ui-forms-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomSelectComponent implements OnInit {
  isOpen = false;
  itemStyles = 'text-gray-900';
  descStyles = 'text-gray-500';
  checkStyles = 'text-blue-500';
  highlightIndex!: number;

  @Input()
  selectedValue!: Option | null;

  @Input()
  name = '';

  @Input()
  label = '';

  @Input()
  options!: Array<Option>;

  @Input()
  optionsAsColumn: boolean = false;

  @Output()
  cchange = new EventEmitter<Option>();

  ngOnInit(): void {
    if (!this.selectedValue) {
      this.selectedValue = this.options[0];
    }
  }

  select(option: Option) {
    this.selectedValue = option;
    this.cchange.emit(option);
    this.isOpen = false;
  }

  highlightItem(event: MouseEvent, index: number) {
    this.highlightIndex = index;
    this.itemStyles = event.type == 'mouseenter' ? 'text-white bg-blue-500' : 'text-gray-900';
    this.descStyles = event.type == 'mouseenter' ? 'text-blue-200' : 'text-gray-500';
  }
}
