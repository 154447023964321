<tx-portal-create-bucket-modal
  (createBucket)="createBucket.emit($event)"
  [serviceInstance]="serviceInstance"
  [serviceAccountOptions$]="serviceAccountOptions$"
  (jumpToServiceAccountsTab)="jumpToServiceAccountsTab.emit()"
  [bucketList$]="bucketList$"
></tx-portal-create-bucket-modal>

<div class="flex flex-wrap items-center justify-between gap-4" *ngIf="!detailBucket$.value">
  <ui-forms-input
    [icon]="'magnifying-glass'"
    [placeholder]="'Suche'"
    [name]="'buckets-search'"
    [parentForm]="searchForm"
    [controlName]="'searchterm'"
    ngDefaultControl
    class="w-full md:w-64"
  ></ui-forms-input>
  <div class="flex flex-row justify-between w-full md:w-auto gap-2">
    <ui-button
      [icon]="'arrow-path'"
      [color]="'gray'"
      [isOnlyIcon]="true"
      size="sm"
      (cclick)="reloadBuckets.emit()"
    ></ui-button>
    <ui-button [icon]="'plus-small'" [color]="'goblin'" size="sm" (cclick)="openModal('create-bucket')"
      >Bucket erstellen</ui-button
    >
  </div>
</div>
<div class="flex items-center justify-between gap-4 flex-wrap" *ngIf="detailBucket$.value">
  <ui-button
    [icon]="'arrow-left'"
    [color]="'gray'"
    size="sm"
    class="w-full md:w-64"
    [isFullWidth]="true"
    (cclick)="detailBucket$.next(null)"
    >Zurück zur Übersicht</ui-button
  >
  <div class="flex flex-row justify-between w-full md:w-auto gap-2">
    <ui-button
      [icon]="'arrow-path'"
      [color]="'gray'"
      [isOnlyIcon]="true"
      size="sm"
      (cclick)="reloadBuckets.emit()"
    ></ui-button>
    <ui-button [icon]="'v-trash'" [color]="'red'" size="sm" (cclick)="isDeleteBucketModalOpen = true"
      >Bucket löschen</ui-button
    >
  </div>
</div>
<div class="flex flex-col mt-6 gap-4 overflow-y-scroll max-h-[calc(100vh-413px)]" *ngIf="!detailBucket$.value">
  <tx-portal-bucket-item
    (manage)="openDetailView($event)"
    [serviceInstance]="serviceInstance"
    *ngFor="let bucket of searchedBucketList$ | async"
    [bucket]="bucket"
  ></tx-portal-bucket-item>
  <span
    class="my-32 w-full flex text-center items-center justify-center text-gray-500 font-medium"
    *ngIf="(bucketList$ | async)?.length === 0"
    >Keine Buckets mit diesen Namen gefunden.</span
  >
</div>
<div class="mt-6" *ngIf="selectedBucket$ | async as _bucket">
  <tx-portal-bucket-detail [bucket]="_bucket" [serviceInstance]="serviceInstance"></tx-portal-bucket-detail>
</div>

<ui-modal
  [isModalOpen]="isDeleteBucketModalOpen"
  [title]="'Bucket löschen'"
  (onClose)="isDeleteBucketModalOpen = false"
  [showButtons]="false"
>
  <ng-container *ngIf="!!(selectedBucket$ | async) as _selectedBucket">
    <p>
      Leere S3-Buckets können gelöscht werden.<br />
      Buckets dürfen keine Objekte/Daten mehr enthalten, damit sie gelöscht werden können.<br />
      Nach dem Löschen wird der Bucket-Name wieder freigegeben und kann erneut verwendet werden.
    </p>
    <ui-info *ngIf="!(selectedBucketIsEmpty$ | async)" class="mt-4">
      Der Bucket enthält noch Objekte/Daten und kann daher nicht gelöscht werden. Löschen sie alle Objekte/Daten im
      Bucket über die Kommandozeile oder einem S3-Client und versuchen sie es erneut.
    </ui-info>

    <div class="flex items-center justify-end gap-4 mt-6">
      <ui-button [size]="'sm'" [color]="'gray'" (cclick)="isDeleteBucketModalOpen = false">Abbrechen</ui-button>
      <ui-button
        [size]="'sm'"
        [icon]="'v-trash'"
        [color]="'red'"
        [isDisabled]="!(selectedBucketIsEmpty$ | async)"
        (cclick)="deleteBucketWrapper(detailBucket$.value)"
        >Bucket löschen</ui-button
      >
    </div>
  </ng-container>
</ui-modal>
