<ui-modal
  [isModalOpen]="isCreateCredentialsModalOpen"
  [title]="'Credentials erstellt'"
  label=""
  (onClose)="closeCreateCredentialsModal.emit()"
  (onSubmit)="closeCreateCredentialsModal.emit()"
  [submitLabel]="'Ich habe alles gespeichert!'"
  [isSubmitDanger]="true"
  [isCloseButtonVisible]="false"
>
  <div class="flex flex-col gap-4" *ngIf="_generatedAccessKey">
    <p>
      Credentials für den Service-Account
      <span class="font-semibold">{{ this._generatedAccessKey?.user }}</span> wurden erstellt.
    </p>
    <div>
      <ui-forms-input-copy
        class="mt-4 w-full"
        label="Access Key"
        [value]="_generatedAccessKey.access_key"
        (onCopy)="this.onCopy($event)"
      ></ui-forms-input-copy>
    </div>
    <div>
      <ui-forms-input-copy
        class="mt-4 w-full"
        label="Secret Key"
        [value]="_generatedAccessKey.secret_key"
        (onCopy)="this.onCopy($event)"
      ></ui-forms-input-copy>
    </div>
    <ui-tag [label]="'Vorsicht'" [type]="'red'" [icon]="'exclamation-circle'"
      >Make sure you save it - you won't be able to access it again</ui-tag
    >
  </div>
</ui-modal>
