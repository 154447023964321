import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Icon } from '@n/nui';

@Component({
  selector: 'tx-portal-step',
  templateUrl: './step.component.html'
})
export class StepComponent {
  @Input() heading!: string;
  @Input() step!: number;
  @Input() done: boolean | null = false;
  @Input() cta!: string;
  @Input() icon: Icon | undefined;

  @Output() onAction: EventEmitter<any> = new EventEmitter();
}
