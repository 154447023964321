<div *ngIf="parentForm && parentForm.controls[controlName] && options" [formGroup]="parentForm">
  <label [for]="name" *ngIf="label" class="block mb-2 text-sm font-medium text-gray-700">{{ label }}</label>
  <div class="absolute inset-y-0 left-0 top-3 flex items-center h-full pl-3 pointer-events-none" *ngIf="icon">
    <ui-icon [icon]="icon" class="w-5 h-5 text-gray-400"></ui-icon>
  </div>
  <div class="relative">
    <select
      [name]="name"
      [formControlName]="controlName"
      class="block hover:cursor-pointer w-full py-2 pl-3 pr-10 rounded-md focus:outline-none focus:ring-blue-500 focus:border-gray-300 hover:border-blue-500 focus:ring-offset-2 text-sm"
      [class.invalid]="!control.valid"
      [attr.data-selected]="parentForm.controls[controlName].value"
      [ngClass]="[
        icon ? 'pl-10' : '',
        control.valid && control.touched ? 'border-goblin-400' : 'border-gray-300',
        !control.valid && control.touched ? errorBorderColor : 'border-gray-300',
        control.disabled
          ? 'hover:border-gray-300 focus:ring-0 focus:border-gray-300 bg-gray-50 text-gray-500'
          : 'hover:border-blue-500 focus:ring-blue-500 focus:border-gray-300 focus:ring-offset-2'
      ]"
    >
      <option *ngIf="placeholder" [ngValue]="''" [selected]="parentForm.controls[controlName].value === ''" disabled>
        {{ placeholder }}
      </option>
      <option
        *ngFor="let option of options"
        [ngValue]="option"
        [selected]="parentForm.controls[controlName].value === option && control.valid"
        [class.disabled]="selectable && selectable.length > 0 && selectable.includes(option)"
      >
        <ng-container *ngIf="option !== '#Empty'"> {{ option }} </ng-container>
      </option>
    </select>
    <ng-container
      *ngTemplateOutlet="errors || defaultErrors; context: { $implicit: control, name: name }"
    ></ng-container>
    <ng-template #defaultErrors let-control>
      <p *ngIf="control.errors && control.touched" class="mt-2 text-sm text-red-500" [id]="name + '-error'">
        {{ control.errors | errors : 1 }}
      </p>
    </ng-template>
  </div>
</div>
<div *ngIf="!parentForm">REFACTOR! PARENTFORM MISSING</div>
<div *ngIf="!parentForm.controls[controlName]">CONTROLNAME WRONG</div>
<div *ngIf="!options">OPTIONS MISSING</div>
