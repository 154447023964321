import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  Renderer2,
  ElementRef,
  ViewChild,
  ChangeDetectorRef,
  SimpleChanges
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounce, debounceTime, distinctUntilChanged, map, Observable, startWith, switchMap, timer } from 'rxjs';
import { Icon } from '@n/nui';
import { Option } from '../combobox/combobox.component';

@Component({
  selector: 'ui-forms-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiSelectComponent {
  @Input()
  selectedValues!: Option[] | undefined | null;
  selectedValuesPlain: any[] = [];
  initValuesPlain: any[] = [];
  
  @Input() allLabel: string = 'Alle Services'

  @Input()
  name = '';

  @Input()
  icon: Icon | null = null; // used

  @Input()
  readonly = false;

  @Input()
  label = '';

  @Input()
  showSelectAll = false;

  @Input()
  options$!: Observable<Option[] | undefined>;

  filteredOptions$!: Observable<Option[] | undefined>;

  @Output()
  cchange = new EventEmitter<any>();

  @Output()
  diffChange = new EventEmitter<[old: any[], new: any[]]>();

  searchInput = new FormControl();

  isOpen = false;

  @ViewChild('elem') elem!: ElementRef;
  constructor(private renderer: Renderer2, private cdr: ChangeDetectorRef) {
    /**
     * This events get called by all clicks on the page
     */
    this.renderer.listen('window', 'click', (e: Event) => {
      /**
       * Only run when toggleButton is not clicked
       * If we don't check this, all clicks (even on the toggle button) gets into this
       * section which in the result we might never see the menu open!
       * And the menu itself is checked here, and it's where we check just outside of
       * the menu and button the condition abbove must close the menu
       */

      if (!this.elem.nativeElement.contains(e.target)) {
        this.isOpen = false;
        this.cdr.detectChanges();
      }
    });
  }

  ngOnInit() {
    if (this.selectedValues != undefined) {
      this.selectedValuesPlain = this.selectedValues.map((s) => s.value);
      this.initValuesPlain = this.selectedValuesPlain;
    }
    this.filteredOptions$ = this.searchInput.valueChanges.pipe(
      startWith(''),
      debounce((val) => timer(val == '' ? 0 : 400)),
      distinctUntilChanged(),
      switchMap((search) =>
        this.options$.pipe(
          map((options) => {
            return options?.filter(
              (opt) => opt.value.toLowerCase().includes(search) || opt.label.toLowerCase().includes(search)
            );
          })
        )
      )
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedValues'] && !changes['selectedValues'].firstChange) {
      if (this.selectedValues) {
        this.selectedValuesPlain = this.selectedValues.map((s) => s.value);
        this.initValuesPlain = this.selectedValuesPlain;
      }
    }
  }

  changeValue() {
    if (!this.selectedValues) return;
    this.selectedValuesPlain = this.selectedValues.map((v) => v.value);
    this.cchange.emit(this.selectedValuesPlain);
    this.diffChange.emit([this.initValuesPlain, this.selectedValuesPlain]);
  }

  addAllValues(options: Option[]) {
    for (const option of options) {
      this.addSelectedValue(option);
    }
  }
  addSelectedValue(opt: any) {
    if (this.selectedValues == undefined) this.selectedValues = [];
    this.selectedValues.push(opt);
    this.changeValue();
    this.isOpen = false;
    this.searchInput.setValue('');
  }

  removeSelectedValue(index: number) {
    if (this.selectedValues == undefined) return;
    this.selectedValues.splice(index, 1);
    this.changeValue();
  }
}
