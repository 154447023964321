import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'ui-forms-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxComponent {
  @Input() parentForm!: FormGroup;
  @Input() controlName!:  string;
  @Input() label = '';
  @Input() description = '';
  @Input() id = '';

  changeValue() {
    if(this.parentForm.controls[this.controlName].disabled) return;
    let newValue = !this.parentForm.controls[this.controlName].value;
    this.parentForm.controls[this.controlName].setValue(newValue);
  }
}
