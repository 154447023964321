import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'tx-portal-first-steps',
  templateUrl: './first-steps.component.html'
})
export class FirstStepsComponent {
  @Input()
  bucketData$!: Observable<{ buckets: number; files: number; size: number }> | undefined;

  @Input() hasServiceAccounts$!: Observable<boolean>;

  @Output() stepClick: EventEmitter<number> = new EventEmitter();
}
