<div class="flex flex-col">
  <div class="flex items-center justify-start gap-4">
    <button
      *ngIf="!readonly"
      type="button"
      class="relative inline-flex flex-shrink-0 h-5 transition-colors duration-200 ease-in-out border-2 border-transparent rounded-md cursor-pointer w-9 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      [ngClass]="parentForm.controls[controlName].value ? 'bg-blue-500' : 'bg-gray-200'"
      role="switch"
      aria-checked="false"
      [attr.aria-labelledby]="id + '-label'"
      (click)="changeValue()"
    >
      <span
        aria-hidden="true"
        class="inline-block w-4 h-4 transition duration-200 ease-in-out transform bg-white rounded pointer-events-none ring-0"
        [ngClass]="parentForm.controls[controlName].value ? 'translate-x-4' : 'translate-x-0'"
      ></span>
    </button>
    <!-- start readonly fake button -->
    <div
      *ngIf="readonly"
      class="relative inline-flex flex-shrink-0 h-5 transition-colors duration-200 ease-in-out border-2 border-transparent rounded-md w-9"
      [ngClass]="parentForm.controls[controlName].value ? 'bg-blue-400' : 'bg-gray-200'"
    >
      <span
        aria-hidden="true"
        class="w-4 h-4 transition duration-200 ease-in-out transform bg-white rounded pointer-events-none ring-0 flex items-center justify-center"
        [ngClass]="parentForm.controls[controlName].value ? 'translate-x-4' : 'translate-x-0'"
      >
        <ui-icon [icon]="'lock-closed'" class="h-3 w-3 text-blue-400"></ui-icon>
      </span>
    </div>
    <!-- end readonly fake button -->
    <span class="text-sm font-medium text-gray-900 cursor-pointer" [id]="id + '-label'" *ngIf="readonly">{{
      label
    }}</span>
    <span
      class="text-sm font-medium text-gray-900 cursor-pointer"
      [id]="id + '-label'"
      (click)="changeValue()"
      *ngIf="!readonly"
      >{{ label }}</span
    >
  </div>
  <span class="block text-sm text-gray-500 mt-4 xl:mt-1 ml-0 xl:ml-[52px]" *ngIf="description">{{ description }}</span>
</div>
