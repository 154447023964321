<tx-portal-create-bucket-modal
  (createBucket)="createBucket($event)"
  (jumpToServiceAccountsTab)="jumpToServiceAccountsTab()"
  [serviceInstance]="serviceInstance"
  [serviceAccountOptions$]="serviceAccountOptions$"
  [bucketList$]="bucketList$"
></tx-portal-create-bucket-modal>
<tx-portal-add-service-account-modal
  (closeAddServiceAccountModal)="isAddServiceAccountModalOpen = false"
  (addServiceAccount)="addServiceAccount($event)"
  [serviceInstance]="serviceInstance"
  [isAddServiceAccountModalOpen]="isAddServiceAccountModalOpen"
></tx-portal-add-service-account-modal>
<tx-portal-create-credentials-modal
  (closeCreateCredentialsModal)="isCreateCredentialsModalOpen = false"
  [_generatedAccessKey]="_generatedAccessKey"
  [isCreateCredentialsModalOpen]="isCreateCredentialsModalOpen"
></tx-portal-create-credentials-modal>

<div ui-card class="flex flex-col gap-4" *ngIf="!disabled">
  <ui-tabs
    *ngIf="tabs"
    [objTabs]="tabs"
    (activeTabChange)="switchTab($event)"
    [activeTab]="activeTab$ | async"
    [onWhite]="true"
  ></ui-tabs>
  <hr class="my-2" />
  <ng-container *ngIf="activeTab$.value === tabs[0].value">
    <tx-portal-dashboard
      [bucketData$]="bucketData$"
      [bucketList$]="bucketList$"
      [serviceAccountLength$]="serviceAccountLength$"
      [userList]="userList | async"
    ></tx-portal-dashboard>
  </ng-container>
  <ng-container *ngIf="activeTab$.value === tabs[1].value">
    <tx-portal-buckets
      [bucketList$]="bucketList$"
      (reloadBuckets)="reloadBuckets()"
      (deleteBucket)="deleteBucket($event)"
      (createBucket)="createBucket($event)"
      (jumpToServiceAccountsTab)="jumpToServiceAccountsTab()"
      [serviceInstance]="serviceInstance"
      [serviceAccountOptions$]="serviceAccountOptions$"
    ></tx-portal-buckets>
  </ng-container>
  <ng-container *ngIf="activeTab$.value === tabs[2].value">
    <tx-portal-accounts
      (openAddServiceAccountModal)="isAddServiceAccountModalOpen = true"
      (closeAddServiceAccountModal)="isAddServiceAccountModalOpen = false"
      (addServiceAccount)="addServiceAccount($event)"
      [serviceInstance]="serviceInstance"
      [isAddServiceAccountModalOpen]="isAddServiceAccountModalOpen"
      (closeCreateCredentialsModal)="isCreateCredentialsModalOpen = false"
      (openCreateCredentialsModal)="isCreateCredentialsModalOpen = true"
      (writeGeneratedAccessKey)="_generatedAccessKey = $event"
      [_generatedAccessKey]="_generatedAccessKey"
      [isCreateCredentialsModalOpen]="isCreateCredentialsModalOpen"
    ></tx-portal-accounts>
  </ng-container>
</div>
<div class="mt-8" *ngIf="activeTab$.value === tabs[0].value">
  <tx-portal-first-steps
    [bucketData$]="bucketData$"
    [hasServiceAccounts$]="hasServiceAccounts$"
    (stepClick)="runAction($event)"
  ></tx-portal-first-steps>
</div>
