import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ContextMenuItem } from '@tx/ui';

@Component({
  selector: 'tx-portal-policies',
  templateUrl: './policies.component.html',
  styleUrls: ['./policies.component.scss']
})
export class PoliciesComponent implements OnInit {
  constructor(private fb: FormBuilder) {}

  searchForm = this.fb.group({
    searchterm: [''],
  })

    contextMenu: ContextMenuItem[][] = [
    [
      { label: 'Policy bearbeiten', icon: 'pencil', event: 'edit' },
      { label: 'User verwalten', icon: 'user-plus', event: 'manage' }
    ],
    [{ label: 'Policy löschen', icon: 'v-trash', event: 'delete', isDanger: true }]
  ];

  contextMenuClick(event: string) {
    switch (event) {
      case 'edit':
        return;
      case 'manage':
        return;
      case 'delete':
        return;
      default:
        break;
    }
  }

  ngOnInit(): void {}
}
