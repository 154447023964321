import { Injectable } from '@angular/core';
import { OpencloudProjectSortFields, OpencloudProjectsQuery, SortDirection, TxApi } from '@tx/api';
import { NotificationService } from '@tx/ui';
import { BaseService } from '@tx/shared';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

type OpencloudProjectType = OpencloudProjectsQuery['opencloudProjects']['nodes'][0];

@Injectable({
  providedIn: 'root'
})
export class OpencloudProjectService extends BaseService<OpencloudProjectType[], OpencloudProjectService, TxApi> {
  queryFunction = this.txApi.opencloudProjectsWatch.bind(this.txApi);
  queryOneFunction = undefined;
  updateOneFunction = this.txApi.updateOneOpencloudProject.bind(this.txApi);
  deleteOneFunction = this.txApi.deleteOneOpencloudProject.bind(this.txApi);
  createOneFunction = this.txApi.createOneOpencloudProject.bind(this.txApi);

  override sortableFields: string[] = ['name'];

  // May override the default page limit like this:
  // protected override _pageLimit: number = 20;

  updateManyFunction = undefined;

  constructor(txApi: TxApi, fb: FormBuilder, route: ActivatedRoute, notificationService: NotificationService) {
    super(txApi, fb, route, notificationService);
    this.init();

    // Example stuff
    this.pageInfoObservable$.subscribe((pageInfo) => {
      console.log(pageInfo);
    });

    setTimeout(() => {
      this.filter({ name: { iLike: '$est' } }, false);
      this.sort({ direction: SortDirection.Asc, field: OpencloudProjectSortFields.Name }, false);
      this.pageLimit = 20;
    }, 2000);
  }

  mapQueryData(data: OpencloudProjectsQuery) {
    return data?.opencloudProjects.nodes;
  }
}
