import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keycloakRole'
})
export class KeycloakRolePipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): string {
    if (value.includes('/')) {
      return value.split('/').reverse()[0];
    }
    return value;
  }
}
