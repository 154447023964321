import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Bucket, ObjectStorageService } from '@tx/s3';
import { Observable, first, map } from 'rxjs';
import { CodeEditorService, CodeModel } from '@ngstack/code-editor';
import { ServiceInstance } from '@tx/api';
import { PartyService } from 'apps/tx-portal/src/app/party/party.service';
import { NotificationService, inArrayValidator } from '@tx/ui';

@Component({
  selector: 'tx-portal-bucket-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {
  @Input() bucket!: Bucket;

  defaultPolicyValue = `{
    "Version": "2012-10-17",
    "Statement": [
        {
            "Effect": "Allow",
            "Action": [
                "s3:*"
            ],
            "Resource": [
                "arn:aws:s3:::*"
            ]
        }
    ]
  }`;

  policyModel: CodeModel | null = null;

  bucketDetails!: Promise<any>;

  @Input()
  serviceInstance!: ServiceInstance;

  constructor(
    private fb: FormBuilder,
    public s3Service: ObjectStorageService,
    private partyService: PartyService,
    private notificationService: NotificationService,
    private service: CodeEditorService
  ) {}

  onCopy(data: string) {
    this.notificationService.showInfo('Kopiert', data);
  }

  accessPolicyOptions: string[] = ['private', 'authenticated-read', 'public-read', 'public-read-write'];
  editAccessPolicyForm = this.fb.nonNullable.group({
    accessPolicy: 'private',
    code: ['']
  });

  retentionModeOptions: string[] = ['COMPLIANCE', 'GOVERNANCE'];
  editRetentionModeForm = this.fb.group({
    retention: [false],
    retentionMode: 'COMPLIANCE',
    retentionValidity: [90, [Validators.required, Validators.min(1), Validators.max(365 * 10)]]
  });

  isAccessPolicyModalOpen: boolean = false;
  isObjectLockingModalOpen: boolean = false;
  isVersioningModalOpen: boolean = false;
  isRetentionModalOpen: boolean = false;

  async reloadBucketDetails() {
    const [versioning, retention, policy] = await this.s3Service.getBucketDetails(this.bucket);
    if (this.policyModel && policy?.Policy) {
      this.policyModel.value = policy.Policy;
    }
    this.editRetentionModeForm.patchValue({
      retention: retention?.ObjectLockConfiguration?.Rule?.DefaultRetention !== undefined,
      retentionMode: retention?.ObjectLockConfiguration?.Rule?.DefaultRetention?.Mode ?? 'COMPLIANCE',
      retentionValidity: retention?.ObjectLockConfiguration?.Rule?.DefaultRetention?.Days ?? 90
    });
    // this.policyModel?.value = bucket.policy

    return {
      versioning,
      retention,
      policy
    };
  }

  async disableVersioning(bucket: Bucket) {
    try {
      await this.s3Service.disableBucketVersioning(bucket);
      this.bucketDetails = this.reloadBucketDetails();
      this.isVersioningModalOpen = false;
      this.notificationService.showSuccess('Versioning deaktiviert', 'Versioning wurde deaktiviert');
      this.bucketDetails = this.reloadBucketDetails();
    } catch (e) {
      this.notificationService.showError('Fehler', 'Versioning konnte nicht deaktiviert werden');
    }
  }

  async enableVersioning(bucket: Bucket) {
    try {
      await this.s3Service.enableBucketVersioning(bucket);
      this.bucketDetails = this.reloadBucketDetails();
      this.isVersioningModalOpen = false;
      this.notificationService.showSuccess('Versioning aktiviert', 'Versioning wurde aktiviert');
      this.bucketDetails = this.reloadBucketDetails();
    } catch (e) {
      this.notificationService.showError('Fehler', 'Versioning konnte nicht aktiviert werden');
    }
  }

  async saveRetentionSettings(bucket: Bucket) {
    if (!this.editRetentionModeForm.value.retentionMode) return;
    if (!this.editRetentionModeForm.value.retentionValidity) return;
    try {
      await this.s3Service.saveBucketRetentionSettings(
        bucket,
        this.editRetentionModeForm.value.retention
          ? {
              DefaultRetention: {
                Mode: this.editRetentionModeForm.value.retentionMode as any,
                Days: this.editRetentionModeForm.value.retentionValidity
              }
            }
          : undefined,
        true
      );
      this.notificationService.showSuccess('Retention gespeichert', 'Retention wurde gespeichert');
      this.bucketDetails = this.reloadBucketDetails();
    } catch (e) {
      this.notificationService.showError('Fehler', 'Retention konnte nicht gespeichert werden');
    }
    this.isRetentionModalOpen = false;
  }

  async saveAccessPolicy(bucket: Bucket) {
    try {
      if (this.policyModel?.value) {
        await this.s3Service.saveBucketAccessPolicy(bucket.bucket, this.policyModel?.value);
        this.notificationService.showSuccess('Policy gespeichert', 'Policy wurde gespeichert');
        this.isAccessPolicyModalOpen = false;
      }
    } catch (e) {
      this.notificationService.showError('Fehler', 'Policy konnte nicht gespeichert werden');
    }
  }

  resetPolicyModal() {
    if (this.policyModel) {
      this.policyModel.value = this._policyModelValue;
    }
  }

  _policyModelValue = '';

  async ngOnInit() {
    this.bucketDetails = this.reloadBucketDetails();
    const policy = (await this.bucketDetails).policy?.Policy;
    this.policyModel = {
      language: 'json',
      uri: 'main.json',
      value: policy
    };
    this._policyModelValue = policy ?? '';

    this.partyService.person$.pipe(first()).subscribe((user) => {
      if (!this.policyModel || this.policyModel.value) return;

      this._policyModelValue = `{
        "Version": "2012-10-17",
        "Statement": [{
          "Effect": "Allow",
          "Principal": {"AWS": ["arn:aws:iam::${this.serviceInstance.ptid}:user/SERVICE_ACCOUNT_NAME"]},
          "Action": "s3:*",
          "Resource": [
            "arn:aws:s3::${this.serviceInstance.ptid}:${this.bucket.bucket}/*",
            "arn:aws:s3::${this.serviceInstance.ptid}:${this.bucket.bucket}"
          ]
        }]
      }`;
      this.policyModel.value = this._policyModelValue;
    });
  }
}
