import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { Icon } from '@n/nui';

export interface Subitem {
  icon: Icon;
  label: string;
  isActive: boolean;
  route: string;
}

@Component({
  selector: 'ui-nav-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavItemComponent {
  isExpanded = false;

  @Input()
  collapsed = false;

  @Input()
  label = 'Item';

  @Input()
  icon: Icon = 'cube';

  @Input()
  isActive = false;

  @Input()
  isSecondary = false;

  @Input()
  activeItem = '';

  @Input()
  route = '';

  @Input()
  subitems: Subitem[] = [];

  @Output()
  cclick = new EventEmitter<Event>();

  click(event: Event) {
    if (this.subitems.length > 0) {
      this.isExpanded = !this.isExpanded;
    } else {
      this.cclick.emit(event);
    }
  }

  @Output()
  routeHandler: EventEmitter<string> = new EventEmitter();

  subclick(event: Event) {
    this.cclick.emit(event);
  }
}
