import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CustomCellRenderer } from '../base/custom-renderer.base';

@Component({
  selector: 'contact-renderer',
  templateUrl: './contact-renderer.component.html',
  styleUrls: ['./contact-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactRendererComponent extends CustomCellRenderer {
  public get content() {
    if (this.data == undefined) {
      return undefined;
    }

    return this.data.value;
  }
  public get verified() {
    return true;
    if (this.data.meta?.verified == undefined) {
      return false;
    }

    return this.data.meta.verified;
  }
}
