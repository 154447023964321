import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CustomCellRenderer } from '../base/custom-renderer.base';

@Component({
  selector: 'tag-renderer',
  templateUrl: './tag-renderer.component.html',
  styleUrls: ['./tag-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagRendererComponent extends CustomCellRenderer {
  public get type(): 'lime' | 'amber' {
    if (this.data.value == 'new') {
      return 'amber';
    }
    if (this.data.value == 'finished') {
      return 'lime';
    }

    return 'lime';
  }

  public get content() {
    if (this.data == undefined) {
      return undefined;
    }
    if (Array.isArray(this.data.value)) {
      return this.data.value;
    }
    return [this.data.value];
  }
  public get verified() {
    if (this.data.meta?.verified == undefined) {
      return false;
    }

    return this.data.meta.verified;
  }
}
