import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NotificationService } from '@tx/ui';

@Component({
  selector: 'tx-portal-create-credentials-modal',
  templateUrl: './create-credentials-modal.component.html'
})
export class CreateCredentialsModalComponent {
  constructor(private notificationService: NotificationService) {}

  @Input() isCreateCredentialsModalOpen: boolean = false;
  @Input() _generatedAccessKey: any;
  @Output() closeCreateCredentialsModal: EventEmitter<any> = new EventEmitter();

  onCopy(data: string) {
    this.notificationService.showInfo('Kopiert', data);
  }
}
