import { Component, Input } from '@angular/core';

import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Observable, map } from 'rxjs';

@Component({
  template: ''
})
export abstract class RoutableBaseComponent {
  modalId?: string;

  open: Observable<boolean> = this.route.queryParamMap.pipe(
    map((params: ParamMap) => {
      return params.get('modal') === this.modalId;
    })
  );

  close() {
    this.router.navigate([], {
      queryParams: { modal: null },
      queryParamsHandling: 'merge'
    });
  }

  constructor(private route: ActivatedRoute, private router: Router) {}
}
