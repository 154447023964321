<ng-container *ngIf="bucketData$ | async as _bucketData">
  <div ui-card class="flex flex-col gap-4">
    <ui-headline [heading]="'TelemaxX S3 - First Steps'"></ui-headline>
    <div class="grid gird-cols-1 xl:grid-cols-3 divide-y xl:divide-y-0 xl:divide-x divide-gray-200">
      <div class="pb-4 xl:pb-0 xl:pr-4">
        <tx-portal-step
          [step]="1"
          [heading]="'Service Account erstellen'"
          [done]="hasServiceAccounts$ | async"
          [cta]="'Service Account erstellen'"
          [icon]="'user-plus'"
          (onAction)="stepClick.emit(1)"
        >
          Ein Service Account ermöglicht erst den Zugriff auf S3 Buckets und Objekte.<br /><br />Mit den zugehörig
          ausgestellten Credentials und Secret Key, melden sich ein S3 Client am S3 Object Storage an. Über Service
          Account Policies wird zudem der Zugriff auf Buckets und Objekte definiert.<br /><br />Weitere Informationen zu
          Service-Accounts finden Sie in unserer KB:
          <a class="link" target="_blank" href="https://kb.telemaxx.de/docs/s3-service-accounts"
            >Service Accounts, Credentials & Policies</a
          ><br /><br />Alle erstellten Service Accounts mit ihren Credentials und Policies werden in der Service Account
          Liste aufgeführt.
        </tx-portal-step>
      </div>
      <div class="py-4 xl:py-0 xl:px-4">
        <tx-portal-step
          [step]="2"
          [heading]="'S3 Bucket erstellen'"
          [done]="_bucketData.buckets > 0"
          [cta]="'Bucket erstellen'"
          [icon]="'bucket'"
          (onAction)="stepClick.emit(2)"
        >
          Ein Bucket ist der Container für die zu speichernde Objekte.<br />Alle Objekte werden in S3 in einem Bucket
          gespeichert; dies sind neben Dateien und Ordnern auch die zugehörigen Meta-Daten.<br /><br />Eine Beschreibung
          aller Bucket-Optionen finden Sie in unserer KB:
          <a class="link" target="_blank" href="https://kb.telemaxx.de/docs/s3-buckets">Bucket Settings & Optionen</a
          ><br /><br />
          Hier definieren Sie auch den Besitzer (Service Account) des Buckets, so daß dieser direkt Zugriff auf den
          Bucket hat.<br /><br />
          Alle erstellten Buckets Ihrer Organisation werden in der Bucket-Liste aufgeführt.
        </tx-portal-step>
      </div>
      <div class="pt-4 xl:pt-0 xl:pl-4">
        <tx-portal-step
          [step]="3"
          [heading]="'Daten mittels S3 Client hochladen'"
          [done]="_bucketData.size > 0"
          (onAction)="stepClick.emit(3)"
        >
          Um Daten in S3 zu speichern, konfigurieren Sie Ihren S3 Client (CLI, S3 Browser, Veeam, …) mittels
          <ul class="list-disc pl-4 mb-4">
            <li>S3 Service URL</li>
            <li>Bucket Namen</li>
            <li>Credentials & Secret Key</li>
          </ul>
          Weitere Informationen zu S3 Clients finden Sie in unserer KB:
          <ul class="list-disc pl-4 mb-4">
            <li>
              <a class="link" target="_blank" href="https://kb.telemaxx.de/docs/s3-howto-veeam-backup">Veeam-Backup</a>
            </li>
            <li>
              <a class="link" target="_blank" href="https://kb.telemaxx.de/docs/s3-service-accounts">S3 Brower</a>
            </li>
          </ul>
          Sobald Daten in S3 gespeichert wurden, wird Anzahl der Files und Größe je Bucket angezeigt.<br /><br />Laden
          Sie nun Ihre erste Datei in einen S3 Bucket.
        </tx-portal-step>
      </div>
    </div>
  </div>
</ng-container>
