import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Icon } from '@n/nui';
import { isMobile } from '../../../utils/mobile';

export interface TxTab {
  value: string;
  icon: Icon;
}

@Component({
  selector: 'ui-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {
  isMobile = isMobile();

  @Input()
  activeTab: string | null = '';

  @Input()
  onWhite = false;

  @Output()
  activeTabChange = new EventEmitter<string>();

  // @depcrecated
  @Input()
  tabs: string[] = [];

  @Input()
  objTabs: TxTab[] | null = [];

  @Output()
  cchange = new EventEmitter<string>();

  ngOnInit(): void {
    if (!this.objTabs) return;
    if (this.objTabs.length > 0) {
      this.tabs = this.objTabs.map((t) => t.value);
    }
    if (this.activeTab === '') {
      this.activeTab = this.tabs[0];
    }
  }

  click(tab: string) {
    this.activeTab = tab;
    this.activeTabChange.emit(tab);
    this.cchange.emit(tab);
  }

  change(event: Event) {
    const { target } = event;

    if (target) {
      const tab = (target as HTMLButtonElement).value;
      this.activeTab = tab;
      if (tab !== this.activeTab) {
        this.activeTabChange.emit(tab);
        this.cchange.emit(tab);
      }
    }
  }
}
