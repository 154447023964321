<div [ngClass]="['heading', 'header-' + this.size, this.subheading ? 'has-subheading' : '', 'heading-' + this.align]">
  <header>
    <ng-container *ngTemplateOutlet="header || defaultHeader; context: { $implicit: heading }"></ng-container>
  </header>
  <span *ngIf="subheading !== '' || subHeader">
    <ng-container *ngTemplateOutlet="subHeader || defaultSubHeader; context: { $implicit: subheading }"></ng-container>
  </span>
</div>

<ng-template #defaultSubHeader let-subheading>
  {{ subheading }}
</ng-template>

<ng-template #defaultHeader let-heading>
  {{ heading }}
</ng-template>
