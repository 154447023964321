<div *ngIf="parentForm && parentForm.controls[controlName] as control">
  <div class="flex justify-between mb-2" *ngIf="label">
    <label *ngIf="label" [for]="name" class="block text-sm font-medium text-gray-700 flex"
      >{{ label }}
      <span class="opacity-50 text-xs leading-none self-start ml-1" *ngIf="!isRequired">Optional</span></label
    >
  </div>
  <div class="relative rounded-md" [formGroup]="parentForm">
    <div class="absolute inset-y-0 left-0 flex items-center h-full pl-3 pointer-events-none" *ngIf="icon">
      <ui-icon [icon]="icon" class="w-5 h-5 text-gray-400"></ui-icon>
    </div>
    <ng-container [ngSwitch]="type">
      <input
        *ngSwitchDefault
        [formControlName]="controlName"
        [type]="type"
        [readonly]="readonly"
        [name]="name"
        [max]="max ? max : ''"
        [placeholder]="placeholder"
        class="block w-full rounded-md sm:text-sm"
        [ngClass]="[
          icon ? 'pl-10' : '',
          control.valid && control.touched ? 'border-goblin-400' : 'border-gray-300',
          !control.valid && control.touched ? 'border-red-500' : 'border-gray-300',
          control.disabled || readonly
            ? 'hover:border-gray-300 focus:ring-0 focus:border-gray-300 bg-gray-50 text-gray-500'
            : 'hover:border-blue-500 focus:ring-blue-500 focus:border-gray-300 focus:ring-offset-2'
        ]"
      />
      <input
        *ngSwitchCase="'number'"
        [formControlName]="controlName"
        type="number"
        [readonly]="readonly"
        [name]="name"
        [min]="min ? min : '-9999'"
        [max]="max ? max : '9999'"
        [step]="step"
        [placeholder]="placeholder"
        class="block w-full rounded-md sm:text-sm"
        [ngClass]="[
          icon ? 'pl-10' : '',
          control.valid && control.touched ? 'border-goblin-400' : 'border-gray-300',
          !control.valid && control.touched ? 'border-red-500' : 'border-gray-300',
          control.disabled || readonly
            ? 'hover:border-gray-300 focus:ring-0 focus:border-gray-300 bg-gray-50 text-gray-500'
            : 'hover:border-blue-500 focus:ring-blue-500 focus:border-gray-300 focus:ring-offset-2'
        ]"
      />
    </ng-container>

    <div
      class="absolute inset-y-0 right-0 flex items-center pointer-events-none"
      [ngClass]="type === 'number' ? 'pr-9' : 'pr-3'"
    >
      <span class="text-gray-500 sm:text-sm" [id]="name + '-addon'"> {{ addon }} </span>
    </div>
  </div>
  <p *ngIf="control.errors && control.touched" class="mt-2 text-sm text-red-500" [id]="name + '-error'">
    {{ control.errors | errors : 1 }}
  </p>
  <p *ngIf="description" class="mt-2 text-sm text-gray-500" [id]="name + '-description'">{{ description }}</p>
</div>
<!-- debug -->
<div *ngIf="!parentForm">REFACTOR! PARENTFORM MISSING</div>
