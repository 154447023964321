<ui-cell-page-header
  [heading]="'Grundeinstellungen'"
  [subheading]="'Passen Sie die Grundeinstellungen für das Kundencenter und Ihre Benutzer an.'"
></ui-cell-page-header>

<div ui-card class="w-full flex flex-col gap-4">
  <ui-headline [heading]="'Login & Security'" class="mb-4" [size]="'lg'"> </ui-headline>
  <ui-forms-toggle
    [label]="'2FA erzwingen'"
    [description]="
      'Zwei-Faktor-Authentifizierung für alle Benutzer der Organisation erzwingen - Die Verwendung einer Authenticator App wird benötigt.'
    "
    [parentForm]="settingsForm"
    [controlName]="'force2fa'"
  ></ui-forms-toggle>
</div>
