import { ChangeDetectionStrategy, Component, ContentChild, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'ui-headline',
  templateUrl: './headline.component.html',
  styleUrls: ['./headline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeadlineComponent {
  @ContentChild('header') header!: TemplateRef<any>;
  @ContentChild('subHeader') subHeader!: TemplateRef<any>;

  @Input()
  size: 'sm' | 'base' | 'lg' | 'xl' | '2xl' = 'xl';

  @Input()
  heading = '';

  @Input()
  subheading = '';

  @Input() align: 'left' | 'center' = 'left'
}
