<div class="flex flex-col gap-4 md:gap-8">
  <!-- profile data -->
  <div ui-card *ngIf="person$ | async as _person">
    <ui-headline
      [heading]="'Ihre Daten'"
      [subheading]="'Hier können Sie alle Ihre Daten verwalten und pflegen.'"
      size="lg"
      class="mb-4"
    ></ui-headline>
    <ui-form-wrapper [parentForm]="profileForm">
      <ui-forms-input
        [label]="'E-Mail Adresse'"
        [description]="'Dient als Loginname'"
        [name]="'email'"
        [type]="'email'"
        [parentForm]="profileForm"
        [controlName]="'email'"
      ></ui-forms-input>
      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
        <ui-forms-input
          [label]="'Vorname'"
          [name]="'firstname'"
          [parentForm]="profileForm"
          [controlName]="'firstName'"
        ></ui-forms-input>
        <ui-forms-input
          [label]="'Nachname'"
          [name]="'lastname'"
          [parentForm]="profileForm"
          [controlName]="'lastName'"
        ></ui-forms-input>
        <ui-forms-select
          [label]="'Geschlecht'"
          [name]="'gender'"
          [placeholder]="'Geschlecht wählen'"
          [options]="genderOptions"
          [parentForm]="profileForm"
          [controlName]="'gender'"
        ></ui-forms-select>
        <ui-forms-input
          [label]="'Firmenname'"
          [name]="'companyName'"
          [parentForm]="profileForm"
          [controlName]="'companyName'"
        ></ui-forms-input>
        <ui-forms-input
          [label]="'Telefonnummer'"
          [name]="'phone'"
          [parentForm]="profileForm"
          [controlName]="'phone'"
        ></ui-forms-input>
        <ui-forms-input
          [label]="'Mobilnummer'"
          [name]="'mobilePhone'"
          [parentForm]="profileForm"
          [controlName]="'mobilePhone'"
        ></ui-forms-input>
        <div class="flex justify-start col-span-1 sm:col-span-2 lg:col-span-3">
          <ui-button
            class="w-full sm:w-auto"
            [isFullWidth]="true"
            [icon]="'check'"
            [label]="'Speichern'"
            [color]="'goblin'"
            (cclick)="saveProfileWrapper()"
          ></ui-button>
        </div>
      </div>
    </ui-form-wrapper>
    <hr class="my-6" />
    <ui-headline [heading]="'Sicherheit'" [subheading]="'Verwalten Sie Ihr Passwort'" [size]="'base'"></ui-headline>
    <div
      class="mt-4 grid lg:grid-cols-3 grid-cols-1 divide-x divide-gray-200"
      *ngIf="currentTenant$ | async as _currentTenant"
    >
      <div class="flex justify-start">
        <ui-button
          class="w-full sm:w-auto"
          [isFullWidth]="true"
          [label]="'Passwort ändern'"
          [color]="'goblin'"
          [type]="'secondary'"
          (click)="resetPassword.emit()"
        ></ui-button>
      </div>
      <div
        class="flex flex-col gap-4 pl-4 border-l border-gray-200 col-span-2 mt-8 lg:mt-0"
        *ngIf="otpCredentials$ | async as _otpCredentials"
      >
        <div
          class="flex items-center gap-2"
          *ngIf="_otpCredentials[0].userCredentialMetadatas.length > 0 || _currentTenant.isOtpRequired"
        >
          <span class="relative flex h-3 w-3">
            <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-goblin-400 opacity-75"></span>
            <span class="relative inline-flex rounded-full h-3 w-3 bg-goblin-500"></span>
          </span>
          <span class="font-medium text-goblin-500">Ihre Zwei-Faktor-Authentifizierung ist aktiv</span>
        </div>
        <div
          class="flex items-center gap-2"
          *ngIf="_otpCredentials[0].userCredentialMetadatas.length === 0 && !_currentTenant.isOtpRequired"
        >
          <span class="relative flex h-3 w-3">
            <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
            <span class="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
          </span>
          <span class="font-medium text-red-500">Ihre Zwei-Faktor-Authentifizierung ist nicht aktiv</span>
        </div>
        <ui-button
          class="w-full sm:w-auto"
          [isFullWidth]="true"
          [color]="'goblin'"
          type="secondary"
          [routerLink]="['tfa']"
          >2FA Verwaltung öffnen</ui-button
        >
        <p *ngIf="_otpCredentials[0].userCredentialMetadatas.length === 0">
          Ihr Login erfordert zur Zeit nur Ihre E-Mail und Passwort.<br />
          Klicken Sie auf “2FA Verwaltung öffnen”, um dort 2FA zu aktivieren und die Sicherheit Ihres Benutzerkontos zu
          erhöhen.
        </p>
        <p *ngIf="_otpCredentials[0].userCredentialMetadatas.length > 0">
          Hervoragend! Ihr Login erfordert neben Ihrer E-Mail, dem Passwort auch noch den OneTime-Code.
        </p>
      </div>
    </div>
  </div>
  <!-- profile roles -->
  <div ui-card *ngIf="person$ | async as _person">
    <ui-headline
      [heading]="'Rollen'"
      [subheading]="'Der Admin Ihrer Organisation kann Ihre Rollen verwalten.'"
      class="mb-4"
      size="lg"
    ></ui-headline>
    <tx-portal-user-roles-card *ngIf="person$ | async as person" [person]="person"></tx-portal-user-roles-card>
    <!-- <div class="flex flex-wrap w-full gap-2" *ngIf="billingRoles$ && session$">
      <ui-badge label="Verified Account" icon="check" type="mono-lighter" *ngIf="_person.isKeycloakVerified">
      </ui-badge>
      <ui-badge [loading]="true" label="Invitation Pending" type="mono" *ngIf="!_person.isKeycloakVerified"> </ui-badge>

      <div class="hidden xl:block" *ngFor="let role of _person.keycloakRoles">
        <ui-tag
          [isBroken]="true"
          content="Globale Rolle"
          [label]="role | keycloakRole"
          icon="globe-alt"
          type="mono-darker"
        ></ui-tag>
      </div>
      <div class="hidden xl:block" *ngFor="let serviceOwner of _person.serviceOwnerOf">
        <a [routerLink]="'/services/detail/' + serviceOwner.id">
          <ui-tag [isBroken]="true" label="Service-Owner" icon="user-circle" type="eblue">
            <span class="whitespace-nowrap">{{ serviceOwner.serviceName }} ({{ serviceOwner.sinr }})</span>
          </ui-tag>
        </a>
      </div>
      <div class="hidden xl:block" *ngFor="let serviceUser of _person.serviceUserOf">
        <a [routerLink]="'/services/detail/' + serviceUser.id">
          <ui-tag [isBroken]="true" label="Service-User" icon="user-circle" type="goblin">
            <span class="whitespace-nowrap">{{ serviceUser.serviceName }} ({{ serviceUser.sinr }})</span>
          </ui-tag>
        </a>
      </div>
      <div
        class="pt-2 border-t border-gray-200 w-full flex flex-wrap gap-2 xl:hidden"
        *ngIf="_person.keycloakRoles && _person.keycloakRoles.length && _person.keycloakRoles.length > 0"
      >
        <ui-tag
          [isBroken]="true"
          content="Globale Rolle"
          [label]="role | keycloakRole"
          icon="globe-alt"
          type="mono-darker"
          *ngFor="let role of _person.keycloakRoles"
        ></ui-tag>
      </div>
      <div
        class="pt-2 border-t border-gray-200 w-full flex flex-wrap gap-2 xl:hidden"
        *ngIf="_person.serviceOwnerOf && _person.serviceOwnerOf?.length && _person.serviceOwnerOf.length > 0"
      >
        <a [routerLink]="'/services/detail/' + serviceOwner.id" *ngFor="let serviceOwner of _person.serviceOwnerOf">
          <ui-tag [isBroken]="true" label="Service-Owner" icon="user-circle" type="eblue">
            <span class="whitespace-nowrap">{{ serviceOwner.serviceName }} ({{ serviceOwner.sinr }})</span>
          </ui-tag>
        </a>
      </div>
      <div
        class="pt-2 border-t border-gray-200 w-full flex flex-wrap gap-2 xl:hidden"
        *ngIf="_person.serviceUserOf && _person.serviceUserOf?.length && _person.serviceUserOf.length > 0"
      >
        <a [routerLink]="'/services/detail/' + serviceUser.id" *ngFor="let serviceUser of _person.serviceUserOf">
          <ui-tag [isBroken]="true" label="Service-User" icon="user-circle" type="goblin">
            <span class="whitespace-nowrap">{{ serviceUser.serviceName }} ({{ serviceUser.sinr }})</span>
          </ui-tag>
        </a>
      </div>
    </div> -->
  </div>
  <!-- notifications -->
  <div ui-card *ngIf="person$ | async as _person">
    <ui-headline
      [heading]="'Benachrichtigungen'"
      [subheading]="'Verwalten Sie die E-Mail Benachrichtigungen.'"
      size="lg"
    ></ui-headline>
    <div class="mt-4 space-y-4">
      <ui-forms-toggle
        [label]="'Technische Accountbenachrichtungen'"
        [description]="'Kann nicht abgewählt werden.'"
        [readonly]="true"
        [parentForm]="notificationSettingsForm"
        [controlName]="'technicalAccountNotifications'"
      ></ui-forms-toggle>
      <ui-forms-toggle
        [label]="'Technische Updates zu Ihren Produkten und dem Kundencenter'"
        [parentForm]="notificationSettingsForm"
        [controlName]="'technicalUpdateNotifications'"
      ></ui-forms-toggle>
      <ui-forms-toggle
        [label]="'TelemaxX Newsletter und Produktinformationen'"
        [parentForm]="notificationSettingsForm"
        [controlName]="'newsletter'"
      ></ui-forms-toggle>
    </div>
  </div>
</div>
