<div class="flex items-center justify-between">
  <ui-forms-input
    [icon]="'magnifying-glass'"
    [placeholder]="'Suche'"
    [name]="'buckets-search'"
    [parentForm]="searchForm"
    [controlName]="'searchterm'"
    ngDefaultControl
    class="w-full sm:w-64"
  ></ui-forms-input>
  <ui-button [icon]="'plus-small'" [color]="'goblin'" size="sm">Policy erstellen</ui-button>
</div>
<div class="grid grid-cols-3 mt-6 font-semibold text-sm">
  <div>
    Name
  </div>
  <div class="col-span-2">
    Zugewiesene Benutzer
  </div>
</div>
<div class="flex flex-col divide-y divide-gray-200 mt-4">
  <div class="grid grid-cols-3 py-2">
    <span class="text-sm">
      readonly
    </span>
    <div class="flex items-center justify-between col-span-2">
      <div class="flex gap-2">
        <ui-tag [type]="'eblue'">@user</ui-tag>
        <ui-tag [type]="'eblue'">@anotheruser</ui-tag>
      </div>
      <ui-context-menu
        [contextMenu]="contextMenu"
        (contextMenuClick)="contextMenuClick($event)"
      ></ui-context-menu>
    </div>
  </div>
  <div class="grid grid-cols-3 py-2">
    <span class="text-sm">
      readonly
    </span>
    <div class="flex items-center justify-between col-span-2">
      <div class="flex gap-2">
        <ui-tag [type]="'eblue'">@user</ui-tag>
        <ui-tag [type]="'eblue'">@anotheruser</ui-tag>
      </div>
      <ui-context-menu
        [contextMenu]="contextMenu"
        (contextMenuClick)="contextMenuClick($event)"
      ></ui-context-menu>
    </div>
  </div>
</div>