import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CustomCellRenderer } from '../base/custom-renderer.base';

@Component({
  selector: 'currency-renderer',
  templateUrl: './currency-renderer.component.html',
  styleUrls: ['./currency-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CurrencyRendererComponent extends CustomCellRenderer {
  public get content(): string {

    return this.data;
  }
}
