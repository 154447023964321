import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { ServiceInstance } from '@tx/api';
import { NotificationService } from '@tx/ui';

@Component({
  selector: 'tx-portal-add-service-account-modal',
  templateUrl: './add-service-account-modal.component.html'
})
export class AddServiceAccountModal implements OnInit {
  constructor(private fb: NonNullableFormBuilder, private notificationService: NotificationService) {}

  @Input() serviceInstance!: ServiceInstance;
  @Input() isAddServiceAccountModalOpen: boolean = false;
  @Output() closeAddServiceAccountModal: EventEmitter<any> = new EventEmitter();
  @Output() addServiceAccount: EventEmitter<string> = new EventEmitter();

  onCopy(data: string) {
    this.notificationService.showInfo('Kopiert', data);
  }

  addServiceAccountWrapper() {
    let identifier: string = this.serviceAccountForm.controls.identifier.value;
    this.addServiceAccount.emit(identifier);
    this.serviceAccountForm.reset();
  }

  serviceAccountForm = this.fb.group({
    identifier: [
      '',
      [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),
        Validators.pattern('(?!(^xn--|.+-s3alias$))^[a-z0-9][a-z0-9-]{1,61}[a-z0-9]$')
      ]
    ]
  });

  ngOnInit(): void {}
}
