import { Component, OnInit } from '@angular/core';
import { PartyService } from '../party.service';

@Component({
  selector: 'tx-portal-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {
  partyService: PartyService;

  constructor(partyService: PartyService) {
    this.partyService = partyService;
  }

  onSaveProfile(person: any) {
    this.partyService.updatePerson(person);
  }
}
