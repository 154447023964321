import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bytes'
})
export class BytesPipe implements PipeTransform {
  transform(bytes: number, show?: boolean, ...args: number[]): unknown {
    let precision = 1;
    let units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'],
      number = Math.floor(Math.log(bytes) / Math.log(1024));
    if (show) {
      if (bytes === 0) return 'GB';

      return units[number];
    } else {
      if (bytes === 0) return 0;

      return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision);
    }
  }
}
