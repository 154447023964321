import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ActiveToast } from 'ngx-toastr';
import { NotificationService } from '../../../notification/notification.service';

@Component({
  selector: 'ui-toastr',
  templateUrl: './toastr.component.html',
  styleUrls: ['./toastr.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastrExampleComponent {
  @Input()
  message = 'Test Success';

  @Input()
  title = 'Test Success Title';

  constructor(private notificationService: NotificationService) {}

  testSuccess() {
    this.notificationService.showSuccess(this.title, this.message);
  }

  testError() {
    this.notificationService.showError(this.title, this.message);
  }

  testInfo() {
    this.notificationService.showInfo(this.title, this.message);
  }
}
