<div (click)="copyValue()">
  <div class="flex justify-between mb-2" *ngIf="label">
    <label *ngIf="label" class="block text-sm font-medium text-gray-700">{{ label }}</label>
  </div>
  <div class="relative rounded-md overflow-hidden">
    <div
      #text
      class="sm:text-sm block w-full rounded-md border-gray-300 text-gray-600 bg-white border py-2 px-3 ease-in-out duration-200 transition-all pr-10"
    >
      {{ value }}
    </div>
    <div class="absolute inset-y-0 right-0 flex items-center h-full pr-1 z-[1]">
      <div class="bg-white p-1">
        <ui-button [icon]="'clipboard'" [isOnlyIcon]="true" [size]="'xs'"></ui-button>
      </div>
    </div>
  </div>
  <p *ngIf="description" class="mt-2 text-sm text-gray-500">{{ description }}</p>
</div>
