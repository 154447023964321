import { Component, Input } from '@angular/core';
import { Icon } from '@n/nui';

@Component({
  selector: 'ui-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss']
})
export class BadgeComponent {
  @Input()
  label = '';

  @Input()
  content = '';

  @Input()
  loading = false;

  @Input()
  type: 'goblin' | 'red' | 'amber' | 'lime' | 'blue' | 'purple' | 'mono' | 'mono-lighter' | 'mono-darker' | 'bronze' | 'silver' | 'gold' | 'platin' =
    'goblin';

  @Input()
  icon: Icon | undefined = undefined;
}
