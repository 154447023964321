<ui-modal
  *ngIf="open | async as _open"
  [isModalOpen]="_open"
  [title]="'Bucket erstellen'"
  (onClose)="close()"
  [showButtons]="false"
>
  <ng-container *ngIf="!isCreateBucketModalLoading && createBucketForm">
    <ui-forms-input
      [parentForm]="createBucketForm"
      [controlName]="'name'"
      [label]="'Name'"
      [type]="'text'"
      [name]="'name'"
    ></ui-forms-input>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-4 mt-4">
      <span class="font-semibold md:col-span-2">Bucket Naming Rules</span>
      <ng-container *ngFor="let v of bucketNameValidators">
        <div class="flex items-center gap-4">
          <div
            class="w-3 h-3 rounded-full shrink-0"
            [ngClass]="
              testRegExp(v.regex, createBucketForm.controls['name'].value) &&
              createBucketForm.controls['name'].value &&
              createBucketForm.controls['name'].value.length > 0
                ? 'bg-klee-100'
                : 'bg-red-500'
            "
          ></div>
          <p class="text-sm">{{ v.description }}</p>
        </div>
      </ng-container>
      <div class="flex items-center gap-4">
        <div
          class="w-3 h-3 rounded-full shrink-0"
          [ngClass]="
            createBucketForm.controls['name'].valid && createBucketForm.controls['name'].value.length > 0
              ? 'bg-klee-100'
              : 'bg-red-500'
          "
        ></div>
        <p class="text-sm">Der Bucket Bezeichner müssen innerhalb eines Vertrages unique sein</p>
      </div>
    </div>
    <hr class="my-4" />
    <ui-forms-combobox
      *ngIf="serviceAccountOptions$ | async as _options"
      name="users"
      label="Bucket Owner (Service Account)"
      [initalValue]="false"
      [options]="_options"
      (cchange)="selectPerson($event)"
    ></ui-forms-combobox>
    <div class="flex justify-end mt-1">
      <button (click)="jumpToServiceAccountsTab.emit()" class="link">Service Account erstellen</button>
    </div>
    <div class="mt-4">
      <ui-info>
        <ui-icon [icon]="'bucket'" class="w-5 h-5"></ui-icon>
        <p>
          Der Bucket wird mit einer Standard-Policy erstellt. Diese Bucket-Policy kann im Nachgang in den Bucket-Details
          angepasst werden.
        </p>
      </ui-info>
    </div>
    <hr class="my-4" />
    <div class="flex flex-col gap-4 mb-8 mt-4">
      <ui-forms-checkbox [id]="'versioning'" [parentForm]="createBucketForm" [controlName]="'versioning'">
        <span class="font-bold">Versioning</span><br /><span class="font-normal"
          >Versioning ermöglicht Ihnen, mehrere Versionen eines Objekts im selben Bucket aufzubewahren.
          <a href="https://kb.telemaxx.de/docs/s3-overview" target="_blank" class="link">Mehr Infos</a>
        </span>
      </ui-forms-checkbox>
      <ui-forms-checkbox
        [id]="'objectLocking'"
        [parentForm]="createBucketForm"
        [controlName]="'objectLocking'"
        [ngClass]="createBucketForm.controls['versioning'].value ? '' : 'opacity-50 pointer-events-none'"
      >
        <span class="font-bold">Object Locking</span><br /><span class="font-normal"
          >Object Locking verhindert, das Löschen oder Verändern von Dateien für eine zuvor definierte Zeit. Wird für
          die Funktion Retention bzw. Legal Hold benötigt. Kann nur beim Erstellen des Buckets konfiguriert werden.
          <a href="https://kb.telemaxx.de/docs/s3-overview" target="_blank" class="link">Mehr Infos</a></span
        >
      </ui-forms-checkbox>
      <ui-forms-checkbox
        [id]="'retention'"
        [parentForm]="createBucketForm"
        [controlName]="'retention'"
        [ngClass]="
          createBucketForm.controls['versioning'].value && createBucketForm.controls['objectLocking'].value
            ? ''
            : 'opacity-50 pointer-events-none'
        "
      >
        <span class="font-bold">Retention Policy</span><br /><span class="font-normal"
          >Die Bucket Retention Policy definiert den Modus und Zeitraum in dem das Löschen von neu erstellten Objekten
          verhindert wird. <a href="https://kb.telemaxx.de/docs/s3-overview" target="_blank" class="link">Mehr Infos</a>
        </span>
      </ui-forms-checkbox>
      <div *ngIf="createBucketForm.controls['retention'].value" class="grid grid-cols-2 gap-4 ml-7">
        <ui-forms-select
          [parentForm]="createBucketForm"
          [controlName]="'retentionMode'"
          [label]="'Modus'"
          [options]="retentionModeOptions"
        ></ui-forms-select>
        <ui-forms-input
          [parentForm]="createBucketForm"
          [controlName]="'retentionValidity'"
          [label]="'Validity'"
          [type]="'number'"
          [addon]="'Tage'"
        ></ui-forms-input>
      </div>
    </div>
    <ui-button
      [isFullWidth]="true"
      [icon]="'squares-plus'"
      [color]="'goblin'"
      (cclick)="createBucketWrapper()"
      [isDisabled]="createBucketForm.valid === false || createBucketForm.controls['name'].value.length === 0"
      >Bucket erstellen</ui-button
    >
  </ng-container>
  <ng-container *ngIf="isCreateBucketModalLoading">
    <div class="w-full aspect-2 flex flex-col items-center justify-center">
      <ui-icon [icon]="'minus-circle'" class="animate-spin w-8 h-8 block"></ui-icon>
      <div class="font-semibold text-center mt-4">Bucket wird erstellt</div>
    </div>
  </ng-container>
</ui-modal>
