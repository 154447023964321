import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { Icon } from '@n/nui';

export interface ContextMenuItem {
  isDanger?: boolean;
  label: string;
  icon: Icon;
  event: string;
}

@Component({
  selector: 'ui-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContextMenuComponent {
  isMenuOpen = false;

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  @Input()
  icon: Icon = 'ellipsis-vertical';

  @Input()
  contextMenu!: ContextMenuItem[][] | null;

  @Input()
  hasContent: boolean = false;

  @Output()
  contextMenuClick: EventEmitter<string> = new EventEmitter();

  click(event: string) {
    this.contextMenuClick.emit(event);
    this.isMenuOpen = false;
  }

  @ViewChild('elem') elem!: ElementRef;
  constructor(private renderer: Renderer2, private cdr: ChangeDetectorRef) {
    /**
     * This events get called by all clicks on the page
     */
    this.renderer.listen('window', 'click', (e: Event) => {
      /**
       * Only run when toggleButton is not clicked
       * If we don't check this, all clicks (even on the toggle button) gets into this
       * section which in the result we might never see the menu open!
       * And the menu itself is checked here, and it's where we check just outside of
       * the menu and button the condition abbove must close the menu
       */
      if (!this.elem.nativeElement.contains(e.target)) {
        this.isMenuOpen = false;
        this.cdr.detectChanges();
      }
    });
  }
}
