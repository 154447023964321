import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';

interface KeycloakSession {
  current: boolean;
  device: string;
  lastAccess: string;
  mobile: string;
  os: string;
  osVersion: string;
  sessions: [
    {
      id: string;
      browser: string;
      current: boolean;
      expires: string;
      ipAddress: string;
      lastAccess: string;
      started: string;
    }
  ];
}

@Component({
  selector: 'tx-portal-sessions-card',
  templateUrl: './sessions-card.component.html',
  styleUrls: ['./sessions-card.component.scss']
})
export class SessionsCardComponent {
  today = new Date();

  @Input()
  sessions$!: Observable<[KeycloakSession]>;

  @Output()
  deleteCurrentSession: EventEmitter<any> = new EventEmitter();
}
