<!-- component -->
<div class="relative h-full p-4 border border-gray-200 rounded-xl">
  <div class="absolute top-6 right-6" *ngIf="contextMenu">
    <ui-context-menu [contextMenu]="contextMenu" (contextMenuClick)="contextMenuClick.emit($event)"></ui-context-menu>
  </div>
  <div class="flex flex-col gap-4">
    <div class="flex gap-4 items-center">
      <ui-avatar
        [size]="'sm'"
        [firstname]="person.firstName ? person.firstName : person.email.toUpperCase()"
        [lastname]="person.lastName ? person.lastName : person.email.split('@')[1].toUpperCase()"
      ></ui-avatar>
      <div class="w-full">
        <ng-container *ngTemplateOutlet="header || defaultHeader; context: { $implicit: person }"></ng-container>
        <span class="block text-sm text-gray-500">{{ person.email }}</span>
      </div>
    </div>
    <ng-container *ngTemplateOutlet="badges || defaultBadges; context: { $implicit: person }"></ng-container>
  </div>
</div>

<!-- templates -->
<ng-template #defaultHeader let-person>
  <div class="flex flex-wrap items-center gap-x-1 w-4/5">
    <span class="block font-semibold" *ngIf="person.firstName || person.lastName; else elseBlock">
      {{ person.firstName }} {{ person.lastName }}
    </span>
    <div class="gap-0.5 flex items-center">
      <span class="text-goblin-500" *ngIf="person.isKeycloakVerified" title="Verified">
        <ui-icon [icon]="'check-badge'" class="block w-5 h-5"></ui-icon>
      </span>
      <span
        class="text-amber-600 flex items-center justify-center gap-1 text-sm leading-none"
        *ngIf="person.keycloakRoles?.length > 0"
        title="Administrator"
      >
        <ui-icon [icon]="'shield-exclamation'" class="block w-5 h-5"></ui-icon> Administrator
      </span>
    </div>
  </div>
  <ng-template #elseBlock><span class="block font-semibold">Kein Name</span></ng-template>
</ng-template>

<ng-template #defaultBadges let-person class="group">
  <div
    class="flex gap-2 flex-wrap items-center"
    *ngIf="person.serviceOwnerOf.length > 0 || person.serviceUserOf.length > 0 || (isDCAMServiceOwner$ | async)"
  >
    <ui-tag label="Owner" icon="user-circle" type="eblue" *ngFor="let owner of person.serviceOwnerOf">
      <span class="whitespace-nowrap">{{ owner.serviceName }} ({{ owner.sinr }})</span>
    </ui-tag>
    <ui-tag label="Owner" icon="user-circle" type="purple" *ngIf="isDCAMServiceOwner$ | async">
      <span class="whitespace-nowrap">Datacenter Access</span>
    </ui-tag>
    <ui-tag label="User" icon="user-circle" type="goblin" *ngFor="let user of person.serviceUserOf">
      <span class="whitespace-nowrap">{{ user.serviceName }} ({{ user.sinr }})</span>
    </ui-tag>
  </div>
</ng-template>
