import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from '../../environments/environment';

export const SCOPE = 'openid profile roles username email offline_access aud company';

export const authConfig: AuthConfig = {
  issuer: environment.oidc.issuer,
  redirectUri: window.location.origin + window.location.pathname,
  clientId: environment.oidc.clientId,
  postLogoutRedirectUri: window.location.origin + window.location.pathname,
  responseType: 'code',
  useSilentRefresh: false,
  scope: SCOPE,
  showDebugInformation: true,
  sessionChecksEnabled: true
};
