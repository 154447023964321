import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnInit,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  Renderer2,
  SimpleChanges
} from '@angular/core';
import { FormControl } from '@angular/forms';

export interface Option {
  value: string;
  label: string;
  description: string;
}

@Component({
  selector: 'ui-forms-combobox',
  templateUrl: './combobox.component.html',
  styleUrls: ['./combobox.component.scss']
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComboboxComponent implements OnInit {
  _isOpen = false;
  get isOpen() {
    return this._isOpen;
  }
  set isOpen(val: boolean) {
    if (!val) this.resetForm();
    this._isOpen = val;
  }

  itemStyles = 'text-gray-900';
  descStyles = 'text-gray-500';
  checkStyles = 'text-blue-500';
  highlightIndex!: number;

  inputControl = new FormControl('');

  @Input()
  selectedValue!: Option | undefined | null;

  @Input()
  name = '';

  @Input()
  label = '';

  @Input()
  readonly = false;

  @Input()
  options!: Array<Option>;

  @Input()
  initalValue: boolean = true;

  filteredOptions!: Array<Option>;

  @Output()
  cchange = new EventEmitter<Option>();

  @ViewChild('elem') elem!: ElementRef;
  constructor(private renderer: Renderer2, private cdr: ChangeDetectorRef) {
    /**
     * This events get called by all clicks on the page
     */
    this.renderer.listen('window', 'click', (e: Event) => {
      /**
       * Only run when toggleButton is not clicked
       * If we don't check this, all clicks (even on the toggle button) gets into this
       * section which in the result we might never see the menu open!
       * And the menu itself is checked here, and it's where we check just outside of
       * the menu and button the condition abbove must close the menu
       */

      if (!this.elem.nativeElement.contains(e.target)) {
        this.isOpen = false;
        this.cdr.detectChanges();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['options'] && !changes['options'].firstChange) {
      this.filteredOptions = this.options;
    }
  }

  ngOnInit(): void {
    if (this.initalValue) {
      if (!this.selectedValue) {
        this.select(this.options[0]);
      }
    }

    this.filteredOptions = this.options;
  }

  select(option: Option) {
    this.selectedValue = option;
    this.inputControl.setValue(option.label);
    this.filteredOptions = this.options;
    this.cchange.emit(option);
    this.isOpen = false;
  }

  changeValue(event: Event) {
    const { target } = event;
    if (target) {
      this.isOpen = true;
      const value = (target as HTMLButtonElement).value;
      this.filteredOptions = this.options.filter((option) => {
        const puted: string = option.value + ' ' + option.label + ' ' + option.description;
        return puted.toLowerCase().includes(value.toLowerCase());
      });
      if (this.filteredOptions.length === 0) {
        this.filteredOptions = this.options;
      }
    }
  }

  resetForm() {
    // Reset  next frame
    if (this.selectedValue) {
      this.inputControl.setValue(this.selectedValue.label);
    } else {
      this.inputControl.setValue('');
    }
    this.filteredOptions = this.options;
  }
}
