//@ts-nocheck
import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
import * as ApolloCore from '@apollo/client/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** Cursor for paging through collections */
  ConnectionCursor: { input: any; output: any };
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: any; output: any };
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any };
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: any; output: any };
};

export type AddContactPersonOfToPersonInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']['input']>;
};

export type AddInvoiceRecipientsToOrganisationInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']['input']>;
};

export type AddPeopleToCompanyInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']['input']>;
};

export type AddServiceAccountsToServiceInstanceInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']['input']>;
};

export type AddServiceInstancesToOrganisationInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']['input']>;
};

export type AddServiceOwnerOfToPersonInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']['input']>;
};

export type AddServiceOwnersToDcamInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']['input']>;
};

export type AddServiceOwnersToServiceInstanceInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']['input']>;
};

export type AddServiceUserOfToPersonInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']['input']>;
};

export type AddServiceUsersToServiceInstanceInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']['input']>;
};

export type Address = {
  __typename?: 'Address';
  addressAddition?: Maybe<Scalars['String']['output']>;
  billingAddress: BillingAddress;
  city: Scalars['String']['output'];
  companyName?: Maybe<Scalars['String']['output']>;
  country: Scalars['String']['output'];
  created?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  organisation: Organisation;
  organisationAltRecipient: Organisation;
  organisationAltRecipientId?: Maybe<Scalars['String']['output']>;
  organisationId?: Maybe<Scalars['String']['output']>;
  organisationalUnit?: Maybe<Scalars['String']['output']>;
  postal?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  updated?: Maybe<Scalars['DateTime']['output']>;
  version?: Maybe<Scalars['Float']['output']>;
};

export type AddressConnection = {
  __typename?: 'AddressConnection';
  /** Array of edges. */
  edges: Array<AddressEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type AddressDeleteFilter = {
  addressAddition?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<AddressDeleteFilter>>;
  city?: InputMaybe<StringFieldComparison>;
  companyName?: InputMaybe<StringFieldComparison>;
  country?: InputMaybe<StringFieldComparison>;
  created?: InputMaybe<DateFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  firstName?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  lastName?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<AddressDeleteFilter>>;
  organisationAltRecipientId?: InputMaybe<StringFieldComparison>;
  organisationId?: InputMaybe<StringFieldComparison>;
  organisationalUnit?: InputMaybe<StringFieldComparison>;
  postal?: InputMaybe<StringFieldComparison>;
  street?: InputMaybe<StringFieldComparison>;
  updated?: InputMaybe<DateFieldComparison>;
  version?: InputMaybe<NumberFieldComparison>;
};

export type AddressDeleteResponse = {
  __typename?: 'AddressDeleteResponse';
  addressAddition?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  organisationAltRecipientId?: Maybe<Scalars['String']['output']>;
  organisationId?: Maybe<Scalars['String']['output']>;
  organisationalUnit?: Maybe<Scalars['String']['output']>;
  postal?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  updated?: Maybe<Scalars['DateTime']['output']>;
  version?: Maybe<Scalars['Float']['output']>;
};

export type AddressEdge = {
  __typename?: 'AddressEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the Address */
  node: Address;
};

export type AddressFilter = {
  addressAddition?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<AddressFilter>>;
  city?: InputMaybe<StringFieldComparison>;
  companyName?: InputMaybe<StringFieldComparison>;
  country?: InputMaybe<StringFieldComparison>;
  created?: InputMaybe<DateFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  firstName?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  lastName?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<AddressFilter>>;
  organisationAltRecipientId?: InputMaybe<StringFieldComparison>;
  organisationId?: InputMaybe<StringFieldComparison>;
  organisationalUnit?: InputMaybe<StringFieldComparison>;
  postal?: InputMaybe<StringFieldComparison>;
  street?: InputMaybe<StringFieldComparison>;
  updated?: InputMaybe<DateFieldComparison>;
  version?: InputMaybe<NumberFieldComparison>;
};

export type AddressInput = {
  addressAddition?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  companyName?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  created?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  organisationAltRecipientId?: InputMaybe<Scalars['String']['input']>;
  organisationId?: InputMaybe<Scalars['String']['input']>;
  organisationalUnit?: InputMaybe<Scalars['String']['input']>;
  postal?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  updated?: InputMaybe<Scalars['DateTime']['input']>;
  version?: InputMaybe<Scalars['Float']['input']>;
};

export type AddressSort = {
  direction: SortDirection;
  field: AddressSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum AddressSortFields {
  AddressAddition = 'addressAddition',
  City = 'city',
  CompanyName = 'companyName',
  Country = 'country',
  Created = 'created',
  Email = 'email',
  FirstName = 'firstName',
  Id = 'id',
  LastName = 'lastName',
  OrganisationAltRecipientId = 'organisationAltRecipientId',
  OrganisationId = 'organisationId',
  OrganisationalUnit = 'organisationalUnit',
  Postal = 'postal',
  Street = 'street',
  Updated = 'updated',
  Version = 'version'
}

export type AddressSubscriptionFilter = {
  addressAddition?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<AddressSubscriptionFilter>>;
  city?: InputMaybe<StringFieldComparison>;
  companyName?: InputMaybe<StringFieldComparison>;
  country?: InputMaybe<StringFieldComparison>;
  created?: InputMaybe<DateFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  firstName?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  lastName?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<AddressSubscriptionFilter>>;
  organisationAltRecipientId?: InputMaybe<StringFieldComparison>;
  organisationId?: InputMaybe<StringFieldComparison>;
  organisationalUnit?: InputMaybe<StringFieldComparison>;
  postal?: InputMaybe<StringFieldComparison>;
  street?: InputMaybe<StringFieldComparison>;
  updated?: InputMaybe<DateFieldComparison>;
  version?: InputMaybe<NumberFieldComparison>;
};

export type AddressUpdateFilter = {
  addressAddition?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<AddressUpdateFilter>>;
  city?: InputMaybe<StringFieldComparison>;
  companyName?: InputMaybe<StringFieldComparison>;
  country?: InputMaybe<StringFieldComparison>;
  created?: InputMaybe<DateFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  firstName?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  lastName?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<AddressUpdateFilter>>;
  organisationAltRecipientId?: InputMaybe<StringFieldComparison>;
  organisationId?: InputMaybe<StringFieldComparison>;
  organisationalUnit?: InputMaybe<StringFieldComparison>;
  postal?: InputMaybe<StringFieldComparison>;
  street?: InputMaybe<StringFieldComparison>;
  updated?: InputMaybe<DateFieldComparison>;
  version?: InputMaybe<NumberFieldComparison>;
};

export type BillingAddress = {
  __typename?: 'BillingAddress';
  address: Address;
  addressId: Scalars['String']['output'];
  billingReferenceNo1?: Maybe<Scalars['String']['output']>;
  billingReferenceNo2?: Maybe<Scalars['String']['output']>;
  billingReferenceNo3?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  serviceInstance: ServiceInstance;
  serviceInstanceId?: Maybe<Scalars['String']['output']>;
};

export type BillingAddressConnection = {
  __typename?: 'BillingAddressConnection';
  /** Array of edges. */
  edges: Array<BillingAddressEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type BillingAddressDeleteFilter = {
  addressId?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<BillingAddressDeleteFilter>>;
  billingReferenceNo1?: InputMaybe<StringFieldComparison>;
  billingReferenceNo2?: InputMaybe<StringFieldComparison>;
  billingReferenceNo3?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<BillingAddressDeleteFilter>>;
  serviceInstanceId?: InputMaybe<StringFieldComparison>;
};

export type BillingAddressDeleteResponse = {
  __typename?: 'BillingAddressDeleteResponse';
  addressId?: Maybe<Scalars['String']['output']>;
  billingReferenceNo1?: Maybe<Scalars['String']['output']>;
  billingReferenceNo2?: Maybe<Scalars['String']['output']>;
  billingReferenceNo3?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  serviceInstanceId?: Maybe<Scalars['String']['output']>;
};

export type BillingAddressEdge = {
  __typename?: 'BillingAddressEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the BillingAddress */
  node: BillingAddress;
};

export type BillingAddressFilter = {
  addressId?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<BillingAddressFilter>>;
  billingReferenceNo1?: InputMaybe<StringFieldComparison>;
  billingReferenceNo2?: InputMaybe<StringFieldComparison>;
  billingReferenceNo3?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<BillingAddressFilter>>;
  serviceInstanceId?: InputMaybe<StringFieldComparison>;
};

export type BillingAddressInput = {
  addressId: Scalars['String']['input'];
  billingReferenceNo1?: InputMaybe<Scalars['String']['input']>;
  billingReferenceNo2?: InputMaybe<Scalars['String']['input']>;
  billingReferenceNo3?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  serviceInstanceId?: InputMaybe<Scalars['String']['input']>;
};

export type BillingAddressSort = {
  direction: SortDirection;
  field: BillingAddressSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum BillingAddressSortFields {
  AddressId = 'addressId',
  BillingReferenceNo1 = 'billingReferenceNo1',
  BillingReferenceNo2 = 'billingReferenceNo2',
  BillingReferenceNo3 = 'billingReferenceNo3',
  Id = 'id',
  ServiceInstanceId = 'serviceInstanceId'
}

export type BillingAddressSubscriptionFilter = {
  addressId?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<BillingAddressSubscriptionFilter>>;
  billingReferenceNo1?: InputMaybe<StringFieldComparison>;
  billingReferenceNo2?: InputMaybe<StringFieldComparison>;
  billingReferenceNo3?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<BillingAddressSubscriptionFilter>>;
  serviceInstanceId?: InputMaybe<StringFieldComparison>;
};

export type BillingAddressUpdateFilter = {
  addressId?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<BillingAddressUpdateFilter>>;
  billingReferenceNo1?: InputMaybe<StringFieldComparison>;
  billingReferenceNo2?: InputMaybe<StringFieldComparison>;
  billingReferenceNo3?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<BillingAddressUpdateFilter>>;
  serviceInstanceId?: InputMaybe<StringFieldComparison>;
};

export type BooleanFieldComparison = {
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Company = {
  __typename?: 'Company';
  created: Scalars['DateTime']['output'];
  features?: Maybe<CompanyFeatures>;
  id: Scalars['ID']['output'];
  isOtpRequired?: Maybe<Scalars['Boolean']['output']>;
  keycloakId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  organisations: Array<Organisation>;
  people: Array<Person>;
  updated: Scalars['DateTime']['output'];
  version: Scalars['Float']['output'];
};

export type CompanyOrganisationsArgs = {
  filter?: OrganisationFilter;
  sorting?: Array<OrganisationSort>;
};

export type CompanyPeopleArgs = {
  filter?: PersonFilter;
  sorting?: Array<PersonSort>;
};

export type CompanyConnection = {
  __typename?: 'CompanyConnection';
  /** Array of edges. */
  edges: Array<CompanyEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type CompanyDeleteResponse = {
  __typename?: 'CompanyDeleteResponse';
  created?: Maybe<Scalars['DateTime']['output']>;
  features?: Maybe<CompanyFeatures>;
  id?: Maybe<Scalars['ID']['output']>;
  isOtpRequired?: Maybe<Scalars['Boolean']['output']>;
  keycloakId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated?: Maybe<Scalars['DateTime']['output']>;
  version?: Maybe<Scalars['Float']['output']>;
};

export type CompanyEdge = {
  __typename?: 'CompanyEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the Company */
  node: Company;
};

export type CompanyFeatures = {
  __typename?: 'CompanyFeatures';
  isDcamEnabled?: Maybe<Scalars['Boolean']['output']>;
  isInvoiceEnabled?: Maybe<Scalars['Boolean']['output']>;
};

export type CompanyFeaturesInput = {
  isDcamEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isInvoiceEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CompanyFilter = {
  and?: InputMaybe<Array<CompanyFilter>>;
  created?: InputMaybe<DateFieldComparison>;
  features?: InputMaybe<CompanyFilterCompanyFeaturesFilter>;
  id?: InputMaybe<IdFilterComparison>;
  isOtpRequired?: InputMaybe<BooleanFieldComparison>;
  keycloakId?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CompanyFilter>>;
  people?: InputMaybe<CompanyFilterPersonFilter>;
  updated?: InputMaybe<DateFieldComparison>;
  version?: InputMaybe<NumberFieldComparison>;
};

export type CompanyFilterCompanyFeaturesFilter = {
  and?: InputMaybe<Array<CompanyFilterCompanyFeaturesFilter>>;
  isDcamEnabled?: InputMaybe<BooleanFieldComparison>;
  isInvoiceEnabled?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<CompanyFilterCompanyFeaturesFilter>>;
};

export type CompanyFilterPersonFilter = {
  and?: InputMaybe<Array<CompanyFilterPersonFilter>>;
  companyName?: InputMaybe<StringFieldComparison>;
  created?: InputMaybe<DateFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  firstName?: InputMaybe<StringFieldComparison>;
  gender?: InputMaybe<GenderFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isKeycloakVerified?: InputMaybe<BooleanFieldComparison>;
  isRoot?: InputMaybe<BooleanFieldComparison>;
  keycloakUser?: InputMaybe<StringFieldComparison>;
  lastName?: InputMaybe<StringFieldComparison>;
  mobilePhone?: InputMaybe<StringFieldComparison>;
  newsletter?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<CompanyFilterPersonFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
  technicalAccountNotifications?: InputMaybe<BooleanFieldComparison>;
  technicalUpdateNotifications?: InputMaybe<BooleanFieldComparison>;
  updated?: InputMaybe<DateFieldComparison>;
  version?: InputMaybe<NumberFieldComparison>;
};

export type CompanyInput = {
  created: Scalars['DateTime']['input'];
  features?: InputMaybe<CompanyFeaturesInput>;
  id: Scalars['ID']['input'];
  isOtpRequired?: InputMaybe<Scalars['Boolean']['input']>;
  keycloakId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  updated: Scalars['DateTime']['input'];
  version: Scalars['Float']['input'];
};

export type CompanySort = {
  direction: SortDirection;
  field: CompanySortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum CompanySortFields {
  Created = 'created',
  Features = 'features',
  Id = 'id',
  IsOtpRequired = 'isOtpRequired',
  KeycloakId = 'keycloakId',
  Name = 'name',
  Updated = 'updated',
  Version = 'version'
}

export type CompanySubscriptionFilter = {
  and?: InputMaybe<Array<CompanySubscriptionFilter>>;
  created?: InputMaybe<DateFieldComparison>;
  features?: InputMaybe<CompanySubscriptionFilterCompanyFeaturesSubscriptionFilter>;
  id?: InputMaybe<IdFilterComparison>;
  isOtpRequired?: InputMaybe<BooleanFieldComparison>;
  keycloakId?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CompanySubscriptionFilter>>;
  updated?: InputMaybe<DateFieldComparison>;
  version?: InputMaybe<NumberFieldComparison>;
};

export type CompanySubscriptionFilterCompanyFeaturesSubscriptionFilter = {
  and?: InputMaybe<Array<CompanySubscriptionFilterCompanyFeaturesSubscriptionFilter>>;
  isDcamEnabled?: InputMaybe<BooleanFieldComparison>;
  isInvoiceEnabled?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<CompanySubscriptionFilterCompanyFeaturesSubscriptionFilter>>;
};

export type ContactPerson = {
  __typename?: 'ContactPerson';
  contactPersonFor: Array<ContactPersonFor>;
  created: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
  person: Person;
  personId?: Maybe<Scalars['String']['output']>;
  serviceInstance: ServiceInstance;
  serviceInstanceId: Scalars['String']['output'];
  updated: Scalars['DateTime']['output'];
  version: Scalars['Float']['output'];
};

export type ContactPersonConnection = {
  __typename?: 'ContactPersonConnection';
  /** Array of edges. */
  edges: Array<ContactPersonEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type ContactPersonDeleteFilter = {
  and?: InputMaybe<Array<ContactPersonDeleteFilter>>;
  created?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  note?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ContactPersonDeleteFilter>>;
  personId?: InputMaybe<StringFieldComparison>;
  serviceInstanceId?: InputMaybe<StringFieldComparison>;
  updated?: InputMaybe<DateFieldComparison>;
  version?: InputMaybe<NumberFieldComparison>;
};

export type ContactPersonDeleteResponse = {
  __typename?: 'ContactPersonDeleteResponse';
  contactPersonFor?: Maybe<Array<ContactPersonFor>>;
  created?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  personId?: Maybe<Scalars['String']['output']>;
  serviceInstanceId?: Maybe<Scalars['String']['output']>;
  updated?: Maybe<Scalars['DateTime']['output']>;
  version?: Maybe<Scalars['Float']['output']>;
};

export type ContactPersonEdge = {
  __typename?: 'ContactPersonEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the ContactPerson */
  node: ContactPerson;
};

export type ContactPersonFilter = {
  and?: InputMaybe<Array<ContactPersonFilter>>;
  created?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  note?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ContactPersonFilter>>;
  personId?: InputMaybe<StringFieldComparison>;
  serviceInstanceId?: InputMaybe<StringFieldComparison>;
  updated?: InputMaybe<DateFieldComparison>;
  version?: InputMaybe<NumberFieldComparison>;
};

/** Contact Type */
export enum ContactPersonFor {
  ChangeRequest = 'Change_Request',
  ContactIncidentDefect = 'Contact_IncidentDefect',
  ContactMaintenance = 'Contact_Maintenance',
  DcAccess = 'DC_Access',
  DcAuthorizer = 'DC_Authorizer',
  ServiceRequest = 'Service_Request'
}

export type ContactPersonInput = {
  contactPersonFor: Array<ContactPersonFor>;
  created: Scalars['DateTime']['input'];
  id: Scalars['ID']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  personId?: InputMaybe<Scalars['String']['input']>;
  serviceInstanceId: Scalars['String']['input'];
  updated: Scalars['DateTime']['input'];
  version: Scalars['Float']['input'];
};

export type ContactPersonSort = {
  direction: SortDirection;
  field: ContactPersonSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ContactPersonSortFields {
  Created = 'created',
  Id = 'id',
  Note = 'note',
  PersonId = 'personId',
  ServiceInstanceId = 'serviceInstanceId',
  Updated = 'updated',
  Version = 'version'
}

export type ContactPersonSubscriptionFilter = {
  and?: InputMaybe<Array<ContactPersonSubscriptionFilter>>;
  created?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  note?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ContactPersonSubscriptionFilter>>;
  personId?: InputMaybe<StringFieldComparison>;
  serviceInstanceId?: InputMaybe<StringFieldComparison>;
  updated?: InputMaybe<DateFieldComparison>;
  version?: InputMaybe<NumberFieldComparison>;
};

export type ContactPersonUpdateFilter = {
  and?: InputMaybe<Array<ContactPersonUpdateFilter>>;
  created?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  note?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ContactPersonUpdateFilter>>;
  personId?: InputMaybe<StringFieldComparison>;
  serviceInstanceId?: InputMaybe<StringFieldComparison>;
  updated?: InputMaybe<DateFieldComparison>;
  version?: InputMaybe<NumberFieldComparison>;
};

/** Status of a contract */
export enum ContractStatus {
  Active = 'active',
  Canceled = 'canceled',
  Commissioned = 'commissioned',
  Terminated = 'terminated'
}

export type ContractStatusFilterComparison = {
  eq?: InputMaybe<ContractStatus>;
  gt?: InputMaybe<ContractStatus>;
  gte?: InputMaybe<ContractStatus>;
  iLike?: InputMaybe<ContractStatus>;
  in?: InputMaybe<Array<ContractStatus>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<ContractStatus>;
  lt?: InputMaybe<ContractStatus>;
  lte?: InputMaybe<ContractStatus>;
  neq?: InputMaybe<ContractStatus>;
  notILike?: InputMaybe<ContractStatus>;
  notIn?: InputMaybe<Array<ContractStatus>>;
  notLike?: InputMaybe<ContractStatus>;
};

export type CreateAddressInput = {
  addressAddition?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  companyName?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  organisationAltRecipientId?: InputMaybe<Scalars['String']['input']>;
  organisationId?: InputMaybe<Scalars['String']['input']>;
  organisationalUnit?: InputMaybe<Scalars['String']['input']>;
  postal?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
};

export type CreateAddressSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: AddressSubscriptionFilter;
};

export type CreateBillingAddressInput = {
  addressId: Scalars['String']['input'];
  billingReferenceNo1?: InputMaybe<Scalars['String']['input']>;
  billingReferenceNo2?: InputMaybe<Scalars['String']['input']>;
  billingReferenceNo3?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  serviceInstanceId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateBillingAddressSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: BillingAddressSubscriptionFilter;
};

export type CreateCompanySubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: CompanySubscriptionFilter;
};

export type CreateContactPersonInput = {
  contactPersonFor: Array<ContactPersonFor>;
  note?: InputMaybe<Scalars['String']['input']>;
  person?: InputMaybe<CreatePersonInput>;
  personId?: InputMaybe<Scalars['String']['input']>;
  serviceInstanceId: Scalars['String']['input'];
};

export type CreateContactPersonSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: ContactPersonSubscriptionFilter;
};

export type CreateDcamContactDto = {
  birthday?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  dcamAccessId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
};

export type CreateIpWhitelist = {
  id: Scalars['ID']['input'];
  ips: Array<Scalars['String']['input']>;
  serviceInstance: ServiceInstanceInput;
};

export type CreateIpWhitelistSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: IpWhitelistSubscriptionFilter;
};

export type CreateManyAddressesInput = {
  /** Array of records to create */
  addresses: Array<CreateAddressInput>;
};

export type CreateManyBillingAddressesInput = {
  /** Array of records to create */
  billingAddresses: Array<CreateBillingAddressInput>;
};

export type CreateManyContactPeopleInput = {
  /** Array of records to create */
  contactPeople: Array<CreateContactPersonInput>;
};

export type CreateManyIpWhitelistsInput = {
  /** Array of records to create */
  ipWhitelists: Array<CreateIpWhitelist>;
};

export type CreateManyOpencloudProjectsInput = {
  /** Array of records to create */
  opencloudProjects: Array<CreateOpencloudProjectInput>;
};

export type CreateManyRackifyConfigurationsInput = {
  /** Array of records to create */
  rackifyConfigurations: Array<RackifyConfigurationInput>;
};

export type CreateManyRackifyConfiguredServicesInput = {
  /** Array of records to create */
  rackifyConfiguredServices: Array<RackifyCalculatedServiceInput>;
};

export type CreateManyS3ServiceAccountsInput = {
  /** Array of records to create */
  s3ServiceAccounts: Array<S3ServiceAccountInput>;
};

export type CreateManyServiceInstancesInput = {
  /** Array of records to create */
  serviceInstances: Array<CreateServiceInstanceInput>;
};

export type CreateManyServiceUsersInput = {
  /** Array of records to create */
  serviceUsers: Array<CreateServiceUser>;
};

export type CreateOneAddressInput = {
  /** The record to create */
  address: CreateAddressInput;
};

export type CreateOneBillingAddressInput = {
  /** The record to create */
  billingAddress: CreateBillingAddressInput;
};

export type CreateOneContactPersonInput = {
  /** The record to create */
  contactPerson: CreateContactPersonInput;
};

export type CreateOneDcamContactInput = {
  /** The record to create */
  dcamContact: CreateDcamContactDto;
};

export type CreateOneIpWhitelistInput = {
  /** The record to create */
  ipWhitelist: CreateIpWhitelist;
};

export type CreateOneOpencloudProjectInput = {
  /** The record to create */
  opencloudProject: CreateOpencloudProjectInput;
};

export type CreateOneRackifyConfigurationInput = {
  /** The record to create */
  rackifyConfiguration: RackifyConfigurationInput;
};

export type CreateOneRackifyConfiguredServiceInput = {
  /** The record to create */
  rackifyConfiguredService: RackifyCalculatedServiceInput;
};

export type CreateOneS3ServiceAccountInput = {
  /** The record to create */
  s3ServiceAccount: S3ServiceAccountInput;
};

export type CreateOneServiceInstanceInput = {
  /** The record to create */
  serviceInstance: CreateServiceInstanceInput;
};

export type CreateOneServiceUserInput = {
  /** The record to create */
  serviceUser: CreateServiceUser;
};

export type CreateOpencloudProjectInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  serviceInstance: InlineServiceInstance;
  setup?: InputMaybe<ProjectSetupInput>;
};

export type CreateOrganisationSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: OrganisationSubscriptionFilter;
};

export type CreatePersonInput = {
  companies?: InputMaybe<Array<InlineCompany>>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mobilePhone?: InputMaybe<Scalars['String']['input']>;
  newsletter?: InputMaybe<Scalars['Boolean']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  serviceOwnerOf?: InputMaybe<Array<InlineServiceInstance>>;
  serviceUserGroups?: InputMaybe<ServiceUserGroupInput>;
  technicalUpdateNotifications?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreatePersonSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: PersonSubscriptionFilter;
};

export type CreateRackifyConfigurationSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: RackifyConfigurationSubscriptionFilter;
};

export type CreateRackifyConfiguredServiceSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: RackifyConfiguredServiceSubscriptionFilter;
};

export type CreateServiceInstanceInput = {
  invoiceRecipient?: InputMaybe<UpdateBillingAddressInput>;
  serviceOwnerIsContactPerson?: InputMaybe<Scalars['Boolean']['input']>;
  serviceOwnerIsRequestContactPerson?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateServiceInstanceSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: ServiceInstanceSubscriptionFilter;
};

export type CreateServiceUser = {
  created: Scalars['DateTime']['input'];
  deleted: Scalars['DateTime']['input'];
  id: Scalars['String']['input'];
  role: ServiceUserRole;
  updated: Scalars['DateTime']['input'];
  version: Scalars['Float']['input'];
};

export type CreateServiceUserInput = {
  id: Scalars['ID']['input'];
  person: InlinePerson;
  role: ServiceUserRole;
};

export type CursorPaging = {
  /** Paginate after opaque cursor */
  after?: InputMaybe<Scalars['ConnectionCursor']['input']>;
  /** Paginate before opaque cursor */
  before?: InputMaybe<Scalars['ConnectionCursor']['input']>;
  /** Paginate first */
  first?: InputMaybe<Scalars['Int']['input']>;
  /** Paginate last */
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type DateFieldComparison = {
  between?: InputMaybe<DateFieldComparisonBetween>;
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  neq?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<DateFieldComparisonBetween>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type DateFieldComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type Dcam = {
  __typename?: 'Dcam';
  access: Array<DcamAccess>;
  companyId: Scalars['ID']['output'];
  created: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  serviceOwners: Array<Person>;
  updated: Scalars['DateTime']['output'];
  version: Scalars['Float']['output'];
};

export type DcamServiceOwnersArgs = {
  filter?: PersonFilter;
  sorting?: Array<PersonSort>;
};

export type DcamAccess = {
  __typename?: 'DcamAccess';
  accessType: DcamAccessMode;
  contacts?: Maybe<Array<DcamContact>>;
  created: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  keySet?: Maybe<DcamKeyset>;
  name?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  updated: Scalars['DateTime']['output'];
  version: Scalars['Float']['output'];
};

export type DcamAccessContactsArgs = {
  filter?: DcamContactFilter;
  sorting?: Array<DcamContactSort>;
};

export type DcamAccessInput = {
  accessType: DcamAccessMode;
  created: Scalars['DateTime']['input'];
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  updated: Scalars['DateTime']['input'];
  version: Scalars['Float']['input'];
};

export enum DcamAccessMode {
  Read = 'READ',
  Write = 'WRITE'
}

export type DcamContact = {
  __typename?: 'DcamContact';
  birthday: Scalars['String']['output'];
  changedBy: Scalars['String']['output'];
  company?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTime']['output'];
  dcamAccess: DcamAccess;
  email?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  gender: Gender;
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  updated: Scalars['DateTime']['output'];
  version: Scalars['Float']['output'];
};

export type DcamContactDeleteResponse = {
  __typename?: 'DcamContactDeleteResponse';
  birthday?: Maybe<Scalars['String']['output']>;
  changedBy?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Gender>;
  id?: Maybe<Scalars['ID']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  updated?: Maybe<Scalars['DateTime']['output']>;
  version?: Maybe<Scalars['Float']['output']>;
};

export type DcamContactEdge = {
  __typename?: 'DcamContactEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the DcamContact */
  node: DcamContact;
};

export type DcamContactFilter = {
  and?: InputMaybe<Array<DcamContactFilter>>;
  birthday?: InputMaybe<StringFieldComparison>;
  changedBy?: InputMaybe<StringFieldComparison>;
  company?: InputMaybe<StringFieldComparison>;
  created?: InputMaybe<DateFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  firstName?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  lastName?: InputMaybe<StringFieldComparison>;
  note?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<DcamContactFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
  updated?: InputMaybe<DateFieldComparison>;
  version?: InputMaybe<NumberFieldComparison>;
};

export type DcamContactSort = {
  direction: SortDirection;
  field: DcamContactSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum DcamContactSortFields {
  Birthday = 'birthday',
  ChangedBy = 'changedBy',
  Company = 'company',
  Created = 'created',
  Email = 'email',
  FirstName = 'firstName',
  Id = 'id',
  LastName = 'lastName',
  Note = 'note',
  Phone = 'phone',
  Updated = 'updated',
  Version = 'version'
}

export type DcamInput = {
  companyId: Scalars['ID']['input'];
  created: Scalars['DateTime']['input'];
  id: Scalars['ID']['input'];
  updated: Scalars['DateTime']['input'];
  version: Scalars['Float']['input'];
};

export type DcamKeyset = {
  __typename?: 'DcamKeyset';
  id: Scalars['ID']['output'];
  keyId: Scalars['String']['output'];
  serviceInfos?: Maybe<Array<DcamKeysetServiceInfo>>;
};

export type DcamKeysetInput = {
  id: Scalars['ID']['input'];
  keyId: Scalars['String']['input'];
  serviceInfos?: InputMaybe<Array<DcamKeysetServiceInfoInput>>;
};

export type DcamKeysetServiceInfo = {
  __typename?: 'DcamKeysetServiceInfo';
  dc: Scalars['String']['output'];
  loc1: Scalars['String']['output'];
  loc2: Scalars['String']['output'];
  loc3: Scalars['String']['output'];
  loc4?: Maybe<Scalars['String']['output']>;
  loc5?: Maybe<Scalars['String']['output']>;
  serviceNumber: Scalars['String']['output'];
};

export type DcamKeysetServiceInfoInput = {
  dc: Scalars['String']['input'];
  loc1: Scalars['String']['input'];
  loc2: Scalars['String']['input'];
  loc3: Scalars['String']['input'];
  loc4?: InputMaybe<Scalars['String']['input']>;
  loc5?: InputMaybe<Scalars['String']['input']>;
  serviceNumber: Scalars['String']['input'];
};

export type DcamUpdateFilter = {
  and?: InputMaybe<Array<DcamUpdateFilter>>;
  companyId?: InputMaybe<IdFilterComparison>;
  created?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<DcamUpdateFilter>>;
  updated?: InputMaybe<DateFieldComparison>;
  version?: InputMaybe<NumberFieldComparison>;
};

export type DeleteManyAddressesInput = {
  /** Filter to find records to delete */
  filter: AddressDeleteFilter;
};

export type DeleteManyBillingAddressesInput = {
  /** Filter to find records to delete */
  filter: BillingAddressDeleteFilter;
};

export type DeleteManyContactPeopleInput = {
  /** Filter to find records to delete */
  filter: ContactPersonDeleteFilter;
};

export type DeleteManyIpWhitelistsInput = {
  /** Filter to find records to delete */
  filter: IpWhitelistDeleteFilter;
};

export type DeleteManyOpencloudProjectsInput = {
  /** Filter to find records to delete */
  filter: OpencloudProjectDeleteFilter;
};

export type DeleteManyRackifyConfigurationsInput = {
  /** Filter to find records to delete */
  filter: RackifyConfigurationDeleteFilter;
};

export type DeleteManyRackifyConfiguredServicesInput = {
  /** Filter to find records to delete */
  filter: RackifyConfiguredServiceDeleteFilter;
};

export type DeleteManyResponse = {
  __typename?: 'DeleteManyResponse';
  /** The number of records deleted. */
  deletedCount: Scalars['Int']['output'];
};

export type DeleteManyS3ServiceAccountsInput = {
  /** Filter to find records to delete */
  filter: S3ServiceAccountDeleteFilter;
};

export type DeleteManyServiceInstancesInput = {
  /** Filter to find records to delete */
  filter: ServiceInstanceDeleteFilter;
};

export type DeleteManyServiceUsersInput = {
  /** Filter to find records to delete */
  filter: ServiceUserDeleteFilter;
};

export type DeleteOneAddressInput = {
  /** The id of the record to delete. */
  id: Scalars['ID']['input'];
};

export type DeleteOneAddressSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: AddressSubscriptionFilter;
};

export type DeleteOneBillingAddressInput = {
  /** The id of the record to delete. */
  id: Scalars['ID']['input'];
};

export type DeleteOneBillingAddressSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: BillingAddressSubscriptionFilter;
};

export type DeleteOneCompanySubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: CompanySubscriptionFilter;
};

export type DeleteOneContactPersonInput = {
  /** The id of the record to delete. */
  id: Scalars['ID']['input'];
};

export type DeleteOneContactPersonSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: ContactPersonSubscriptionFilter;
};

export type DeleteOneDcamContactInput = {
  /** The id of the record to delete. */
  id: Scalars['ID']['input'];
};

export type DeleteOneIpWhitelistInput = {
  /** The id of the record to delete. */
  id: Scalars['ID']['input'];
};

export type DeleteOneIpWhitelistSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: IpWhitelistSubscriptionFilter;
};

export type DeleteOneOpencloudProjectInput = {
  /** The id of the record to delete. */
  id: Scalars['ID']['input'];
};

export type DeleteOneOrganisationSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: OrganisationSubscriptionFilter;
};

export type DeleteOnePersonSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: PersonSubscriptionFilter;
};

export type DeleteOneRackifyConfigurationInput = {
  /** The id of the record to delete. */
  id: Scalars['ID']['input'];
};

export type DeleteOneRackifyConfigurationSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: RackifyConfigurationSubscriptionFilter;
};

export type DeleteOneRackifyConfiguredServiceInput = {
  /** The id of the record to delete. */
  id: Scalars['ID']['input'];
};

export type DeleteOneRackifyConfiguredServiceSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: RackifyConfiguredServiceSubscriptionFilter;
};

export type DeleteOneS3ServiceAccountInput = {
  /** The id of the record to delete. */
  id: Scalars['ID']['input'];
};

export type DeleteOneServiceInstanceInput = {
  /** The id of the record to delete. */
  id: Scalars['ID']['input'];
};

export type DeleteOneServiceInstanceSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: ServiceInstanceSubscriptionFilter;
};

export type DeleteOneServiceUserInput = {
  /** The id of the record to delete. */
  id: Scalars['ID']['input'];
};

/** Gender of a Person */
export enum Gender {
  D = 'd',
  M = 'm',
  W = 'w'
}

export type GenderFilterComparison = {
  eq?: InputMaybe<Gender>;
  gt?: InputMaybe<Gender>;
  gte?: InputMaybe<Gender>;
  iLike?: InputMaybe<Gender>;
  in?: InputMaybe<Array<Gender>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<Gender>;
  lt?: InputMaybe<Gender>;
  lte?: InputMaybe<Gender>;
  neq?: InputMaybe<Gender>;
  notILike?: InputMaybe<Gender>;
  notIn?: InputMaybe<Array<Gender>>;
  notLike?: InputMaybe<Gender>;
};

export type IdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
  gt?: InputMaybe<Scalars['ID']['input']>;
  gte?: InputMaybe<Scalars['ID']['input']>;
  iLike?: InputMaybe<Scalars['ID']['input']>;
  in?: InputMaybe<Array<Scalars['ID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<Scalars['ID']['input']>;
  lt?: InputMaybe<Scalars['ID']['input']>;
  lte?: InputMaybe<Scalars['ID']['input']>;
  neq?: InputMaybe<Scalars['ID']['input']>;
  notILike?: InputMaybe<Scalars['ID']['input']>;
  notIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  notLike?: InputMaybe<Scalars['ID']['input']>;
};

export type InlineCompany = {
  id: Scalars['ID']['input'];
};

export type InlinePerson = {
  id: Scalars['ID']['input'];
};

export type InlineServiceInstance = {
  id: Scalars['ID']['input'];
};

export type IpWhitelist = {
  __typename?: 'IpWhitelist';
  id: Scalars['ID']['output'];
  ips: Array<Scalars['String']['output']>;
  serviceInstance: ServiceInstance;
};

export type IpWhitelistConnection = {
  __typename?: 'IpWhitelistConnection';
  /** Array of edges. */
  edges: Array<IpWhitelistEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type IpWhitelistDeleteFilter = {
  and?: InputMaybe<Array<IpWhitelistDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<IpWhitelistDeleteFilter>>;
};

export type IpWhitelistDeleteResponse = {
  __typename?: 'IpWhitelistDeleteResponse';
  id?: Maybe<Scalars['ID']['output']>;
  ips?: Maybe<Array<Scalars['String']['output']>>;
  serviceInstance?: Maybe<ServiceInstance>;
};

export type IpWhitelistEdge = {
  __typename?: 'IpWhitelistEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the IpWhitelist */
  node: IpWhitelist;
};

export type IpWhitelistFilter = {
  and?: InputMaybe<Array<IpWhitelistFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<IpWhitelistFilter>>;
  serviceInstance?: InputMaybe<IpWhitelistFilterServiceInstanceFilter>;
};

export type IpWhitelistFilterServiceInstanceFilter = {
  amount?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<IpWhitelistFilterServiceInstanceFilter>>;
  articleDescription?: InputMaybe<StringFieldComparison>;
  companyId?: InputMaybe<StringFieldComparison>;
  contractStatus?: InputMaybe<ContractStatusFilterComparison>;
  created?: InputMaybe<DateFieldComparison>;
  customerNumber?: InputMaybe<StringFieldComparison>;
  details?: InputMaybe<StringFieldComparison>;
  endDate?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  location1?: InputMaybe<StringFieldComparison>;
  location2?: InputMaybe<StringFieldComparison>;
  minimumContractPeriod?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<IpWhitelistFilterServiceInstanceFilter>>;
  provisioningDate?: InputMaybe<DateFieldComparison>;
  provisioningStatus?: InputMaybe<ProvisioningStatusFilterComparison>;
  ptid?: InputMaybe<StringFieldComparison>;
  serviceGroup?: InputMaybe<StringFieldComparison>;
  serviceInstanceAdditionalURL?: InputMaybe<StringFieldComparison>;
  serviceInstanceURL?: InputMaybe<StringFieldComparison>;
  serviceName?: InputMaybe<StringFieldComparison>;
  serviceOwnerIsContactPerson?: InputMaybe<BooleanFieldComparison>;
  serviceOwnerIsRequestContactPerson?: InputMaybe<BooleanFieldComparison>;
  serviceType?: InputMaybe<ServiceTypeFilterComparison>;
  sinr?: InputMaybe<StringFieldComparison>;
  startDate?: InputMaybe<DateFieldComparison>;
  subServiceText?: InputMaybe<StringFieldComparison>;
  updated?: InputMaybe<DateFieldComparison>;
  version?: InputMaybe<NumberFieldComparison>;
};

export type IpWhitelistInput = {
  id: Scalars['ID']['input'];
  ips: Array<Scalars['String']['input']>;
  serviceInstance: ServiceInstanceInput;
};

export type IpWhitelistSort = {
  direction: SortDirection;
  field: IpWhitelistSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum IpWhitelistSortFields {
  Id = 'id'
}

export type IpWhitelistSubscriptionFilter = {
  and?: InputMaybe<Array<IpWhitelistSubscriptionFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<IpWhitelistSubscriptionFilter>>;
};

export type IpWhitelistUpdateFilter = {
  and?: InputMaybe<Array<IpWhitelistUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<IpWhitelistUpdateFilter>>;
};

export type JsonFilterComparison = {
  eq?: InputMaybe<Scalars['JSON']['input']>;
  gt?: InputMaybe<Scalars['JSON']['input']>;
  gte?: InputMaybe<Scalars['JSON']['input']>;
  iLike?: InputMaybe<Scalars['JSON']['input']>;
  in?: InputMaybe<Array<Scalars['JSON']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<Scalars['JSON']['input']>;
  lt?: InputMaybe<Scalars['JSON']['input']>;
  lte?: InputMaybe<Scalars['JSON']['input']>;
  neq?: InputMaybe<Scalars['JSON']['input']>;
  notILike?: InputMaybe<Scalars['JSON']['input']>;
  notIn?: InputMaybe<Array<Scalars['JSON']['input']>>;
  notLike?: InputMaybe<Scalars['JSON']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addContactPersonOfToPerson: Person;
  addInvoiceRecipientsToOrganisation: Organisation;
  addPeopleToCompany: Company;
  addServiceAccountsToServiceInstance: ServiceInstance;
  addServiceInstancesToOrganisation: Organisation;
  addServiceOwnerOfToPerson: Person;
  addServiceOwnersToDcam: Dcam;
  addServiceOwnersToServiceInstance: ServiceInstance;
  addServiceUserOfToPerson: Person;
  addServiceUsersToServiceInstance: ServiceInstance;
  createManyAddresses: Array<Address>;
  createManyBillingAddresses: Array<BillingAddress>;
  createManyContactPeople: Array<ContactPerson>;
  createManyIpWhitelists: Array<IpWhitelist>;
  createManyOpencloudProjects: Array<OpencloudProject>;
  createManyRackifyConfigurations: Array<RackifyConfiguration>;
  createManyRackifyConfiguredServices: Array<RackifyConfiguredService>;
  createManyS3ServiceAccounts: Array<S3ServiceAccount>;
  createManyServiceInstances: Array<ServiceInstance>;
  createManyServiceUsers: Array<ServiceUser>;
  createOneAddress: Address;
  createOneBillingAddress: BillingAddress;
  createOneContactPerson: ContactPerson;
  createOneDcamContact: DcamContact;
  createOneIpWhitelist: IpWhitelist;
  createOneOpencloudProject: OpencloudProject;
  createOneRackifyConfiguration: RackifyConfiguration;
  createOneRackifyConfiguredService: RackifyConfiguredService;
  createOneS3ServiceAccount: S3ServiceAccount;
  createOneServiceInstance: ServiceInstance;
  createOneServiceUser: ServiceUser;
  deleteManyAddresses: DeleteManyResponse;
  deleteManyBillingAddresses: DeleteManyResponse;
  deleteManyContactPeople: DeleteManyResponse;
  deleteManyIpWhitelists: DeleteManyResponse;
  deleteManyOpencloudProjects: DeleteManyResponse;
  deleteManyRackifyConfigurations: DeleteManyResponse;
  deleteManyRackifyConfiguredServices: DeleteManyResponse;
  deleteManyS3ServiceAccounts: DeleteManyResponse;
  deleteManyServiceInstances: DeleteManyResponse;
  deleteManyServiceUsers: DeleteManyResponse;
  deleteOneAddress: AddressDeleteResponse;
  deleteOneBillingAddress: BillingAddressDeleteResponse;
  deleteOneContactPerson: ContactPersonDeleteResponse;
  deleteOneDcamContact: DcamContactDeleteResponse;
  deleteOneIpWhitelist: IpWhitelistDeleteResponse;
  deleteOneOpencloudProject: OpencloudProjectDeleteResponse;
  deleteOneRackifyConfiguration: RackifyConfigurationDeleteResponse;
  deleteOneRackifyConfiguredService: RackifyConfiguredServiceDeleteResponse;
  deleteOneS3ServiceAccount: S3ServiceAccountDeleteResponse;
  deleteOneServiceInstance: ServiceInstanceDeleteResponse;
  deleteOneServiceUser: ServiceUserDeleteResponse;
  removeAddressFromBillingAddress: BillingAddress;
  removeBillingAddressFromAddress: Address;
  removeContactPersonOfFromPerson: Person;
  removeInvoiceRecipientFromOrganisation: Organisation;
  removeInvoiceRecipientFromServiceInstance: ServiceInstance;
  removeInvoiceRecipientsFromOrganisation: Organisation;
  removeOrganisationAltRecipientFromAddress: Address;
  removeOrganisationFromAddress: Address;
  removePeopleFromCompany: Company;
  removeServiceAccountsFromServiceInstance: ServiceInstance;
  removeServiceInstanceFromBillingAddress: BillingAddress;
  removeServiceOwnerOfFromPerson: Person;
  removeServiceOwnersFromDcam: Dcam;
  removeServiceOwnersFromServiceInstance: ServiceInstance;
  removeServiceUserOfFromPerson: Person;
  removeServiceUsersFromServiceInstance: ServiceInstance;
  setAddressOnBillingAddress: BillingAddress;
  setBillingAddressOnAddress: Address;
  setContactPersonOfOnPerson: Person;
  setInvoiceRecipientOnOrganisation: Organisation;
  setInvoiceRecipientOnServiceInstance: ServiceInstance;
  setInvoiceRecipientsOnOrganisation: Organisation;
  setOrganisationAltRecipientOnAddress: Address;
  setOrganisationOnAddress: Address;
  setPeopleOnCompany: Company;
  setServiceAccountsOnServiceInstance: ServiceInstance;
  setServiceInstanceOnBillingAddress: BillingAddress;
  setServiceInstancesOnOrganisation: Organisation;
  setServiceOwnerOfOnPerson: Person;
  setServiceOwnersOnDcam: Dcam;
  setServiceOwnersOnServiceInstance: ServiceInstance;
  setServiceUserOfOnPerson: Person;
  setServiceUsersOnServiceInstance: ServiceInstance;
  toggleCompanyOTPRequirement: ToggleCompanyOtpRequirement;
  updateManyAddresses: UpdateManyResponse;
  updateManyBillingAddresses: UpdateManyResponse;
  updateManyContactPeople: UpdateManyResponse;
  updateManyDcams: UpdateManyResponse;
  updateManyIpWhitelists: UpdateManyResponse;
  updateManyOpencloudProjects: UpdateManyResponse;
  updateManyOrganisations: UpdateManyResponse;
  updateManyPeople: UpdateManyResponse;
  updateManyRackifyConfigurations: UpdateManyResponse;
  updateManyRackifyConfiguredServices: UpdateManyResponse;
  updateManyS3ServiceAccounts: UpdateManyResponse;
  updateManyServiceGroups: UpdateManyResponse;
  updateManyServiceInstances: UpdateManyResponse;
  updateManyServiceUsers: UpdateManyResponse;
  updateOneAddress: Address;
  updateOneBillingAddress: BillingAddress;
  updateOneContactPerson: ContactPerson;
  updateOneDcam: Dcam;
  updateOneDcamAccess: DcamAccess;
  updateOneDcamContact: DcamContact;
  updateOneIpWhitelist: IpWhitelist;
  updateOneOpencloudProject: OpencloudProject;
  updateOneOrganisation: Organisation;
  updateOnePerson: Person;
  updateOneRackifyConfiguration: RackifyConfiguration;
  updateOneRackifyConfiguredService: RackifyConfiguredService;
  updateOneS3ServiceAccount: S3ServiceAccount;
  updateOneServiceGroup: ServiceGroup;
  updateOneServiceInstance: ServiceInstance;
  updateOneServiceUser: ServiceUser;
};

export type MutationAddContactPersonOfToPersonArgs = {
  input: AddContactPersonOfToPersonInput;
};

export type MutationAddInvoiceRecipientsToOrganisationArgs = {
  input: AddInvoiceRecipientsToOrganisationInput;
};

export type MutationAddPeopleToCompanyArgs = {
  input: AddPeopleToCompanyInput;
};

export type MutationAddServiceAccountsToServiceInstanceArgs = {
  input: AddServiceAccountsToServiceInstanceInput;
};

export type MutationAddServiceInstancesToOrganisationArgs = {
  input: AddServiceInstancesToOrganisationInput;
};

export type MutationAddServiceOwnerOfToPersonArgs = {
  input: AddServiceOwnerOfToPersonInput;
};

export type MutationAddServiceOwnersToDcamArgs = {
  input: AddServiceOwnersToDcamInput;
};

export type MutationAddServiceOwnersToServiceInstanceArgs = {
  input: AddServiceOwnersToServiceInstanceInput;
};

export type MutationAddServiceUserOfToPersonArgs = {
  input: AddServiceUserOfToPersonInput;
};

export type MutationAddServiceUsersToServiceInstanceArgs = {
  input: AddServiceUsersToServiceInstanceInput;
};

export type MutationCreateManyAddressesArgs = {
  input: CreateManyAddressesInput;
};

export type MutationCreateManyBillingAddressesArgs = {
  input: CreateManyBillingAddressesInput;
};

export type MutationCreateManyContactPeopleArgs = {
  input: CreateManyContactPeopleInput;
};

export type MutationCreateManyIpWhitelistsArgs = {
  input: CreateManyIpWhitelistsInput;
};

export type MutationCreateManyOpencloudProjectsArgs = {
  input: CreateManyOpencloudProjectsInput;
};

export type MutationCreateManyRackifyConfigurationsArgs = {
  input: CreateManyRackifyConfigurationsInput;
};

export type MutationCreateManyRackifyConfiguredServicesArgs = {
  input: CreateManyRackifyConfiguredServicesInput;
};

export type MutationCreateManyS3ServiceAccountsArgs = {
  input: CreateManyS3ServiceAccountsInput;
};

export type MutationCreateManyServiceInstancesArgs = {
  input: CreateManyServiceInstancesInput;
};

export type MutationCreateManyServiceUsersArgs = {
  input: CreateManyServiceUsersInput;
};

export type MutationCreateOneAddressArgs = {
  input: CreateOneAddressInput;
};

export type MutationCreateOneBillingAddressArgs = {
  input: CreateOneBillingAddressInput;
};

export type MutationCreateOneContactPersonArgs = {
  input: CreateOneContactPersonInput;
};

export type MutationCreateOneDcamContactArgs = {
  input: CreateOneDcamContactInput;
};

export type MutationCreateOneIpWhitelistArgs = {
  input: CreateOneIpWhitelistInput;
};

export type MutationCreateOneOpencloudProjectArgs = {
  input: CreateOneOpencloudProjectInput;
};

export type MutationCreateOneRackifyConfigurationArgs = {
  input: CreateOneRackifyConfigurationInput;
};

export type MutationCreateOneRackifyConfiguredServiceArgs = {
  input: CreateOneRackifyConfiguredServiceInput;
};

export type MutationCreateOneS3ServiceAccountArgs = {
  input: CreateOneS3ServiceAccountInput;
};

export type MutationCreateOneServiceInstanceArgs = {
  input: CreateOneServiceInstanceInput;
};

export type MutationCreateOneServiceUserArgs = {
  input: CreateOneServiceUserInput;
};

export type MutationDeleteManyAddressesArgs = {
  input: DeleteManyAddressesInput;
};

export type MutationDeleteManyBillingAddressesArgs = {
  input: DeleteManyBillingAddressesInput;
};

export type MutationDeleteManyContactPeopleArgs = {
  input: DeleteManyContactPeopleInput;
};

export type MutationDeleteManyIpWhitelistsArgs = {
  input: DeleteManyIpWhitelistsInput;
};

export type MutationDeleteManyOpencloudProjectsArgs = {
  input: DeleteManyOpencloudProjectsInput;
};

export type MutationDeleteManyRackifyConfigurationsArgs = {
  input: DeleteManyRackifyConfigurationsInput;
};

export type MutationDeleteManyRackifyConfiguredServicesArgs = {
  input: DeleteManyRackifyConfiguredServicesInput;
};

export type MutationDeleteManyS3ServiceAccountsArgs = {
  input: DeleteManyS3ServiceAccountsInput;
};

export type MutationDeleteManyServiceInstancesArgs = {
  input: DeleteManyServiceInstancesInput;
};

export type MutationDeleteManyServiceUsersArgs = {
  input: DeleteManyServiceUsersInput;
};

export type MutationDeleteOneAddressArgs = {
  input: DeleteOneAddressInput;
};

export type MutationDeleteOneBillingAddressArgs = {
  input: DeleteOneBillingAddressInput;
};

export type MutationDeleteOneContactPersonArgs = {
  input: DeleteOneContactPersonInput;
};

export type MutationDeleteOneDcamContactArgs = {
  input: DeleteOneDcamContactInput;
};

export type MutationDeleteOneIpWhitelistArgs = {
  input: DeleteOneIpWhitelistInput;
};

export type MutationDeleteOneOpencloudProjectArgs = {
  input: DeleteOneOpencloudProjectInput;
};

export type MutationDeleteOneRackifyConfigurationArgs = {
  input: DeleteOneRackifyConfigurationInput;
};

export type MutationDeleteOneRackifyConfiguredServiceArgs = {
  input: DeleteOneRackifyConfiguredServiceInput;
};

export type MutationDeleteOneS3ServiceAccountArgs = {
  input: DeleteOneS3ServiceAccountInput;
};

export type MutationDeleteOneServiceInstanceArgs = {
  input: DeleteOneServiceInstanceInput;
};

export type MutationDeleteOneServiceUserArgs = {
  input: DeleteOneServiceUserInput;
};

export type MutationRemoveAddressFromBillingAddressArgs = {
  input: RemoveAddressFromBillingAddressInput;
};

export type MutationRemoveBillingAddressFromAddressArgs = {
  input: RemoveBillingAddressFromAddressInput;
};

export type MutationRemoveContactPersonOfFromPersonArgs = {
  input: RemoveContactPersonOfFromPersonInput;
};

export type MutationRemoveInvoiceRecipientFromOrganisationArgs = {
  input: RemoveInvoiceRecipientFromOrganisationInput;
};

export type MutationRemoveInvoiceRecipientFromServiceInstanceArgs = {
  input: RemoveInvoiceRecipientFromServiceInstanceInput;
};

export type MutationRemoveInvoiceRecipientsFromOrganisationArgs = {
  input: RemoveInvoiceRecipientsFromOrganisationInput;
};

export type MutationRemoveOrganisationAltRecipientFromAddressArgs = {
  input: RemoveOrganisationAltRecipientFromAddressInput;
};

export type MutationRemoveOrganisationFromAddressArgs = {
  input: RemoveOrganisationFromAddressInput;
};

export type MutationRemovePeopleFromCompanyArgs = {
  input: RemovePeopleFromCompanyInput;
};

export type MutationRemoveServiceAccountsFromServiceInstanceArgs = {
  input: RemoveServiceAccountsFromServiceInstanceInput;
};

export type MutationRemoveServiceInstanceFromBillingAddressArgs = {
  input: RemoveServiceInstanceFromBillingAddressInput;
};

export type MutationRemoveServiceOwnerOfFromPersonArgs = {
  input: RemoveServiceOwnerOfFromPersonInput;
};

export type MutationRemoveServiceOwnersFromDcamArgs = {
  input: RemoveServiceOwnersFromDcamInput;
};

export type MutationRemoveServiceOwnersFromServiceInstanceArgs = {
  input: RemoveServiceOwnersFromServiceInstanceInput;
};

export type MutationRemoveServiceUserOfFromPersonArgs = {
  input: RemoveServiceUserOfFromPersonInput;
};

export type MutationRemoveServiceUsersFromServiceInstanceArgs = {
  input: RemoveServiceUsersFromServiceInstanceInput;
};

export type MutationSetAddressOnBillingAddressArgs = {
  input: SetAddressOnBillingAddressInput;
};

export type MutationSetBillingAddressOnAddressArgs = {
  input: SetBillingAddressOnAddressInput;
};

export type MutationSetContactPersonOfOnPersonArgs = {
  input: SetContactPersonOfOnPersonInput;
};

export type MutationSetInvoiceRecipientOnOrganisationArgs = {
  input: SetInvoiceRecipientOnOrganisationInput;
};

export type MutationSetInvoiceRecipientOnServiceInstanceArgs = {
  input: SetInvoiceRecipientOnServiceInstanceInput;
};

export type MutationSetInvoiceRecipientsOnOrganisationArgs = {
  input: SetInvoiceRecipientsOnOrganisationInput;
};

export type MutationSetOrganisationAltRecipientOnAddressArgs = {
  input: SetOrganisationAltRecipientOnAddressInput;
};

export type MutationSetOrganisationOnAddressArgs = {
  input: SetOrganisationOnAddressInput;
};

export type MutationSetPeopleOnCompanyArgs = {
  input: SetPeopleOnCompanyInput;
};

export type MutationSetServiceAccountsOnServiceInstanceArgs = {
  input: SetServiceAccountsOnServiceInstanceInput;
};

export type MutationSetServiceInstanceOnBillingAddressArgs = {
  input: SetServiceInstanceOnBillingAddressInput;
};

export type MutationSetServiceInstancesOnOrganisationArgs = {
  input: SetServiceInstancesOnOrganisationInput;
};

export type MutationSetServiceOwnerOfOnPersonArgs = {
  input: SetServiceOwnerOfOnPersonInput;
};

export type MutationSetServiceOwnersOnDcamArgs = {
  input: SetServiceOwnersOnDcamInput;
};

export type MutationSetServiceOwnersOnServiceInstanceArgs = {
  input: SetServiceOwnersOnServiceInstanceInput;
};

export type MutationSetServiceUserOfOnPersonArgs = {
  input: SetServiceUserOfOnPersonInput;
};

export type MutationSetServiceUsersOnServiceInstanceArgs = {
  input: SetServiceUsersOnServiceInstanceInput;
};

export type MutationUpdateManyAddressesArgs = {
  input: UpdateManyAddressesInput;
};

export type MutationUpdateManyBillingAddressesArgs = {
  input: UpdateManyBillingAddressesInput;
};

export type MutationUpdateManyContactPeopleArgs = {
  input: UpdateManyContactPeopleInput;
};

export type MutationUpdateManyDcamsArgs = {
  input: UpdateManyDcamsInput;
};

export type MutationUpdateManyIpWhitelistsArgs = {
  input: UpdateManyIpWhitelistsInput;
};

export type MutationUpdateManyOpencloudProjectsArgs = {
  input: UpdateManyOpencloudProjectsInput;
};

export type MutationUpdateManyOrganisationsArgs = {
  input: UpdateManyOrganisationsInput;
};

export type MutationUpdateManyPeopleArgs = {
  input: UpdateManyPeopleInput;
};

export type MutationUpdateManyRackifyConfigurationsArgs = {
  input: UpdateManyRackifyConfigurationsInput;
};

export type MutationUpdateManyRackifyConfiguredServicesArgs = {
  input: UpdateManyRackifyConfiguredServicesInput;
};

export type MutationUpdateManyS3ServiceAccountsArgs = {
  input: UpdateManyS3ServiceAccountsInput;
};

export type MutationUpdateManyServiceGroupsArgs = {
  input: UpdateManyServiceGroupsInput;
};

export type MutationUpdateManyServiceInstancesArgs = {
  input: UpdateManyServiceInstancesInput;
};

export type MutationUpdateManyServiceUsersArgs = {
  input: UpdateManyServiceUsersInput;
};

export type MutationUpdateOneAddressArgs = {
  input: UpdateOneAddressInput;
};

export type MutationUpdateOneBillingAddressArgs = {
  input: UpdateOneBillingAddressInput;
};

export type MutationUpdateOneContactPersonArgs = {
  input: UpdateOneContactPersonInput;
};

export type MutationUpdateOneDcamArgs = {
  input: UpdateOneDcamInput;
};

export type MutationUpdateOneDcamAccessArgs = {
  input: UpdateOneDcamAccessInput;
};

export type MutationUpdateOneDcamContactArgs = {
  input: UpdateOneDcamContactInput;
};

export type MutationUpdateOneIpWhitelistArgs = {
  input: UpdateOneIpWhitelistInput;
};

export type MutationUpdateOneOpencloudProjectArgs = {
  input: UpdateOneOpencloudProjectInput;
};

export type MutationUpdateOneOrganisationArgs = {
  input: UpdateOneOrganisationInput;
};

export type MutationUpdateOnePersonArgs = {
  input: UpdateOnePersonInput;
};

export type MutationUpdateOneRackifyConfigurationArgs = {
  input: UpdateOneRackifyConfigurationInput;
};

export type MutationUpdateOneRackifyConfiguredServiceArgs = {
  input: UpdateOneRackifyConfiguredServiceInput;
};

export type MutationUpdateOneS3ServiceAccountArgs = {
  input: UpdateOneS3ServiceAccountInput;
};

export type MutationUpdateOneServiceGroupArgs = {
  input: UpdateOneServiceGroupInput;
};

export type MutationUpdateOneServiceInstanceArgs = {
  input: UpdateOneServiceInstanceInput;
};

export type MutationUpdateOneServiceUserArgs = {
  input: UpdateOneServiceUserInput;
};

export type NumberFieldComparison = {
  between?: InputMaybe<NumberFieldComparisonBetween>;
  eq?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  neq?: InputMaybe<Scalars['Float']['input']>;
  notBetween?: InputMaybe<NumberFieldComparisonBetween>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type NumberFieldComparisonBetween = {
  lower: Scalars['Float']['input'];
  upper: Scalars['Float']['input'];
};

export type OffsetPageInfo = {
  __typename?: 'OffsetPageInfo';
  /** true if paging forward and there are more records. */
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  /** true if paging backwards and there are more records. */
  hasPreviousPage?: Maybe<Scalars['Boolean']['output']>;
};

export type OffsetPaging = {
  /** Limit the number of records returned */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** Offset to start returning records from */
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type OpencloudProject = {
  __typename?: 'OpencloudProject';
  created: Scalars['DateTime']['output'];
  deleted: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  openstackProjectId?: Maybe<Scalars['String']['output']>;
  provisionStatus: ProvisioningStatus;
  serviceInstance: ServiceInstance;
  serviceUsers: Array<ServiceUser>;
  setup?: Maybe<ProjectSetup>;
  updated: Scalars['DateTime']['output'];
  version: Scalars['Float']['output'];
};

export type OpencloudProjectServiceUsersArgs = {
  filter?: ServiceUserFilter;
  sorting?: Array<ServiceUserSort>;
};

export type OpencloudProjectConnection = {
  __typename?: 'OpencloudProjectConnection';
  /** Array of nodes. */
  nodes: Array<OpencloudProject>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
};

export type OpencloudProjectDeleteFilter = {
  and?: InputMaybe<Array<OpencloudProjectDeleteFilter>>;
  created?: InputMaybe<DateFieldComparison>;
  deleted?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  openstackProjectId?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<OpencloudProjectDeleteFilter>>;
  provisionStatus?: InputMaybe<ProvisioningStatusFilterComparison>;
  updated?: InputMaybe<DateFieldComparison>;
  version?: InputMaybe<NumberFieldComparison>;
};

export type OpencloudProjectDeleteResponse = {
  __typename?: 'OpencloudProjectDeleteResponse';
  created?: Maybe<Scalars['DateTime']['output']>;
  deleted?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  openstackProjectId?: Maybe<Scalars['String']['output']>;
  provisionStatus?: Maybe<ProvisioningStatus>;
  setup?: Maybe<ProjectSetup>;
  updated?: Maybe<Scalars['DateTime']['output']>;
  version?: Maybe<Scalars['Float']['output']>;
};

export type OpencloudProjectFilter = {
  and?: InputMaybe<Array<OpencloudProjectFilter>>;
  created?: InputMaybe<DateFieldComparison>;
  deleted?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  openstackProjectId?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<OpencloudProjectFilter>>;
  provisionStatus?: InputMaybe<ProvisioningStatusFilterComparison>;
  serviceInstance?: InputMaybe<OpencloudProjectFilterServiceInstanceFilter>;
  serviceUsers?: InputMaybe<OpencloudProjectFilterServiceUserFilter>;
  updated?: InputMaybe<DateFieldComparison>;
  version?: InputMaybe<NumberFieldComparison>;
};

export type OpencloudProjectFilterServiceInstanceFilter = {
  amount?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<OpencloudProjectFilterServiceInstanceFilter>>;
  articleDescription?: InputMaybe<StringFieldComparison>;
  companyId?: InputMaybe<StringFieldComparison>;
  contractStatus?: InputMaybe<ContractStatusFilterComparison>;
  created?: InputMaybe<DateFieldComparison>;
  customerNumber?: InputMaybe<StringFieldComparison>;
  details?: InputMaybe<StringFieldComparison>;
  endDate?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  location1?: InputMaybe<StringFieldComparison>;
  location2?: InputMaybe<StringFieldComparison>;
  minimumContractPeriod?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<OpencloudProjectFilterServiceInstanceFilter>>;
  provisioningDate?: InputMaybe<DateFieldComparison>;
  provisioningStatus?: InputMaybe<ProvisioningStatusFilterComparison>;
  ptid?: InputMaybe<StringFieldComparison>;
  serviceGroup?: InputMaybe<StringFieldComparison>;
  serviceInstanceAdditionalURL?: InputMaybe<StringFieldComparison>;
  serviceInstanceURL?: InputMaybe<StringFieldComparison>;
  serviceName?: InputMaybe<StringFieldComparison>;
  serviceOwnerIsContactPerson?: InputMaybe<BooleanFieldComparison>;
  serviceOwnerIsRequestContactPerson?: InputMaybe<BooleanFieldComparison>;
  serviceType?: InputMaybe<ServiceTypeFilterComparison>;
  sinr?: InputMaybe<StringFieldComparison>;
  startDate?: InputMaybe<DateFieldComparison>;
  subServiceText?: InputMaybe<StringFieldComparison>;
  updated?: InputMaybe<DateFieldComparison>;
  version?: InputMaybe<NumberFieldComparison>;
};

export type OpencloudProjectFilterServiceUserFilter = {
  and?: InputMaybe<Array<OpencloudProjectFilterServiceUserFilter>>;
  created?: InputMaybe<DateFieldComparison>;
  deleted?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<OpencloudProjectFilterServiceUserFilter>>;
  updated?: InputMaybe<DateFieldComparison>;
  version?: InputMaybe<NumberFieldComparison>;
};

export type OpencloudProjectInput = {
  created: Scalars['DateTime']['input'];
  deleted: Scalars['DateTime']['input'];
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  openstackProjectId?: InputMaybe<Scalars['String']['input']>;
  provisionStatus: ProvisioningStatus;
  setup?: InputMaybe<ProjectSetupInput>;
  updated: Scalars['DateTime']['input'];
  version: Scalars['Float']['input'];
};

export type OpencloudProjectSort = {
  direction: SortDirection;
  field: OpencloudProjectSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum OpencloudProjectSortFields {
  Created = 'created',
  Deleted = 'deleted',
  Id = 'id',
  Name = 'name',
  OpenstackProjectId = 'openstackProjectId',
  ProvisionStatus = 'provisionStatus',
  Updated = 'updated',
  Version = 'version'
}

export type OpencloudProjectUpdateFilter = {
  and?: InputMaybe<Array<OpencloudProjectUpdateFilter>>;
  created?: InputMaybe<DateFieldComparison>;
  deleted?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  openstackProjectId?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<OpencloudProjectUpdateFilter>>;
  provisionStatus?: InputMaybe<ProvisioningStatusFilterComparison>;
  updated?: InputMaybe<DateFieldComparison>;
  version?: InputMaybe<NumberFieldComparison>;
};

export type Organisation = {
  __typename?: 'Organisation';
  city: Scalars['String']['output'];
  company: Company;
  companyRegistrationNumber?: Maybe<Scalars['String']['output']>;
  country: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  customerNumber?: Maybe<Scalars['String']['output']>;
  customerNumbers?: Maybe<Array<Scalars['String']['output']>>;
  dunsId?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  invoiceRecipient: Address;
  invoiceRecipients: Array<Address>;
  isDefaultInvoiceRecipient: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  postal: Scalars['String']['output'];
  serviceInstances: OrganisationServiceInstancesConnection;
  street: Scalars['String']['output'];
  taxId?: Maybe<Scalars['String']['output']>;
  updated: Scalars['DateTime']['output'];
  version: Scalars['Float']['output'];
};

export type OrganisationInvoiceRecipientsArgs = {
  filter?: AddressFilter;
  sorting?: Array<AddressSort>;
};

export type OrganisationServiceInstancesArgs = {
  filter?: ServiceInstanceFilter;
  paging?: OffsetPaging;
  sorting?: Array<ServiceInstanceSort>;
};

export type OrganisationConnection = {
  __typename?: 'OrganisationConnection';
  /** Array of edges. */
  edges: Array<OrganisationEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type OrganisationDeleteResponse = {
  __typename?: 'OrganisationDeleteResponse';
  city?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Company>;
  companyRegistrationNumber?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTime']['output']>;
  customerNumber?: Maybe<Scalars['String']['output']>;
  customerNumbers?: Maybe<Array<Scalars['String']['output']>>;
  dunsId?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isDefaultInvoiceRecipient?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  postal?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  taxId?: Maybe<Scalars['String']['output']>;
  updated?: Maybe<Scalars['DateTime']['output']>;
  version?: Maybe<Scalars['Float']['output']>;
};

export type OrganisationEdge = {
  __typename?: 'OrganisationEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the Organisation */
  node: Organisation;
};

export type OrganisationFilter = {
  and?: InputMaybe<Array<OrganisationFilter>>;
  city?: InputMaybe<StringFieldComparison>;
  companyRegistrationNumber?: InputMaybe<StringFieldComparison>;
  country?: InputMaybe<StringFieldComparison>;
  created?: InputMaybe<DateFieldComparison>;
  customerNumber?: InputMaybe<StringFieldComparison>;
  dunsId?: InputMaybe<StringFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isDefaultInvoiceRecipient?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<OrganisationFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
  postal?: InputMaybe<StringFieldComparison>;
  serviceInstances?: InputMaybe<OrganisationFilterServiceInstanceFilter>;
  street?: InputMaybe<StringFieldComparison>;
  taxId?: InputMaybe<StringFieldComparison>;
  updated?: InputMaybe<DateFieldComparison>;
  version?: InputMaybe<NumberFieldComparison>;
};

export type OrganisationFilterServiceInstanceFilter = {
  amount?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<OrganisationFilterServiceInstanceFilter>>;
  articleDescription?: InputMaybe<StringFieldComparison>;
  companyId?: InputMaybe<StringFieldComparison>;
  contractStatus?: InputMaybe<ContractStatusFilterComparison>;
  created?: InputMaybe<DateFieldComparison>;
  customerNumber?: InputMaybe<StringFieldComparison>;
  details?: InputMaybe<StringFieldComparison>;
  endDate?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  location1?: InputMaybe<StringFieldComparison>;
  location2?: InputMaybe<StringFieldComparison>;
  minimumContractPeriod?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<OrganisationFilterServiceInstanceFilter>>;
  provisioningDate?: InputMaybe<DateFieldComparison>;
  provisioningStatus?: InputMaybe<ProvisioningStatusFilterComparison>;
  ptid?: InputMaybe<StringFieldComparison>;
  serviceGroup?: InputMaybe<StringFieldComparison>;
  serviceInstanceAdditionalURL?: InputMaybe<StringFieldComparison>;
  serviceInstanceURL?: InputMaybe<StringFieldComparison>;
  serviceName?: InputMaybe<StringFieldComparison>;
  serviceOwnerIsContactPerson?: InputMaybe<BooleanFieldComparison>;
  serviceOwnerIsRequestContactPerson?: InputMaybe<BooleanFieldComparison>;
  serviceType?: InputMaybe<ServiceTypeFilterComparison>;
  sinr?: InputMaybe<StringFieldComparison>;
  startDate?: InputMaybe<DateFieldComparison>;
  subServiceText?: InputMaybe<StringFieldComparison>;
  updated?: InputMaybe<DateFieldComparison>;
  version?: InputMaybe<NumberFieldComparison>;
};

export type OrganisationInput = {
  city: Scalars['String']['input'];
  company: CompanyInput;
  companyRegistrationNumber?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  created: Scalars['DateTime']['input'];
  customerNumber?: InputMaybe<Scalars['String']['input']>;
  customerNumbers?: InputMaybe<Array<Scalars['String']['input']>>;
  dunsId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  isDefaultInvoiceRecipient: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  postal: Scalars['String']['input'];
  street: Scalars['String']['input'];
  taxId?: InputMaybe<Scalars['String']['input']>;
  updated: Scalars['DateTime']['input'];
  version: Scalars['Float']['input'];
};

export type OrganisationServiceInstancesConnection = {
  __typename?: 'OrganisationServiceInstancesConnection';
  /** Array of nodes. */
  nodes: Array<ServiceInstance>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
};

export type OrganisationSort = {
  direction: SortDirection;
  field: OrganisationSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum OrganisationSortFields {
  City = 'city',
  CompanyRegistrationNumber = 'companyRegistrationNumber',
  Country = 'country',
  Created = 'created',
  CustomerNumber = 'customerNumber',
  DunsId = 'dunsId',
  Email = 'email',
  Id = 'id',
  IsDefaultInvoiceRecipient = 'isDefaultInvoiceRecipient',
  Name = 'name',
  Phone = 'phone',
  Postal = 'postal',
  Street = 'street',
  TaxId = 'taxId',
  Updated = 'updated',
  Version = 'version'
}

export type OrganisationSubscriptionFilter = {
  and?: InputMaybe<Array<OrganisationSubscriptionFilter>>;
  city?: InputMaybe<StringFieldComparison>;
  companyRegistrationNumber?: InputMaybe<StringFieldComparison>;
  country?: InputMaybe<StringFieldComparison>;
  created?: InputMaybe<DateFieldComparison>;
  customerNumber?: InputMaybe<StringFieldComparison>;
  dunsId?: InputMaybe<StringFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isDefaultInvoiceRecipient?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<OrganisationSubscriptionFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
  postal?: InputMaybe<StringFieldComparison>;
  street?: InputMaybe<StringFieldComparison>;
  taxId?: InputMaybe<StringFieldComparison>;
  updated?: InputMaybe<DateFieldComparison>;
  version?: InputMaybe<NumberFieldComparison>;
};

export type OrganisationUpdateFilter = {
  and?: InputMaybe<Array<OrganisationUpdateFilter>>;
  city?: InputMaybe<StringFieldComparison>;
  companyRegistrationNumber?: InputMaybe<StringFieldComparison>;
  country?: InputMaybe<StringFieldComparison>;
  created?: InputMaybe<DateFieldComparison>;
  customerNumber?: InputMaybe<StringFieldComparison>;
  dunsId?: InputMaybe<StringFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isDefaultInvoiceRecipient?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<OrganisationUpdateFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
  postal?: InputMaybe<StringFieldComparison>;
  street?: InputMaybe<StringFieldComparison>;
  taxId?: InputMaybe<StringFieldComparison>;
  updated?: InputMaybe<DateFieldComparison>;
  version?: InputMaybe<NumberFieldComparison>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  /** The cursor of the last returned record. */
  endCursor?: Maybe<Scalars['ConnectionCursor']['output']>;
  /** true if paging forward and there are more records. */
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  /** true if paging backwards and there are more records. */
  hasPreviousPage?: Maybe<Scalars['Boolean']['output']>;
  /** The cursor of the first returned record. */
  startCursor?: Maybe<Scalars['ConnectionCursor']['output']>;
};

export type Person = {
  __typename?: 'Person';
  companies: Array<Company>;
  companyName?: Maybe<Scalars['String']['output']>;
  contactPersonOf?: Maybe<Array<ContactPerson>>;
  created?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Gender>;
  id: Scalars['ID']['output'];
  isKeycloakVerified?: Maybe<Scalars['Boolean']['output']>;
  isRoot?: Maybe<Scalars['Boolean']['output']>;
  keycloakRoles?: Maybe<Array<Scalars['String']['output']>>;
  keycloakUser?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  mobilePhone?: Maybe<Scalars['String']['output']>;
  newsletter?: Maybe<Scalars['Boolean']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  serviceGroups?: Maybe<Array<ServiceGroup>>;
  serviceOwnerOf?: Maybe<Array<ServiceInstance>>;
  serviceUserGroups?: Maybe<ServiceUserGroup>;
  serviceUserOf?: Maybe<Array<ServiceInstance>>;
  technicalAccountNotifications?: Maybe<Scalars['Boolean']['output']>;
  technicalUpdateNotifications?: Maybe<Scalars['Boolean']['output']>;
  updated?: Maybe<Scalars['DateTime']['output']>;
  version?: Maybe<Scalars['Float']['output']>;
};

export type PersonCompaniesArgs = {
  filter?: CompanyFilter;
  sorting?: Array<CompanySort>;
};

export type PersonContactPersonOfArgs = {
  filter?: ContactPersonFilter;
  sorting?: Array<ContactPersonSort>;
};

export type PersonServiceGroupsArgs = {
  filter?: ServiceGroupFilter;
  sorting?: Array<ServiceGroupSort>;
};

export type PersonServiceOwnerOfArgs = {
  filter?: ServiceInstanceFilter;
  sorting?: Array<ServiceInstanceSort>;
};

export type PersonServiceUserOfArgs = {
  filter?: ServiceInstanceFilter;
  sorting?: Array<ServiceInstanceSort>;
};

export type PersonConnection = {
  __typename?: 'PersonConnection';
  /** Array of edges. */
  edges: Array<PersonEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type PersonDeleteResponse = {
  __typename?: 'PersonDeleteResponse';
  companyName?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Gender>;
  id?: Maybe<Scalars['ID']['output']>;
  isKeycloakVerified?: Maybe<Scalars['Boolean']['output']>;
  isRoot?: Maybe<Scalars['Boolean']['output']>;
  keycloakRoles?: Maybe<Array<Scalars['String']['output']>>;
  keycloakUser?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  mobilePhone?: Maybe<Scalars['String']['output']>;
  newsletter?: Maybe<Scalars['Boolean']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  serviceUserGroups?: Maybe<ServiceUserGroup>;
  technicalAccountNotifications?: Maybe<Scalars['Boolean']['output']>;
  technicalUpdateNotifications?: Maybe<Scalars['Boolean']['output']>;
  updated?: Maybe<Scalars['DateTime']['output']>;
  version?: Maybe<Scalars['Float']['output']>;
};

export type PersonEdge = {
  __typename?: 'PersonEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the Person */
  node: Person;
};

export type PersonFilter = {
  and?: InputMaybe<Array<PersonFilter>>;
  companies?: InputMaybe<PersonFilterCompanyFilter>;
  companyName?: InputMaybe<StringFieldComparison>;
  created?: InputMaybe<DateFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  firstName?: InputMaybe<StringFieldComparison>;
  gender?: InputMaybe<GenderFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isKeycloakVerified?: InputMaybe<BooleanFieldComparison>;
  isRoot?: InputMaybe<BooleanFieldComparison>;
  keycloakUser?: InputMaybe<StringFieldComparison>;
  lastName?: InputMaybe<StringFieldComparison>;
  mobilePhone?: InputMaybe<StringFieldComparison>;
  newsletter?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<PersonFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
  serviceGroups?: InputMaybe<PersonFilterServiceGroupFilter>;
  technicalAccountNotifications?: InputMaybe<BooleanFieldComparison>;
  technicalUpdateNotifications?: InputMaybe<BooleanFieldComparison>;
  updated?: InputMaybe<DateFieldComparison>;
  version?: InputMaybe<NumberFieldComparison>;
};

export type PersonFilterCompanyFilter = {
  and?: InputMaybe<Array<PersonFilterCompanyFilter>>;
  created?: InputMaybe<DateFieldComparison>;
  features?: InputMaybe<PersonFilterCompanyFilterCompanyFeaturesFilter>;
  id?: InputMaybe<IdFilterComparison>;
  isOtpRequired?: InputMaybe<BooleanFieldComparison>;
  keycloakId?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<PersonFilterCompanyFilter>>;
  updated?: InputMaybe<DateFieldComparison>;
  version?: InputMaybe<NumberFieldComparison>;
};

export type PersonFilterCompanyFilterCompanyFeaturesFilter = {
  and?: InputMaybe<Array<PersonFilterCompanyFilterCompanyFeaturesFilter>>;
  isDcamEnabled?: InputMaybe<BooleanFieldComparison>;
  isInvoiceEnabled?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<PersonFilterCompanyFilterCompanyFeaturesFilter>>;
};

export type PersonFilterServiceGroupFilter = {
  and?: InputMaybe<Array<PersonFilterServiceGroupFilter>>;
  created?: InputMaybe<DateFieldComparison>;
  deleted?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<PersonFilterServiceGroupFilter>>;
  updated?: InputMaybe<DateFieldComparison>;
  version?: InputMaybe<NumberFieldComparison>;
};

export type PersonInput = {
  companyName?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  id: Scalars['ID']['input'];
  isKeycloakVerified?: InputMaybe<Scalars['Boolean']['input']>;
  isRoot?: InputMaybe<Scalars['Boolean']['input']>;
  keycloakRoles?: InputMaybe<Array<Scalars['String']['input']>>;
  keycloakUser?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mobilePhone?: InputMaybe<Scalars['String']['input']>;
  newsletter?: InputMaybe<Scalars['Boolean']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  serviceUserGroups?: InputMaybe<ServiceUserGroupInput>;
  technicalAccountNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  technicalUpdateNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  updated?: InputMaybe<Scalars['DateTime']['input']>;
  version?: InputMaybe<Scalars['Float']['input']>;
};

export type PersonSort = {
  direction: SortDirection;
  field: PersonSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum PersonSortFields {
  CompanyName = 'companyName',
  Created = 'created',
  Email = 'email',
  FirstName = 'firstName',
  Gender = 'gender',
  Id = 'id',
  IsKeycloakVerified = 'isKeycloakVerified',
  IsRoot = 'isRoot',
  KeycloakUser = 'keycloakUser',
  LastName = 'lastName',
  MobilePhone = 'mobilePhone',
  Newsletter = 'newsletter',
  Phone = 'phone',
  TechnicalAccountNotifications = 'technicalAccountNotifications',
  TechnicalUpdateNotifications = 'technicalUpdateNotifications',
  Updated = 'updated',
  Version = 'version'
}

export type PersonSubscriptionFilter = {
  and?: InputMaybe<Array<PersonSubscriptionFilter>>;
  companyName?: InputMaybe<StringFieldComparison>;
  created?: InputMaybe<DateFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  firstName?: InputMaybe<StringFieldComparison>;
  gender?: InputMaybe<GenderFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isKeycloakVerified?: InputMaybe<BooleanFieldComparison>;
  isRoot?: InputMaybe<BooleanFieldComparison>;
  keycloakUser?: InputMaybe<StringFieldComparison>;
  lastName?: InputMaybe<StringFieldComparison>;
  mobilePhone?: InputMaybe<StringFieldComparison>;
  newsletter?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<PersonSubscriptionFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
  technicalAccountNotifications?: InputMaybe<BooleanFieldComparison>;
  technicalUpdateNotifications?: InputMaybe<BooleanFieldComparison>;
  updated?: InputMaybe<DateFieldComparison>;
  version?: InputMaybe<NumberFieldComparison>;
};

export type PersonUpdateFilter = {
  and?: InputMaybe<Array<PersonUpdateFilter>>;
  companyName?: InputMaybe<StringFieldComparison>;
  created?: InputMaybe<DateFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  firstName?: InputMaybe<StringFieldComparison>;
  gender?: InputMaybe<GenderFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isKeycloakVerified?: InputMaybe<BooleanFieldComparison>;
  isRoot?: InputMaybe<BooleanFieldComparison>;
  keycloakUser?: InputMaybe<StringFieldComparison>;
  lastName?: InputMaybe<StringFieldComparison>;
  mobilePhone?: InputMaybe<StringFieldComparison>;
  newsletter?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<PersonUpdateFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
  technicalAccountNotifications?: InputMaybe<BooleanFieldComparison>;
  technicalUpdateNotifications?: InputMaybe<BooleanFieldComparison>;
  updated?: InputMaybe<DateFieldComparison>;
  version?: InputMaybe<NumberFieldComparison>;
};

export type PolyServiceAccount = {
  __typename?: 'PolyServiceAccount';
  credentials?: Maybe<Array<Scalars['String']['output']>>;
  groups?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['ID']['output'];
  service?: Maybe<ServiceType>;
};

export type PolyServiceAccountFilter = {
  and?: InputMaybe<Array<PolyServiceAccountFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<PolyServiceAccountFilter>>;
};

export type PolyServiceAccountSort = {
  direction: SortDirection;
  field: PolyServiceAccountSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum PolyServiceAccountSortFields {
  Id = 'id'
}

export type ProjectSetup = {
  __typename?: 'ProjectSetup';
  privateSubnet: Scalars['String']['output'];
  sshKeyName: Scalars['String']['output'];
};

export type ProjectSetupInput = {
  privateSubnet: Scalars['String']['input'];
  sshKeyName: Scalars['String']['input'];
};

/** Provisioning Status of a contract */
export enum ProvisioningStatus {
  Provisioning = 'provisioning',
  Ready = 'ready'
}

export type ProvisioningStatusFilterComparison = {
  eq?: InputMaybe<ProvisioningStatus>;
  gt?: InputMaybe<ProvisioningStatus>;
  gte?: InputMaybe<ProvisioningStatus>;
  iLike?: InputMaybe<ProvisioningStatus>;
  in?: InputMaybe<Array<ProvisioningStatus>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<ProvisioningStatus>;
  lt?: InputMaybe<ProvisioningStatus>;
  lte?: InputMaybe<ProvisioningStatus>;
  neq?: InputMaybe<ProvisioningStatus>;
  notILike?: InputMaybe<ProvisioningStatus>;
  notIn?: InputMaybe<Array<ProvisioningStatus>>;
  notLike?: InputMaybe<ProvisioningStatus>;
};

export type Query = {
  __typename?: 'Query';
  additionalServiceOptions: RackifyVariantAdditionals;
  address: Address;
  addresses: AddressConnection;
  billingAddress: BillingAddress;
  billingAddresses: BillingAddressConnection;
  companies: CompanyConnection;
  company: Company;
  contactPeople: ContactPersonConnection;
  contactPerson: ContactPerson;
  dcam: Dcam;
  dcams: Array<Dcam>;
  ipWhitelist: IpWhitelist;
  ipWhitelists: IpWhitelistConnection;
  opencloudProject: OpencloudProject;
  opencloudProjects: OpencloudProjectConnection;
  organisation: Organisation;
  organisations: OrganisationConnection;
  people: PersonConnection;
  person: Person;
  rackifyConfiguration: RackifyConfiguration;
  rackifyConfigurations: RackifyConfigurationConnection;
  rackifyConfiguredService: RackifyConfiguredService;
  rackifyConfiguredServices: RackifyConfiguredServiceConnection;
  s3ServiceAccount: S3ServiceAccount;
  s3ServiceAccounts: S3ServiceAccountConnection;
  serviceInstance: ServiceInstance;
  serviceInstances: ServiceInstanceConnection;
  serviceOptions: RackifyVariantOptions;
  serviceUser: ServiceUser;
  serviceUsers: ServiceUserConnection;
};

export type QueryAddressArgs = {
  id: Scalars['ID']['input'];
};

export type QueryAddressesArgs = {
  filter?: AddressFilter;
  paging?: CursorPaging;
  sorting?: Array<AddressSort>;
};

export type QueryBillingAddressArgs = {
  id: Scalars['ID']['input'];
};

export type QueryBillingAddressesArgs = {
  filter?: BillingAddressFilter;
  paging?: CursorPaging;
  sorting?: Array<BillingAddressSort>;
};

export type QueryCompaniesArgs = {
  filter?: CompanyFilter;
  paging?: CursorPaging;
  sorting?: Array<CompanySort>;
};

export type QueryCompanyArgs = {
  id: Scalars['ID']['input'];
};

export type QueryContactPeopleArgs = {
  filter?: ContactPersonFilter;
  paging?: CursorPaging;
  sorting?: Array<ContactPersonSort>;
};

export type QueryContactPersonArgs = {
  id: Scalars['ID']['input'];
};

export type QueryDcamArgs = {
  id: Scalars['ID']['input'];
};

export type QueryIpWhitelistArgs = {
  id: Scalars['ID']['input'];
};

export type QueryIpWhitelistsArgs = {
  filter?: IpWhitelistFilter;
  paging?: CursorPaging;
  sorting?: Array<IpWhitelistSort>;
};

export type QueryOpencloudProjectArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOpencloudProjectsArgs = {
  filter?: OpencloudProjectFilter;
  paging?: OffsetPaging;
  sorting?: Array<OpencloudProjectSort>;
};

export type QueryOrganisationArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOrganisationsArgs = {
  filter?: OrganisationFilter;
  paging?: CursorPaging;
  sorting?: Array<OrganisationSort>;
};

export type QueryPeopleArgs = {
  filter?: PersonFilter;
  paging?: CursorPaging;
  sorting?: Array<PersonSort>;
};

export type QueryPersonArgs = {
  id: Scalars['ID']['input'];
};

export type QueryRackifyConfigurationArgs = {
  id: Scalars['ID']['input'];
};

export type QueryRackifyConfigurationsArgs = {
  filter?: RackifyConfigurationFilter;
  paging?: CursorPaging;
  sorting?: Array<RackifyConfigurationSort>;
};

export type QueryRackifyConfiguredServiceArgs = {
  id: Scalars['ID']['input'];
};

export type QueryRackifyConfiguredServicesArgs = {
  filter?: RackifyConfiguredServiceFilter;
  paging?: CursorPaging;
  sorting?: Array<RackifyConfiguredServiceSort>;
};

export type QueryS3ServiceAccountArgs = {
  id: Scalars['ID']['input'];
};

export type QueryS3ServiceAccountsArgs = {
  filter?: S3ServiceAccountFilter;
  paging?: CursorPaging;
  sorting?: Array<S3ServiceAccountSort>;
};

export type QueryServiceInstanceArgs = {
  id: Scalars['ID']['input'];
};

export type QueryServiceInstancesArgs = {
  filter?: ServiceInstanceFilter;
  paging?: CursorPaging;
  sorting?: Array<ServiceInstanceSort>;
};

export type QueryServiceUserArgs = {
  id: Scalars['ID']['input'];
};

export type QueryServiceUsersArgs = {
  filter?: ServiceUserFilter;
  paging?: CursorPaging;
  sorting?: Array<ServiceUserSort>;
};

export type RackifyAdditionalService = {
  enabled: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  options: Scalars['JSON']['input'];
  optionsMap?: InputMaybe<Scalars['JSON']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  priceMonthly?: InputMaybe<Scalars['Float']['input']>;
};

export type RackifyCalculatedServiceInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  amountRacks?: InputMaybe<Scalars['Float']['input']>;
  configurationId?: InputMaybe<Scalars['String']['input']>;
  fixedOptions?: InputMaybe<Scalars['JSON']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  options?: InputMaybe<Scalars['JSON']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  priceMonthly?: InputMaybe<Scalars['Float']['input']>;
  priceOnRequest?: InputMaybe<Scalars['Boolean']['input']>;
  variantOption?: InputMaybe<Scalars['String']['input']>;
  variantOptions?: InputMaybe<Scalars['JSON']['input']>;
};

export type RackifyConfiguration = {
  __typename?: 'RackifyConfiguration';
  additionalServices?: Maybe<Scalars['JSON']['output']>;
  additionalServicesMap?: Maybe<Scalars['JSON']['output']>;
  contact?: Maybe<Scalars['JSONObject']['output']>;
  created?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  price?: Maybe<Scalars['Float']['output']>;
  priceMonthly?: Maybe<Scalars['Float']['output']>;
  services: Array<RackifyConfiguredService>;
  updated?: Maybe<Scalars['DateTime']['output']>;
  version?: Maybe<Scalars['Float']['output']>;
};

export type RackifyConfigurationServicesArgs = {
  filter?: RackifyConfiguredServiceFilter;
  sorting?: Array<RackifyConfiguredServiceSort>;
};

export type RackifyConfigurationConnection = {
  __typename?: 'RackifyConfigurationConnection';
  /** Array of edges. */
  edges: Array<RackifyConfigurationEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type RackifyConfigurationDeleteFilter = {
  and?: InputMaybe<Array<RackifyConfigurationDeleteFilter>>;
  created?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<RackifyConfigurationDeleteFilter>>;
  price?: InputMaybe<NumberFieldComparison>;
  priceMonthly?: InputMaybe<NumberFieldComparison>;
  updated?: InputMaybe<DateFieldComparison>;
  version?: InputMaybe<NumberFieldComparison>;
};

export type RackifyConfigurationDeleteResponse = {
  __typename?: 'RackifyConfigurationDeleteResponse';
  additionalServices?: Maybe<Scalars['JSON']['output']>;
  additionalServicesMap?: Maybe<Scalars['JSON']['output']>;
  contact?: Maybe<Scalars['JSONObject']['output']>;
  created?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  priceMonthly?: Maybe<Scalars['Float']['output']>;
  updated?: Maybe<Scalars['DateTime']['output']>;
  version?: Maybe<Scalars['Float']['output']>;
};

export type RackifyConfigurationEdge = {
  __typename?: 'RackifyConfigurationEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the RackifyConfiguration */
  node: RackifyConfiguration;
};

export type RackifyConfigurationFilter = {
  and?: InputMaybe<Array<RackifyConfigurationFilter>>;
  created?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<RackifyConfigurationFilter>>;
  price?: InputMaybe<NumberFieldComparison>;
  priceMonthly?: InputMaybe<NumberFieldComparison>;
  updated?: InputMaybe<DateFieldComparison>;
  version?: InputMaybe<NumberFieldComparison>;
};

export type RackifyConfigurationInput = {
  additionalServices?: InputMaybe<Array<RackifyAdditionalService>>;
  contact?: InputMaybe<Scalars['JSONObject']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  priceMonthly?: InputMaybe<Scalars['Float']['input']>;
  services?: InputMaybe<Array<RackifyCalculatedServiceInput>>;
};

export type RackifyConfigurationSort = {
  direction: SortDirection;
  field: RackifyConfigurationSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum RackifyConfigurationSortFields {
  Created = 'created',
  Id = 'id',
  Price = 'price',
  PriceMonthly = 'priceMonthly',
  Updated = 'updated',
  Version = 'version'
}

export type RackifyConfigurationSubscriptionFilter = {
  and?: InputMaybe<Array<RackifyConfigurationSubscriptionFilter>>;
  created?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<RackifyConfigurationSubscriptionFilter>>;
  price?: InputMaybe<NumberFieldComparison>;
  priceMonthly?: InputMaybe<NumberFieldComparison>;
  updated?: InputMaybe<DateFieldComparison>;
  version?: InputMaybe<NumberFieldComparison>;
};

export type RackifyConfigurationUpdateFilter = {
  and?: InputMaybe<Array<RackifyConfigurationUpdateFilter>>;
  created?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<RackifyConfigurationUpdateFilter>>;
  price?: InputMaybe<NumberFieldComparison>;
  priceMonthly?: InputMaybe<NumberFieldComparison>;
  updated?: InputMaybe<DateFieldComparison>;
  version?: InputMaybe<NumberFieldComparison>;
};

export type RackifyConfiguredService = {
  __typename?: 'RackifyConfiguredService';
  amount?: Maybe<Scalars['Float']['output']>;
  amountRacks?: Maybe<Scalars['Float']['output']>;
  configuration: RackifyConfiguration;
  configurationId: Scalars['String']['output'];
  created?: Maybe<Scalars['DateTime']['output']>;
  fixedOptions?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  options?: Maybe<Scalars['JSON']['output']>;
  optionsMap?: Maybe<Scalars['JSON']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  priceMonthly?: Maybe<Scalars['Float']['output']>;
  priceOnRequest?: Maybe<Scalars['Boolean']['output']>;
  updated?: Maybe<Scalars['DateTime']['output']>;
  variantOption?: Maybe<Scalars['String']['output']>;
  variantOptions?: Maybe<Scalars['JSON']['output']>;
  version?: Maybe<Scalars['Float']['output']>;
};

export type RackifyConfiguredServiceConnection = {
  __typename?: 'RackifyConfiguredServiceConnection';
  /** Array of edges. */
  edges: Array<RackifyConfiguredServiceEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type RackifyConfiguredServiceDeleteFilter = {
  amount?: InputMaybe<NumberFieldComparison>;
  amountRacks?: InputMaybe<NumberFieldComparison>;
  and?: InputMaybe<Array<RackifyConfiguredServiceDeleteFilter>>;
  configurationId?: InputMaybe<StringFieldComparison>;
  created?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<RackifyConfiguredServiceDeleteFilter>>;
  price?: InputMaybe<NumberFieldComparison>;
  priceMonthly?: InputMaybe<NumberFieldComparison>;
  priceOnRequest?: InputMaybe<BooleanFieldComparison>;
  updated?: InputMaybe<DateFieldComparison>;
  variantOption?: InputMaybe<StringFieldComparison>;
  variantOptions?: InputMaybe<RackifyConfiguredServiceDeleteFilterRackifyVariantOptionsDeleteFilter>;
  version?: InputMaybe<NumberFieldComparison>;
};

export type RackifyConfiguredServiceDeleteFilterRackifyVariantOptionsDeleteFilter = {
  and?: InputMaybe<Array<RackifyConfiguredServiceDeleteFilterRackifyVariantOptionsDeleteFilter>>;
  optionsMap?: InputMaybe<JsonFilterComparison>;
  or?: InputMaybe<Array<RackifyConfiguredServiceDeleteFilterRackifyVariantOptionsDeleteFilter>>;
};

export type RackifyConfiguredServiceDeleteResponse = {
  __typename?: 'RackifyConfiguredServiceDeleteResponse';
  amount?: Maybe<Scalars['Float']['output']>;
  amountRacks?: Maybe<Scalars['Float']['output']>;
  configurationId?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTime']['output']>;
  fixedOptions?: Maybe<Scalars['JSON']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  options?: Maybe<Scalars['JSON']['output']>;
  optionsMap?: Maybe<Scalars['JSON']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  priceMonthly?: Maybe<Scalars['Float']['output']>;
  priceOnRequest?: Maybe<Scalars['Boolean']['output']>;
  updated?: Maybe<Scalars['DateTime']['output']>;
  variantOption?: Maybe<Scalars['String']['output']>;
  variantOptions?: Maybe<Scalars['JSON']['output']>;
  version?: Maybe<Scalars['Float']['output']>;
};

export type RackifyConfiguredServiceEdge = {
  __typename?: 'RackifyConfiguredServiceEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the RackifyConfiguredService */
  node: RackifyConfiguredService;
};

export type RackifyConfiguredServiceFilter = {
  amount?: InputMaybe<NumberFieldComparison>;
  amountRacks?: InputMaybe<NumberFieldComparison>;
  and?: InputMaybe<Array<RackifyConfiguredServiceFilter>>;
  configurationId?: InputMaybe<StringFieldComparison>;
  created?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<RackifyConfiguredServiceFilter>>;
  price?: InputMaybe<NumberFieldComparison>;
  priceMonthly?: InputMaybe<NumberFieldComparison>;
  priceOnRequest?: InputMaybe<BooleanFieldComparison>;
  updated?: InputMaybe<DateFieldComparison>;
  variantOption?: InputMaybe<StringFieldComparison>;
  variantOptions?: InputMaybe<RackifyConfiguredServiceFilterRackifyVariantOptionsFilter>;
  version?: InputMaybe<NumberFieldComparison>;
};

export type RackifyConfiguredServiceFilterRackifyVariantOptionsFilter = {
  and?: InputMaybe<Array<RackifyConfiguredServiceFilterRackifyVariantOptionsFilter>>;
  optionsMap?: InputMaybe<JsonFilterComparison>;
  or?: InputMaybe<Array<RackifyConfiguredServiceFilterRackifyVariantOptionsFilter>>;
};

export type RackifyConfiguredServiceSort = {
  direction: SortDirection;
  field: RackifyConfiguredServiceSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum RackifyConfiguredServiceSortFields {
  Amount = 'amount',
  AmountRacks = 'amountRacks',
  ConfigurationId = 'configurationId',
  Created = 'created',
  Id = 'id',
  Price = 'price',
  PriceMonthly = 'priceMonthly',
  PriceOnRequest = 'priceOnRequest',
  Updated = 'updated',
  VariantOption = 'variantOption',
  VariantOptions = 'variantOptions',
  Version = 'version'
}

export type RackifyConfiguredServiceSubscriptionFilter = {
  amount?: InputMaybe<NumberFieldComparison>;
  amountRacks?: InputMaybe<NumberFieldComparison>;
  and?: InputMaybe<Array<RackifyConfiguredServiceSubscriptionFilter>>;
  configurationId?: InputMaybe<StringFieldComparison>;
  created?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<RackifyConfiguredServiceSubscriptionFilter>>;
  price?: InputMaybe<NumberFieldComparison>;
  priceMonthly?: InputMaybe<NumberFieldComparison>;
  priceOnRequest?: InputMaybe<BooleanFieldComparison>;
  updated?: InputMaybe<DateFieldComparison>;
  variantOption?: InputMaybe<StringFieldComparison>;
  variantOptions?: InputMaybe<RackifyConfiguredServiceSubscriptionFilterRackifyVariantOptionsSubscriptionFilter>;
  version?: InputMaybe<NumberFieldComparison>;
};

export type RackifyConfiguredServiceSubscriptionFilterRackifyVariantOptionsSubscriptionFilter = {
  and?: InputMaybe<Array<RackifyConfiguredServiceSubscriptionFilterRackifyVariantOptionsSubscriptionFilter>>;
  optionsMap?: InputMaybe<JsonFilterComparison>;
  or?: InputMaybe<Array<RackifyConfiguredServiceSubscriptionFilterRackifyVariantOptionsSubscriptionFilter>>;
};

export type RackifyConfiguredServiceUpdateFilter = {
  amount?: InputMaybe<NumberFieldComparison>;
  amountRacks?: InputMaybe<NumberFieldComparison>;
  and?: InputMaybe<Array<RackifyConfiguredServiceUpdateFilter>>;
  configurationId?: InputMaybe<StringFieldComparison>;
  created?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<RackifyConfiguredServiceUpdateFilter>>;
  price?: InputMaybe<NumberFieldComparison>;
  priceMonthly?: InputMaybe<NumberFieldComparison>;
  priceOnRequest?: InputMaybe<BooleanFieldComparison>;
  updated?: InputMaybe<DateFieldComparison>;
  variantOption?: InputMaybe<StringFieldComparison>;
  variantOptions?: InputMaybe<RackifyConfiguredServiceUpdateFilterRackifyVariantOptionsUpdateFilter>;
  version?: InputMaybe<NumberFieldComparison>;
};

export type RackifyConfiguredServiceUpdateFilterRackifyVariantOptionsUpdateFilter = {
  and?: InputMaybe<Array<RackifyConfiguredServiceUpdateFilterRackifyVariantOptionsUpdateFilter>>;
  optionsMap?: InputMaybe<JsonFilterComparison>;
  or?: InputMaybe<Array<RackifyConfiguredServiceUpdateFilterRackifyVariantOptionsUpdateFilter>>;
};

export type RackifyVariantAdditional = {
  __typename?: 'RackifyVariantAdditional';
  name: Scalars['String']['output'];
  options: Array<RackifyVariantOption>;
};

export type RackifyVariantAdditionalOptionInput = {
  name: Scalars['String']['input'];
  options: Array<RackifyVariantOptionInput>;
};

export type RackifyVariantAdditionals = {
  __typename?: 'RackifyVariantAdditionals';
  optionsList: Array<RackifyVariantAdditional>;
  optionsMap: Scalars['JSON']['output'];
};

export type RackifyVariantOption = {
  __typename?: 'RackifyVariantOption';
  default: Scalars['String']['output'];
  name: Scalars['String']['output'];
  values: Array<Scalars['String']['output']>;
};

export type RackifyVariantOptionInput = {
  default: Scalars['String']['input'];
  name: Scalars['String']['input'];
  values: Array<Scalars['String']['input']>;
};

export type RackifyVariantOptions = {
  __typename?: 'RackifyVariantOptions';
  optionsList: Array<RackifyVariantOption>;
  optionsMap: Scalars['JSON']['output'];
};

export type RemoveAddressFromBillingAddressInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The id of relation. */
  relationId: Scalars['ID']['input'];
};

export type RemoveBillingAddressFromAddressInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The id of relation. */
  relationId: Scalars['ID']['input'];
};

export type RemoveContactPersonOfFromPersonInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']['input']>;
};

export type RemoveInvoiceRecipientFromOrganisationInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The id of relation. */
  relationId: Scalars['ID']['input'];
};

export type RemoveInvoiceRecipientFromServiceInstanceInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The id of relation. */
  relationId: Scalars['ID']['input'];
};

export type RemoveInvoiceRecipientsFromOrganisationInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']['input']>;
};

export type RemoveOrganisationAltRecipientFromAddressInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The id of relation. */
  relationId: Scalars['ID']['input'];
};

export type RemoveOrganisationFromAddressInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The id of relation. */
  relationId: Scalars['ID']['input'];
};

export type RemovePeopleFromCompanyInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']['input']>;
};

export type RemoveServiceAccountsFromServiceInstanceInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']['input']>;
};

export type RemoveServiceInstanceFromBillingAddressInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The id of relation. */
  relationId: Scalars['ID']['input'];
};

export type RemoveServiceOwnerOfFromPersonInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']['input']>;
};

export type RemoveServiceOwnersFromDcamInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']['input']>;
};

export type RemoveServiceOwnersFromServiceInstanceInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']['input']>;
};

export type RemoveServiceUserOfFromPersonInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']['input']>;
};

export type RemoveServiceUsersFromServiceInstanceInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']['input']>;
};

export type S3ServiceAccount = {
  __typename?: 'S3ServiceAccount';
  credentials?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['ID']['output'];
  identifier?: Maybe<Scalars['String']['output']>;
  service?: Maybe<ServiceType>;
  serviceInstanceId?: Maybe<Scalars['String']['output']>;
};

export type S3ServiceAccountConnection = {
  __typename?: 'S3ServiceAccountConnection';
  /** Array of edges. */
  edges: Array<S3ServiceAccountEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type S3ServiceAccountDeleteFilter = {
  and?: InputMaybe<Array<S3ServiceAccountDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  identifier?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<S3ServiceAccountDeleteFilter>>;
  serviceInstanceId?: InputMaybe<StringFieldComparison>;
};

export type S3ServiceAccountDeleteResponse = {
  __typename?: 'S3ServiceAccountDeleteResponse';
  credentials?: Maybe<Array<Scalars['String']['output']>>;
  id?: Maybe<Scalars['ID']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  service?: Maybe<ServiceType>;
  serviceInstanceId?: Maybe<Scalars['String']['output']>;
};

export type S3ServiceAccountEdge = {
  __typename?: 'S3ServiceAccountEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the S3ServiceAccount */
  node: S3ServiceAccount;
};

export type S3ServiceAccountFilter = {
  and?: InputMaybe<Array<S3ServiceAccountFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  identifier?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<S3ServiceAccountFilter>>;
  serviceInstanceId?: InputMaybe<StringFieldComparison>;
};

export type S3ServiceAccountInput = {
  credentials?: InputMaybe<Array<Scalars['String']['input']>>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  service?: InputMaybe<ServiceType>;
  serviceInstanceId?: InputMaybe<Scalars['String']['input']>;
};

export type S3ServiceAccountSort = {
  direction: SortDirection;
  field: S3ServiceAccountSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum S3ServiceAccountSortFields {
  Id = 'id',
  Identifier = 'identifier',
  ServiceInstanceId = 'serviceInstanceId'
}

export type S3ServiceAccountUpdateFilter = {
  and?: InputMaybe<Array<S3ServiceAccountUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  identifier?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<S3ServiceAccountUpdateFilter>>;
  serviceInstanceId?: InputMaybe<StringFieldComparison>;
};

export type ServiceGroup = {
  __typename?: 'ServiceGroup';
  created: Scalars['DateTime']['output'];
  deleted: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  person: Person;
  serviceInstances: Array<ServiceInstance>;
  updated: Scalars['DateTime']['output'];
  version: Scalars['Float']['output'];
};

export type ServiceGroupServiceInstancesArgs = {
  filter?: ServiceInstanceFilter;
  sorting?: Array<ServiceInstanceSort>;
};

export type ServiceGroupEdge = {
  __typename?: 'ServiceGroupEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the ServiceGroup */
  node: ServiceGroup;
};

export type ServiceGroupFilter = {
  and?: InputMaybe<Array<ServiceGroupFilter>>;
  created?: InputMaybe<DateFieldComparison>;
  deleted?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ServiceGroupFilter>>;
  person?: InputMaybe<ServiceGroupFilterPersonFilter>;
  serviceInstances?: InputMaybe<ServiceGroupFilterServiceInstanceFilter>;
  updated?: InputMaybe<DateFieldComparison>;
  version?: InputMaybe<NumberFieldComparison>;
};

export type ServiceGroupFilterPersonFilter = {
  and?: InputMaybe<Array<ServiceGroupFilterPersonFilter>>;
  companyName?: InputMaybe<StringFieldComparison>;
  created?: InputMaybe<DateFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  firstName?: InputMaybe<StringFieldComparison>;
  gender?: InputMaybe<GenderFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isKeycloakVerified?: InputMaybe<BooleanFieldComparison>;
  isRoot?: InputMaybe<BooleanFieldComparison>;
  keycloakUser?: InputMaybe<StringFieldComparison>;
  lastName?: InputMaybe<StringFieldComparison>;
  mobilePhone?: InputMaybe<StringFieldComparison>;
  newsletter?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<ServiceGroupFilterPersonFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
  technicalAccountNotifications?: InputMaybe<BooleanFieldComparison>;
  technicalUpdateNotifications?: InputMaybe<BooleanFieldComparison>;
  updated?: InputMaybe<DateFieldComparison>;
  version?: InputMaybe<NumberFieldComparison>;
};

export type ServiceGroupFilterServiceInstanceFilter = {
  amount?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<ServiceGroupFilterServiceInstanceFilter>>;
  articleDescription?: InputMaybe<StringFieldComparison>;
  companyId?: InputMaybe<StringFieldComparison>;
  contractStatus?: InputMaybe<ContractStatusFilterComparison>;
  created?: InputMaybe<DateFieldComparison>;
  customerNumber?: InputMaybe<StringFieldComparison>;
  details?: InputMaybe<StringFieldComparison>;
  endDate?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  location1?: InputMaybe<StringFieldComparison>;
  location2?: InputMaybe<StringFieldComparison>;
  minimumContractPeriod?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ServiceGroupFilterServiceInstanceFilter>>;
  provisioningDate?: InputMaybe<DateFieldComparison>;
  provisioningStatus?: InputMaybe<ProvisioningStatusFilterComparison>;
  ptid?: InputMaybe<StringFieldComparison>;
  serviceGroup?: InputMaybe<StringFieldComparison>;
  serviceInstanceAdditionalURL?: InputMaybe<StringFieldComparison>;
  serviceInstanceURL?: InputMaybe<StringFieldComparison>;
  serviceName?: InputMaybe<StringFieldComparison>;
  serviceOwnerIsContactPerson?: InputMaybe<BooleanFieldComparison>;
  serviceOwnerIsRequestContactPerson?: InputMaybe<BooleanFieldComparison>;
  serviceType?: InputMaybe<ServiceTypeFilterComparison>;
  sinr?: InputMaybe<StringFieldComparison>;
  startDate?: InputMaybe<DateFieldComparison>;
  subServiceText?: InputMaybe<StringFieldComparison>;
  updated?: InputMaybe<DateFieldComparison>;
  version?: InputMaybe<NumberFieldComparison>;
};

export type ServiceGroupInput = {
  created: Scalars['DateTime']['input'];
  deleted: Scalars['DateTime']['input'];
  id: Scalars['String']['input'];
  updated: Scalars['DateTime']['input'];
  version: Scalars['Float']['input'];
};

export type ServiceGroupSort = {
  direction: SortDirection;
  field: ServiceGroupSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ServiceGroupSortFields {
  Created = 'created',
  Deleted = 'deleted',
  Id = 'id',
  Updated = 'updated',
  Version = 'version'
}

export type ServiceGroupUpdateFilter = {
  and?: InputMaybe<Array<ServiceGroupUpdateFilter>>;
  created?: InputMaybe<DateFieldComparison>;
  deleted?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ServiceGroupUpdateFilter>>;
  updated?: InputMaybe<DateFieldComparison>;
  version?: InputMaybe<NumberFieldComparison>;
};

export type ServiceInstance = {
  __typename?: 'ServiceInstance';
  amount?: Maybe<Scalars['String']['output']>;
  articleDescription?: Maybe<Scalars['String']['output']>;
  companyId: Scalars['String']['output'];
  contactPeople?: Maybe<Array<ContactPerson>>;
  contractStatus: ContractStatus;
  created: Scalars['DateTime']['output'];
  customerNumber: Scalars['String']['output'];
  details?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  invoiceRecipient?: Maybe<BillingAddress>;
  location1?: Maybe<Scalars['String']['output']>;
  location2?: Maybe<Scalars['String']['output']>;
  minimumContractPeriod?: Maybe<Scalars['String']['output']>;
  organisation: Organisation;
  provisioningDate?: Maybe<Scalars['DateTime']['output']>;
  provisioningStatus: ProvisioningStatus;
  ptid?: Maybe<Scalars['String']['output']>;
  serviceAccounts?: Maybe<Array<PolyServiceAccount>>;
  serviceGroup: Scalars['String']['output'];
  serviceInstanceAdditionalURL?: Maybe<Scalars['String']['output']>;
  serviceInstanceURL?: Maybe<Scalars['String']['output']>;
  serviceName: Scalars['String']['output'];
  serviceOwnerIsContactPerson: Scalars['Boolean']['output'];
  serviceOwnerIsRequestContactPerson: Scalars['Boolean']['output'];
  serviceOwners: Array<Person>;
  serviceType?: Maybe<ServiceType>;
  serviceUsers?: Maybe<Array<Person>>;
  sinr: Scalars['String']['output'];
  startDate: Scalars['DateTime']['output'];
  subServiceText?: Maybe<Scalars['String']['output']>;
  updated: Scalars['DateTime']['output'];
  version: Scalars['Float']['output'];
};

export type ServiceInstanceContactPeopleArgs = {
  filter?: ContactPersonFilter;
  sorting?: Array<ContactPersonSort>;
};

export type ServiceInstanceServiceAccountsArgs = {
  filter?: PolyServiceAccountFilter;
  sorting?: Array<PolyServiceAccountSort>;
};

export type ServiceInstanceServiceOwnersArgs = {
  filter?: PersonFilter;
  sorting?: Array<PersonSort>;
};

export type ServiceInstanceServiceUsersArgs = {
  filter?: PersonFilter;
  sorting?: Array<PersonSort>;
};

export type ServiceInstanceConnection = {
  __typename?: 'ServiceInstanceConnection';
  /** Array of edges. */
  edges: Array<ServiceInstanceEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type ServiceInstanceDeleteFilter = {
  amount?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<ServiceInstanceDeleteFilter>>;
  articleDescription?: InputMaybe<StringFieldComparison>;
  companyId?: InputMaybe<StringFieldComparison>;
  contractStatus?: InputMaybe<ContractStatusFilterComparison>;
  created?: InputMaybe<DateFieldComparison>;
  customerNumber?: InputMaybe<StringFieldComparison>;
  details?: InputMaybe<StringFieldComparison>;
  endDate?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  location1?: InputMaybe<StringFieldComparison>;
  location2?: InputMaybe<StringFieldComparison>;
  minimumContractPeriod?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ServiceInstanceDeleteFilter>>;
  provisioningDate?: InputMaybe<DateFieldComparison>;
  provisioningStatus?: InputMaybe<ProvisioningStatusFilterComparison>;
  ptid?: InputMaybe<StringFieldComparison>;
  serviceGroup?: InputMaybe<StringFieldComparison>;
  serviceInstanceAdditionalURL?: InputMaybe<StringFieldComparison>;
  serviceInstanceURL?: InputMaybe<StringFieldComparison>;
  serviceName?: InputMaybe<StringFieldComparison>;
  serviceOwnerIsContactPerson?: InputMaybe<BooleanFieldComparison>;
  serviceOwnerIsRequestContactPerson?: InputMaybe<BooleanFieldComparison>;
  serviceType?: InputMaybe<ServiceTypeFilterComparison>;
  sinr?: InputMaybe<StringFieldComparison>;
  startDate?: InputMaybe<DateFieldComparison>;
  subServiceText?: InputMaybe<StringFieldComparison>;
  updated?: InputMaybe<DateFieldComparison>;
  version?: InputMaybe<NumberFieldComparison>;
};

export type ServiceInstanceDeleteResponse = {
  __typename?: 'ServiceInstanceDeleteResponse';
  amount?: Maybe<Scalars['String']['output']>;
  articleDescription?: Maybe<Scalars['String']['output']>;
  companyId?: Maybe<Scalars['String']['output']>;
  contractStatus?: Maybe<ContractStatus>;
  created?: Maybe<Scalars['DateTime']['output']>;
  customerNumber?: Maybe<Scalars['String']['output']>;
  details?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  location1?: Maybe<Scalars['String']['output']>;
  location2?: Maybe<Scalars['String']['output']>;
  minimumContractPeriod?: Maybe<Scalars['String']['output']>;
  provisioningDate?: Maybe<Scalars['DateTime']['output']>;
  provisioningStatus?: Maybe<ProvisioningStatus>;
  ptid?: Maybe<Scalars['String']['output']>;
  serviceGroup?: Maybe<Scalars['String']['output']>;
  serviceInstanceAdditionalURL?: Maybe<Scalars['String']['output']>;
  serviceInstanceURL?: Maybe<Scalars['String']['output']>;
  serviceName?: Maybe<Scalars['String']['output']>;
  serviceOwnerIsContactPerson?: Maybe<Scalars['Boolean']['output']>;
  serviceOwnerIsRequestContactPerson?: Maybe<Scalars['Boolean']['output']>;
  serviceType?: Maybe<ServiceType>;
  sinr?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  subServiceText?: Maybe<Scalars['String']['output']>;
  updated?: Maybe<Scalars['DateTime']['output']>;
  version?: Maybe<Scalars['Float']['output']>;
};

export type ServiceInstanceEdge = {
  __typename?: 'ServiceInstanceEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the ServiceInstance */
  node: ServiceInstance;
};

export type ServiceInstanceFilter = {
  amount?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<ServiceInstanceFilter>>;
  articleDescription?: InputMaybe<StringFieldComparison>;
  companyId?: InputMaybe<StringFieldComparison>;
  contractStatus?: InputMaybe<ContractStatusFilterComparison>;
  created?: InputMaybe<DateFieldComparison>;
  customerNumber?: InputMaybe<StringFieldComparison>;
  details?: InputMaybe<StringFieldComparison>;
  endDate?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  location1?: InputMaybe<StringFieldComparison>;
  location2?: InputMaybe<StringFieldComparison>;
  minimumContractPeriod?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ServiceInstanceFilter>>;
  organisation?: InputMaybe<ServiceInstanceFilterOrganisationFilter>;
  provisioningDate?: InputMaybe<DateFieldComparison>;
  provisioningStatus?: InputMaybe<ProvisioningStatusFilterComparison>;
  ptid?: InputMaybe<StringFieldComparison>;
  serviceGroup?: InputMaybe<StringFieldComparison>;
  serviceInstanceAdditionalURL?: InputMaybe<StringFieldComparison>;
  serviceInstanceURL?: InputMaybe<StringFieldComparison>;
  serviceName?: InputMaybe<StringFieldComparison>;
  serviceOwnerIsContactPerson?: InputMaybe<BooleanFieldComparison>;
  serviceOwnerIsRequestContactPerson?: InputMaybe<BooleanFieldComparison>;
  serviceType?: InputMaybe<ServiceTypeFilterComparison>;
  sinr?: InputMaybe<StringFieldComparison>;
  startDate?: InputMaybe<DateFieldComparison>;
  subServiceText?: InputMaybe<StringFieldComparison>;
  updated?: InputMaybe<DateFieldComparison>;
  version?: InputMaybe<NumberFieldComparison>;
};

export type ServiceInstanceFilterOrganisationFilter = {
  and?: InputMaybe<Array<ServiceInstanceFilterOrganisationFilter>>;
  city?: InputMaybe<StringFieldComparison>;
  companyRegistrationNumber?: InputMaybe<StringFieldComparison>;
  country?: InputMaybe<StringFieldComparison>;
  created?: InputMaybe<DateFieldComparison>;
  customerNumber?: InputMaybe<StringFieldComparison>;
  dunsId?: InputMaybe<StringFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isDefaultInvoiceRecipient?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ServiceInstanceFilterOrganisationFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
  postal?: InputMaybe<StringFieldComparison>;
  street?: InputMaybe<StringFieldComparison>;
  taxId?: InputMaybe<StringFieldComparison>;
  updated?: InputMaybe<DateFieldComparison>;
  version?: InputMaybe<NumberFieldComparison>;
};

export type ServiceInstanceInput = {
  amount?: InputMaybe<Scalars['String']['input']>;
  articleDescription?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['String']['input'];
  contractStatus: ContractStatus;
  created: Scalars['DateTime']['input'];
  customerNumber: Scalars['String']['input'];
  details?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['String']['input'];
  location1?: InputMaybe<Scalars['String']['input']>;
  location2?: InputMaybe<Scalars['String']['input']>;
  minimumContractPeriod?: InputMaybe<Scalars['String']['input']>;
  provisioningDate?: InputMaybe<Scalars['DateTime']['input']>;
  provisioningStatus: ProvisioningStatus;
  ptid?: InputMaybe<Scalars['String']['input']>;
  serviceGroup: Scalars['String']['input'];
  serviceInstanceAdditionalURL?: InputMaybe<Scalars['String']['input']>;
  serviceInstanceURL?: InputMaybe<Scalars['String']['input']>;
  serviceName: Scalars['String']['input'];
  serviceOwnerIsContactPerson: Scalars['Boolean']['input'];
  serviceOwnerIsRequestContactPerson: Scalars['Boolean']['input'];
  serviceType?: InputMaybe<ServiceType>;
  sinr: Scalars['String']['input'];
  startDate: Scalars['DateTime']['input'];
  subServiceText?: InputMaybe<Scalars['String']['input']>;
  updated: Scalars['DateTime']['input'];
  version: Scalars['Float']['input'];
};

export type ServiceInstanceSort = {
  direction: SortDirection;
  field: ServiceInstanceSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ServiceInstanceSortFields {
  Amount = 'amount',
  ArticleDescription = 'articleDescription',
  CompanyId = 'companyId',
  ContractStatus = 'contractStatus',
  Created = 'created',
  CustomerNumber = 'customerNumber',
  Details = 'details',
  EndDate = 'endDate',
  Id = 'id',
  Location1 = 'location1',
  Location2 = 'location2',
  MinimumContractPeriod = 'minimumContractPeriod',
  ProvisioningDate = 'provisioningDate',
  ProvisioningStatus = 'provisioningStatus',
  Ptid = 'ptid',
  ServiceGroup = 'serviceGroup',
  ServiceInstanceAdditionalUrl = 'serviceInstanceAdditionalURL',
  ServiceInstanceUrl = 'serviceInstanceURL',
  ServiceName = 'serviceName',
  ServiceOwnerIsContactPerson = 'serviceOwnerIsContactPerson',
  ServiceOwnerIsRequestContactPerson = 'serviceOwnerIsRequestContactPerson',
  ServiceType = 'serviceType',
  Sinr = 'sinr',
  StartDate = 'startDate',
  SubServiceText = 'subServiceText',
  Updated = 'updated',
  Version = 'version'
}

export type ServiceInstanceSubscriptionFilter = {
  amount?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<ServiceInstanceSubscriptionFilter>>;
  articleDescription?: InputMaybe<StringFieldComparison>;
  companyId?: InputMaybe<StringFieldComparison>;
  contractStatus?: InputMaybe<ContractStatusFilterComparison>;
  created?: InputMaybe<DateFieldComparison>;
  customerNumber?: InputMaybe<StringFieldComparison>;
  details?: InputMaybe<StringFieldComparison>;
  endDate?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  location1?: InputMaybe<StringFieldComparison>;
  location2?: InputMaybe<StringFieldComparison>;
  minimumContractPeriod?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ServiceInstanceSubscriptionFilter>>;
  provisioningDate?: InputMaybe<DateFieldComparison>;
  provisioningStatus?: InputMaybe<ProvisioningStatusFilterComparison>;
  ptid?: InputMaybe<StringFieldComparison>;
  serviceGroup?: InputMaybe<StringFieldComparison>;
  serviceInstanceAdditionalURL?: InputMaybe<StringFieldComparison>;
  serviceInstanceURL?: InputMaybe<StringFieldComparison>;
  serviceName?: InputMaybe<StringFieldComparison>;
  serviceOwnerIsContactPerson?: InputMaybe<BooleanFieldComparison>;
  serviceOwnerIsRequestContactPerson?: InputMaybe<BooleanFieldComparison>;
  serviceType?: InputMaybe<ServiceTypeFilterComparison>;
  sinr?: InputMaybe<StringFieldComparison>;
  startDate?: InputMaybe<DateFieldComparison>;
  subServiceText?: InputMaybe<StringFieldComparison>;
  updated?: InputMaybe<DateFieldComparison>;
  version?: InputMaybe<NumberFieldComparison>;
};

export type ServiceInstanceUpdateFilter = {
  amount?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<ServiceInstanceUpdateFilter>>;
  articleDescription?: InputMaybe<StringFieldComparison>;
  companyId?: InputMaybe<StringFieldComparison>;
  contractStatus?: InputMaybe<ContractStatusFilterComparison>;
  created?: InputMaybe<DateFieldComparison>;
  customerNumber?: InputMaybe<StringFieldComparison>;
  details?: InputMaybe<StringFieldComparison>;
  endDate?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  location1?: InputMaybe<StringFieldComparison>;
  location2?: InputMaybe<StringFieldComparison>;
  minimumContractPeriod?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ServiceInstanceUpdateFilter>>;
  provisioningDate?: InputMaybe<DateFieldComparison>;
  provisioningStatus?: InputMaybe<ProvisioningStatusFilterComparison>;
  ptid?: InputMaybe<StringFieldComparison>;
  serviceGroup?: InputMaybe<StringFieldComparison>;
  serviceInstanceAdditionalURL?: InputMaybe<StringFieldComparison>;
  serviceInstanceURL?: InputMaybe<StringFieldComparison>;
  serviceName?: InputMaybe<StringFieldComparison>;
  serviceOwnerIsContactPerson?: InputMaybe<BooleanFieldComparison>;
  serviceOwnerIsRequestContactPerson?: InputMaybe<BooleanFieldComparison>;
  serviceType?: InputMaybe<ServiceTypeFilterComparison>;
  sinr?: InputMaybe<StringFieldComparison>;
  startDate?: InputMaybe<DateFieldComparison>;
  subServiceText?: InputMaybe<StringFieldComparison>;
  updated?: InputMaybe<DateFieldComparison>;
  version?: InputMaybe<NumberFieldComparison>;
};

/** ServiceType as in S3 or VRA */
export enum ServiceType {
  Opencloud = 'opencloud',
  S3 = 's3',
  Securemaxx = 'securemaxx',
  Vpc = 'vpc',
  Vra = 'vra'
}

export type ServiceTypeFilterComparison = {
  eq?: InputMaybe<ServiceType>;
  gt?: InputMaybe<ServiceType>;
  gte?: InputMaybe<ServiceType>;
  iLike?: InputMaybe<ServiceType>;
  in?: InputMaybe<Array<ServiceType>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<ServiceType>;
  lt?: InputMaybe<ServiceType>;
  lte?: InputMaybe<ServiceType>;
  neq?: InputMaybe<ServiceType>;
  notILike?: InputMaybe<ServiceType>;
  notIn?: InputMaybe<Array<ServiceType>>;
  notLike?: InputMaybe<ServiceType>;
};

export type ServiceUser = {
  __typename?: 'ServiceUser';
  created: Scalars['DateTime']['output'];
  deleted: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  person: Person;
  project: OpencloudProject;
  role: ServiceUserRole;
  updated: Scalars['DateTime']['output'];
  version: Scalars['Float']['output'];
};

export type ServiceUserConnection = {
  __typename?: 'ServiceUserConnection';
  /** Array of edges. */
  edges: Array<ServiceUserEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type ServiceUserDeleteFilter = {
  and?: InputMaybe<Array<ServiceUserDeleteFilter>>;
  created?: InputMaybe<DateFieldComparison>;
  deleted?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ServiceUserDeleteFilter>>;
  updated?: InputMaybe<DateFieldComparison>;
  version?: InputMaybe<NumberFieldComparison>;
};

export type ServiceUserDeleteResponse = {
  __typename?: 'ServiceUserDeleteResponse';
  created?: Maybe<Scalars['DateTime']['output']>;
  deleted?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  role?: Maybe<ServiceUserRole>;
  updated?: Maybe<Scalars['DateTime']['output']>;
  version?: Maybe<Scalars['Float']['output']>;
};

export type ServiceUserEdge = {
  __typename?: 'ServiceUserEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the ServiceUser */
  node: ServiceUser;
};

export type ServiceUserFilter = {
  and?: InputMaybe<Array<ServiceUserFilter>>;
  created?: InputMaybe<DateFieldComparison>;
  deleted?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ServiceUserFilter>>;
  person?: InputMaybe<ServiceUserFilterPersonFilter>;
  project?: InputMaybe<ServiceUserFilterOpencloudProjectFilter>;
  updated?: InputMaybe<DateFieldComparison>;
  version?: InputMaybe<NumberFieldComparison>;
};

export type ServiceUserFilterOpencloudProjectFilter = {
  and?: InputMaybe<Array<ServiceUserFilterOpencloudProjectFilter>>;
  created?: InputMaybe<DateFieldComparison>;
  deleted?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  openstackProjectId?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ServiceUserFilterOpencloudProjectFilter>>;
  provisionStatus?: InputMaybe<ProvisioningStatusFilterComparison>;
  updated?: InputMaybe<DateFieldComparison>;
  version?: InputMaybe<NumberFieldComparison>;
};

export type ServiceUserFilterPersonFilter = {
  and?: InputMaybe<Array<ServiceUserFilterPersonFilter>>;
  companyName?: InputMaybe<StringFieldComparison>;
  created?: InputMaybe<DateFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  firstName?: InputMaybe<StringFieldComparison>;
  gender?: InputMaybe<GenderFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isKeycloakVerified?: InputMaybe<BooleanFieldComparison>;
  isRoot?: InputMaybe<BooleanFieldComparison>;
  keycloakUser?: InputMaybe<StringFieldComparison>;
  lastName?: InputMaybe<StringFieldComparison>;
  mobilePhone?: InputMaybe<StringFieldComparison>;
  newsletter?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<ServiceUserFilterPersonFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
  technicalAccountNotifications?: InputMaybe<BooleanFieldComparison>;
  technicalUpdateNotifications?: InputMaybe<BooleanFieldComparison>;
  updated?: InputMaybe<DateFieldComparison>;
  version?: InputMaybe<NumberFieldComparison>;
};

export type ServiceUserGroup = {
  __typename?: 'ServiceUserGroup';
  data?: Maybe<Scalars['JSON']['output']>;
};

export type ServiceUserGroupInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
};

export type ServiceUserInput = {
  created: Scalars['DateTime']['input'];
  deleted: Scalars['DateTime']['input'];
  id: Scalars['String']['input'];
  role: ServiceUserRole;
  updated: Scalars['DateTime']['input'];
  version: Scalars['Float']['input'];
};

/** Role of the service user */
export enum ServiceUserRole {
  Admin = 'ADMIN',
  User = 'USER'
}

export type ServiceUserSort = {
  direction: SortDirection;
  field: ServiceUserSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ServiceUserSortFields {
  Created = 'created',
  Deleted = 'deleted',
  Id = 'id',
  Updated = 'updated',
  Version = 'version'
}

export type ServiceUserUpdateFilter = {
  and?: InputMaybe<Array<ServiceUserUpdateFilter>>;
  created?: InputMaybe<DateFieldComparison>;
  deleted?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ServiceUserUpdateFilter>>;
  updated?: InputMaybe<DateFieldComparison>;
  version?: InputMaybe<NumberFieldComparison>;
};

export type SetAddressOnBillingAddressInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The id of relation. */
  relationId: Scalars['ID']['input'];
};

export type SetBillingAddressOnAddressInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The id of relation. */
  relationId: Scalars['ID']['input'];
};

export type SetContactPersonOfOnPersonInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']['input']>;
};

export type SetInvoiceRecipientOnOrganisationInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The id of relation. */
  relationId: Scalars['ID']['input'];
};

export type SetInvoiceRecipientOnServiceInstanceInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The id of relation. */
  relationId: Scalars['ID']['input'];
};

export type SetInvoiceRecipientsOnOrganisationInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']['input']>;
};

export type SetOrganisationAltRecipientOnAddressInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The id of relation. */
  relationId: Scalars['ID']['input'];
};

export type SetOrganisationOnAddressInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The id of relation. */
  relationId: Scalars['ID']['input'];
};

export type SetPeopleOnCompanyInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']['input']>;
};

export type SetServiceAccountsOnServiceInstanceInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']['input']>;
};

export type SetServiceInstanceOnBillingAddressInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The id of relation. */
  relationId: Scalars['ID']['input'];
};

export type SetServiceInstancesOnOrganisationInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']['input']>;
};

export type SetServiceOwnerOfOnPersonInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']['input']>;
};

export type SetServiceOwnersOnDcamInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']['input']>;
};

export type SetServiceOwnersOnServiceInstanceInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']['input']>;
};

export type SetServiceUserOfOnPersonInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']['input']>;
};

export type SetServiceUsersOnServiceInstanceInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']['input']>;
};

/** Sort Directions */
export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Sort Nulls Options */
export enum SortNulls {
  NullsFirst = 'NULLS_FIRST',
  NullsLast = 'NULLS_LAST'
}

export type StringFieldComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  iLike?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notILike?: InputMaybe<Scalars['String']['input']>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  createdAddress: Address;
  createdBillingAddress: BillingAddress;
  createdCompany: Company;
  createdContactPerson: ContactPerson;
  createdIpWhitelist: IpWhitelist;
  createdOrganisation: Organisation;
  createdPerson: Person;
  createdRackifyConfiguration: RackifyConfiguration;
  createdRackifyConfiguredService: RackifyConfiguredService;
  createdServiceInstance: ServiceInstance;
  deletedManyAddresses: DeleteManyResponse;
  deletedManyBillingAddresses: DeleteManyResponse;
  deletedManyCompanies: DeleteManyResponse;
  deletedManyContactPeople: DeleteManyResponse;
  deletedManyIpWhitelists: DeleteManyResponse;
  deletedManyOrganisations: DeleteManyResponse;
  deletedManyPeople: DeleteManyResponse;
  deletedManyRackifyConfigurations: DeleteManyResponse;
  deletedManyRackifyConfiguredServices: DeleteManyResponse;
  deletedManyServiceInstances: DeleteManyResponse;
  deletedOneAddress: AddressDeleteResponse;
  deletedOneBillingAddress: BillingAddressDeleteResponse;
  deletedOneCompany: CompanyDeleteResponse;
  deletedOneContactPerson: ContactPersonDeleteResponse;
  deletedOneIpWhitelist: IpWhitelistDeleteResponse;
  deletedOneOrganisation: OrganisationDeleteResponse;
  deletedOnePerson: PersonDeleteResponse;
  deletedOneRackifyConfiguration: RackifyConfigurationDeleteResponse;
  deletedOneRackifyConfiguredService: RackifyConfiguredServiceDeleteResponse;
  deletedOneServiceInstance: ServiceInstanceDeleteResponse;
  updatedManyAddresses: UpdateManyResponse;
  updatedManyBillingAddresses: UpdateManyResponse;
  updatedManyCompanies: UpdateManyResponse;
  updatedManyContactPeople: UpdateManyResponse;
  updatedManyIpWhitelists: UpdateManyResponse;
  updatedManyOrganisations: UpdateManyResponse;
  updatedManyPeople: UpdateManyResponse;
  updatedManyRackifyConfigurations: UpdateManyResponse;
  updatedManyRackifyConfiguredServices: UpdateManyResponse;
  updatedManyServiceInstances: UpdateManyResponse;
  updatedOneAddress: Address;
  updatedOneBillingAddress: BillingAddress;
  updatedOneCompany: Company;
  updatedOneContactPerson: ContactPerson;
  updatedOneIpWhitelist: IpWhitelist;
  updatedOneOrganisation: Organisation;
  updatedOnePerson: Person;
  updatedOneRackifyConfiguration: RackifyConfiguration;
  updatedOneRackifyConfiguredService: RackifyConfiguredService;
  updatedOneServiceInstance: ServiceInstance;
};

export type SubscriptionCreatedAddressArgs = {
  input?: InputMaybe<CreateAddressSubscriptionFilterInput>;
};

export type SubscriptionCreatedBillingAddressArgs = {
  input?: InputMaybe<CreateBillingAddressSubscriptionFilterInput>;
};

export type SubscriptionCreatedCompanyArgs = {
  input?: InputMaybe<CreateCompanySubscriptionFilterInput>;
};

export type SubscriptionCreatedContactPersonArgs = {
  input?: InputMaybe<CreateContactPersonSubscriptionFilterInput>;
};

export type SubscriptionCreatedIpWhitelistArgs = {
  input?: InputMaybe<CreateIpWhitelistSubscriptionFilterInput>;
};

export type SubscriptionCreatedOrganisationArgs = {
  input?: InputMaybe<CreateOrganisationSubscriptionFilterInput>;
};

export type SubscriptionCreatedPersonArgs = {
  input?: InputMaybe<CreatePersonSubscriptionFilterInput>;
};

export type SubscriptionCreatedRackifyConfigurationArgs = {
  input?: InputMaybe<CreateRackifyConfigurationSubscriptionFilterInput>;
};

export type SubscriptionCreatedRackifyConfiguredServiceArgs = {
  input?: InputMaybe<CreateRackifyConfiguredServiceSubscriptionFilterInput>;
};

export type SubscriptionCreatedServiceInstanceArgs = {
  input?: InputMaybe<CreateServiceInstanceSubscriptionFilterInput>;
};

export type SubscriptionDeletedOneAddressArgs = {
  input?: InputMaybe<DeleteOneAddressSubscriptionFilterInput>;
};

export type SubscriptionDeletedOneBillingAddressArgs = {
  input?: InputMaybe<DeleteOneBillingAddressSubscriptionFilterInput>;
};

export type SubscriptionDeletedOneCompanyArgs = {
  input?: InputMaybe<DeleteOneCompanySubscriptionFilterInput>;
};

export type SubscriptionDeletedOneContactPersonArgs = {
  input?: InputMaybe<DeleteOneContactPersonSubscriptionFilterInput>;
};

export type SubscriptionDeletedOneIpWhitelistArgs = {
  input?: InputMaybe<DeleteOneIpWhitelistSubscriptionFilterInput>;
};

export type SubscriptionDeletedOneOrganisationArgs = {
  input?: InputMaybe<DeleteOneOrganisationSubscriptionFilterInput>;
};

export type SubscriptionDeletedOnePersonArgs = {
  input?: InputMaybe<DeleteOnePersonSubscriptionFilterInput>;
};

export type SubscriptionDeletedOneRackifyConfigurationArgs = {
  input?: InputMaybe<DeleteOneRackifyConfigurationSubscriptionFilterInput>;
};

export type SubscriptionDeletedOneRackifyConfiguredServiceArgs = {
  input?: InputMaybe<DeleteOneRackifyConfiguredServiceSubscriptionFilterInput>;
};

export type SubscriptionDeletedOneServiceInstanceArgs = {
  input?: InputMaybe<DeleteOneServiceInstanceSubscriptionFilterInput>;
};

export type SubscriptionUpdatedOneAddressArgs = {
  input?: InputMaybe<UpdateOneAddressSubscriptionFilterInput>;
};

export type SubscriptionUpdatedOneBillingAddressArgs = {
  input?: InputMaybe<UpdateOneBillingAddressSubscriptionFilterInput>;
};

export type SubscriptionUpdatedOneCompanyArgs = {
  input?: InputMaybe<UpdateOneCompanySubscriptionFilterInput>;
};

export type SubscriptionUpdatedOneContactPersonArgs = {
  input?: InputMaybe<UpdateOneContactPersonSubscriptionFilterInput>;
};

export type SubscriptionUpdatedOneIpWhitelistArgs = {
  input?: InputMaybe<UpdateOneIpWhitelistSubscriptionFilterInput>;
};

export type SubscriptionUpdatedOneOrganisationArgs = {
  input?: InputMaybe<UpdateOneOrganisationSubscriptionFilterInput>;
};

export type SubscriptionUpdatedOnePersonArgs = {
  input?: InputMaybe<UpdateOnePersonSubscriptionFilterInput>;
};

export type SubscriptionUpdatedOneRackifyConfigurationArgs = {
  input?: InputMaybe<UpdateOneRackifyConfigurationSubscriptionFilterInput>;
};

export type SubscriptionUpdatedOneRackifyConfiguredServiceArgs = {
  input?: InputMaybe<UpdateOneRackifyConfiguredServiceSubscriptionFilterInput>;
};

export type SubscriptionUpdatedOneServiceInstanceArgs = {
  input?: InputMaybe<UpdateOneServiceInstanceSubscriptionFilterInput>;
};

export type ToggleCompanyOtpRequirement = {
  __typename?: 'ToggleCompanyOTPRequirement';
  companyId: Scalars['String']['output'];
  isOtpRequired: Scalars['Boolean']['output'];
};

export type UpdateAddressInput = {
  addressAddition?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  companyName?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  organisationAltRecipientId?: InputMaybe<Scalars['String']['input']>;
  organisationId?: InputMaybe<Scalars['String']['input']>;
  organisationalUnit?: InputMaybe<Scalars['String']['input']>;
  postal?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateBillingAddressInput = {
  addressId: Scalars['String']['input'];
  billingReferenceNo1?: InputMaybe<Scalars['String']['input']>;
  billingReferenceNo2?: InputMaybe<Scalars['String']['input']>;
  billingReferenceNo3?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  serviceInstanceId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateContactPersonInput = {
  note?: InputMaybe<Scalars['String']['input']>;
  person?: InputMaybe<UpdatePersonAndIdInput>;
};

export type UpdateDcamAccessInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDcamContactDto = {
  birthday?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
};

export type UpdateDcamInput = {
  id: Scalars['ID']['input'];
  serviceOwners: Array<CreatePersonInput>;
};

export type UpdateIpWhitelist = {
  id?: InputMaybe<Scalars['ID']['input']>;
  ips?: InputMaybe<Array<Scalars['String']['input']>>;
  serviceInstance?: InputMaybe<ServiceInstanceInput>;
};

export type UpdateManyAddressesInput = {
  /** Filter used to find fields to update */
  filter: AddressUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateAddressInput;
};

export type UpdateManyBillingAddressesInput = {
  /** Filter used to find fields to update */
  filter: BillingAddressUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateBillingAddressInput;
};

export type UpdateManyContactPeopleInput = {
  /** Filter used to find fields to update */
  filter: ContactPersonUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateContactPersonInput;
};

export type UpdateManyDcamsInput = {
  /** Filter used to find fields to update */
  filter: DcamUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateDcamInput;
};

export type UpdateManyIpWhitelistsInput = {
  /** Filter used to find fields to update */
  filter: IpWhitelistUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateIpWhitelist;
};

export type UpdateManyOpencloudProjectsInput = {
  /** Filter used to find fields to update */
  filter: OpencloudProjectUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateOpencloudProjectInput;
};

export type UpdateManyOrganisationsInput = {
  /** Filter used to find fields to update */
  filter: OrganisationUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateOrganisationInput;
};

export type UpdateManyPeopleInput = {
  /** Filter used to find fields to update */
  filter: PersonUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdatePersonInput;
};

export type UpdateManyRackifyConfigurationsInput = {
  /** Filter used to find fields to update */
  filter: RackifyConfigurationUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: RackifyConfigurationInput;
};

export type UpdateManyRackifyConfiguredServicesInput = {
  /** Filter used to find fields to update */
  filter: RackifyConfiguredServiceUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: RackifyCalculatedServiceInput;
};

export type UpdateManyResponse = {
  __typename?: 'UpdateManyResponse';
  /** The number of records updated. */
  updatedCount: Scalars['Int']['output'];
};

export type UpdateManyS3ServiceAccountsInput = {
  /** Filter used to find fields to update */
  filter: S3ServiceAccountUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: S3ServiceAccountInput;
};

export type UpdateManyServiceGroupsInput = {
  /** Filter used to find fields to update */
  filter: ServiceGroupUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateServiceGroupInput;
};

export type UpdateManyServiceInstancesInput = {
  /** Filter used to find fields to update */
  filter: ServiceInstanceUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateServiceInstanceInput;
};

export type UpdateManyServiceUsersInput = {
  /** Filter used to find fields to update */
  filter: ServiceUserUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateServiceUser;
};

export type UpdateOneAddressInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: UpdateAddressInput;
};

export type UpdateOneAddressSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: AddressSubscriptionFilter;
};

export type UpdateOneBillingAddressInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: UpdateBillingAddressInput;
};

export type UpdateOneBillingAddressSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: BillingAddressSubscriptionFilter;
};

export type UpdateOneCompanySubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: CompanySubscriptionFilter;
};

export type UpdateOneContactPersonInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: UpdateContactPersonInput;
};

export type UpdateOneContactPersonSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: ContactPersonSubscriptionFilter;
};

export type UpdateOneDcamAccessInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: UpdateDcamAccessInput;
};

export type UpdateOneDcamContactInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: UpdateDcamContactDto;
};

export type UpdateOneDcamInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: UpdateDcamInput;
};

export type UpdateOneIpWhitelistInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: UpdateIpWhitelist;
};

export type UpdateOneIpWhitelistSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: IpWhitelistSubscriptionFilter;
};

export type UpdateOneOpencloudProjectInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: UpdateOpencloudProjectInput;
};

export type UpdateOneOrganisationInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: UpdateOrganisationInput;
};

export type UpdateOneOrganisationSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: OrganisationSubscriptionFilter;
};

export type UpdateOnePersonInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: UpdatePersonInput;
};

export type UpdateOnePersonSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: PersonSubscriptionFilter;
};

export type UpdateOneRackifyConfigurationInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: RackifyConfigurationInput;
};

export type UpdateOneRackifyConfigurationSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: RackifyConfigurationSubscriptionFilter;
};

export type UpdateOneRackifyConfiguredServiceInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: RackifyCalculatedServiceInput;
};

export type UpdateOneRackifyConfiguredServiceSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: RackifyConfiguredServiceSubscriptionFilter;
};

export type UpdateOneS3ServiceAccountInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: S3ServiceAccountInput;
};

export type UpdateOneServiceGroupInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: UpdateServiceGroupInput;
};

export type UpdateOneServiceInstanceInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: UpdateServiceInstanceInput;
};

export type UpdateOneServiceInstanceSubscriptionFilterInput = {
  /** Specify to filter the records returned. */
  filter: ServiceInstanceSubscriptionFilter;
};

export type UpdateOneServiceUserInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: UpdateServiceUser;
};

export type UpdateOpencloudProjectInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  serviceUsers?: InputMaybe<Array<CreateServiceUserInput>>;
  setup?: InputMaybe<ProjectSetupInput>;
};

export type UpdateOrganisationInput = {
  city: Scalars['String']['input'];
  companyRegistrationNumber?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  dunsId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  invoiceRecipient?: InputMaybe<UpsertAddresInput>;
  invoiceRecipients?: InputMaybe<Array<UpsertAddresInput>>;
  isDefaultInvoiceRecipient: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  postal: Scalars['String']['input'];
  street: Scalars['String']['input'];
  taxId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePersonAndIdInput = {
  companyName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  id: Scalars['ID']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  mobilePhone?: InputMaybe<Scalars['String']['input']>;
  newsletter?: InputMaybe<Scalars['Boolean']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  serviceOwnerOf?: InputMaybe<Array<InlineServiceInstance>>;
  serviceUserGroups?: InputMaybe<ServiceUserGroupInput>;
  technicalUpdateNotifications?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdatePersonInput = {
  companyName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mobilePhone?: InputMaybe<Scalars['String']['input']>;
  newsletter?: InputMaybe<Scalars['Boolean']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  serviceOwnerOf?: InputMaybe<Array<InlineServiceInstance>>;
  serviceUserGroups?: InputMaybe<ServiceUserGroupInput>;
  technicalUpdateNotifications?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateRackifyConfigurationInput = {
  additionalServices?: InputMaybe<Scalars['JSON']['input']>;
  additionalServicesMap?: InputMaybe<Scalars['JSON']['input']>;
  contact?: InputMaybe<Scalars['JSONObject']['input']>;
  created?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['ID']['input'];
  price?: InputMaybe<Scalars['Float']['input']>;
  priceMonthly?: InputMaybe<Scalars['Float']['input']>;
  updated?: InputMaybe<Scalars['DateTime']['input']>;
  version?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateRackifyConfiguredServiceInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  amountRacks?: InputMaybe<Scalars['Float']['input']>;
  configurationId: Scalars['String']['input'];
  created?: InputMaybe<Scalars['DateTime']['input']>;
  fixedOptions?: InputMaybe<Scalars['JSON']['input']>;
  id: Scalars['ID']['input'];
  options?: InputMaybe<Scalars['JSON']['input']>;
  optionsMap?: InputMaybe<Scalars['JSON']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  priceMonthly?: InputMaybe<Scalars['Float']['input']>;
  priceOnRequest?: InputMaybe<Scalars['Boolean']['input']>;
  updated?: InputMaybe<Scalars['DateTime']['input']>;
  variantOption?: InputMaybe<Scalars['String']['input']>;
  variantOptions?: InputMaybe<Scalars['JSON']['input']>;
  version?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateServiceGroupInput = {
  id: Scalars['ID']['input'];
  person: InlinePerson;
};

export type UpdateServiceInstanceInput = {
  invoiceRecipient?: InputMaybe<UpdateBillingAddressInput>;
  serviceOwnerIsContactPerson?: InputMaybe<Scalars['Boolean']['input']>;
  serviceOwnerIsRequestContactPerson?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateServiceUser = {
  created?: InputMaybe<Scalars['DateTime']['input']>;
  deleted?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<ServiceUserRole>;
  updated?: InputMaybe<Scalars['DateTime']['input']>;
  version?: InputMaybe<Scalars['Float']['input']>;
};

export type UpsertAddresInput = {
  addressAddition?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  companyName?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  organisationAltRecipientId?: InputMaybe<Scalars['String']['input']>;
  organisationId?: InputMaybe<Scalars['String']['input']>;
  organisationalUnit?: InputMaybe<Scalars['String']['input']>;
  postal?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
};

export type BillingAddressFragmentFragment = {
  __typename?: 'BillingAddress';
  id: string;
  serviceInstanceId?: string | null;
  addressId: string;
  billingReferenceNo1?: string | null;
  billingReferenceNo2?: string | null;
  billingReferenceNo3?: string | null;
  address: {
    __typename?: 'Address';
    id: string;
    companyName?: string | null;
    street?: string | null;
    postal?: string | null;
    country: string;
    city: string;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
  };
};

export type CreateOneBillingAddressMutationVariables = Exact<{
  input: CreateOneBillingAddressInput;
}>;

export type CreateOneBillingAddressMutation = {
  __typename?: 'Mutation';
  createOneBillingAddress: {
    __typename?: 'BillingAddress';
    id: string;
    serviceInstanceId?: string | null;
    addressId: string;
    billingReferenceNo1?: string | null;
    billingReferenceNo2?: string | null;
    billingReferenceNo3?: string | null;
    address: {
      __typename?: 'Address';
      id: string;
      companyName?: string | null;
      street?: string | null;
      postal?: string | null;
      country: string;
      city: string;
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
    };
  };
};

export type UpdateOneBillingAddressMutationVariables = Exact<{
  input: UpdateOneBillingAddressInput;
}>;

export type UpdateOneBillingAddressMutation = {
  __typename?: 'Mutation';
  updateOneBillingAddress: {
    __typename?: 'BillingAddress';
    id: string;
    serviceInstanceId?: string | null;
    addressId: string;
    billingReferenceNo1?: string | null;
    billingReferenceNo2?: string | null;
    billingReferenceNo3?: string | null;
    address: {
      __typename?: 'Address';
      id: string;
      companyName?: string | null;
      street?: string | null;
      postal?: string | null;
      country: string;
      city: string;
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
    };
  };
};

export type IpWhitelistFragmentFragment = { __typename?: 'IpWhitelist'; id: string; ips: Array<string> };

export type UpdateOneIpWhitelistMutationVariables = Exact<{
  input: UpdateOneIpWhitelistInput;
}>;

export type UpdateOneIpWhitelistMutation = {
  __typename?: 'Mutation';
  updateOneIpWhitelist: { __typename?: 'IpWhitelist'; id: string; ips: Array<string> };
};

export type IpWhitelistsQueryVariables = Exact<{
  filter?: InputMaybe<IpWhitelistFilter>;
  sorting?: InputMaybe<Array<IpWhitelistSort> | IpWhitelistSort>;
}>;

export type IpWhitelistsQuery = {
  __typename?: 'Query';
  ipWhitelists: {
    __typename?: 'IpWhitelistConnection';
    edges: Array<{
      __typename?: 'IpWhitelistEdge';
      node: { __typename?: 'IpWhitelist'; id: string; ips: Array<string> };
    }>;
  };
};

export type ProjectSetupFragmentFragment = { __typename?: 'ProjectSetup'; privateSubnet: string; sshKeyName: string };

export type OpencloudProjectFragmentFragment = {
  __typename?: 'OpencloudProject';
  id: string;
  name: string;
  provisionStatus: ProvisioningStatus;
  created: any;
  updated: any;
  setup?: { __typename?: 'ProjectSetup'; privateSubnet: string; sshKeyName: string } | null;
  serviceUsers: Array<{
    __typename?: 'ServiceUser';
    id: string;
    role: ServiceUserRole;
    created: any;
    updated: any;
    person: {
      __typename?: 'Person';
      id: string;
      email: string;
      firstName?: string | null;
      companyName?: string | null;
      mobilePhone?: string | null;
      phone?: string | null;
      lastName?: string | null;
      gender?: Gender | null;
      updated?: any | null;
      created?: any | null;
      version?: number | null;
      keycloakUser?: string | null;
      isKeycloakVerified?: boolean | null;
      keycloakRoles?: Array<string> | null;
      technicalAccountNotifications?: boolean | null;
      technicalUpdateNotifications?: boolean | null;
      newsletter?: boolean | null;
      serviceUserGroups?: { __typename?: 'ServiceUserGroup'; data?: any | null } | null;
    };
  }>;
};

export type UpdateOneOpencloudProjectMutationVariables = Exact<{
  input: UpdateOneOpencloudProjectInput;
}>;

export type UpdateOneOpencloudProjectMutation = {
  __typename?: 'Mutation';
  updateOneOpencloudProject: {
    __typename?: 'OpencloudProject';
    id: string;
    name: string;
    provisionStatus: ProvisioningStatus;
    created: any;
    updated: any;
    setup?: { __typename?: 'ProjectSetup'; privateSubnet: string; sshKeyName: string } | null;
    serviceUsers: Array<{
      __typename?: 'ServiceUser';
      id: string;
      role: ServiceUserRole;
      created: any;
      updated: any;
      person: {
        __typename?: 'Person';
        id: string;
        email: string;
        firstName?: string | null;
        companyName?: string | null;
        mobilePhone?: string | null;
        phone?: string | null;
        lastName?: string | null;
        gender?: Gender | null;
        updated?: any | null;
        created?: any | null;
        version?: number | null;
        keycloakUser?: string | null;
        isKeycloakVerified?: boolean | null;
        keycloakRoles?: Array<string> | null;
        technicalAccountNotifications?: boolean | null;
        technicalUpdateNotifications?: boolean | null;
        newsletter?: boolean | null;
        serviceUserGroups?: { __typename?: 'ServiceUserGroup'; data?: any | null } | null;
      };
    }>;
  };
};

export type CreateOneOpencloudProjectMutationVariables = Exact<{
  input: CreateOneOpencloudProjectInput;
}>;

export type CreateOneOpencloudProjectMutation = {
  __typename?: 'Mutation';
  createOneOpencloudProject: {
    __typename?: 'OpencloudProject';
    id: string;
    name: string;
    provisionStatus: ProvisioningStatus;
    created: any;
    updated: any;
    setup?: { __typename?: 'ProjectSetup'; privateSubnet: string; sshKeyName: string } | null;
    serviceUsers: Array<{
      __typename?: 'ServiceUser';
      id: string;
      role: ServiceUserRole;
      created: any;
      updated: any;
      person: {
        __typename?: 'Person';
        id: string;
        email: string;
        firstName?: string | null;
        companyName?: string | null;
        mobilePhone?: string | null;
        phone?: string | null;
        lastName?: string | null;
        gender?: Gender | null;
        updated?: any | null;
        created?: any | null;
        version?: number | null;
        keycloakUser?: string | null;
        isKeycloakVerified?: boolean | null;
        keycloakRoles?: Array<string> | null;
        technicalAccountNotifications?: boolean | null;
        technicalUpdateNotifications?: boolean | null;
        newsletter?: boolean | null;
        serviceUserGroups?: { __typename?: 'ServiceUserGroup'; data?: any | null } | null;
      };
    }>;
  };
};

export type DeleteOneOpencloudProjectMutationVariables = Exact<{
  input: DeleteOneOpencloudProjectInput;
}>;

export type DeleteOneOpencloudProjectMutation = {
  __typename?: 'Mutation';
  deleteOneOpencloudProject: {
    __typename?: 'OpencloudProjectDeleteResponse';
    id?: string | null;
    deleted?: any | null;
  };
};

export type OpencloudProjectsQueryVariables = Exact<{
  filter?: InputMaybe<OpencloudProjectFilter>;
  sorting?: InputMaybe<Array<OpencloudProjectSort> | OpencloudProjectSort>;
}>;

export type OpencloudProjectsQuery = {
  __typename?: 'Query';
  opencloudProjects: {
    __typename?: 'OpencloudProjectConnection';
    nodes: Array<{
      __typename?: 'OpencloudProject';
      id: string;
      name: string;
      provisionStatus: ProvisioningStatus;
      created: any;
      updated: any;
      setup?: { __typename?: 'ProjectSetup'; privateSubnet: string; sshKeyName: string } | null;
      serviceUsers: Array<{
        __typename?: 'ServiceUser';
        id: string;
        role: ServiceUserRole;
        created: any;
        updated: any;
        person: {
          __typename?: 'Person';
          id: string;
          email: string;
          firstName?: string | null;
          companyName?: string | null;
          mobilePhone?: string | null;
          phone?: string | null;
          lastName?: string | null;
          gender?: Gender | null;
          updated?: any | null;
          created?: any | null;
          version?: number | null;
          keycloakUser?: string | null;
          isKeycloakVerified?: boolean | null;
          keycloakRoles?: Array<string> | null;
          technicalAccountNotifications?: boolean | null;
          technicalUpdateNotifications?: boolean | null;
          newsletter?: boolean | null;
          serviceUserGroups?: { __typename?: 'ServiceUserGroup'; data?: any | null } | null;
        };
      }>;
    }>;
    pageInfo: { __typename?: 'OffsetPageInfo'; hasNextPage?: boolean | null; hasPreviousPage?: boolean | null };
  };
};

export type PersonFragmentPlainFragment = {
  __typename?: 'Person';
  id: string;
  email: string;
  firstName?: string | null;
  companyName?: string | null;
  mobilePhone?: string | null;
  phone?: string | null;
  lastName?: string | null;
  gender?: Gender | null;
  updated?: any | null;
  created?: any | null;
  version?: number | null;
  keycloakUser?: string | null;
  isKeycloakVerified?: boolean | null;
  keycloakRoles?: Array<string> | null;
  technicalAccountNotifications?: boolean | null;
  technicalUpdateNotifications?: boolean | null;
  newsletter?: boolean | null;
  serviceUserGroups?: { __typename?: 'ServiceUserGroup'; data?: any | null } | null;
};

export type ServiceInstanceFragmentFragment = {
  __typename?: 'ServiceInstance';
  id: string;
  serviceName: string;
  serviceGroup: string;
  serviceType?: ServiceType | null;
  articleDescription?: string | null;
  ptid?: string | null;
  sinr: string;
  customerNumber: string;
  subServiceText?: string | null;
  location1?: string | null;
  location2?: string | null;
  contractStatus: ContractStatus;
  provisioningStatus: ProvisioningStatus;
  startDate: any;
  endDate?: any | null;
  minimumContractPeriod?: string | null;
  details?: string | null;
  serviceOwnerIsContactPerson: boolean;
  serviceOwnerIsRequestContactPerson: boolean;
  serviceInstanceURL?: string | null;
  serviceInstanceAdditionalURL?: string | null;
  provisioningDate?: any | null;
  amount?: string | null;
  created: any;
  updated: any;
  version: number;
  organisation: {
    __typename?: 'Organisation';
    id: string;
    customerNumber?: string | null;
    customerNumbers?: Array<string> | null;
    company: { __typename?: 'Company'; id: string; name: string };
  };
  serviceUsers?: Array<{
    __typename?: 'Person';
    id: string;
    email: string;
    firstName?: string | null;
    companyName?: string | null;
    mobilePhone?: string | null;
    phone?: string | null;
    lastName?: string | null;
    gender?: Gender | null;
    updated?: any | null;
    created?: any | null;
    version?: number | null;
    keycloakUser?: string | null;
    isKeycloakVerified?: boolean | null;
    keycloakRoles?: Array<string> | null;
    technicalAccountNotifications?: boolean | null;
    technicalUpdateNotifications?: boolean | null;
    newsletter?: boolean | null;
    serviceUserGroups?: { __typename?: 'ServiceUserGroup'; data?: any | null } | null;
  }> | null;
  serviceOwners: Array<{
    __typename?: 'Person';
    id: string;
    email: string;
    firstName?: string | null;
    companyName?: string | null;
    mobilePhone?: string | null;
    phone?: string | null;
    lastName?: string | null;
    gender?: Gender | null;
    updated?: any | null;
    created?: any | null;
    version?: number | null;
    keycloakUser?: string | null;
    isKeycloakVerified?: boolean | null;
    keycloakRoles?: Array<string> | null;
    technicalAccountNotifications?: boolean | null;
    technicalUpdateNotifications?: boolean | null;
    newsletter?: boolean | null;
    serviceUserGroups?: { __typename?: 'ServiceUserGroup'; data?: any | null } | null;
  }>;
  contactPeople?: Array<{
    __typename?: 'ContactPerson';
    id: string;
    contactPersonFor: Array<ContactPersonFor>;
    note?: string | null;
    person: {
      __typename?: 'Person';
      id: string;
      email: string;
      firstName?: string | null;
      companyName?: string | null;
      mobilePhone?: string | null;
      phone?: string | null;
      lastName?: string | null;
      gender?: Gender | null;
      updated?: any | null;
      created?: any | null;
      version?: number | null;
      keycloakUser?: string | null;
      isKeycloakVerified?: boolean | null;
      keycloakRoles?: Array<string> | null;
      technicalAccountNotifications?: boolean | null;
      technicalUpdateNotifications?: boolean | null;
      newsletter?: boolean | null;
      serviceUserGroups?: { __typename?: 'ServiceUserGroup'; data?: any | null } | null;
    };
  }> | null;
  invoiceRecipient?: {
    __typename?: 'BillingAddress';
    id: string;
    serviceInstanceId?: string | null;
    addressId: string;
    billingReferenceNo1?: string | null;
    billingReferenceNo2?: string | null;
    billingReferenceNo3?: string | null;
    address: {
      __typename?: 'Address';
      id: string;
      companyName?: string | null;
      street?: string | null;
      postal?: string | null;
      country: string;
      city: string;
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
    };
  } | null;
};

export type AddServiceOwnerOfToPersonMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  serviceInstanceIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type AddServiceOwnerOfToPersonMutation = {
  __typename?: 'Mutation';
  addServiceOwnerOfToPerson: { __typename?: 'Person'; id: string };
};

export type AddServiceUserOfToPersonMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  serviceInstanceIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type AddServiceUserOfToPersonMutation = {
  __typename?: 'Mutation';
  addServiceUserOfToPerson: { __typename?: 'Person'; id: string };
};

export type RemoveServiceUserOfFromPersonMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  serviceInstanceIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type RemoveServiceUserOfFromPersonMutation = {
  __typename?: 'Mutation';
  removeServiceUserOfFromPerson: { __typename?: 'Person'; id: string };
};

export type RemoveServiceOwnerOfFromPersonMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  serviceInstanceIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type RemoveServiceOwnerOfFromPersonMutation = {
  __typename?: 'Mutation';
  removeServiceOwnerOfFromPerson: { __typename?: 'Person'; id: string };
};

export type CreateOneContactPersonMutationVariables = Exact<{
  contactPerson: CreateContactPersonInput;
}>;

export type CreateOneContactPersonMutation = {
  __typename?: 'Mutation';
  createOneContactPerson: { __typename?: 'ContactPerson'; id: string };
};

export type UpdateOneContactPersonMutationVariables = Exact<{
  input: UpdateOneContactPersonInput;
}>;

export type UpdateOneContactPersonMutation = {
  __typename?: 'Mutation';
  updateOneContactPerson: { __typename?: 'ContactPerson'; note?: string | null };
};

export type DeleteOneContactPersonMutationVariables = Exact<{
  input: DeleteOneContactPersonInput;
}>;

export type DeleteOneContactPersonMutation = {
  __typename?: 'Mutation';
  deleteOneContactPerson: { __typename?: 'ContactPersonDeleteResponse'; id?: string | null };
};

export type UpdateOneServiceInstanceMutationVariables = Exact<{
  input: UpdateOneServiceInstanceInput;
}>;

export type UpdateOneServiceInstanceMutation = {
  __typename?: 'Mutation';
  updateOneServiceInstance: {
    __typename?: 'ServiceInstance';
    id: string;
    serviceName: string;
    serviceGroup: string;
    serviceType?: ServiceType | null;
    articleDescription?: string | null;
    ptid?: string | null;
    sinr: string;
    customerNumber: string;
    subServiceText?: string | null;
    location1?: string | null;
    location2?: string | null;
    contractStatus: ContractStatus;
    provisioningStatus: ProvisioningStatus;
    startDate: any;
    endDate?: any | null;
    minimumContractPeriod?: string | null;
    details?: string | null;
    serviceOwnerIsContactPerson: boolean;
    serviceOwnerIsRequestContactPerson: boolean;
    serviceInstanceURL?: string | null;
    serviceInstanceAdditionalURL?: string | null;
    provisioningDate?: any | null;
    amount?: string | null;
    created: any;
    updated: any;
    version: number;
    organisation: {
      __typename?: 'Organisation';
      id: string;
      customerNumber?: string | null;
      customerNumbers?: Array<string> | null;
      company: { __typename?: 'Company'; id: string; name: string };
    };
    serviceUsers?: Array<{
      __typename?: 'Person';
      id: string;
      email: string;
      firstName?: string | null;
      companyName?: string | null;
      mobilePhone?: string | null;
      phone?: string | null;
      lastName?: string | null;
      gender?: Gender | null;
      updated?: any | null;
      created?: any | null;
      version?: number | null;
      keycloakUser?: string | null;
      isKeycloakVerified?: boolean | null;
      keycloakRoles?: Array<string> | null;
      technicalAccountNotifications?: boolean | null;
      technicalUpdateNotifications?: boolean | null;
      newsletter?: boolean | null;
      serviceUserGroups?: { __typename?: 'ServiceUserGroup'; data?: any | null } | null;
    }> | null;
    serviceOwners: Array<{
      __typename?: 'Person';
      id: string;
      email: string;
      firstName?: string | null;
      companyName?: string | null;
      mobilePhone?: string | null;
      phone?: string | null;
      lastName?: string | null;
      gender?: Gender | null;
      updated?: any | null;
      created?: any | null;
      version?: number | null;
      keycloakUser?: string | null;
      isKeycloakVerified?: boolean | null;
      keycloakRoles?: Array<string> | null;
      technicalAccountNotifications?: boolean | null;
      technicalUpdateNotifications?: boolean | null;
      newsletter?: boolean | null;
      serviceUserGroups?: { __typename?: 'ServiceUserGroup'; data?: any | null } | null;
    }>;
    contactPeople?: Array<{
      __typename?: 'ContactPerson';
      id: string;
      contactPersonFor: Array<ContactPersonFor>;
      note?: string | null;
      person: {
        __typename?: 'Person';
        id: string;
        email: string;
        firstName?: string | null;
        companyName?: string | null;
        mobilePhone?: string | null;
        phone?: string | null;
        lastName?: string | null;
        gender?: Gender | null;
        updated?: any | null;
        created?: any | null;
        version?: number | null;
        keycloakUser?: string | null;
        isKeycloakVerified?: boolean | null;
        keycloakRoles?: Array<string> | null;
        technicalAccountNotifications?: boolean | null;
        technicalUpdateNotifications?: boolean | null;
        newsletter?: boolean | null;
        serviceUserGroups?: { __typename?: 'ServiceUserGroup'; data?: any | null } | null;
      };
    }> | null;
    invoiceRecipient?: {
      __typename?: 'BillingAddress';
      id: string;
      serviceInstanceId?: string | null;
      addressId: string;
      billingReferenceNo1?: string | null;
      billingReferenceNo2?: string | null;
      billingReferenceNo3?: string | null;
      address: {
        __typename?: 'Address';
        id: string;
        companyName?: string | null;
        street?: string | null;
        postal?: string | null;
        country: string;
        city: string;
        firstName?: string | null;
        lastName?: string | null;
        email?: string | null;
      };
    } | null;
  };
};

export type ServiceInstancesQueryVariables = Exact<{
  filter?: InputMaybe<ServiceInstanceFilter>;
  sorting?: InputMaybe<Array<ServiceInstanceSort> | ServiceInstanceSort>;
}>;

export type ServiceInstancesQuery = {
  __typename?: 'Query';
  serviceInstances: {
    __typename?: 'ServiceInstanceConnection';
    edges: Array<{
      __typename?: 'ServiceInstanceEdge';
      node: {
        __typename?: 'ServiceInstance';
        id: string;
        serviceName: string;
        serviceGroup: string;
        serviceType?: ServiceType | null;
        articleDescription?: string | null;
        ptid?: string | null;
        sinr: string;
        customerNumber: string;
        subServiceText?: string | null;
        location1?: string | null;
        location2?: string | null;
        contractStatus: ContractStatus;
        provisioningStatus: ProvisioningStatus;
        startDate: any;
        endDate?: any | null;
        minimumContractPeriod?: string | null;
        details?: string | null;
        serviceOwnerIsContactPerson: boolean;
        serviceOwnerIsRequestContactPerson: boolean;
        serviceInstanceURL?: string | null;
        serviceInstanceAdditionalURL?: string | null;
        provisioningDate?: any | null;
        amount?: string | null;
        created: any;
        updated: any;
        version: number;
        organisation: {
          __typename?: 'Organisation';
          id: string;
          customerNumber?: string | null;
          customerNumbers?: Array<string> | null;
          company: { __typename?: 'Company'; id: string; name: string };
        };
        serviceUsers?: Array<{
          __typename?: 'Person';
          id: string;
          email: string;
          firstName?: string | null;
          companyName?: string | null;
          mobilePhone?: string | null;
          phone?: string | null;
          lastName?: string | null;
          gender?: Gender | null;
          updated?: any | null;
          created?: any | null;
          version?: number | null;
          keycloakUser?: string | null;
          isKeycloakVerified?: boolean | null;
          keycloakRoles?: Array<string> | null;
          technicalAccountNotifications?: boolean | null;
          technicalUpdateNotifications?: boolean | null;
          newsletter?: boolean | null;
          serviceUserGroups?: { __typename?: 'ServiceUserGroup'; data?: any | null } | null;
        }> | null;
        serviceOwners: Array<{
          __typename?: 'Person';
          id: string;
          email: string;
          firstName?: string | null;
          companyName?: string | null;
          mobilePhone?: string | null;
          phone?: string | null;
          lastName?: string | null;
          gender?: Gender | null;
          updated?: any | null;
          created?: any | null;
          version?: number | null;
          keycloakUser?: string | null;
          isKeycloakVerified?: boolean | null;
          keycloakRoles?: Array<string> | null;
          technicalAccountNotifications?: boolean | null;
          technicalUpdateNotifications?: boolean | null;
          newsletter?: boolean | null;
          serviceUserGroups?: { __typename?: 'ServiceUserGroup'; data?: any | null } | null;
        }>;
        contactPeople?: Array<{
          __typename?: 'ContactPerson';
          id: string;
          contactPersonFor: Array<ContactPersonFor>;
          note?: string | null;
          person: {
            __typename?: 'Person';
            id: string;
            email: string;
            firstName?: string | null;
            companyName?: string | null;
            mobilePhone?: string | null;
            phone?: string | null;
            lastName?: string | null;
            gender?: Gender | null;
            updated?: any | null;
            created?: any | null;
            version?: number | null;
            keycloakUser?: string | null;
            isKeycloakVerified?: boolean | null;
            keycloakRoles?: Array<string> | null;
            technicalAccountNotifications?: boolean | null;
            technicalUpdateNotifications?: boolean | null;
            newsletter?: boolean | null;
            serviceUserGroups?: { __typename?: 'ServiceUserGroup'; data?: any | null } | null;
          };
        }> | null;
        invoiceRecipient?: {
          __typename?: 'BillingAddress';
          id: string;
          serviceInstanceId?: string | null;
          addressId: string;
          billingReferenceNo1?: string | null;
          billingReferenceNo2?: string | null;
          billingReferenceNo3?: string | null;
          address: {
            __typename?: 'Address';
            id: string;
            companyName?: string | null;
            street?: string | null;
            postal?: string | null;
            country: string;
            city: string;
            firstName?: string | null;
            lastName?: string | null;
            email?: string | null;
          };
        } | null;
      };
    }>;
  };
};

export type DcamContactFragment = {
  __typename?: 'DcamContact';
  id: string;
  firstName: string;
  lastName: string;
  gender: Gender;
  birthday: string;
  email?: string | null;
  phone?: string | null;
  note?: string | null;
  company?: string | null;
  changedBy: string;
  created: any;
  updated: any;
  version: number;
};

export type DcamAccessFragment = {
  __typename?: 'DcamAccess';
  id: string;
  accessType: DcamAccessMode;
  note?: string | null;
  name?: string | null;
  created: any;
  updated: any;
  version: number;
};

export type DcamFragment = {
  __typename?: 'Dcam';
  id: string;
  companyId: string;
  created: any;
  updated: any;
  version: number;
  serviceOwners: Array<{
    __typename?: 'Person';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
  }>;
  access: Array<{
    __typename?: 'DcamAccess';
    id: string;
    accessType: DcamAccessMode;
    note?: string | null;
    name?: string | null;
    created: any;
    updated: any;
    version: number;
    keySet?: {
      __typename?: 'DcamKeyset';
      id: string;
      keyId: string;
      serviceInfos?: Array<{
        __typename?: 'DcamKeysetServiceInfo';
        dc: string;
        loc1: string;
        loc2: string;
        loc3: string;
        loc4?: string | null;
        loc5?: string | null;
        serviceNumber: string;
      }> | null;
    } | null;
    contacts?: Array<{
      __typename?: 'DcamContact';
      id: string;
      firstName: string;
      lastName: string;
      gender: Gender;
      birthday: string;
      email?: string | null;
      phone?: string | null;
      note?: string | null;
      company?: string | null;
      changedBy: string;
      created: any;
      updated: any;
      version: number;
    }> | null;
  }>;
};

export type CreateOneDcamContactMutationVariables = Exact<{
  input: CreateOneDcamContactInput;
}>;

export type CreateOneDcamContactMutation = {
  __typename?: 'Mutation';
  createOneDcamContact: {
    __typename?: 'DcamContact';
    id: string;
    firstName: string;
    lastName: string;
    gender: Gender;
    birthday: string;
    email?: string | null;
    phone?: string | null;
    note?: string | null;
    company?: string | null;
    changedBy: string;
    created: any;
    updated: any;
    version: number;
    dcamAccess: {
      __typename?: 'DcamAccess';
      id: string;
      contacts?: Array<{ __typename?: 'DcamContact'; id: string }> | null;
    };
  };
};

export type UpdateOneDcamContactMutationVariables = Exact<{
  input: UpdateOneDcamContactInput;
}>;

export type UpdateOneDcamContactMutation = {
  __typename?: 'Mutation';
  updateOneDcamContact: {
    __typename?: 'DcamContact';
    id: string;
    firstName: string;
    lastName: string;
    gender: Gender;
    birthday: string;
    email?: string | null;
    phone?: string | null;
    note?: string | null;
    company?: string | null;
    changedBy: string;
    created: any;
    updated: any;
    version: number;
    dcamAccess: {
      __typename?: 'DcamAccess';
      id: string;
      contacts?: Array<{ __typename?: 'DcamContact'; id: string }> | null;
    };
  };
};

export type DeleteOneDcamContactMutationVariables = Exact<{
  input: DeleteOneDcamContactInput;
}>;

export type DeleteOneDcamContactMutation = {
  __typename?: 'Mutation';
  deleteOneDcamContact: { __typename?: 'DcamContactDeleteResponse'; id?: string | null };
};

export type UpdateOneDcamAccessMutationVariables = Exact<{
  input: UpdateOneDcamAccessInput;
}>;

export type UpdateOneDcamAccessMutation = {
  __typename?: 'Mutation';
  updateOneDcamAccess: {
    __typename?: 'DcamAccess';
    id: string;
    accessType: DcamAccessMode;
    note?: string | null;
    name?: string | null;
    created: any;
    updated: any;
    version: number;
  };
};

export type AddServiceOwnersToDcamMutationVariables = Exact<{
  input: AddServiceOwnersToDcamInput;
}>;

export type AddServiceOwnersToDcamMutation = {
  __typename?: 'Mutation';
  addServiceOwnersToDcam: {
    __typename?: 'Dcam';
    id: string;
    companyId: string;
    created: any;
    updated: any;
    version: number;
    serviceOwners: Array<{
      __typename?: 'Person';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      email: string;
    }>;
    access: Array<{
      __typename?: 'DcamAccess';
      id: string;
      accessType: DcamAccessMode;
      note?: string | null;
      name?: string | null;
      created: any;
      updated: any;
      version: number;
      keySet?: {
        __typename?: 'DcamKeyset';
        id: string;
        keyId: string;
        serviceInfos?: Array<{
          __typename?: 'DcamKeysetServiceInfo';
          dc: string;
          loc1: string;
          loc2: string;
          loc3: string;
          loc4?: string | null;
          loc5?: string | null;
          serviceNumber: string;
        }> | null;
      } | null;
      contacts?: Array<{
        __typename?: 'DcamContact';
        id: string;
        firstName: string;
        lastName: string;
        gender: Gender;
        birthday: string;
        email?: string | null;
        phone?: string | null;
        note?: string | null;
        company?: string | null;
        changedBy: string;
        created: any;
        updated: any;
        version: number;
      }> | null;
    }>;
  };
};

export type RemoveServiceOwnersFromDcamMutationVariables = Exact<{
  input: RemoveServiceOwnersFromDcamInput;
}>;

export type RemoveServiceOwnersFromDcamMutation = {
  __typename?: 'Mutation';
  removeServiceOwnersFromDcam: {
    __typename?: 'Dcam';
    id: string;
    companyId: string;
    created: any;
    updated: any;
    version: number;
    serviceOwners: Array<{
      __typename?: 'Person';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      email: string;
    }>;
    access: Array<{
      __typename?: 'DcamAccess';
      id: string;
      accessType: DcamAccessMode;
      note?: string | null;
      name?: string | null;
      created: any;
      updated: any;
      version: number;
      keySet?: {
        __typename?: 'DcamKeyset';
        id: string;
        keyId: string;
        serviceInfos?: Array<{
          __typename?: 'DcamKeysetServiceInfo';
          dc: string;
          loc1: string;
          loc2: string;
          loc3: string;
          loc4?: string | null;
          loc5?: string | null;
          serviceNumber: string;
        }> | null;
      } | null;
      contacts?: Array<{
        __typename?: 'DcamContact';
        id: string;
        firstName: string;
        lastName: string;
        gender: Gender;
        birthday: string;
        email?: string | null;
        phone?: string | null;
        note?: string | null;
        company?: string | null;
        changedBy: string;
        created: any;
        updated: any;
        version: number;
      }> | null;
    }>;
  };
};

export type UpdateOneDcamMutationVariables = Exact<{
  input: UpdateOneDcamInput;
}>;

export type UpdateOneDcamMutation = {
  __typename?: 'Mutation';
  updateOneDcam: {
    __typename?: 'Dcam';
    id: string;
    companyId: string;
    created: any;
    updated: any;
    version: number;
    serviceOwners: Array<{
      __typename?: 'Person';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      email: string;
    }>;
    access: Array<{
      __typename?: 'DcamAccess';
      id: string;
      accessType: DcamAccessMode;
      note?: string | null;
      name?: string | null;
      created: any;
      updated: any;
      version: number;
      keySet?: {
        __typename?: 'DcamKeyset';
        id: string;
        keyId: string;
        serviceInfos?: Array<{
          __typename?: 'DcamKeysetServiceInfo';
          dc: string;
          loc1: string;
          loc2: string;
          loc3: string;
          loc4?: string | null;
          loc5?: string | null;
          serviceNumber: string;
        }> | null;
      } | null;
      contacts?: Array<{
        __typename?: 'DcamContact';
        id: string;
        firstName: string;
        lastName: string;
        gender: Gender;
        birthday: string;
        email?: string | null;
        phone?: string | null;
        note?: string | null;
        company?: string | null;
        changedBy: string;
        created: any;
        updated: any;
        version: number;
      }> | null;
    }>;
  };
};

export type DcamQueryVariables = Exact<{ [key: string]: never }>;

export type DcamQuery = {
  __typename?: 'Query';
  dcams: Array<{
    __typename?: 'Dcam';
    id: string;
    companyId: string;
    created: any;
    updated: any;
    version: number;
    serviceOwners: Array<{
      __typename?: 'Person';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      email: string;
    }>;
    access: Array<{
      __typename?: 'DcamAccess';
      id: string;
      accessType: DcamAccessMode;
      note?: string | null;
      name?: string | null;
      created: any;
      updated: any;
      version: number;
      keySet?: {
        __typename?: 'DcamKeyset';
        id: string;
        keyId: string;
        serviceInfos?: Array<{
          __typename?: 'DcamKeysetServiceInfo';
          dc: string;
          loc1: string;
          loc2: string;
          loc3: string;
          loc4?: string | null;
          loc5?: string | null;
          serviceNumber: string;
        }> | null;
      } | null;
      contacts?: Array<{
        __typename?: 'DcamContact';
        id: string;
        firstName: string;
        lastName: string;
        gender: Gender;
        birthday: string;
        email?: string | null;
        phone?: string | null;
        note?: string | null;
        company?: string | null;
        changedBy: string;
        created: any;
        updated: any;
        version: number;
      }> | null;
    }>;
  }>;
};

export type PageInfoFragmentFragment = {
  __typename?: 'PageInfo';
  hasNextPage?: boolean | null;
  hasPreviousPage?: boolean | null;
  startCursor?: any | null;
  endCursor?: any | null;
};

export type OffsetPageInfoFragmentFragment = {
  __typename?: 'OffsetPageInfo';
  hasNextPage?: boolean | null;
  hasPreviousPage?: boolean | null;
};

export type AddressFragmentFragment = {
  __typename?: 'Address';
  id: string;
  city: string;
  street?: string | null;
  postal?: string | null;
  companyName?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  country: string;
  organisationalUnit?: string | null;
  addressAddition?: string | null;
};

export type ToggleCompanyOtpRequirementMutationVariables = Exact<{ [key: string]: never }>;

export type ToggleCompanyOtpRequirementMutation = {
  __typename?: 'Mutation';
  toggleCompanyOTPRequirement: {
    __typename?: 'ToggleCompanyOTPRequirement';
    companyId: string;
    isOtpRequired: boolean;
  };
};

export type CompanyQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type CompanyQuery = {
  __typename?: 'Query';
  company: { __typename?: 'Company'; id: string; name: string; isOtpRequired?: boolean | null; keycloakId: string };
};

export type CreateOneAddressMutationVariables = Exact<{
  input: CreateOneAddressInput;
}>;

export type CreateOneAddressMutation = {
  __typename?: 'Mutation';
  createOneAddress: {
    __typename?: 'Address';
    id: string;
    city: string;
    street?: string | null;
    postal?: string | null;
    companyName?: string | null;
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    country: string;
    organisationalUnit?: string | null;
    addressAddition?: string | null;
  };
};

export type OrganisationFragmentFragment = {
  __typename?: 'Organisation';
  id: string;
  name: string;
  customerNumber?: string | null;
  customerNumbers?: Array<string> | null;
  companyRegistrationNumber?: string | null;
  taxId?: string | null;
  dunsId?: string | null;
  street: string;
  postal: string;
  city: string;
  country: string;
  phone?: string | null;
  email: string;
  isDefaultInvoiceRecipient: boolean;
  created: any;
  updated: any;
  version: number;
  invoiceRecipient: {
    __typename?: 'Address';
    id: string;
    companyName?: string | null;
    street?: string | null;
    postal?: string | null;
    country: string;
    city: string;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    organisationalUnit?: string | null;
    addressAddition?: string | null;
  };
  invoiceRecipients: Array<{
    __typename?: 'Address';
    id: string;
    companyName?: string | null;
    street?: string | null;
    postal?: string | null;
    country: string;
    city: string;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    organisationalUnit?: string | null;
    addressAddition?: string | null;
  }>;
};

export type UpdateOneOrganisationMutationVariables = Exact<{
  input: UpdateOneOrganisationInput;
}>;

export type UpdateOneOrganisationMutation = {
  __typename?: 'Mutation';
  updateOneOrganisation: {
    __typename?: 'Organisation';
    id: string;
    name: string;
    customerNumber?: string | null;
    customerNumbers?: Array<string> | null;
    companyRegistrationNumber?: string | null;
    taxId?: string | null;
    dunsId?: string | null;
    street: string;
    postal: string;
    city: string;
    country: string;
    phone?: string | null;
    email: string;
    isDefaultInvoiceRecipient: boolean;
    created: any;
    updated: any;
    version: number;
    invoiceRecipient: {
      __typename?: 'Address';
      id: string;
      companyName?: string | null;
      street?: string | null;
      postal?: string | null;
      country: string;
      city: string;
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
      organisationalUnit?: string | null;
      addressAddition?: string | null;
    };
    invoiceRecipients: Array<{
      __typename?: 'Address';
      id: string;
      companyName?: string | null;
      street?: string | null;
      postal?: string | null;
      country: string;
      city: string;
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
      organisationalUnit?: string | null;
      addressAddition?: string | null;
    }>;
  };
};

export type OrganisationsQueryVariables = Exact<{
  filter?: InputMaybe<OrganisationFilter>;
}>;

export type OrganisationsQuery = {
  __typename?: 'Query';
  organisations: {
    __typename?: 'OrganisationConnection';
    edges: Array<{
      __typename?: 'OrganisationEdge';
      node: {
        __typename?: 'Organisation';
        id: string;
        name: string;
        customerNumber?: string | null;
        customerNumbers?: Array<string> | null;
        companyRegistrationNumber?: string | null;
        taxId?: string | null;
        dunsId?: string | null;
        street: string;
        postal: string;
        city: string;
        country: string;
        phone?: string | null;
        email: string;
        isDefaultInvoiceRecipient: boolean;
        created: any;
        updated: any;
        version: number;
        invoiceRecipient: {
          __typename?: 'Address';
          id: string;
          companyName?: string | null;
          street?: string | null;
          postal?: string | null;
          country: string;
          city: string;
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
          organisationalUnit?: string | null;
          addressAddition?: string | null;
        };
        invoiceRecipients: Array<{
          __typename?: 'Address';
          id: string;
          companyName?: string | null;
          street?: string | null;
          postal?: string | null;
          country: string;
          city: string;
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
          organisationalUnit?: string | null;
          addressAddition?: string | null;
        }>;
      };
    }>;
  };
};

export type PeopleQueryVariables = Exact<{ [key: string]: never }>;

export type PeopleQuery = {
  __typename?: 'Query';
  people: {
    __typename?: 'PersonConnection';
    edges: Array<{
      __typename?: 'PersonEdge';
      node: {
        __typename?: 'Person';
        id: string;
        email: string;
        firstName?: string | null;
        companyName?: string | null;
        mobilePhone?: string | null;
        phone?: string | null;
        lastName?: string | null;
        gender?: Gender | null;
        updated?: any | null;
        created?: any | null;
        version?: number | null;
        isRoot?: boolean | null;
        keycloakUser?: string | null;
        isKeycloakVerified?: boolean | null;
        keycloakRoles?: Array<string> | null;
        technicalAccountNotifications?: boolean | null;
        technicalUpdateNotifications?: boolean | null;
        newsletter?: boolean | null;
        contactPersonOf?: Array<{
          __typename?: 'ContactPerson';
          id: string;
          contactPersonFor: Array<ContactPersonFor>;
          serviceInstanceId: string;
        }> | null;
        serviceUserGroups?: { __typename?: 'ServiceUserGroup'; data?: any | null } | null;
        serviceOwnerOf?: Array<{
          __typename?: 'ServiceInstance';
          id: string;
          serviceName: string;
          sinr: string;
          subServiceText?: string | null;
          location1?: string | null;
          location2?: string | null;
          contractStatus: ContractStatus;
          provisioningStatus: ProvisioningStatus;
          startDate: any;
          endDate?: any | null;
          details?: string | null;
          serviceOwnerIsContactPerson: boolean;
          serviceOwnerIsRequestContactPerson: boolean;
          amount?: string | null;
          created: any;
          updated: any;
          version: number;
        }> | null;
        serviceUserOf?: Array<{
          __typename?: 'ServiceInstance';
          id: string;
          serviceName: string;
          sinr: string;
          created: any;
          updated: any;
          version: number;
        }> | null;
        companies: Array<{
          __typename?: 'Company';
          id: string;
          name: string;
          keycloakId: string;
          isOtpRequired?: boolean | null;
          features?: {
            __typename?: 'CompanyFeatures';
            isDcamEnabled?: boolean | null;
            isInvoiceEnabled?: boolean | null;
          } | null;
          organisations: Array<{
            __typename?: 'Organisation';
            id: string;
            name: string;
            customerNumber?: string | null;
            customerNumbers?: Array<string> | null;
            companyRegistrationNumber?: string | null;
            taxId?: string | null;
            dunsId?: string | null;
            street: string;
            postal: string;
            city: string;
            country: string;
            phone?: string | null;
            email: string;
            isDefaultInvoiceRecipient: boolean;
            created: any;
            updated: any;
            version: number;
            invoiceRecipient: {
              __typename?: 'Address';
              id: string;
              companyName?: string | null;
              street?: string | null;
              postal?: string | null;
              country: string;
              city: string;
              firstName?: string | null;
              lastName?: string | null;
              email?: string | null;
              organisationalUnit?: string | null;
              addressAddition?: string | null;
            };
            invoiceRecipients: Array<{
              __typename?: 'Address';
              id: string;
              companyName?: string | null;
              street?: string | null;
              postal?: string | null;
              country: string;
              city: string;
              firstName?: string | null;
              lastName?: string | null;
              email?: string | null;
              organisationalUnit?: string | null;
              addressAddition?: string | null;
            }>;
          }>;
        }>;
      };
    }>;
  };
};

export type UpdatedOnePersonSubscriptionVariables = Exact<{
  filter: PersonSubscriptionFilter;
}>;

export type UpdatedOnePersonSubscription = {
  __typename?: 'Subscription';
  updatedOnePerson: {
    __typename?: 'Person';
    id: string;
    email: string;
    firstName?: string | null;
    updated?: any | null;
    created?: any | null;
    version?: number | null;
    companies: Array<{ __typename?: 'Company'; id: string; name: string }>;
  };
};

export type PersonFragmentFragment = {
  __typename?: 'Person';
  id: string;
  email: string;
  firstName?: string | null;
  companyName?: string | null;
  mobilePhone?: string | null;
  phone?: string | null;
  lastName?: string | null;
  gender?: Gender | null;
  updated?: any | null;
  created?: any | null;
  version?: number | null;
  isRoot?: boolean | null;
  keycloakUser?: string | null;
  isKeycloakVerified?: boolean | null;
  keycloakRoles?: Array<string> | null;
  technicalAccountNotifications?: boolean | null;
  technicalUpdateNotifications?: boolean | null;
  newsletter?: boolean | null;
  contactPersonOf?: Array<{
    __typename?: 'ContactPerson';
    id: string;
    contactPersonFor: Array<ContactPersonFor>;
    serviceInstanceId: string;
  }> | null;
  serviceUserGroups?: { __typename?: 'ServiceUserGroup'; data?: any | null } | null;
  serviceOwnerOf?: Array<{
    __typename?: 'ServiceInstance';
    id: string;
    serviceName: string;
    sinr: string;
    subServiceText?: string | null;
    location1?: string | null;
    location2?: string | null;
    contractStatus: ContractStatus;
    provisioningStatus: ProvisioningStatus;
    startDate: any;
    endDate?: any | null;
    details?: string | null;
    serviceOwnerIsContactPerson: boolean;
    serviceOwnerIsRequestContactPerson: boolean;
    amount?: string | null;
    created: any;
    updated: any;
    version: number;
  }> | null;
  serviceUserOf?: Array<{
    __typename?: 'ServiceInstance';
    id: string;
    serviceName: string;
    sinr: string;
    created: any;
    updated: any;
    version: number;
  }> | null;
  companies: Array<{
    __typename?: 'Company';
    id: string;
    name: string;
    keycloakId: string;
    isOtpRequired?: boolean | null;
    features?: {
      __typename?: 'CompanyFeatures';
      isDcamEnabled?: boolean | null;
      isInvoiceEnabled?: boolean | null;
    } | null;
    organisations: Array<{
      __typename?: 'Organisation';
      id: string;
      name: string;
      customerNumber?: string | null;
      customerNumbers?: Array<string> | null;
      companyRegistrationNumber?: string | null;
      taxId?: string | null;
      dunsId?: string | null;
      street: string;
      postal: string;
      city: string;
      country: string;
      phone?: string | null;
      email: string;
      isDefaultInvoiceRecipient: boolean;
      created: any;
      updated: any;
      version: number;
      invoiceRecipient: {
        __typename?: 'Address';
        id: string;
        companyName?: string | null;
        street?: string | null;
        postal?: string | null;
        country: string;
        city: string;
        firstName?: string | null;
        lastName?: string | null;
        email?: string | null;
        organisationalUnit?: string | null;
        addressAddition?: string | null;
      };
      invoiceRecipients: Array<{
        __typename?: 'Address';
        id: string;
        companyName?: string | null;
        street?: string | null;
        postal?: string | null;
        country: string;
        city: string;
        firstName?: string | null;
        lastName?: string | null;
        email?: string | null;
        organisationalUnit?: string | null;
        addressAddition?: string | null;
      }>;
    }>;
  }>;
};

export type UpdateOnePersonMutationVariables = Exact<{
  input: UpdateOnePersonInput;
}>;

export type UpdateOnePersonMutation = {
  __typename?: 'Mutation';
  updateOnePerson: {
    __typename?: 'Person';
    id: string;
    email: string;
    firstName?: string | null;
    companyName?: string | null;
    mobilePhone?: string | null;
    phone?: string | null;
    lastName?: string | null;
    gender?: Gender | null;
    updated?: any | null;
    created?: any | null;
    version?: number | null;
    isRoot?: boolean | null;
    keycloakUser?: string | null;
    isKeycloakVerified?: boolean | null;
    keycloakRoles?: Array<string> | null;
    technicalAccountNotifications?: boolean | null;
    technicalUpdateNotifications?: boolean | null;
    newsletter?: boolean | null;
    contactPersonOf?: Array<{
      __typename?: 'ContactPerson';
      id: string;
      contactPersonFor: Array<ContactPersonFor>;
      serviceInstanceId: string;
    }> | null;
    serviceUserGroups?: { __typename?: 'ServiceUserGroup'; data?: any | null } | null;
    serviceOwnerOf?: Array<{
      __typename?: 'ServiceInstance';
      id: string;
      serviceName: string;
      sinr: string;
      subServiceText?: string | null;
      location1?: string | null;
      location2?: string | null;
      contractStatus: ContractStatus;
      provisioningStatus: ProvisioningStatus;
      startDate: any;
      endDate?: any | null;
      details?: string | null;
      serviceOwnerIsContactPerson: boolean;
      serviceOwnerIsRequestContactPerson: boolean;
      amount?: string | null;
      created: any;
      updated: any;
      version: number;
    }> | null;
    serviceUserOf?: Array<{
      __typename?: 'ServiceInstance';
      id: string;
      serviceName: string;
      sinr: string;
      created: any;
      updated: any;
      version: number;
    }> | null;
    companies: Array<{
      __typename?: 'Company';
      id: string;
      name: string;
      keycloakId: string;
      isOtpRequired?: boolean | null;
      features?: {
        __typename?: 'CompanyFeatures';
        isDcamEnabled?: boolean | null;
        isInvoiceEnabled?: boolean | null;
      } | null;
      organisations: Array<{
        __typename?: 'Organisation';
        id: string;
        name: string;
        customerNumber?: string | null;
        customerNumbers?: Array<string> | null;
        companyRegistrationNumber?: string | null;
        taxId?: string | null;
        dunsId?: string | null;
        street: string;
        postal: string;
        city: string;
        country: string;
        phone?: string | null;
        email: string;
        isDefaultInvoiceRecipient: boolean;
        created: any;
        updated: any;
        version: number;
        invoiceRecipient: {
          __typename?: 'Address';
          id: string;
          companyName?: string | null;
          street?: string | null;
          postal?: string | null;
          country: string;
          city: string;
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
          organisationalUnit?: string | null;
          addressAddition?: string | null;
        };
        invoiceRecipients: Array<{
          __typename?: 'Address';
          id: string;
          companyName?: string | null;
          street?: string | null;
          postal?: string | null;
          country: string;
          city: string;
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
          organisationalUnit?: string | null;
          addressAddition?: string | null;
        }>;
      }>;
    }>;
  };
};

export type PolyServiceAccountFragmentFragment = {
  __typename?: 'PolyServiceAccount';
  id: string;
  groups?: Array<string> | null;
  credentials?: Array<string> | null;
  service?: ServiceType | null;
};

export type S3ServiceAccountFragmentFragment = {
  __typename?: 'S3ServiceAccount';
  id: string;
  credentials?: Array<string> | null;
  service?: ServiceType | null;
  serviceInstanceId?: string | null;
  identifier?: string | null;
};

export type CreateOneS3ServiceAccountMutationVariables = Exact<{
  input: CreateOneS3ServiceAccountInput;
}>;

export type CreateOneS3ServiceAccountMutation = {
  __typename?: 'Mutation';
  createOneS3ServiceAccount: {
    __typename?: 'S3ServiceAccount';
    id: string;
    credentials?: Array<string> | null;
    service?: ServiceType | null;
    serviceInstanceId?: string | null;
    identifier?: string | null;
  };
};

export type UpdateOneS3ServiceAccountMutationVariables = Exact<{
  input: UpdateOneS3ServiceAccountInput;
}>;

export type UpdateOneS3ServiceAccountMutation = {
  __typename?: 'Mutation';
  updateOneS3ServiceAccount: {
    __typename?: 'S3ServiceAccount';
    id: string;
    credentials?: Array<string> | null;
    service?: ServiceType | null;
    serviceInstanceId?: string | null;
    identifier?: string | null;
  };
};

export type DeleteOneS3ServiceAccountMutationVariables = Exact<{
  input: DeleteOneS3ServiceAccountInput;
}>;

export type DeleteOneS3ServiceAccountMutation = {
  __typename?: 'Mutation';
  deleteOneS3ServiceAccount: { __typename?: 'S3ServiceAccountDeleteResponse'; id?: string | null };
};

export type S3ServiceAccountsQueryVariables = Exact<{
  serviceInstanceId?: InputMaybe<Scalars['String']['input']>;
}>;

export type S3ServiceAccountsQuery = {
  __typename?: 'Query';
  s3ServiceAccounts: {
    __typename?: 'S3ServiceAccountConnection';
    edges: Array<{
      __typename?: 'S3ServiceAccountEdge';
      node: {
        __typename?: 'S3ServiceAccount';
        id: string;
        credentials?: Array<string> | null;
        service?: ServiceType | null;
        serviceInstanceId?: string | null;
        identifier?: string | null;
      };
    }>;
  };
};

export type ServiceUserFragmentFragment = {
  __typename?: 'ServiceUser';
  id: string;
  role: ServiceUserRole;
  created: any;
  updated: any;
  person: {
    __typename?: 'Person';
    id: string;
    email: string;
    firstName?: string | null;
    companyName?: string | null;
    mobilePhone?: string | null;
    phone?: string | null;
    lastName?: string | null;
    gender?: Gender | null;
    updated?: any | null;
    created?: any | null;
    version?: number | null;
    keycloakUser?: string | null;
    isKeycloakVerified?: boolean | null;
    keycloakRoles?: Array<string> | null;
    technicalAccountNotifications?: boolean | null;
    technicalUpdateNotifications?: boolean | null;
    newsletter?: boolean | null;
    serviceUserGroups?: { __typename?: 'ServiceUserGroup'; data?: any | null } | null;
  };
};

export type ServiceUsersQueryVariables = Exact<{
  filter?: InputMaybe<ServiceUserFilter>;
  sorting?: InputMaybe<Array<ServiceUserSort> | ServiceUserSort>;
}>;

export type ServiceUsersQuery = {
  __typename?: 'Query';
  serviceUsers: {
    __typename?: 'ServiceUserConnection';
    edges: Array<{
      __typename?: 'ServiceUserEdge';
      node: {
        __typename?: 'ServiceUser';
        id: string;
        role: ServiceUserRole;
        created: any;
        updated: any;
        person: {
          __typename?: 'Person';
          id: string;
          email: string;
          firstName?: string | null;
          companyName?: string | null;
          mobilePhone?: string | null;
          phone?: string | null;
          lastName?: string | null;
          gender?: Gender | null;
          updated?: any | null;
          created?: any | null;
          version?: number | null;
          keycloakUser?: string | null;
          isKeycloakVerified?: boolean | null;
          keycloakRoles?: Array<string> | null;
          technicalAccountNotifications?: boolean | null;
          technicalUpdateNotifications?: boolean | null;
          newsletter?: boolean | null;
          serviceUserGroups?: { __typename?: 'ServiceUserGroup'; data?: any | null } | null;
        };
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage?: boolean | null;
      hasPreviousPage?: boolean | null;
      startCursor?: any | null;
      endCursor?: any | null;
    };
  };
};

export type UpdateConfigurationMutationVariables = Exact<{
  input: UpdateOneRackifyConfigurationInput;
}>;

export type UpdateConfigurationMutation = {
  __typename?: 'Mutation';
  updateOneRackifyConfiguration: {
    __typename?: 'RackifyConfiguration';
    id: string;
    version?: number | null;
    created?: any | null;
    contact?: any | null;
    additionalServicesMap?: any | null;
    additionalServices?: any | null;
    price?: number | null;
    priceMonthly?: number | null;
    services: Array<{
      __typename?: 'RackifyConfiguredService';
      id: string;
      options?: any | null;
      fixedOptions?: any | null;
      variantOptions?: any | null;
      variantOption?: string | null;
      price?: number | null;
      priceMonthly?: number | null;
      priceOnRequest?: boolean | null;
      amount?: number | null;
      amountRacks?: number | null;
      version?: number | null;
      created?: any | null;
    }>;
  };
};

export type CreateConfigurationMutationVariables = Exact<{
  input: CreateOneRackifyConfigurationInput;
}>;

export type CreateConfigurationMutation = {
  __typename?: 'Mutation';
  createOneRackifyConfiguration: { __typename?: 'RackifyConfiguration'; id: string };
};

export type CreateConfiguredServiceMutationVariables = Exact<{
  input: CreateOneRackifyConfiguredServiceInput;
}>;

export type CreateConfiguredServiceMutation = {
  __typename?: 'Mutation';
  createOneRackifyConfiguredService: {
    __typename?: 'RackifyConfiguredService';
    id: string;
    options?: any | null;
    fixedOptions?: any | null;
    variantOptions?: any | null;
    variantOption?: string | null;
    price?: number | null;
    priceMonthly?: number | null;
    priceOnRequest?: boolean | null;
    amount?: number | null;
    amountRacks?: number | null;
    version?: number | null;
    created?: any | null;
    configuration: {
      __typename?: 'RackifyConfiguration';
      id: string;
      price?: number | null;
      priceMonthly?: number | null;
    };
  };
};

export type DeleteConfiguredServiceMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteConfiguredServiceMutation = {
  __typename?: 'Mutation';
  deleteOneRackifyConfiguredService: { __typename?: 'RackifyConfiguredServiceDeleteResponse'; id?: string | null };
};

export type RackifyConfigurationQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type RackifyConfigurationQuery = {
  __typename?: 'Query';
  rackifyConfiguration: {
    __typename?: 'RackifyConfiguration';
    id: string;
    version?: number | null;
    created?: any | null;
    contact?: any | null;
    additionalServicesMap?: any | null;
    additionalServices?: any | null;
    price?: number | null;
    priceMonthly?: number | null;
    services: Array<{
      __typename?: 'RackifyConfiguredService';
      id: string;
      options?: any | null;
      fixedOptions?: any | null;
      variantOptions?: any | null;
      variantOption?: string | null;
      price?: number | null;
      priceMonthly?: number | null;
      priceOnRequest?: boolean | null;
      amount?: number | null;
      amountRacks?: number | null;
      version?: number | null;
      created?: any | null;
    }>;
  };
};

export type RootQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type RootQuery = {
  __typename?: 'Query';
  rackifyConfiguration: {
    __typename?: 'RackifyConfiguration';
    id: string;
    version?: number | null;
    created?: any | null;
    contact?: any | null;
    additionalServicesMap?: any | null;
    additionalServices?: any | null;
    price?: number | null;
    priceMonthly?: number | null;
    services: Array<{
      __typename?: 'RackifyConfiguredService';
      id: string;
      options?: any | null;
      fixedOptions?: any | null;
      variantOptions?: any | null;
      variantOption?: string | null;
      price?: number | null;
      priceMonthly?: number | null;
      priceOnRequest?: boolean | null;
      amount?: number | null;
      amountRacks?: number | null;
      version?: number | null;
      created?: any | null;
    }>;
  };
  serviceOptions: {
    __typename?: 'RackifyVariantOptions';
    optionsMap: any;
    optionsList: Array<{ __typename?: 'RackifyVariantOption'; name: string; values: Array<string> }>;
  };
  additionalServiceOptions: {
    __typename?: 'RackifyVariantAdditionals';
    optionsMap: any;
    optionsList: Array<{
      __typename?: 'RackifyVariantAdditional';
      name: string;
      options: Array<{ __typename?: 'RackifyVariantOption'; name: string; values: Array<string> }>;
    }>;
  };
};

export type ServiceOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type ServiceOptionsQuery = {
  __typename?: 'Query';
  serviceOptions: {
    __typename?: 'RackifyVariantOptions';
    optionsMap: any;
    optionsList: Array<{ __typename?: 'RackifyVariantOption'; name: string; values: Array<string> }>;
  };
};

export type AdditionalServiceOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type AdditionalServiceOptionsQuery = {
  __typename?: 'Query';
  additionalServiceOptions: {
    __typename?: 'RackifyVariantAdditionals';
    optionsMap: any;
    optionsList: Array<{
      __typename?: 'RackifyVariantAdditional';
      name: string;
      options: Array<{ __typename?: 'RackifyVariantOption'; name: string; values: Array<string> }>;
    }>;
  };
};

export const IpWhitelistFragmentFragmentDoc = gql`
  fragment IpWhitelistFragment on IpWhitelist {
    id
    ips
  }
`;
export const ProjectSetupFragmentFragmentDoc = gql`
  fragment ProjectSetupFragment on ProjectSetup {
    privateSubnet
    sshKeyName
  }
`;
export const PersonFragmentPlainFragmentDoc = gql`
  fragment PersonFragmentPlain on Person {
    id
    email
    firstName
    companyName
    mobilePhone
    phone
    lastName
    gender
    updated
    created
    version
    keycloakUser
    serviceUserGroups {
      data
    }
    isKeycloakVerified
    keycloakRoles
    technicalAccountNotifications
    technicalUpdateNotifications
    newsletter
  }
`;
export const ServiceUserFragmentFragmentDoc = gql`
  fragment ServiceUserFragment on ServiceUser {
    id
    role
    created
    updated
    person {
      ...PersonFragmentPlain
    }
  }
  ${PersonFragmentPlainFragmentDoc}
`;
export const OpencloudProjectFragmentFragmentDoc = gql`
  fragment OpencloudProjectFragment on OpencloudProject {
    id
    name
    provisionStatus
    setup {
      ...ProjectSetupFragment
    }
    created
    updated
    serviceUsers {
      ...ServiceUserFragment
    }
  }
  ${ProjectSetupFragmentFragmentDoc}
  ${ServiceUserFragmentFragmentDoc}
`;
export const BillingAddressFragmentFragmentDoc = gql`
  fragment BillingAddressFragment on BillingAddress {
    id
    serviceInstanceId
    addressId
    billingReferenceNo1
    billingReferenceNo2
    billingReferenceNo3
    address {
      id
      companyName
      street
      postal
      country
      city
      firstName
      lastName
      email
    }
  }
`;
export const ServiceInstanceFragmentFragmentDoc = gql`
  fragment ServiceInstanceFragment on ServiceInstance {
    id
    serviceName
    serviceGroup
    serviceType
    articleDescription
    ptid
    sinr
    customerNumber
    subServiceText
    location1
    location2
    contractStatus
    provisioningStatus
    startDate
    endDate
    minimumContractPeriod
    details
    serviceOwnerIsContactPerson
    serviceOwnerIsRequestContactPerson
    serviceInstanceURL
    serviceInstanceAdditionalURL
    provisioningDate
    amount
    created
    updated
    version
    organisation {
      id
      customerNumber
      customerNumbers
      company {
        id
        name
      }
    }
    serviceUsers {
      ...PersonFragmentPlain
    }
    serviceOwners {
      ...PersonFragmentPlain
    }
    contactPeople {
      id
      contactPersonFor
      note
      person {
        ...PersonFragmentPlain
      }
    }
    invoiceRecipient {
      ...BillingAddressFragment
    }
  }
  ${PersonFragmentPlainFragmentDoc}
  ${BillingAddressFragmentFragmentDoc}
`;
export const DcamAccessFragmentDoc = gql`
  fragment DcamAccess on DcamAccess {
    id
    accessType
    note
    name
    created
    updated
    version
  }
`;
export const DcamContactFragmentDoc = gql`
  fragment DcamContact on DcamContact {
    id
    firstName
    lastName
    gender
    birthday
    email
    phone
    note
    company
    changedBy
    created
    updated
    version
  }
`;
export const DcamFragmentDoc = gql`
  fragment Dcam on Dcam {
    id
    companyId
    created
    updated
    version
    serviceOwners {
      id
      firstName
      lastName
      email
    }
    access {
      ...DcamAccess
      keySet {
        id
        keyId
        serviceInfos {
          dc
          loc1
          loc2
          loc3
          loc4
          loc5
          serviceNumber
        }
      }
      contacts {
        ...DcamContact
      }
    }
  }
  ${DcamAccessFragmentDoc}
  ${DcamContactFragmentDoc}
`;
export const PageInfoFragmentFragmentDoc = gql`
  fragment PageInfoFragment on PageInfo {
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
  }
`;
export const OffsetPageInfoFragmentFragmentDoc = gql`
  fragment OffsetPageInfoFragment on OffsetPageInfo {
    hasNextPage
    hasPreviousPage
  }
`;
export const AddressFragmentFragmentDoc = gql`
  fragment AddressFragment on Address {
    id
    city
    street
    postal
    companyName
    email
    firstName
    lastName
    country
    organisationalUnit
    addressAddition
  }
`;
export const OrganisationFragmentFragmentDoc = gql`
  fragment OrganisationFragment on Organisation {
    id
    name
    customerNumber
    customerNumbers
    companyRegistrationNumber
    taxId
    dunsId
    street
    postal
    city
    country
    phone
    email
    isDefaultInvoiceRecipient
    created
    updated
    version
    invoiceRecipient {
      id
      companyName
      street
      postal
      country
      city
      firstName
      lastName
      email
      organisationalUnit
      addressAddition
    }
    invoiceRecipients {
      id
      companyName
      street
      postal
      country
      city
      firstName
      lastName
      email
      organisationalUnit
      addressAddition
    }
  }
`;
export const PersonFragmentFragmentDoc = gql`
  fragment PersonFragment on Person {
    id
    email
    firstName
    companyName
    mobilePhone
    phone
    lastName
    gender
    updated
    created
    version
    isRoot
    keycloakUser
    isKeycloakVerified
    keycloakRoles
    technicalAccountNotifications
    technicalUpdateNotifications
    newsletter
    contactPersonOf {
      id
      contactPersonFor
      serviceInstanceId
    }
    serviceUserGroups {
      data
    }
    serviceOwnerOf {
      id
      serviceName
      sinr
      subServiceText
      location1
      location2
      contractStatus
      provisioningStatus
      startDate
      endDate
      details
      serviceOwnerIsContactPerson
      serviceOwnerIsRequestContactPerson
      amount
      created
      updated
      version
    }
    serviceUserOf {
      id
      serviceName
      sinr
      created
      updated
      version
    }
    companies {
      id
      name
      keycloakId
      isOtpRequired
      features {
        isDcamEnabled
        isInvoiceEnabled
      }
      organisations {
        ...OrganisationFragment
      }
    }
  }
  ${OrganisationFragmentFragmentDoc}
`;
export const PolyServiceAccountFragmentFragmentDoc = gql`
  fragment PolyServiceAccountFragment on PolyServiceAccount {
    id
    groups
    credentials
    service
  }
`;
export const S3ServiceAccountFragmentFragmentDoc = gql`
  fragment S3ServiceAccountFragment on S3ServiceAccount {
    id
    credentials
    service
    serviceInstanceId
    identifier
  }
`;
export const CreateOneBillingAddressDocument = gql`
  mutation createOneBillingAddress($input: CreateOneBillingAddressInput!) {
    createOneBillingAddress(input: $input) {
      ...BillingAddressFragment
    }
  }
  ${BillingAddressFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root'
})
export class CreateOneBillingAddressGQL extends Apollo.Mutation<
  CreateOneBillingAddressMutation,
  CreateOneBillingAddressMutationVariables
> {
  override document = CreateOneBillingAddressDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateOneBillingAddressDocument = gql`
  mutation updateOneBillingAddress($input: UpdateOneBillingAddressInput!) {
    updateOneBillingAddress(input: $input) {
      ...BillingAddressFragment
    }
  }
  ${BillingAddressFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root'
})
export class UpdateOneBillingAddressGQL extends Apollo.Mutation<
  UpdateOneBillingAddressMutation,
  UpdateOneBillingAddressMutationVariables
> {
  override document = UpdateOneBillingAddressDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateOneIpWhitelistDocument = gql`
  mutation updateOneIpWhitelist($input: UpdateOneIpWhitelistInput!) {
    updateOneIpWhitelist(input: $input) {
      ...IpWhitelistFragment
    }
  }
  ${IpWhitelistFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root'
})
export class UpdateOneIpWhitelistGQL extends Apollo.Mutation<
  UpdateOneIpWhitelistMutation,
  UpdateOneIpWhitelistMutationVariables
> {
  override document = UpdateOneIpWhitelistDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const IpWhitelistsDocument = gql`
  query ipWhitelists($filter: IpWhitelistFilter, $sorting: [IpWhitelistSort!]) {
    ipWhitelists(filter: $filter, sorting: $sorting) {
      edges {
        node {
          ...IpWhitelistFragment
        }
      }
    }
  }
  ${IpWhitelistFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root'
})
export class IpWhitelistsGQL extends Apollo.Query<IpWhitelistsQuery, IpWhitelistsQueryVariables> {
  override document = IpWhitelistsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateOneOpencloudProjectDocument = gql`
  mutation updateOneOpencloudProject($input: UpdateOneOpencloudProjectInput!) {
    updateOneOpencloudProject(input: $input) {
      ...OpencloudProjectFragment
    }
  }
  ${OpencloudProjectFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root'
})
export class UpdateOneOpencloudProjectGQL extends Apollo.Mutation<
  UpdateOneOpencloudProjectMutation,
  UpdateOneOpencloudProjectMutationVariables
> {
  override document = UpdateOneOpencloudProjectDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateOneOpencloudProjectDocument = gql`
  mutation createOneOpencloudProject($input: CreateOneOpencloudProjectInput!) {
    createOneOpencloudProject(input: $input) {
      ...OpencloudProjectFragment
    }
  }
  ${OpencloudProjectFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root'
})
export class CreateOneOpencloudProjectGQL extends Apollo.Mutation<
  CreateOneOpencloudProjectMutation,
  CreateOneOpencloudProjectMutationVariables
> {
  override document = CreateOneOpencloudProjectDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DeleteOneOpencloudProjectDocument = gql`
  mutation deleteOneOpencloudProject($input: DeleteOneOpencloudProjectInput!) {
    deleteOneOpencloudProject(input: $input) {
      id
      deleted
    }
  }
`;

@Injectable({
  providedIn: 'root'
})
export class DeleteOneOpencloudProjectGQL extends Apollo.Mutation<
  DeleteOneOpencloudProjectMutation,
  DeleteOneOpencloudProjectMutationVariables
> {
  override document = DeleteOneOpencloudProjectDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const OpencloudProjectsDocument = gql`
  query opencloudProjects($filter: OpencloudProjectFilter, $sorting: [OpencloudProjectSort!]) {
    opencloudProjects(filter: $filter, sorting: $sorting) {
      nodes {
        ...OpencloudProjectFragment
      }
      pageInfo {
        ...OffsetPageInfoFragment
      }
    }
  }
  ${OpencloudProjectFragmentFragmentDoc}
  ${OffsetPageInfoFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root'
})
export class OpencloudProjectsGQL extends Apollo.Query<OpencloudProjectsQuery, OpencloudProjectsQueryVariables> {
  override document = OpencloudProjectsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AddServiceOwnerOfToPersonDocument = gql`
  mutation addServiceOwnerOfToPerson($id: ID!, $serviceInstanceIds: [ID!]!) {
    addServiceOwnerOfToPerson(input: { id: $id, relationIds: $serviceInstanceIds }) {
      id
    }
  }
`;

@Injectable({
  providedIn: 'root'
})
export class AddServiceOwnerOfToPersonGQL extends Apollo.Mutation<
  AddServiceOwnerOfToPersonMutation,
  AddServiceOwnerOfToPersonMutationVariables
> {
  override document = AddServiceOwnerOfToPersonDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AddServiceUserOfToPersonDocument = gql`
  mutation addServiceUserOfToPerson($id: ID!, $serviceInstanceIds: [ID!]!) {
    addServiceUserOfToPerson(input: { id: $id, relationIds: $serviceInstanceIds }) {
      id
    }
  }
`;

@Injectable({
  providedIn: 'root'
})
export class AddServiceUserOfToPersonGQL extends Apollo.Mutation<
  AddServiceUserOfToPersonMutation,
  AddServiceUserOfToPersonMutationVariables
> {
  override document = AddServiceUserOfToPersonDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const RemoveServiceUserOfFromPersonDocument = gql`
  mutation removeServiceUserOfFromPerson($id: ID!, $serviceInstanceIds: [ID!]!) {
    removeServiceUserOfFromPerson(input: { id: $id, relationIds: $serviceInstanceIds }) {
      id
    }
  }
`;

@Injectable({
  providedIn: 'root'
})
export class RemoveServiceUserOfFromPersonGQL extends Apollo.Mutation<
  RemoveServiceUserOfFromPersonMutation,
  RemoveServiceUserOfFromPersonMutationVariables
> {
  override document = RemoveServiceUserOfFromPersonDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const RemoveServiceOwnerOfFromPersonDocument = gql`
  mutation removeServiceOwnerOfFromPerson($id: ID!, $serviceInstanceIds: [ID!]!) {
    removeServiceOwnerOfFromPerson(input: { id: $id, relationIds: $serviceInstanceIds }) {
      id
    }
  }
`;

@Injectable({
  providedIn: 'root'
})
export class RemoveServiceOwnerOfFromPersonGQL extends Apollo.Mutation<
  RemoveServiceOwnerOfFromPersonMutation,
  RemoveServiceOwnerOfFromPersonMutationVariables
> {
  override document = RemoveServiceOwnerOfFromPersonDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateOneContactPersonDocument = gql`
  mutation createOneContactPerson($contactPerson: CreateContactPersonInput!) {
    createOneContactPerson(input: { contactPerson: $contactPerson }) {
      id
    }
  }
`;

@Injectable({
  providedIn: 'root'
})
export class CreateOneContactPersonGQL extends Apollo.Mutation<
  CreateOneContactPersonMutation,
  CreateOneContactPersonMutationVariables
> {
  override document = CreateOneContactPersonDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateOneContactPersonDocument = gql`
  mutation updateOneContactPerson($input: UpdateOneContactPersonInput!) {
    updateOneContactPerson(input: $input) {
      note
    }
  }
`;

@Injectable({
  providedIn: 'root'
})
export class UpdateOneContactPersonGQL extends Apollo.Mutation<
  UpdateOneContactPersonMutation,
  UpdateOneContactPersonMutationVariables
> {
  override document = UpdateOneContactPersonDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DeleteOneContactPersonDocument = gql`
  mutation deleteOneContactPerson($input: DeleteOneContactPersonInput!) {
    deleteOneContactPerson(input: $input) {
      id
    }
  }
`;

@Injectable({
  providedIn: 'root'
})
export class DeleteOneContactPersonGQL extends Apollo.Mutation<
  DeleteOneContactPersonMutation,
  DeleteOneContactPersonMutationVariables
> {
  override document = DeleteOneContactPersonDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateOneServiceInstanceDocument = gql`
  mutation updateOneServiceInstance($input: UpdateOneServiceInstanceInput!) {
    updateOneServiceInstance(input: $input) {
      ...ServiceInstanceFragment
    }
  }
  ${ServiceInstanceFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root'
})
export class UpdateOneServiceInstanceGQL extends Apollo.Mutation<
  UpdateOneServiceInstanceMutation,
  UpdateOneServiceInstanceMutationVariables
> {
  override document = UpdateOneServiceInstanceDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ServiceInstancesDocument = gql`
  query serviceInstances($filter: ServiceInstanceFilter, $sorting: [ServiceInstanceSort!]) {
    serviceInstances(filter: $filter, sorting: $sorting) {
      edges {
        node {
          ...ServiceInstanceFragment
        }
      }
    }
  }
  ${ServiceInstanceFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root'
})
export class ServiceInstancesGQL extends Apollo.Query<ServiceInstancesQuery, ServiceInstancesQueryVariables> {
  override document = ServiceInstancesDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateOneDcamContactDocument = gql`
  mutation CreateOneDcamContact($input: CreateOneDcamContactInput!) {
    createOneDcamContact(input: $input) {
      ...DcamContact
      dcamAccess {
        id
        contacts {
          id
        }
      }
    }
  }
  ${DcamContactFragmentDoc}
`;

@Injectable({
  providedIn: 'root'
})
export class CreateOneDcamContactGQL extends Apollo.Mutation<
  CreateOneDcamContactMutation,
  CreateOneDcamContactMutationVariables
> {
  override document = CreateOneDcamContactDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateOneDcamContactDocument = gql`
  mutation UpdateOneDcamContact($input: UpdateOneDcamContactInput!) {
    updateOneDcamContact(input: $input) {
      ...DcamContact
      dcamAccess {
        id
        contacts {
          id
        }
      }
    }
  }
  ${DcamContactFragmentDoc}
`;

@Injectable({
  providedIn: 'root'
})
export class UpdateOneDcamContactGQL extends Apollo.Mutation<
  UpdateOneDcamContactMutation,
  UpdateOneDcamContactMutationVariables
> {
  override document = UpdateOneDcamContactDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DeleteOneDcamContactDocument = gql`
  mutation DeleteOneDcamContact($input: DeleteOneDcamContactInput!) {
    deleteOneDcamContact(input: $input) {
      id
    }
  }
`;

@Injectable({
  providedIn: 'root'
})
export class DeleteOneDcamContactGQL extends Apollo.Mutation<
  DeleteOneDcamContactMutation,
  DeleteOneDcamContactMutationVariables
> {
  override document = DeleteOneDcamContactDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateOneDcamAccessDocument = gql`
  mutation UpdateOneDcamAccess($input: UpdateOneDcamAccessInput!) {
    updateOneDcamAccess(input: $input) {
      ...DcamAccess
    }
  }
  ${DcamAccessFragmentDoc}
`;

@Injectable({
  providedIn: 'root'
})
export class UpdateOneDcamAccessGQL extends Apollo.Mutation<
  UpdateOneDcamAccessMutation,
  UpdateOneDcamAccessMutationVariables
> {
  override document = UpdateOneDcamAccessDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AddServiceOwnersToDcamDocument = gql`
  mutation AddServiceOwnersToDcam($input: AddServiceOwnersToDcamInput!) {
    addServiceOwnersToDcam(input: $input) {
      ...Dcam
    }
  }
  ${DcamFragmentDoc}
`;

@Injectable({
  providedIn: 'root'
})
export class AddServiceOwnersToDcamGQL extends Apollo.Mutation<
  AddServiceOwnersToDcamMutation,
  AddServiceOwnersToDcamMutationVariables
> {
  override document = AddServiceOwnersToDcamDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const RemoveServiceOwnersFromDcamDocument = gql`
  mutation RemoveServiceOwnersFromDcam($input: RemoveServiceOwnersFromDcamInput!) {
    removeServiceOwnersFromDcam(input: $input) {
      ...Dcam
    }
  }
  ${DcamFragmentDoc}
`;

@Injectable({
  providedIn: 'root'
})
export class RemoveServiceOwnersFromDcamGQL extends Apollo.Mutation<
  RemoveServiceOwnersFromDcamMutation,
  RemoveServiceOwnersFromDcamMutationVariables
> {
  override document = RemoveServiceOwnersFromDcamDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateOneDcamDocument = gql`
  mutation UpdateOneDcam($input: UpdateOneDcamInput!) {
    updateOneDcam(input: $input) {
      ...Dcam
    }
  }
  ${DcamFragmentDoc}
`;

@Injectable({
  providedIn: 'root'
})
export class UpdateOneDcamGQL extends Apollo.Mutation<UpdateOneDcamMutation, UpdateOneDcamMutationVariables> {
  override document = UpdateOneDcamDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DcamDocument = gql`
  query dcam {
    dcams {
      ...Dcam
    }
  }
  ${DcamFragmentDoc}
`;

@Injectable({
  providedIn: 'root'
})
export class DcamGQL extends Apollo.Query<DcamQuery, DcamQueryVariables> {
  override document = DcamDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ToggleCompanyOtpRequirementDocument = gql`
  mutation toggleCompanyOTPRequirement {
    toggleCompanyOTPRequirement {
      companyId
      isOtpRequired
    }
  }
`;

@Injectable({
  providedIn: 'root'
})
export class ToggleCompanyOtpRequirementGQL extends Apollo.Mutation<
  ToggleCompanyOtpRequirementMutation,
  ToggleCompanyOtpRequirementMutationVariables
> {
  override document = ToggleCompanyOtpRequirementDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CompanyDocument = gql`
  query company($id: ID!) {
    company(id: $id) {
      id
      name
      isOtpRequired
      keycloakId
    }
  }
`;

@Injectable({
  providedIn: 'root'
})
export class CompanyGQL extends Apollo.Query<CompanyQuery, CompanyQueryVariables> {
  override document = CompanyDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateOneAddressDocument = gql`
  mutation createOneAddress($input: CreateOneAddressInput!) {
    createOneAddress(input: $input) {
      ...AddressFragment
    }
  }
  ${AddressFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root'
})
export class CreateOneAddressGQL extends Apollo.Mutation<CreateOneAddressMutation, CreateOneAddressMutationVariables> {
  override document = CreateOneAddressDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateOneOrganisationDocument = gql`
  mutation updateOneOrganisation($input: UpdateOneOrganisationInput!) {
    updateOneOrganisation(input: $input) {
      ...OrganisationFragment
    }
  }
  ${OrganisationFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root'
})
export class UpdateOneOrganisationGQL extends Apollo.Mutation<
  UpdateOneOrganisationMutation,
  UpdateOneOrganisationMutationVariables
> {
  override document = UpdateOneOrganisationDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const OrganisationsDocument = gql`
  query organisations($filter: OrganisationFilter) {
    organisations(filter: $filter) {
      edges {
        node {
          ...OrganisationFragment
        }
      }
    }
  }
  ${OrganisationFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root'
})
export class OrganisationsGQL extends Apollo.Query<OrganisationsQuery, OrganisationsQueryVariables> {
  override document = OrganisationsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const PeopleDocument = gql`
  query people {
    people(
      filter: { keycloakUser: { isNot: null } }
      sorting: [{ field: firstName, direction: ASC }, { field: lastName, direction: ASC }]
      paging: { first: 50 }
    ) {
      edges {
        node {
          ...PersonFragment
        }
      }
    }
  }
  ${PersonFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root'
})
export class PeopleGQL extends Apollo.Query<PeopleQuery, PeopleQueryVariables> {
  override document = PeopleDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdatedOnePersonDocument = gql`
  subscription updatedOnePerson($filter: PersonSubscriptionFilter!) {
    updatedOnePerson(input: { filter: $filter }) {
      id
      email
      firstName
      companies {
        id
        name
      }
      updated
      created
      version
    }
  }
`;

@Injectable({
  providedIn: 'root'
})
export class UpdatedOnePersonGQL extends Apollo.Subscription<
  UpdatedOnePersonSubscription,
  UpdatedOnePersonSubscriptionVariables
> {
  override document = UpdatedOnePersonDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateOnePersonDocument = gql`
  mutation updateOnePerson($input: UpdateOnePersonInput!) {
    updateOnePerson(input: $input) {
      ...PersonFragment
    }
  }
  ${PersonFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root'
})
export class UpdateOnePersonGQL extends Apollo.Mutation<UpdateOnePersonMutation, UpdateOnePersonMutationVariables> {
  override document = UpdateOnePersonDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateOneS3ServiceAccountDocument = gql`
  mutation createOneS3ServiceAccount($input: CreateOneS3ServiceAccountInput!) {
    createOneS3ServiceAccount(input: $input) {
      ...S3ServiceAccountFragment
    }
  }
  ${S3ServiceAccountFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root'
})
export class CreateOneS3ServiceAccountGQL extends Apollo.Mutation<
  CreateOneS3ServiceAccountMutation,
  CreateOneS3ServiceAccountMutationVariables
> {
  override document = CreateOneS3ServiceAccountDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateOneS3ServiceAccountDocument = gql`
  mutation updateOneS3ServiceAccount($input: UpdateOneS3ServiceAccountInput!) {
    updateOneS3ServiceAccount(input: $input) {
      ...S3ServiceAccountFragment
    }
  }
  ${S3ServiceAccountFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root'
})
export class UpdateOneS3ServiceAccountGQL extends Apollo.Mutation<
  UpdateOneS3ServiceAccountMutation,
  UpdateOneS3ServiceAccountMutationVariables
> {
  override document = UpdateOneS3ServiceAccountDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DeleteOneS3ServiceAccountDocument = gql`
  mutation deleteOneS3ServiceAccount($input: DeleteOneS3ServiceAccountInput!) {
    deleteOneS3ServiceAccount(input: $input) {
      id
    }
  }
`;

@Injectable({
  providedIn: 'root'
})
export class DeleteOneS3ServiceAccountGQL extends Apollo.Mutation<
  DeleteOneS3ServiceAccountMutation,
  DeleteOneS3ServiceAccountMutationVariables
> {
  override document = DeleteOneS3ServiceAccountDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const S3ServiceAccountsDocument = gql`
  query s3ServiceAccounts($serviceInstanceId: String) {
    s3ServiceAccounts(filter: { serviceInstanceId: { eq: $serviceInstanceId } }) {
      edges {
        node {
          ...S3ServiceAccountFragment
        }
      }
    }
  }
  ${S3ServiceAccountFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root'
})
export class S3ServiceAccountsGQL extends Apollo.Query<S3ServiceAccountsQuery, S3ServiceAccountsQueryVariables> {
  override document = S3ServiceAccountsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ServiceUsersDocument = gql`
  query serviceUsers($filter: ServiceUserFilter, $sorting: [ServiceUserSort!]) {
    serviceUsers(filter: $filter) {
      edges {
        node {
          ...ServiceUserFragment
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }
  ${ServiceUserFragmentFragmentDoc}
  ${PageInfoFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root'
})
export class ServiceUsersGQL extends Apollo.Query<ServiceUsersQuery, ServiceUsersQueryVariables> {
  override document = ServiceUsersDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateConfigurationDocument = gql`
  mutation updateConfiguration($input: UpdateOneRackifyConfigurationInput!) {
    updateOneRackifyConfiguration(input: $input) {
      id
      version
      created
      contact
      additionalServicesMap
      additionalServices
      price
      priceMonthly
      services(sorting: { field: created, direction: ASC }) {
        id
        options
        fixedOptions
        variantOptions
        variantOption
        price
        priceMonthly
        priceOnRequest
        amount
        amountRacks
        version
        created
      }
    }
  }
`;

@Injectable({
  providedIn: 'root'
})
export class UpdateConfigurationGQL extends Apollo.Mutation<
  UpdateConfigurationMutation,
  UpdateConfigurationMutationVariables
> {
  override document = UpdateConfigurationDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateConfigurationDocument = gql`
  mutation createConfiguration($input: CreateOneRackifyConfigurationInput!) {
    createOneRackifyConfiguration(input: $input) {
      id
    }
  }
`;

@Injectable({
  providedIn: 'root'
})
export class CreateConfigurationGQL extends Apollo.Mutation<
  CreateConfigurationMutation,
  CreateConfigurationMutationVariables
> {
  override document = CreateConfigurationDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateConfiguredServiceDocument = gql`
  mutation createConfiguredService($input: CreateOneRackifyConfiguredServiceInput!) {
    createOneRackifyConfiguredService(input: $input) {
      id
      options
      fixedOptions
      variantOptions
      variantOption
      price
      priceMonthly
      priceOnRequest
      amount
      amountRacks
      version
      created
      configuration {
        id
        price
        priceMonthly
      }
    }
  }
`;

@Injectable({
  providedIn: 'root'
})
export class CreateConfiguredServiceGQL extends Apollo.Mutation<
  CreateConfiguredServiceMutation,
  CreateConfiguredServiceMutationVariables
> {
  override document = CreateConfiguredServiceDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DeleteConfiguredServiceDocument = gql`
  mutation deleteConfiguredService($id: ID!) {
    deleteOneRackifyConfiguredService(input: { id: $id }) {
      id
    }
  }
`;

@Injectable({
  providedIn: 'root'
})
export class DeleteConfiguredServiceGQL extends Apollo.Mutation<
  DeleteConfiguredServiceMutation,
  DeleteConfiguredServiceMutationVariables
> {
  override document = DeleteConfiguredServiceDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const RackifyConfigurationDocument = gql`
  query rackifyConfiguration($id: ID!) {
    rackifyConfiguration(id: $id) {
      id
      version
      created
      contact
      additionalServicesMap
      additionalServices
      price
      priceMonthly
      services {
        id
        options
        fixedOptions
        variantOptions
        variantOption
        price
        priceMonthly
        priceOnRequest
        amount
        amountRacks
        version
        created
      }
    }
  }
`;

@Injectable({
  providedIn: 'root'
})
export class RackifyConfigurationGQL extends Apollo.Query<
  RackifyConfigurationQuery,
  RackifyConfigurationQueryVariables
> {
  override document = RackifyConfigurationDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const RootDocument = gql`
  query root($id: ID!) {
    rackifyConfiguration(id: $id) {
      id
      version
      created
      contact
      additionalServicesMap
      additionalServices
      price
      priceMonthly
      services(sorting: { field: created, direction: ASC }) {
        id
        options
        fixedOptions
        variantOptions
        variantOption
        price
        priceMonthly
        priceOnRequest
        amount
        amountRacks
        version
        created
      }
    }
    serviceOptions {
      optionsMap
      optionsList {
        name
        values
      }
    }
    additionalServiceOptions {
      optionsMap
      optionsList {
        name
        options {
          name
          values
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root'
})
export class RootGQL extends Apollo.Query<RootQuery, RootQueryVariables> {
  override document = RootDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ServiceOptionsDocument = gql`
  query serviceOptions {
    serviceOptions {
      optionsMap
      optionsList {
        name
        values
      }
    }
  }
`;

@Injectable({
  providedIn: 'root'
})
export class ServiceOptionsGQL extends Apollo.Query<ServiceOptionsQuery, ServiceOptionsQueryVariables> {
  override document = ServiceOptionsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AdditionalServiceOptionsDocument = gql`
  query additionalServiceOptions {
    additionalServiceOptions {
      optionsMap
      optionsList {
        name
        options {
          name
          values
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root'
})
export class AdditionalServiceOptionsGQL extends Apollo.Query<
  AdditionalServiceOptionsQuery,
  AdditionalServiceOptionsQueryVariables
> {
  override document = AdditionalServiceOptionsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

interface WatchQueryOptionsAlone<V> extends Omit<ApolloCore.WatchQueryOptions<V>, 'query' | 'variables'> {}

interface QueryOptionsAlone<V> extends Omit<ApolloCore.QueryOptions<V>, 'query' | 'variables'> {}

interface MutationOptionsAlone<T, V> extends Omit<ApolloCore.MutationOptions<T, V>, 'mutation' | 'variables'> {}

interface SubscriptionOptionsAlone<V> extends Omit<ApolloCore.SubscriptionOptions<V>, 'query' | 'variables'> {}

@Injectable({ providedIn: 'root' })
export class TxApi {
  constructor(
    private createOneBillingAddressGql: CreateOneBillingAddressGQL,
    private updateOneBillingAddressGql: UpdateOneBillingAddressGQL,
    private updateOneIpWhitelistGql: UpdateOneIpWhitelistGQL,
    private ipWhitelistsGql: IpWhitelistsGQL,
    private updateOneOpencloudProjectGql: UpdateOneOpencloudProjectGQL,
    private createOneOpencloudProjectGql: CreateOneOpencloudProjectGQL,
    private deleteOneOpencloudProjectGql: DeleteOneOpencloudProjectGQL,
    private opencloudProjectsGql: OpencloudProjectsGQL,
    private addServiceOwnerOfToPersonGql: AddServiceOwnerOfToPersonGQL,
    private addServiceUserOfToPersonGql: AddServiceUserOfToPersonGQL,
    private removeServiceUserOfFromPersonGql: RemoveServiceUserOfFromPersonGQL,
    private removeServiceOwnerOfFromPersonGql: RemoveServiceOwnerOfFromPersonGQL,
    private createOneContactPersonGql: CreateOneContactPersonGQL,
    private updateOneContactPersonGql: UpdateOneContactPersonGQL,
    private deleteOneContactPersonGql: DeleteOneContactPersonGQL,
    private updateOneServiceInstanceGql: UpdateOneServiceInstanceGQL,
    private serviceInstancesGql: ServiceInstancesGQL,
    private createOneDcamContactGql: CreateOneDcamContactGQL,
    private updateOneDcamContactGql: UpdateOneDcamContactGQL,
    private deleteOneDcamContactGql: DeleteOneDcamContactGQL,
    private updateOneDcamAccessGql: UpdateOneDcamAccessGQL,
    private addServiceOwnersToDcamGql: AddServiceOwnersToDcamGQL,
    private removeServiceOwnersFromDcamGql: RemoveServiceOwnersFromDcamGQL,
    private updateOneDcamGql: UpdateOneDcamGQL,
    private dcamGql: DcamGQL,
    private toggleCompanyOtpRequirementGql: ToggleCompanyOtpRequirementGQL,
    private companyGql: CompanyGQL,
    private createOneAddressGql: CreateOneAddressGQL,
    private updateOneOrganisationGql: UpdateOneOrganisationGQL,
    private organisationsGql: OrganisationsGQL,
    private peopleGql: PeopleGQL,
    private updatedOnePersonGql: UpdatedOnePersonGQL,
    private updateOnePersonGql: UpdateOnePersonGQL,
    private createOneS3ServiceAccountGql: CreateOneS3ServiceAccountGQL,
    private updateOneS3ServiceAccountGql: UpdateOneS3ServiceAccountGQL,
    private deleteOneS3ServiceAccountGql: DeleteOneS3ServiceAccountGQL,
    private s3ServiceAccountsGql: S3ServiceAccountsGQL,
    private serviceUsersGql: ServiceUsersGQL,
    private updateConfigurationGql: UpdateConfigurationGQL,
    private createConfigurationGql: CreateConfigurationGQL,
    private createConfiguredServiceGql: CreateConfiguredServiceGQL,
    private deleteConfiguredServiceGql: DeleteConfiguredServiceGQL,
    private rackifyConfigurationGql: RackifyConfigurationGQL,
    private rootGql: RootGQL,
    private serviceOptionsGql: ServiceOptionsGQL,
    private additionalServiceOptionsGql: AdditionalServiceOptionsGQL
  ) {}

  createOneBillingAddress(
    variables: CreateOneBillingAddressMutationVariables,
    options?: MutationOptionsAlone<CreateOneBillingAddressMutation, CreateOneBillingAddressMutationVariables>
  ) {
    return this.createOneBillingAddressGql.mutate(variables, options);
  }

  updateOneBillingAddress(
    variables: UpdateOneBillingAddressMutationVariables,
    options?: MutationOptionsAlone<UpdateOneBillingAddressMutation, UpdateOneBillingAddressMutationVariables>
  ) {
    return this.updateOneBillingAddressGql.mutate(variables, options);
  }

  updateOneIpWhitelist(
    variables: UpdateOneIpWhitelistMutationVariables,
    options?: MutationOptionsAlone<UpdateOneIpWhitelistMutation, UpdateOneIpWhitelistMutationVariables>
  ) {
    return this.updateOneIpWhitelistGql.mutate(variables, options);
  }

  ipWhitelists(variables?: IpWhitelistsQueryVariables, options?: QueryOptionsAlone<IpWhitelistsQueryVariables>) {
    return this.ipWhitelistsGql.fetch(variables, options);
  }

  ipWhitelistsWatch(
    variables?: IpWhitelistsQueryVariables,
    options?: WatchQueryOptionsAlone<IpWhitelistsQueryVariables>
  ) {
    return this.ipWhitelistsGql.watch(variables, options);
  }

  updateOneOpencloudProject(
    variables: UpdateOneOpencloudProjectMutationVariables,
    options?: MutationOptionsAlone<UpdateOneOpencloudProjectMutation, UpdateOneOpencloudProjectMutationVariables>
  ) {
    return this.updateOneOpencloudProjectGql.mutate(variables, options);
  }

  createOneOpencloudProject(
    variables: CreateOneOpencloudProjectMutationVariables,
    options?: MutationOptionsAlone<CreateOneOpencloudProjectMutation, CreateOneOpencloudProjectMutationVariables>
  ) {
    return this.createOneOpencloudProjectGql.mutate(variables, options);
  }

  deleteOneOpencloudProject(
    variables: DeleteOneOpencloudProjectMutationVariables,
    options?: MutationOptionsAlone<DeleteOneOpencloudProjectMutation, DeleteOneOpencloudProjectMutationVariables>
  ) {
    return this.deleteOneOpencloudProjectGql.mutate(variables, options);
  }

  opencloudProjects(
    variables?: OpencloudProjectsQueryVariables,
    options?: QueryOptionsAlone<OpencloudProjectsQueryVariables>
  ) {
    return this.opencloudProjectsGql.fetch(variables, options);
  }

  opencloudProjectsWatch(
    variables?: OpencloudProjectsQueryVariables,
    options?: WatchQueryOptionsAlone<OpencloudProjectsQueryVariables>
  ) {
    return this.opencloudProjectsGql.watch(variables, options);
  }

  addServiceOwnerOfToPerson(
    variables: AddServiceOwnerOfToPersonMutationVariables,
    options?: MutationOptionsAlone<AddServiceOwnerOfToPersonMutation, AddServiceOwnerOfToPersonMutationVariables>
  ) {
    return this.addServiceOwnerOfToPersonGql.mutate(variables, options);
  }

  addServiceUserOfToPerson(
    variables: AddServiceUserOfToPersonMutationVariables,
    options?: MutationOptionsAlone<AddServiceUserOfToPersonMutation, AddServiceUserOfToPersonMutationVariables>
  ) {
    return this.addServiceUserOfToPersonGql.mutate(variables, options);
  }

  removeServiceUserOfFromPerson(
    variables: RemoveServiceUserOfFromPersonMutationVariables,
    options?: MutationOptionsAlone<
      RemoveServiceUserOfFromPersonMutation,
      RemoveServiceUserOfFromPersonMutationVariables
    >
  ) {
    return this.removeServiceUserOfFromPersonGql.mutate(variables, options);
  }

  removeServiceOwnerOfFromPerson(
    variables: RemoveServiceOwnerOfFromPersonMutationVariables,
    options?: MutationOptionsAlone<
      RemoveServiceOwnerOfFromPersonMutation,
      RemoveServiceOwnerOfFromPersonMutationVariables
    >
  ) {
    return this.removeServiceOwnerOfFromPersonGql.mutate(variables, options);
  }

  createOneContactPerson(
    variables: CreateOneContactPersonMutationVariables,
    options?: MutationOptionsAlone<CreateOneContactPersonMutation, CreateOneContactPersonMutationVariables>
  ) {
    return this.createOneContactPersonGql.mutate(variables, options);
  }

  updateOneContactPerson(
    variables: UpdateOneContactPersonMutationVariables,
    options?: MutationOptionsAlone<UpdateOneContactPersonMutation, UpdateOneContactPersonMutationVariables>
  ) {
    return this.updateOneContactPersonGql.mutate(variables, options);
  }

  deleteOneContactPerson(
    variables: DeleteOneContactPersonMutationVariables,
    options?: MutationOptionsAlone<DeleteOneContactPersonMutation, DeleteOneContactPersonMutationVariables>
  ) {
    return this.deleteOneContactPersonGql.mutate(variables, options);
  }

  updateOneServiceInstance(
    variables: UpdateOneServiceInstanceMutationVariables,
    options?: MutationOptionsAlone<UpdateOneServiceInstanceMutation, UpdateOneServiceInstanceMutationVariables>
  ) {
    return this.updateOneServiceInstanceGql.mutate(variables, options);
  }

  serviceInstances(
    variables?: ServiceInstancesQueryVariables,
    options?: QueryOptionsAlone<ServiceInstancesQueryVariables>
  ) {
    return this.serviceInstancesGql.fetch(variables, options);
  }

  serviceInstancesWatch(
    variables?: ServiceInstancesQueryVariables,
    options?: WatchQueryOptionsAlone<ServiceInstancesQueryVariables>
  ) {
    return this.serviceInstancesGql.watch(variables, options);
  }

  createOneDcamContact(
    variables: CreateOneDcamContactMutationVariables,
    options?: MutationOptionsAlone<CreateOneDcamContactMutation, CreateOneDcamContactMutationVariables>
  ) {
    return this.createOneDcamContactGql.mutate(variables, options);
  }

  updateOneDcamContact(
    variables: UpdateOneDcamContactMutationVariables,
    options?: MutationOptionsAlone<UpdateOneDcamContactMutation, UpdateOneDcamContactMutationVariables>
  ) {
    return this.updateOneDcamContactGql.mutate(variables, options);
  }

  deleteOneDcamContact(
    variables: DeleteOneDcamContactMutationVariables,
    options?: MutationOptionsAlone<DeleteOneDcamContactMutation, DeleteOneDcamContactMutationVariables>
  ) {
    return this.deleteOneDcamContactGql.mutate(variables, options);
  }

  updateOneDcamAccess(
    variables: UpdateOneDcamAccessMutationVariables,
    options?: MutationOptionsAlone<UpdateOneDcamAccessMutation, UpdateOneDcamAccessMutationVariables>
  ) {
    return this.updateOneDcamAccessGql.mutate(variables, options);
  }

  addServiceOwnersToDcam(
    variables: AddServiceOwnersToDcamMutationVariables,
    options?: MutationOptionsAlone<AddServiceOwnersToDcamMutation, AddServiceOwnersToDcamMutationVariables>
  ) {
    return this.addServiceOwnersToDcamGql.mutate(variables, options);
  }

  removeServiceOwnersFromDcam(
    variables: RemoveServiceOwnersFromDcamMutationVariables,
    options?: MutationOptionsAlone<RemoveServiceOwnersFromDcamMutation, RemoveServiceOwnersFromDcamMutationVariables>
  ) {
    return this.removeServiceOwnersFromDcamGql.mutate(variables, options);
  }

  updateOneDcam(
    variables: UpdateOneDcamMutationVariables,
    options?: MutationOptionsAlone<UpdateOneDcamMutation, UpdateOneDcamMutationVariables>
  ) {
    return this.updateOneDcamGql.mutate(variables, options);
  }

  dcam(variables?: DcamQueryVariables, options?: QueryOptionsAlone<DcamQueryVariables>) {
    return this.dcamGql.fetch(variables, options);
  }

  dcamWatch(variables?: DcamQueryVariables, options?: WatchQueryOptionsAlone<DcamQueryVariables>) {
    return this.dcamGql.watch(variables, options);
  }

  toggleCompanyOtpRequirement(
    variables?: ToggleCompanyOtpRequirementMutationVariables,
    options?: MutationOptionsAlone<ToggleCompanyOtpRequirementMutation, ToggleCompanyOtpRequirementMutationVariables>
  ) {
    return this.toggleCompanyOtpRequirementGql.mutate(variables, options);
  }

  company(variables: CompanyQueryVariables, options?: QueryOptionsAlone<CompanyQueryVariables>) {
    return this.companyGql.fetch(variables, options);
  }

  companyWatch(variables: CompanyQueryVariables, options?: WatchQueryOptionsAlone<CompanyQueryVariables>) {
    return this.companyGql.watch(variables, options);
  }

  createOneAddress(
    variables: CreateOneAddressMutationVariables,
    options?: MutationOptionsAlone<CreateOneAddressMutation, CreateOneAddressMutationVariables>
  ) {
    return this.createOneAddressGql.mutate(variables, options);
  }

  updateOneOrganisation(
    variables: UpdateOneOrganisationMutationVariables,
    options?: MutationOptionsAlone<UpdateOneOrganisationMutation, UpdateOneOrganisationMutationVariables>
  ) {
    return this.updateOneOrganisationGql.mutate(variables, options);
  }

  organisations(variables?: OrganisationsQueryVariables, options?: QueryOptionsAlone<OrganisationsQueryVariables>) {
    return this.organisationsGql.fetch(variables, options);
  }

  organisationsWatch(
    variables?: OrganisationsQueryVariables,
    options?: WatchQueryOptionsAlone<OrganisationsQueryVariables>
  ) {
    return this.organisationsGql.watch(variables, options);
  }

  people(variables?: PeopleQueryVariables, options?: QueryOptionsAlone<PeopleQueryVariables>) {
    return this.peopleGql.fetch(variables, options);
  }

  peopleWatch(variables?: PeopleQueryVariables, options?: WatchQueryOptionsAlone<PeopleQueryVariables>) {
    return this.peopleGql.watch(variables, options);
  }

  updatedOnePerson(
    variables: UpdatedOnePersonSubscriptionVariables,
    options?: SubscriptionOptionsAlone<UpdatedOnePersonSubscriptionVariables>
  ) {
    return this.updatedOnePersonGql.subscribe(variables, options);
  }

  updateOnePerson(
    variables: UpdateOnePersonMutationVariables,
    options?: MutationOptionsAlone<UpdateOnePersonMutation, UpdateOnePersonMutationVariables>
  ) {
    return this.updateOnePersonGql.mutate(variables, options);
  }

  createOneS3ServiceAccount(
    variables: CreateOneS3ServiceAccountMutationVariables,
    options?: MutationOptionsAlone<CreateOneS3ServiceAccountMutation, CreateOneS3ServiceAccountMutationVariables>
  ) {
    return this.createOneS3ServiceAccountGql.mutate(variables, options);
  }

  updateOneS3ServiceAccount(
    variables: UpdateOneS3ServiceAccountMutationVariables,
    options?: MutationOptionsAlone<UpdateOneS3ServiceAccountMutation, UpdateOneS3ServiceAccountMutationVariables>
  ) {
    return this.updateOneS3ServiceAccountGql.mutate(variables, options);
  }

  deleteOneS3ServiceAccount(
    variables: DeleteOneS3ServiceAccountMutationVariables,
    options?: MutationOptionsAlone<DeleteOneS3ServiceAccountMutation, DeleteOneS3ServiceAccountMutationVariables>
  ) {
    return this.deleteOneS3ServiceAccountGql.mutate(variables, options);
  }

  s3ServiceAccounts(
    variables?: S3ServiceAccountsQueryVariables,
    options?: QueryOptionsAlone<S3ServiceAccountsQueryVariables>
  ) {
    return this.s3ServiceAccountsGql.fetch(variables, options);
  }

  s3ServiceAccountsWatch(
    variables?: S3ServiceAccountsQueryVariables,
    options?: WatchQueryOptionsAlone<S3ServiceAccountsQueryVariables>
  ) {
    return this.s3ServiceAccountsGql.watch(variables, options);
  }

  serviceUsers(variables?: ServiceUsersQueryVariables, options?: QueryOptionsAlone<ServiceUsersQueryVariables>) {
    return this.serviceUsersGql.fetch(variables, options);
  }

  serviceUsersWatch(
    variables?: ServiceUsersQueryVariables,
    options?: WatchQueryOptionsAlone<ServiceUsersQueryVariables>
  ) {
    return this.serviceUsersGql.watch(variables, options);
  }

  updateConfiguration(
    variables: UpdateConfigurationMutationVariables,
    options?: MutationOptionsAlone<UpdateConfigurationMutation, UpdateConfigurationMutationVariables>
  ) {
    return this.updateConfigurationGql.mutate(variables, options);
  }

  createConfiguration(
    variables: CreateConfigurationMutationVariables,
    options?: MutationOptionsAlone<CreateConfigurationMutation, CreateConfigurationMutationVariables>
  ) {
    return this.createConfigurationGql.mutate(variables, options);
  }

  createConfiguredService(
    variables: CreateConfiguredServiceMutationVariables,
    options?: MutationOptionsAlone<CreateConfiguredServiceMutation, CreateConfiguredServiceMutationVariables>
  ) {
    return this.createConfiguredServiceGql.mutate(variables, options);
  }

  deleteConfiguredService(
    variables: DeleteConfiguredServiceMutationVariables,
    options?: MutationOptionsAlone<DeleteConfiguredServiceMutation, DeleteConfiguredServiceMutationVariables>
  ) {
    return this.deleteConfiguredServiceGql.mutate(variables, options);
  }

  rackifyConfiguration(
    variables: RackifyConfigurationQueryVariables,
    options?: QueryOptionsAlone<RackifyConfigurationQueryVariables>
  ) {
    return this.rackifyConfigurationGql.fetch(variables, options);
  }

  rackifyConfigurationWatch(
    variables: RackifyConfigurationQueryVariables,
    options?: WatchQueryOptionsAlone<RackifyConfigurationQueryVariables>
  ) {
    return this.rackifyConfigurationGql.watch(variables, options);
  }

  root(variables: RootQueryVariables, options?: QueryOptionsAlone<RootQueryVariables>) {
    return this.rootGql.fetch(variables, options);
  }

  rootWatch(variables: RootQueryVariables, options?: WatchQueryOptionsAlone<RootQueryVariables>) {
    return this.rootGql.watch(variables, options);
  }

  serviceOptions(variables?: ServiceOptionsQueryVariables, options?: QueryOptionsAlone<ServiceOptionsQueryVariables>) {
    return this.serviceOptionsGql.fetch(variables, options);
  }

  serviceOptionsWatch(
    variables?: ServiceOptionsQueryVariables,
    options?: WatchQueryOptionsAlone<ServiceOptionsQueryVariables>
  ) {
    return this.serviceOptionsGql.watch(variables, options);
  }

  additionalServiceOptions(
    variables?: AdditionalServiceOptionsQueryVariables,
    options?: QueryOptionsAlone<AdditionalServiceOptionsQueryVariables>
  ) {
    return this.additionalServiceOptionsGql.fetch(variables, options);
  }

  additionalServiceOptionsWatch(
    variables?: AdditionalServiceOptionsQueryVariables,
    options?: WatchQueryOptionsAlone<AdditionalServiceOptionsQueryVariables>
  ) {
    return this.additionalServiceOptionsGql.watch(variables, options);
  }
}
