import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Pipe({
  name: 'errors'
})
export class ErrorsPipe implements PipeTransform {
  transform(errors: ValidationErrors, showErrors: number): string {
    let errorArray: Array<string> = [];

    for (const [key, value] of Object.entries(errors)) {
      switch (key) {
        case 'required':
          errorArray.push('Feld darf nicht leer sein.');
          break;
        case 'email':
          errorArray.push('Gebe eine valide E-Mail Adresse ein.');
          break;
        case 'inValidOptionSelected':
          errorArray.push('Diese Auswahl gibt es nicht mehr!');
          break;
        default:
          errorArray.push('Invalides Feld');
          break;
      }
    }

    let string = '';
    errorArray.forEach((error, index) => {
      if (index > 1) {
        string += ' ' + error;
      } else {
        string += error;
      }
    });
    return string;
  }
}
