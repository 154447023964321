import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { BehaviorSubject, Observable, combineLatest, first, map, of, startWith, switchMap, tap } from 'rxjs';

import { Validators } from '@angular/forms';
// import { CodeModel } from '@ngstack/code-editor';
import { ServiceInstance } from '@tx/api';
import { NotificationService, Option, inArrayAsyncValidator, inArrayValidator, jsonValidator } from '@tx/ui';
import { Bucket, ObjectStorageService } from '@tx/s3';
import { Router } from '@angular/router';

@Component({
  selector: 'tx-portal-buckets',
  templateUrl: './buckets.component.html',
  styleUrls: ['./buckets.component.scss']
})
export class BucketsComponent implements OnInit {
  @Input() serviceAccountOptions$!: Observable<Option[]>;
  @Input() bucketList$!: Observable<Bucket[]>;
  @Input() searchedBucketList$!: Observable<Bucket[]>;

  @Output() deleteBucket: EventEmitter<Bucket> = new EventEmitter();
  @Output() reloadBuckets: EventEmitter<Bucket> = new EventEmitter();
  @Output() createBucket: EventEmitter<any> = new EventEmitter();
  @Output() jumpToServiceAccountsTab: EventEmitter<any> = new EventEmitter();

  detailBucket$ = new BehaviorSubject<Bucket | null>(null);

  selectedBucket$: Observable<Bucket | null | undefined> = of(null);

  selectedBucketIsEmpty$: Observable<boolean> = this.detailBucket$.pipe(
    switchMap((bucket) => {
      if (!bucket) return of(false);
      if (!bucket.usage['rgw.main']) return of(true);
      return bucket.usage['rgw.main'].num_objects === 0 ? of(true) : of(false);
    })
  );

  @Input()
  serviceInstance!: ServiceInstance;

  constructor(
    private fb: FormBuilder,
    private s3Service: ObjectStorageService,
    private notificationService: NotificationService,
    private router: Router
  ) {}

  openModal(modalId: string) {
    this.router.navigate([], {
      queryParams: { modal: modalId },
      queryParamsHandling: 'merge'
    });
  }

  // createBucketForm!: FormGroup;

  bucketNameValidators: Array<{ description: string; regex: RegExp }> = [
    {
      description: 'Der Bucket Bezeichner muss zwischen 3 und 63 Zeichen lang sein',
      regex: /^.{3,63}$/
    },
    {
      description: 'Der Bucket Bezeichner dürfen nicht einer IP gleichen (z.B. 192.168.5.4)',
      regex: /^((?!\b((?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(?:(?<!\.)\b|\.)){4}).)*$/
    },
    {
      description: "Der Bucket Bezeichner dürfen nicht mit dem Suffix '-s3alias' enden",
      regex: /^.+(?<!-s3alias)$/
    },
    {
      description: "Der Bucket Bezeichner dürfen nicht mit dem Präfix 'xn--' starten",
      regex: /^(?!xn--).+$/
    },
    {
      description: 'Der Bucket Bezeichner darf nur Kleinbuchstaben, Zahlen, Punkte und Bindestrichen enthalten',
      regex: /^[a-z0-9.-]*$/
    }
  ];

  testRegExp(regex: RegExp, string: string | null): boolean {
    if (string) return regex.test(string);
    return false;
  }

  // testName(): boolean {
  //   let isValidArray: Array<boolean> = [];
  //   let string: string = this.createBucketForm.controls['name'].value
  //     ? this.createBucketForm.controls['name'].value
  //     : '';
  //   this.bucketNameValidators.forEach((validator) => {
  //     isValidArray.push(validator.regex.test(string));
  //   });
  //   return isValidArray.every((bool) => bool === true);
  // }

  searchForm = this.fb.group({
    searchterm: ['']
  });

  retentionModeOptions: string[] = ['COMPLIANCE', 'GOVERNANCE'];

  openDetailView(bucket: Bucket) {
    this.detailBucket$.next(bucket);
  }

  accessPolicyOptions: string[] = ['private', 'authenticated-read', 'public-read', 'public-read-write'];

  isBucketEmpty(bucket: Bucket): boolean {
    // TODO: check if bucket is empty
    return false;
  }

  deleteBucketWrapper(bucket: Bucket | null) {
    if (!bucket) return;
    this.deleteBucket.emit(bucket);
    this.isDeleteBucketModalOpen = false;
    this.detailBucket$.next(null);
  }

  isDeleteBucketModalOpen: boolean = false;

  // policyModel: CodeModel | null = null;

  ngOnInit(): void {
    this.searchedBucketList$ = combineLatest([
      this.bucketList$,
      this.searchForm.controls['searchterm'].valueChanges.pipe(startWith(''))
    ]).pipe(
      map(([buckets, searchterm]) => {
        if (searchterm === '' || searchterm === null) return buckets;
        return buckets.filter((bucket) => {
          return bucket.bucket.includes(`${searchterm.toLocaleLowerCase()}`);
        });
      }),
      map((buckets) => {
        return buckets.sort((a, b) => {
          if (a.bucket < b.bucket) return -1;
          if (a.bucket > b.bucket) return 1;
          return 0;
        });
      })
    );
    this.searchForm.patchValue({
      searchterm: null
    });

    this.selectedBucket$ = combineLatest([this.detailBucket$, this.bucketList$]).pipe(
      map(([bucket, buckets]) => {
        return buckets.find((b) => b.bucket === bucket?.bucket);
      })
    );
  }
}
