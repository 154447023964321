import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { map, Observable, zip } from 'rxjs';
import { PartyService } from './party/party.service';
import { isMobile } from '@tx/ui';
import { DcamService } from './dcam/dcam.service';

@Component({
  selector: 'tx-portal-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  // sidebar releated
  ngOnInit(): void {
    this.isSidebarCollapsed = localStorage.getItem('isSidebarCollapsed') === 'true' ? true : false;
    if (isMobile()) {
      this.isSidebarCollapsed = true;
    }
  }

  isSidebarCollapsed: boolean = false;
  setSidebarCollaped(event: boolean) {
    this.isSidebarCollapsed = event;
  }

  activeRoute?: string;

  profile$!: Observable<{
    firstName: string;
    lastName: string;
    companyName: string;
    email: string;
  }>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public partyService: PartyService,
    public dcamService: DcamService
  ) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.activeRoute = this.router.url;
      }
    });
    this.profile$ = zip(this.partyService.currentTenant$, this.partyService.person$).pipe(
      map(([company, person]) => {
        return {
          firstName: `${person.firstName}`,
          lastName: `${person.lastName}`,
          companyName: `${company}`,
          email: `${person.email}`
        };
      })
    );
  }

  onRouteChange(event: string) {
    this.activeRoute = `/${event}`;
    this.router.navigate([event]);
  }
}
