import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  Renderer2,
  ChangeDetectorRef,
  OnInit
} from '@angular/core';
import { map, Observable, tap } from 'rxjs';
import { ContextMenuItem } from '../../atoms/context-menu/context-menu.component';

@Component({
  selector: 'ui-nav-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('detailExpand', [
      state('void', style({ opacity: '0', height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('*', style({ opacity: '1', height: '*', visibility: 'visible' })),
      transition('void <=> *', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class NavProfileComponent implements OnInit {
  contextMenu$!: Observable<ContextMenuItem[][]>;

  contextMenuClick(event: string) {
    this.changeCompany.emit(event);
  }

  @Input()
  companies$!: Observable<{ id: string; name: string }[]>;

  @Input()
  collapsed = false;

  @Input()
  isActive = false;

  @Input()
  firstname = '';

  @Input()
  lastname = '';

  @Input()
  email = '';

  @Input()
  companyName?: string;

  @Output()
  cclick = new EventEmitter<Event>();

  @Output()
  changeCompany = new EventEmitter<string>();

  @ViewChild('contextMenu') elem!: ElementRef;

  constructor() {}

  clickCheck(e: Event) {
    if ((this.elem && !this.elem.nativeElement.contains(e.target)) || this.elem === undefined) {
      this.cclick.emit(e);
    }
  }

  fixCompanyName(name: string): string {
    if (name.endsWith(' Company')) return name.slice(0, -8);
    return name;
  }

  ngOnInit(): void {
    if (!this.firstname) this.firstname = 'Mein';
    if (!this.lastname) this.lastname = 'Profil';
    this.contextMenu$ = this.companies$.pipe(
      map((companies) => [
        companies.map(
          (company) =>
            ({
              label: this.fixCompanyName(company.name),
              icon: 'briefcase',
              event: company.id
            } as ContextMenuItem)
        )
      ])
    );
  }
}
