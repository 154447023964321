import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CustomCellRenderer } from '../base/custom-renderer.base';
import { format } from 'date-fns';
import parseISO from 'date-fns/parseISO';
@Component({
  selector: 'date-renderer',
  templateUrl: './date-renderer.component.html',
  styleUrls: ['./date-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateRendererComponent extends CustomCellRenderer {
  public get content() {
    if (this.data == undefined) {
      return 'Fehlerhaftes Datum';
    }
    try {
      const content = format(new Date(this.data), 'dd.MM.yyyy HH:mm');
      return content;
    } catch (e) {
      return 'Ungültiges Datum';
    }
  }
}
