<!-- <div class="flex items-center justify-center my-11">
  <hr class="w-full" />
  <span class="text-sm text-gray-300 absolute bg-[#f0f0f0] px-4">Zusätzliche Leistungen</span>
</div> -->

<div>
  <div class="rounded-2xl w-full bg-white relative">
    <ui-button
      [align]="'center'"
      [color]="'white'"
      [icon]="'arrow-long-left'"
      [isOnlyIcon]="true"
      [size]="'sm'"
      class="absolute -left-2 xl:left-8 top-1/2 -translate-y-1/2 z-10"
      (cclick)="prevClick()"
    ></ui-button>
    <!-- <div class="absolute right-8 bottom-8 flex gap-2 z-10">
      <div
        *ngFor="let service of services; let i = index"
        class="rounded-full h-1.5 transition-all ease-in-out duration-700 bg-white/25 relative overflow-hidden border border-white/50"
        [ngClass]="active === i ? 'w-64' : 'w-1.5'"
      >
        <div
          *ngIf="active === i"
          class="absolute h-full left-0 ease-linear transition-all duration-[10000ms] bg-white"
          [ngClass]="[currentState === '' ? 'w-full' : 'w-0']"
        ></div>
      </div>
    </div> -->
    <div
      class="relative rounded-xl w-full h-full transform-gpu duration-500 ease-in-out transition-all"
      [ngClass]="[
        currentState === 'out' ? 'scale-95 opacity-0' : '',
        currentState === 'in' ? 'scale-100 opacity-100' : ''
      ]"
    >
      <div class="absolute inset-0">
        <img src="/assets/backgrounds/servers.webp" class="w-full h-full object-cover rounded-3xl" />
      </div>
      <div class="absolute inset-0 bg-black/50 rounded-3xl"></div>
      <div
        class="absolute inset-0 bg-gradient-to-r from-gray-100 xl:to-transparent to-gray-100/70 via-gray-100/90 rounded-l-3xl"
      ></div>
      <div
        class="relative w-full h-full py-8 px-12 xl:px-0 rounded-2xl ring-4 ring-white ring-offset-0 ring-inset grid gird-cols-1 xl:grid-cols-2 gap-8 xl:gap-32"
      >
        <div class="flex flex-col items-start justify-center pl-0 xl:pl-[25%]">
          <div class="flex items-center gap-1 text-klee-100">
            <ui-icon [icon]="'information-circle'" class="block w-4 h-4"></ui-icon>
            <span class="text-xs">Zusätzliche Leistungen</span>
          </div>
          <h1 class="text-lg font-semibold mb-1">{{ activeService.name }}</h1>
          <ul class="text-sm list-tx">
            <li *ngFor="let listItem of activeService.listItems">{{ listItem }}</li>
          </ul>
        </div>
        <div class="flex items-center justify-start">
          <ui-button
            [label]="'Mehr über ' + activeService.name + ' erfahren'"
            [icon]="activeService.icon"
            [color]="'white'"
            (cclick)="openLink(activeService.link)"
          ></ui-button>
        </div>
      </div>
    </div>
    <ui-button
      [align]="'center'"
      [color]="'white'"
      [icon]="'arrow-long-right'"
      [isOnlyIcon]="true"
      [size]="'sm'"
      class="absolute -right-2 xl:right-8 top-1/2 -translate-y-1/2 z-10"
      (cclick)="nextClick()"
    ></ui-button>
  </div>
</div>
